<template>
  <div class="user-management_container container-fluid">
    <TitleContainer
      v-if="!included"
      :title="title"
    />
    <b-form
      class="user-management_form"
      @submit="onSubmit"
      @reset="onReset"
    >
      <div class="user-management_fields">
        <b-form-group
          label="Scope name"
          label-for="scope-input-name"
          class="col-5 mb-2 pl-0"
        >
          <b-form-input
            id="scope-input-name"
            :value="displayNameValue"
            :disabled="!allResourcesFetched"
            @input="(displayName) => setDisplayName({ displayName })"
            required
          />
        </b-form-group>

        <FireDetails
          class="col-5 mb-4 pl-0"
          title="Description"
        >
          <b-form-textarea
            id="comments"
            :value="descriptionValue"
            placeholder="Enter a description"
            rows="3"
            no-resize
            :disabled="isAppLoading || !allResourcesFetched"
            @input="(description) => setDescription({ description })"
          />
        </FireDetails>

        <div
          v-if="!allResourcesFetched"
          class="w-100 text-center my-3"
        >
          <b-spinner />
        </div>

        <template v-else>
          <template v-for="select in selects">
            <FieldsGroup
              :key="'user-management_select--' + select"
              :title="computedScope[select].title"
            >
              <b-form-group
                :label="'Select ' + select + ' to include'"
                :label-for="'select-included-' + select"
                class="col-5"
                :description="
                  computedScope[select].hasIncorrectTenants
                    ? 'Select the tenant(s) first.'
                    : ''
                "
              >
                <Multiselect
                  :id="'select-included-' + select"
                  :value="computedScope[select].includedValue"
                  :placeholder="'Select ' + select + ' to include'"
                  track-by="id"
                  label="displayName"
                  multiple
                  searchable
                  :options="computedScope[select].options"
                  :show-labels="false"
                  :disabled="
                    isAppLoading ||
                    computedScope[select].allIncludedValue ||
                    computedScope[select].hasIncorrectTenants
                  "
                  @input="
                    (values) => setIncludedValues({ key: select, values })
                  "
                />
              </b-form-group>
              <b-form-group
                v-if="computedScope[select].allIncludedValue"
                :label="'Select ' + select + ' to exclude'"
                :label-for="'select-excluded-' + select"
                class="col-5"
              >
                <Multiselect
                  :id="'select-excluded-' + select"
                  :value="computedScope[select].excludedValue"
                  :placeholder="'Select ' + select + ' to exclude'"
                  track-by="id"
                  label="displayName"
                  multiple
                  searchable
                  :options="computedScope[select].options"
                  :show-labels="false"
                  :disabled="
                    isAppLoading || computedScope[select].hasIncorrectTenants
                  "
                  @input="
                    (values) => setExcludedValues({ key: select, values })
                  "
                />
              </b-form-group>
              <b-form-group
                v-if="computedScope[select].hasAllIncludedScope"
                class="col-12"
              >
                <input
                  :id="'checkbox-included-' + select"
                  :checked="computedScope[select].allIncludedValue"
                  type="checkbox"
                  :disabled="computedScope[select].hasIncorrectTenants"
                  @change="
                    setAllIncludedValue({
                      key: select,
                      allIncludedValue: $event.target.checked,
                    })
                  "
                />
                <label
                  :for="'checkbox-included-' + select"
                  class="ml-2"
                  >{{ 'Include all ' + select }}</label
                >
              </b-form-group>
            </FieldsGroup>
          </template>
        </template>

        <b-row>
          <div :class="included ? 'action-button_container' : 'mt-4 pl-0'">
            <b-button
              type="reset"
              variant="link"
              class="text-danger"
              :class="!included ? 'w-100' : ''"
              >{{ edition ? 'Delete' : 'Reset' }}</b-button
            >
            <SubmitButton
              :disabled="isSubmitDisabled"
              :loading="isAppLoading"
              :class="included ? 'w-auto mb-auto ml-2' : ''"
            />
          </div>
        </b-row>
      </div>
    </b-form>
  </div>
</template>

<script>
  import { mapGetters, mapState, mapActions } from 'vuex'

  import TitleContainer from '@/components/containers/TitleContainer'
  import Multiselect from '@/components/form/Multiselect'
  import FieldsGroup from '@/components/fields-group/FieldsGroup'
  import SubmitButton from '@/components/form/SubmitButton'
  import FireDetails from '@/components/containers/FireDetails'
  import { SCOPE_COLLECTIONS } from '@/constants/UserConstants'
  import { RESOURCES } from '@/service/ResourceService'

  export default {
    name: 'ScopeCreation',

    components: {
      TitleContainer,
      Multiselect,
      FieldsGroup,
      SubmitButton,
      FireDetails,
    },

    props: {
      included: {
        type: Boolean,
        default: false,
      },
      edition: {
        type: Boolean,
        default: false,
      },
      id: {
        type: String,
        default: null,
      },
      description: {
        type: String,
        default: null,
      },
      displayName: {
        type: String,
        default: null,
      },
      details: {
        type: Object,
        default: null,
      },
      applications: {
        type: Object,
        default: null,
      },
      countries: {
        type: Object,
        default: null,
      },
      tenants: {
        type: Object,
        default: null,
      },
      touchpoints: {
        type: Object,
        default: null,
      },
      environments: {
        type: Object,
        default: null,
      },
      customers: {
        type: Object,
        default: null,
      },
      brands: {
        type: Object,
        default: null,
      },
      meta: {
        type: Object,
        default: null,
      },
    },

    data() {
      return {
        allResourcesFetched: false,
        selects: SCOPE_COLLECTIONS,
      }
    },

    computed: {
      ...mapState({
        displayNameValue: (state) => state.scopes.displayName,
        descriptionValue: (state) => state.scopes.description,
      }),

      ...mapGetters(['getUserScopeCollection']),

      ...mapGetters('scopes', ['isValidScope', 'computedScope']),

      title() {
        return this.edition ? 'Scope edition' : 'Scope creation'
      },

      isSubmitDisabled() {
        return !this.isValidScope
      },
    },

    async mounted() {
      await this.loadResources({
        resourceNames: [
          RESOURCES.TOUCHPOINTS,
          RESOURCES.ENVIRONMENTS,
          RESOURCES.TENANTS,
          RESOURCES.BRANDS,
          RESOURCES.COUNTRIES,
          RESOURCES.CUSTOMERS,
          RESOURCES.APPLICATIONS,
        ],
      })

      this.allResourcesFetched = true

      this.initializeState()
    },

    methods: {
      ...mapActions(['loadResources']),

      ...mapActions('scopes', [
        'setScopeId',
        'setDisplayName',
        'setDescription',
        'setMeta',
        'initScopeValues',
        'setAllIncludedValue',
        'setIncludedValues',
        'setExcludedValues',
        'resetValues',
        'save',
      ]),

      initializeState() {
        const isEdition = Boolean(this.id)

        if (isEdition) {
          this.setScopeId({ scopeId: this.id })
          this.setDisplayName({ displayName: this.displayName })
          this.setDescription({ description: this.description })
          this.setMeta({ meta: this.meta })

          SCOPE_COLLECTIONS.forEach((collectionName) => {
            this.initScopeValues({
              key: collectionName,
              scopeValues: this[collectionName],
            })
          })
        } else {
          this.resetValues()
        }
      },

      async onSubmit(event) {
        event.preventDefault()

        try {
          await this.save()

          this.setMessageInformations({
            message:
              `Scope ${this.displayNameValue} has been successfully ` +
              (this.edition ? 'updated!' : 'created!'),
            state: 'success',
          })

          this.$emit('done')
        } catch (error) {
          this.handleErrors({ error })
        } finally {
          this.appLoading(false)
        }
      },

      onReset(event) {
        event.preventDefault()
        if (this.edition && this.id) {
          this.$emit('delete', this.id)
        } else {
          this.resetValues()
        }
      },
    },
  }
</script>
