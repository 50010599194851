<template>
  <div class="collection-board_container">
    <div class="collection-board_actions mb-3">
      <Search
        v-if="hasSearchBar"
        class="search-action_container mr-4"
        placeholder="Filter"
        :value-model.sync="searchText"
        :disabled="loading"
        :debounce="75"
      />

      <SortAlphaButtons
        v-if="isSortable"
        id="collection-board-action_sort-alpha"
        class="action_sort-alpha mr-4"
        desc-only
        @sortAlphaBy="handleSortAlpha(...arguments)"
      />
    </div>

    <p
      v-if="!filteredFormatedData || filteredFormatedData.length === 0"
      class="text-center"
    >
      No items found ...
    </p>

    <div
      v-else
      class="collection-board-items_container row"
    >
      <template v-if="loading">
        <Tile
          v-for="index in 9"
          :key="`item-load-${index}`"
          mock
          :list="false"
          :tags-values="tagsValues"
        />
      </template>
      <BoardCard
        v-for="element in filteredFormatedData"
        v-else
        :key="`collection-element-${element.id}`"
        class="collection-board_card"
        v-bind="element"
        even-highlighted
        :destination="element.destination"
      />
    </div>
  </div>
</template>

<script>
  import { getDocumentsInCollection } from '@/service/FirebaseService'
  import { compareValues } from '@/service/UtilsService'

  import Search from '@/components/menus/Search.vue'
  import Tile from '@/components/tile/Tile.vue'
  import BoardCard from '@/components/board/BoardCard.vue'
  import SortAlphaButtons from '@/components/buttons/SortAlphaButtons.vue'

  export default {
    name: 'CollectionBoard',

    components: {
      Search,
      Tile,
      BoardCard,
      SortAlphaButtons,
    },

    props: {
      // collectionData or collectionName
      collectionData: {
        type: Array,
        required: false,
        default: null,
      },
      collectionName: {
        type: String,
        required: false,
        default: null,
      },
      formatFunction: {
        type: Function,
        default: () => null,
      },
      hasSearchBar: {
        type: Boolean,
        default: true,
      },
      isSortable: {
        type: Boolean,
        default: true,
      },
      tagsValues: {
        type: Array,
        default: () => [],
      },
    },

    data() {
      return {
        loading: false,
        searchText: null,
        sortMode: 'alphaAsc',
        collection: this.collectionData ?? [],
        formatedData: [],
      }
    },

    computed: {
      filteredFormatedData() {
        return this.sortElements(this.setFormatedData(this.collection))?.filter(
          (element) =>
            this.searchText
              ? element?.title?.toLowerCase()?.includes(this.searchText)
              : element,
        )
      },
    },

    watch: {
      async collectionName() {
        await this.initCollectionData()
      },

      collection(newVal) {
        this.formatedData = this.setFormatedData(newVal)
      },

      collectionData() {
        this.collection = this.collectionData ?? []
      },
    },

    async created() {
      if (!this.collectionData) {
        await this.initCollectionData()
      }
    },

    methods: {
      handleSortAlpha(type) {
        this.sortMode = type ?? 'alphaAsc'
      },

      async initCollectionData() {
        this.loading = true
        // If needed add handling with state collections
        this.collection = await getDocumentsInCollection(this.collectionName)
        this.loading = false
      },

      sortElements(elements) {
        if (this.sortMode === null) return elements
        return elements.sort(
          compareValues('title', this.sortMode === 'alphaAsc' ? 'asc' : 'desc'),
        )
      },

      setFormatedData(data) {
        return this.formatFunction
          ? data?.map(this.formatFunction)
          : data?.map((element) => {
              return {
                ...element,
                title: element?.displayName ?? element?.id,
                destination: `/${this.collectionName}/${element?.id}`,
              }
            })
      },
    },
  }
</script>

<style lang="scss">
  .collection-board_actions {
    display: flex;
    padding-right: 5px;
  }

  .search-action_container {
    color: var(--background-font-color);
  }

  .action_sort-alpha {
    background-color: var(--primary);
    padding: 5px 5px 5px 5px;
    align-items: center;
    border-radius: 10%;
  }

  .collection-board-items_container {
    height: 100%;
    width: 100%;
    margin: 20px 0px;
  }

  .collection-board_card {
    &:hover {
      cursor: pointer;
    }
  }
</style>
