<template>
  <MultistepForm
    v-model="formData"
    :step.sync="stepIndex"
    :title="title"
    :total-step-number="totalStepNumber"
    success-message="App version successfully updated!"
    :steps="filterSteps"
    :is-next-disabled="isNextDisabled"
    :is-submit-success="success"
    submit-label="Update"
    @update:step="handleStepIndex"
    @submit="updateAppVersion"
  >
    <template #success>
      <NextSteps />
    </template>
  </MultistepForm>
</template>

<script>
  import MultistepForm from '@/components/multistep-form/MultistepForm'
  import NextSteps from '@/components/apps/forms/NextSteps'
  import { mapState, mapActions } from 'vuex'

  const initialData = {
    appId: null,
    appVersionId: null,
    firehierarchy: null,
    uploadedHierarchy: false,
    firecomponents: null,
    uploadedComponents: [],
    mode: 'json',
    firedefaultcontent: {
      appconfigurations: null,
      appcontent: null,
      appcustomisations: null,
      appresources: null,
    },
    uploadedContent: [],
    prescriptionEnabled: false,
    releaseNotes: null,
    description: null,
    simulationSuccess: false,
  }

  export default {
    name: 'UpdateAppVersion',

    components: {
      MultistepForm,
      NextSteps,
    },

    data() {
      return {
        stepsLabels: [
          'Application Version',
          'Application Version Informations',
          'Application Hierarchy',
          'Application Components',
          'Application Default Content',
          'Update Simulation',
        ],
        success: false,
        stepIndex: 1,
        formData: {},
      }
    },

    computed: {
      ...mapState({
        currentVersion: (state) => state.appversions.currentVersion,
      }),

      title() {
        return 'Update App Version'
      },

      totalStepNumber() {
        return this.formData.mode === 'dynamic' ? 4 : 6
      },

      filterSteps() {
        return this.stepsComponents.filter(
          (_, i) =>
            (this.formData.mode === 'dynamic' && [0, 1, 2, 5].includes(i)) ||
            this.formData.mode === 'json',
        )
      },

      stepsComponents() {
        return [
          'UpdateAppVersion',
          'UpdateAppVersionParams',
          'CreateAppHierarchy',
          'CreateAppComponents',
          'CreateAppDefaultContent',
          'SimulateAppUpdate',
        ].map((component, index) => {
          return {
            id: component,
            label: this.stepsLabels[index],
            component: () => import(`@/components/apps/forms/${component}`),
            step: index + 1,
          }
        })
      },

      isNextDisabled() {
        let isDisabled = true

        switch (this.stepIndex) {
          case 1:
            isDisabled =
              [null, undefined].includes(this.formData?.appVersionId) ||
              [null, undefined].includes(this.formData?.appId)
            break
          case 6:
            isDisabled = this.formData.simulationSuccess === false
            break
          default:
            isDisabled = false
            break
        }

        return isDisabled || this.isAppLoading
      },
    },

    created() {
      if (this.$route.params) {
        const { versionId, appId, simulation } = this.$route.params
        initialData.appId = appId
        initialData.appVersionId = versionId
        if (simulation) {
          this.stepIndex = 4
        }
      }

      this.formData = JSON.parse(JSON.stringify(initialData))
    },

    destroyed() {
      this.unsetCurrentVersion()
    },

    methods: {
      ...mapActions([
        'unsetCurrentVersion',
        'updateVersion',
        'compareAndUpdateRoutes',
        'updateVersionDetails',
      ]),

      handleStepIndex(stepIndex) {
        if (stepIndex === 4 && this.formData.mode === 'dynamic') {
          this.updateVersionDetails({
            versionPath: 'appversions/' + this.formData.appVersionId,
            ...(this.formData.releaseNotes !==
              this.currentVersion?.releaseNotes && {
              releaseNotes: this.formData.releaseNotes,
            }),
            ...(this.formData.description !==
              this.currentVersion?.description && {
              description: this.formData.description,
            }),
            ...(this.formData.prescriptionEnabled !==
              this.currentVersion?.hasPrescription && {
              prescriptionEnabled: this.formData.prescriptionEnabled,
            }),
          })
          this.$router.push({
            name: 'version-mapping',
            params: {
              appVersionId: this.formData.appVersionId,

              fromUpdate: true,
            },
          })
        }
      },

      async updateAppVersion() {
        this.appLoading(true)

        try {
          if (this.formData.mode === 'dynamic') {
            await this.compareAndUpdateRoutes({
              simulate: false,
              appVersionId: this.formData.appVersionId,
            })
          } else {
            await this.updateVersion({
              appVersionId: this.formData.appVersionId,
              ...(this.formData.releaseNotes !==
                this.currentVersion?.releaseNotes && {
                releaseNotes: this.formData.releaseNotes,
              }),
              ...(this.formData.description !==
                this.currentVersion?.description && {
                description: this.formData.description,
              }),
              ...(this.formData.prescriptionEnabled !==
                this.currentVersion?.hasPrescription && {
                prescriptionEnabled: this.formData.prescriptionEnabled,
              }),
              hierarchy: this.formData.firehierarchy,
              components: this.formData.firecomponents,
              collections: this.formData.firedefaultcontent,
              simulate: false,
            })

            this.setMessageInformations({
              message: 'App version sucessfully updated!',
              state: 'success',
            })
          }

          this.success = true
        } catch (error) {
          this.handleErrors({ error })
        } finally {
          this.appLoading(false)
        }
      },
    },
  }
</script>
