<template>
  <BaseContainer
    :reduce-container="reduceContainer"
    :reduced="reduced"
    :title="title"
    v-on="$listeners"
  >
    <slot name="nav" />

    <template v-if="!hideActions">
      <div class="list_actions-area">
        <slot name="top" />
      </div>

      <div class="row list_actions">
        <div class="col-auto list_actions_main">
          <slot name="main-action" />
        </div>
        <div
          v-if="sortByItems !== undefined && sortByItems !== null"
          class="col-4 col-md-4 col-lg-2 text-right"
        >
          <Multiselect
            id="sort-items"
            v-model="sortedBy"
            placeholder="Sort by"
            select-label=""
            track-by="value"
            label="text"
            :options="sortByItems"
            :searchable="false"
            deselect-label=""
            @input="emitSortedBy"
          />
        </div>
        <div class="col-2 col-lg-1 list_actions_display">
          <b-button
            class="button-link-action"
            :disabled="isList"
            variant="link"
            @click="listOnOff()"
          >
            <b-icon icon="list" />
          </b-button>
          <b-button
            class="button-link-action"
            :disabled="!isList"
            variant="link"
            @click="listOnOff()"
          >
            <b-icon icon="grid-fill" />
          </b-button>
        </div>
      </div>
    </template>

    <div
      v-if="$slots.default"
      class="row list_container"
    >
      <slot />
    </div>

    <div
      v-if="isTileList"
      class="row tile-list"
    >
      <template v-if="loading">
        <Tile
          v-for="index in 9"
          :key="index"
          :mock="true"
          :list="isList"
        />
      </template>
      <template v-else-if="sortedItems?.length">
        <Tile
          v-for="(item, index) in sortedItems"
          :key="`tile_' ${item.details.type}_${index}`"
          :data-test="`tile${item.id}`"
          v-bind="item"
          :list="isList"
          :show-flag="showFlag"
          v-on="$listeners"
        />
      </template>
      <div
        v-if="!loading && items.length == 0"
        class="col d-flex justify-content-center align-items-center"
      >
        No results found ...
      </div>
    </div>

    <slot name="bottom" />
  </BaseContainer>
</template>

<script>
  import Tile from '@/components/tile/Tile'
  import Multiselect from '@/components/form/Multiselect'
  import BaseContainer from '@/components/containers/BaseContainer'
  import { compareValues } from '@/service/UtilsService'

  export default {
    components: {
      Tile,
      Multiselect,
      BaseContainer,
    },

    props: {
      title: {
        type: String,
        default: null,
      },
      sortByItems: {
        type: Array,
        default: null,
      },
      isTileList: {
        type: Boolean,
      },
      items: {
        type: Array,
        default: null,
      },
      loading: {
        type: Boolean,
      },
      reduceContainer: {
        type: Boolean,
        default: false,
      },
      reduced: {
        type: Boolean,
        default: false,
      },
      hideActions: {
        type: Boolean,
        default: false,
      },
      showFlag: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        isList: true,
        sortedBy: null,
        showMessage: false,
        showList: true,
      }
    },

    computed: {
      sortedItems() {
        const sortedItems = this.items ?? []
        if (this.sortedBy)
          sortedItems.sort(
            compareValues(this.sortedBy.value.key, this.sortedBy.value.order),
          )
        return sortedItems
      },
      hasNavSlot() {
        return !!this.$slots.nav
      },
      hasTitleSlot() {
        return !!this.$slots.title
      },
    },

    methods: {
      listOnOff() {
        this.isList = !this.isList
      },
      emitSortedBy(value) {
        this.$emit('sortBy', value)
      },
    },
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
  .list_title {
    text-align: left;
  }

  .list_actions-area {
    text-align: left;
    margin-bottom: 40px;
    border: 1px solid var(--primary);
    border-radius: 0.25rem;
    padding: 0.9em;

    &--with-nav {
      border-top: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    &:empty {
      display: none;
    }
  }

  .list_actions {
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
  }

  .list_actions_main {
    margin-right: auto;
    text-align: left;
    display: flex;

    gap: 0.5rem;

    &:empty {
      display: none;
    }
  }

  .button-link-action {
    color: var(--secondary);

    &:hover {
      color: var(--app-background-font-color);
    }
  }

  .list_actions_display {
    min-width: 125px;
    text-align: right;
  }

  .list_container {
    &:empty {
      display: none;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter,
  .fade-leave {
    opacity: 0;
  }
</style>
