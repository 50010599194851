import { getDocumentsInCollection, deleteRef } from '@/service/FirebaseService'
import {
  pluralToSingular,
  firstCharacterToUppercase,
} from '@/utils/StringHelpers'

import QuestionnaireCreation from '@/components/admin/questionnaires/QuestionnaireCreation'
import { mapGetters } from 'vuex'

export const QuestionnairesManagementMixin = {
  props: {
    activeQuestionnairePath: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      collection: [],
      filteredCollection: [],
      modalComponent: QuestionnaireCreation,
      initialized: false,
      loading: true,
    }
  },

  computed: {
    ...mapGetters(['getUserScopeCollection']),
    pathToCollection() {
      return `${this.activeQuestionnairePath}/${this.documentType}`
    },

    formatedSingularDocType() {
      const singular = pluralToSingular(this.documentType)
      return firstCharacterToUppercase(singular)
    },
  },

  async updated() {
    if (!this.initialized && this.activeQuestionnairePath?.length > 0)
      await this.init()
  },

  methods: {
    async init() {
      this.loading = true
      try {
        const applicationsScope = this.getUserScopeCollection('applications')
        this.collection = (
          await getDocumentsInCollection(this.pathToCollection)
        ).filter((doc) => {
          return doc.applications.some((app) => {
            const appId = app.replace('applications/', '')
            return (
              (applicationsScope.included.includes('*') &&
                !applicationsScope.excluded.includes(appId)) ||
              applicationsScope.included.includes(appId)
            )
          })
        })

        this.filteredCollection = await this.setCollectionForList(
          this.collection,
        )
      } catch (error) {
        this.handleErrors({ error })
      } finally {
        this.initialized = true
        this.loading = false
      }
    },

    async deleteDocument(uid) {
      this.loading = true
      try {
        await deleteRef(`${this.pathToCollection}/${uid}`)

        this.$nextTick(() => {
          this.$bvModal.hide('modal-creation')
        })

        this.setMessageInformations({
          message: `${this.formatedSingularDocType} '${uid}' successfully deleted.`,
          state: 'success',
        })
      } catch (error) {
        this.handleErrors({ error })
      } finally {
        await this.init()
        this.loading = false
      }
    },

    async searchByNameOrId(searchKey) {
      const searchInput = searchKey.toLowerCase()
      const collections = await this.setCollectionForList(this.collection)
      this.filteredCollection =
        searchKey?.length > 0
          ? collections.filter(
              (document) =>
                document?.label?.toLowerCase().includes(searchInput) ||
                document?.id?.toLowerCase().includes(searchInput),
            )
          : collections
    },
  },
}
