<template>
  <BaseContainer
    :reduce-container="false"
    title="Create Product API Configuration"
  >
    <template #titleContainer>
      <ProductAPIConfigurationSelectEnvironment
        :show-all-envs="$route.name === 'productapi-configurations-add-form'"
      />
    </template>

    <b-button
      id="backBtn"
      class="ml-5 back-button"
      @click="backToPreviousPage"
    >
      &lt; Back
    </b-button>
    <router-view />
  </BaseContainer>
</template>

<script>
  import BaseContainer from '@/components/containers/BaseContainer'
  import ProductAPIConfigurationSelectEnvironment from '@/components/product-api-config/ProductAPIConfigurationSelectEnvironment.vue'
  import { mapActions } from 'vuex'
  export default {
    components: {
      BaseContainer,
      ProductAPIConfigurationSelectEnvironment,
    },

    watch: {
      $route: {
        handler() {
          const { customerId, countryCode } = this.$route.params
          this.setCountryCustomerId({ customerId, countryCode })
        },
        immediate: true,
      },
    },
    methods: {
      ...mapActions('productApiSettings', ['setCountryCustomerId']),
      backToPreviousPage() {
        this.$router.back()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .back-button {
    position: relative;
    z-index: 2;
  }
</style>
