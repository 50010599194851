import Functions from '@/firebase/functions'
import { SCOPE_COLLECTIONS } from '@/constants/UserConstants'
import { getDocumentReference } from '../../service/FirebaseService'
import { Role, ScopeDataSchema, Scope, Group, Tenant } from '@/models'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { RootState } from '@/store'

export type ScopesRolesGroupsState = {
  scopes: ScopeDataSchema[]
  roles: Role[]
  groups: Group[]
}

const initialState: ScopesRolesGroupsState = {
  scopes: [],
  roles: [],
  groups: [],
}

const state = { ...initialState }

const mutations: MutationTree<ScopesRolesGroupsState> = {
  SET_SCOPES: (state, payload: ScopeDataSchema[]) => {
    state.scopes = payload
  },
  SET_ROLES: (state, payload: Role[]) => {
    state.roles = payload
  },
  SET_GROUPS: (state, payload: Group[]) => {
    state.groups = payload
  },
  ADD_SCOPE: (state, payload: ScopeDataSchema) => {
    state.scopes.push(payload)
  },
  ADD_ROLE: (state, payload: Role) => {
    state.roles.push(payload)
  },
  ADD_GROUP: (state, payload: Group) => {
    state.groups.push(payload)
  },
  REMOVE_SCOPE_FROM_ID: (state, id: string) => {
    const index = state.scopes.findIndex((scope) => scope?.id === id)
    if (index >= 0) state.scopes.splice(index, 1)
  },
  REMOVE_ROLE_FROM_ID: (state, id: string) => {
    const index = state.roles?.findIndex((role) => role?.id === id)
    if (index >= 0) state.roles?.splice(index, 1)
  },
  REMOVE_GROUP_FROM_ID: (state, id: string) => {
    const index = state.groups?.findIndex((scope) => scope?.id === id)
    if (index >= 0) state.groups?.splice(index, 1)
  },
}

const actions: ActionTree<ScopesRolesGroupsState, RootState> = {
  async initScopesGroups({ dispatch }) {
    await dispatch('getRoles')
    await dispatch('getScopes')
    await dispatch('getGroups')
  },

  async getRoles({ commit }) {
    const filteredRoles = (await Functions.getRolesList()).filteredRoles
    commit('SET_ROLES', filteredRoles)
  },

  async getScopes({ commit }) {
    const filteredScopes = (await Functions.getScopesList()).filteredScopes
    const formatedFilteredScopes = await Promise.all(
      filteredScopes?.map(async (scope) => {
        const formatedScope = { ...scope }

        await Promise.all(
          SCOPE_COLLECTIONS.map(async (collectionKey) => {
            const scopeValue = scope[collectionKey]

            formatedScope[collectionKey] = {
              included: scopeValue.included?.includes('*')
                ? ['*']
                : (await Promise.all(
                    scopeValue.included?.map(
                      async (id) =>
                        await getDocumentReference(`${collectionKey}/${id}`),
                    ),
                  )) ?? [],
              excluded:
                (await Promise.all(
                  scopeValue.excluded?.map(
                    async (id) =>
                      await getDocumentReference(`${collectionKey}/${id}`),
                  ),
                )) ?? [],
            }
          }),
        )

        return formatedScope
      }),
    )
    commit('SET_SCOPES', formatedFilteredScopes)
  },

  async getGroups({ commit }) {
    const filteredGroups = (await Functions.getGroupsList()).filteredGroups
    commit('SET_GROUPS', filteredGroups)
  },

  unbindScopesRolesGroups({ commit }) {
    commit('SET_ROLES', [])
    commit('SET_SCOPES', [])
    commit('SET_GROUPS', [])
  },
}

const getters: GetterTree<ScopesRolesGroupsState, RootState> = {
  // Don't remove state and getters, rootState has to be the third parameter
  isIncludedInUserScope:
    (state, getters, rootState) =>
    (scope: ScopeDataSchema): boolean => {
      const hasInvalidKey = Object.entries(rootState?.user?.scope)?.some(
        ([key, value]: [string, Scope]) => {
          const scopeIncludedValue = scope[key]?.included?.includes('*')
            ? ['*']
            : scope[key]?.included?.map((element) => element.id)
          const scopeExcludedValue =
            scope[key]?.excluded?.map((element) => element?.id) ?? []

          // if user scope includes all values
          if (value?.included?.includes('*')) {
            // if the scope includes all values then if one value excluded in the user's scope isn't excluded in the scope the scope is invalid
            return scopeIncludedValue?.includes('*')
              ? value?.excluded?.some(
                  (userExcl) => !scopeExcludedValue?.includes(userExcl),
                )
              : // else the values included in the scope shouldn't be excluded in the user's scope
                scopeIncludedValue?.some((scopeIncl) =>
                  value?.excluded?.includes(scopeIncl),
                )
          }

          // else if the scope includes all values we can't know if the user's could match so it's invalid OR if there is a value in the scope that isn't in the user's it's invalid
          return (
            scopeIncludedValue?.includes('*') ||
            scopeIncludedValue?.some(
              (scopeIncl) => !value?.included?.includes(scopeIncl),
            )
          )
        },
      )

      // if no invalid entry has been found the scope is included in the user's one
      return !hasInvalidKey
    },

  getScopeTenants:
    (state, getters, rootState) =>
    (scope: ScopeDataSchema): Tenant[] => {
      switch (true) {
        case !scope || !scope.tenants:
          return []
        case scope.tenants.included.includes('*') &&
          (!scope.tenants.excluded || scope.tenants.excluded.length === 0):
          return rootState.tenants
        case scope.tenants.included.includes('*'):
          return rootState.tenants.filter(
            (tenant) => !scope.tenants.excluded?.includes(tenant.id),
          )
        default:
          return rootState.tenants.filter(
            (tenant) =>
              scope.tenants.included.includes(tenant.id) &&
              !scope.tenants.excluded?.includes(tenant.id),
          )
      }
    },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
