
  import { mapActions, mapGetters, mapState } from 'vuex'
  import BaseCollectionTableManagement from '@/components/admin/BaseCollectionTableManagement.vue'
  import UserCreation from '@/components/admin/user/UserCreation.vue'
  import UserProfilePicture from '@/components/admin/user/profile/UserProfilePicture.vue'
  import { DEFAULT_LOCALE } from '@/constants/App.constants'
  import { FiretableFieldType } from '@/constants/FiretableFieldType.enum'
  import Functions from '@/firebase/functions'
  import { UserManagementMixin } from '@/mixins/UserManagementMixin.js'
  import { FireTableField } from '@/models'
  import { RESOURCES } from '@/service/ResourceService'

  export default {
    name: 'UserManagement',

    components: {
      BaseCollectionTableManagement,
      UserProfilePicture,
    },

    mixins: [UserManagementMixin],

    data() {
      return {
        tableId: 'user',
        usersLists: [],
        showWaitingUsers: false,
        modalComponent: UserCreation,
      }
    },

    computed: {
      ...mapState({
        fetchedResources: (state) => state.fetchedResources,
      }),

      ...mapGetters({
        canUserDo: 'canUserDo',
        getScopeTenants: 'scopesrolesgroups/getScopeTenants',
        getTableColumns: 'getTableColumns',
        hasModifaceTenant: 'hasModifaceTenant',
      }),

      fields(): FireTableField[] {
        return [
          {
            key: 'profilePic',
            label: '',
            sortable: false,
            type: FiretableFieldType.Custom,
            noExport: true,
            text: 'Profile picture',
            selectable: true,
          },
          {
            key: 'fullname',
            label: 'NAME',
            text: 'Name',
            sortable: true,
            type: FiretableFieldType.Custom,
            sortDirection: 'asc',
            selectable: true,
            noExport: true,
          },
          {
            key: 'firstname',
            label: 'FIRST NAME',
            hidden: true,
          },
          {
            key: 'name',
            label: 'LAST NAME',
            hidden: true,
          },
          {
            key: 'email',
            label: 'EMAIL',
            sortable: true,
            sortDirection: 'asc',
            type: FiretableFieldType.Custom,
            selectable: true,
          },
          {
            key: 'role',
            label: 'ROLE',
            sortable: true,
            sortDirection: 'asc',
            type: FiretableFieldType.ObjectSummary,
            sortByFormatted: this.objectSortFormatter,
            selectable: true,
          },
          {
            key: 'group',
            label: 'GROUP',
            sortable: true,
            sortDirection: 'asc',
            type: FiretableFieldType.ObjectSummary,
            sortByFormatted: this.objectSortFormatter,
            selectable: true,
          },
          {
            key: 'scope',
            label: 'SCOPE',
            sortable: true,
            sortDirection: 'asc',
            type: FiretableFieldType.ObjectSummary,
            sortByFormatted: this.objectSortFormatter,
            selectable: true,
          },
          {
            key: 'team',
            label: 'TEAM',
            sortable: true,
            sortDirection: 'asc',
            selectable: true,
          },
          {
            key: 'tenants',
            label: 'TENANT',
            sortable: true,
            sortDirection: 'asc',
            type: FiretableFieldType.ObjectArraySummary,
            selectable: true,
          },
          {
            key: 'lastConnexion',
            label: 'Last connection',
            sortable: true,
            sortDirection: 'desc',
            formatter: this.dateFormatter,
            text: 'Last connection date',
            selectable: true,
          },
          {
            key: 'retailersTermsOfUseAccepted',
            label: 'RETAILER T&Cs ACCEPTED',
            sortable: true,
            sortDirection: 'desc',
            type: FiretableFieldType.Boolean,
            text: 'T&Cs Accepted',
            selectable: true,
            undefinedMessage: 'N/A',
          },
          {
            key: 'actions',
            label: 'Actions',
            menuActions: [
              {
                label: 'Edit user',
                icon: 'person-lines-fill',
                variant: 'success',
                onClick: 'edit',
              },
              {
                label: 'Delete',
                variant: 'danger',
                icon: 'trash',
                divider: true,
                onClick: 'delete',
              },
            ],
            selectable: false,
          },
        ]
      },

      usersNotInCollection() {
        return this.usersLists.usersNotInCollection?.map((user) => {
          return {
            id: user.id,
            name: 'New waiting user',
            fullname: '',
            email: user.email,
            _rowVariant: 'warning',
            menuActions: [
              {
                label: 'Create user',
                icon: 'person-plus-fill',
                variant: 'success',
                onClick: 'create',
              },
              {
                label: 'Delete user',
                variant: 'danger',
                icon: 'trash',
                divider: true,
                onClick: 'delete',
              },
            ],
            notInCollection: true,
          }
        })
      },

      usersCollectionList() {
        return this.usersLists.usersCollectionList?.map(this.formatUser) ?? []
      },

      filteredUsersCollectionList() {
        return (
          (this.showWaitingUsers
            ? this.usersNotInCollection?.concat(this.usersCollectionList)
            : this.usersCollectionList) ?? []
        )
      },

      filteredFields() {
        const columns = this.getTableColumns(this.tableId)
        return columns
          ? this.fields.filter(
              (field) => columns.includes(field.key) || !field.selectable,
            )
          : this.fields
      },
    },

    methods: {
      ...mapActions(['loadResources']),

      async init() {
        this.loading = true
        try {
          if (!this.fetchedResources['tenants']) {
            await this.loadResources({ resourceNames: [RESOURCES.TENANTS] })
          }
          this.usersLists = await Functions.getUsersList(
            this.canUserDo('user-management-waiting-users'),
          )
        } catch (error) {
          this.handleErrors({ error })
        } finally {
          this.loading = false
        }
      },

      userCreated(user) {
        if (!user) return
        const userIndex = this.usersCollectionList?.findIndex(
          (u) => u.id === user?.id,
        )

        // if user edited
        if (userIndex >= 0) {
          this.usersLists.usersCollectionList?.splice(userIndex, 1, user)
          // if user created
        } else {
          // if waiting user, remove from waiting list
          this.usersLists.usersNotInCollection =
            this.usersLists.usersNotInCollection?.filter(
              (u) => u.id !== user?.id,
            )

          this.usersLists.usersCollectionList?.push(user)
        }
      },

      // Remove user in array
      deleteUserInArray(uid: string) {
        ;['usersNotInCollection', 'usersCollectionList'].forEach((array) => {
          this.usersLists[array] = this.usersLists[array]?.filter(
            (u) => u.id !== uid,
          )
        })
      },

      async deleteUser(uid: string) {
        try {
          this.loading = true
          const deletion = await Functions.deleteUser(uid)

          if (!deletion.success) {
            throw new Error(deletion.message)
          }
          this.deleteUserInArray(uid)

          this.$nextTick(() => {
            this.$bvModal.hide('modal-creation')
          })

          this.setMessageInformations({
            message: deletion?.message,
            state: 'success',
          })
        } catch (error) {
          this.handleErrors({ error })
        } finally {
          this.loading = false
        }
      },

      async deleteUsers(users: { id: string }[]) {
        this.loading = true
        await Promise.all(
          users.map(async (user) => {
            const deletion = await Functions.deleteUser(user.id)
            if (!deletion.success) {
              throw new Error(deletion.message)
            }
            this.deleteUserInArray(user.id)
          }),
        )
          .then(() => {
            this.setMessageInformations({
              message: 'Users deleted successfully',
              state: 'success',
            })
          })
          .catch((error) => {
            this.handleErrors({ error })
          })
          .finally(() => {
            this.loading = false
          })
      },

      formatUser(user) {
        const groupData = this.securityGroups.find(
          (group) => group.id === user.group,
        )

        return {
          ...user,
          ...(user.lastConnexion && {
            lastConnexion: new Date(user.lastConnexion?._seconds * 1000),
          }),
          ...(groupData && { group: groupData }),
          ...(groupData && !!groupData.role && { role: groupData.role }),
          ...(groupData &&
            !!groupData.scope && {
              scope: groupData.scope,
              tenants: this.getScopeTenants(groupData.scope),
            }),
          fullname: `${user.firstname ?? ''} ${user.name}`.trim(),
        }
      },

      objectSortFormatter(object) {
        return object.displayName || object.id
      },

      dateFormatter(date: Date | undefined | null) {
        return typeof date === 'object' && ![null, undefined].includes(date)
          ? date.toLocaleString(DEFAULT_LOCALE)
          : date
      },

      customFilterFunction(rawItem, filter) {
        const lowerCasedFilter = filter.toLowerCase()
        const keysMatchByValue = ['fullname', 'email', 'team']
        const keysMatchByDisplayName = ['role', 'group', 'scope']

        const checkFunction = (value) =>
          value && value.toLowerCase().includes(lowerCasedFilter)

        for (const key of keysMatchByValue) {
          if (checkFunction(rawItem[key])) {
            return true
          }
        }

        for (const key of keysMatchByDisplayName) {
          if (
            checkFunction(rawItem[key]?.id) ||
            checkFunction(rawItem[key]?.displayName)
          ) {
            return true
          }
        }

        return (
          rawItem.tenants &&
          rawItem.tenants.some((tenant) => {
            return checkFunction(tenant.id) || checkFunction(tenant.displayName)
          })
        )
      },
    },
  }
