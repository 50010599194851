import {
  FIELDS_SETTINGS,
  FIELDS_WITH_OPTIONS,
  OPTIONS_TEMPLATE,
} from '@/constants/FieldsMappingConstants'

export function getFieldMapping(type = null) {
  const settings = getFieldSettings(type)
  let options = null

  if (type && FIELDS_WITH_OPTIONS.includes(type)) {
    options = [OPTIONS_TEMPLATE]
  }

  return {
    id: '',
    label: '',
    description: '',
    settings,
    ...(options && { options }),
    ...(type === 'repeater' && { fields: [] }),
    ...(type === 'events' && { eventFields: [], stepFields: [] }),
  }
}

export function getFieldSettingsOptions(type) {
  let options
  if (type && FIELDS_WITH_OPTIONS.includes(type)) {
    options = [{ ...OPTIONS_TEMPLATE }]
  }

  return {
    fields: type === 'repeater' ? [] : undefined,
    ...(type === 'events' && { eventFields: [], stepFields: [] }),
    options,
    settings: getFieldSettings(type),
  }
}

export function getFieldSettings(type = null) {
  if (!type) {
    return { type: '' }
  }
  const settings = FIELDS_SETTINGS.find((settings) => settings.type === type)

  return settings
}
