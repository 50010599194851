import { firstCharacterToUppercase } from './StringHelpers'

/**
 * Generate an id for show data fields.
 * exemple: modal-duplicate-app-default -> showModalDuplicateAppDefault
 *
 * @param {String} modalId - The id of the modal.
 * @return {String} Returns the formated variable name.
 */
export function generateShowDataModalId(modalId: string): string | undefined {
  if (!modalId) return
  return (
    'show' +
    modalId
      .split('-')
      .map((word) => firstCharacterToUppercase(word))
      .join('')
  )
}

/**
 * Generate data values for a component with modals to not load them when this is unneccessary.
 *
 * @param {Array} modalsIds - An array of modals ids.
 * @return {Object} An object ready to be injected into data of component.
 */
export function generateShowDataValues(
  modalsIds: string[],
): Record<string, boolean> {
  if (!Array.isArray(modalsIds) || !modalsIds) return

  return modalsIds.reduce((accumulator, modalId) => {
    const showModalId = generateShowDataModalId(modalId)
    return {
      ...accumulator,
      [showModalId]: false,
    }
  }, {})
}
