<template>
  <div
    :class="
      loading ? 'auth-component auth-component--loading' : 'auth-component'
    "
  >
    <div class="row">
      <b-form
        class="col-xs-12 col-md-4 auth-component_form"
        @submit="onSubmitCallback"
      >
        <b-alert
          show
          variant="primary"
        >
          <h4 class="alert-heading">NEW</h4>
          To secure your account, if you're login with your email and password,
          you will be asked for your phone number to verify your identity.
        </b-alert>
        <h1>
          <span>{{ title }}</span>
        </h1>
        <slot />
      </b-form>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AuthContainer',

    props: {
      title: {
        type: String,
        required: true,
      },
      onSubmitCallback: {
        type: Function,
        default: (event) => {
          event.preventDefault()
        },
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

<style lang="scss" scoped>
  .auth-component {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh;

    & > .row:first-child {
      width: 100%;
    }

    &:after {
      content: '';
      position: fixed;
      width: 0;
      height: 0;
      left: 0;
      top: 0;
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.2);
    }

    &--loading {
      &:after {
        width: 100vw;
        height: 100vh;
        opacity: 1;
        transition: opacity 0.2s ease-in-out;
      }
    }
  }

  .auth-component_form {
    padding: 20px;
    margin: auto auto 0;
    max-width: 430px;

    h1 {
      position: relative;
      margin: auto;
      font-size: 2.9rem;
      margin-bottom: 70px;
      text-align: center;
      text-transform: uppercase;
      color: var(--primary);
      letter-spacing: 3px;

      span {
        position: relative;

        &:after {
          content: '';
          background-color: var(--app-background-color);
          position: absolute;
          width: calc(100% + 40px);
          height: 100%;
          top: 0;
          left: calc(50%);
          transform: translateX(-50%);
          z-index: -1;
        }
      }

      &:after {
        content: '';
        position: absolute;
        height: 4px;
        width: 100%;
        left: 0;
        top: calc(50% + 2px);
        transform: translateY(-50%);
        background-color: var(--primary);
        z-index: -2;
      }
    }
  }
</style>
