<template>
  <Multiselect
    v-bind="$props"
    class="multiselect"
    :class="{
      'multiselect--valid': state === true,
      'multiselect--invalid': state === false,
    }"
    v-on="$listeners"
    @tag="addTag"
  >
    <template #clear>
      <CopyButton
        v-if="taggable"
        :text-to-copy="textToCopy"
        class="multiselect_copy"
      />
    </template>
    <template #tag="{ option }">
      <span
        class="multiselect__tag"
        :style="{ backgroundColor: option ? option.color : null }"
      >
        <span v-text="getOptionLabel(option)" />
        <i
          tabindex="1"
          class="multiselect__tag-icon"
          @keypress.enter.prevent="removeElement(option)"
          @mousedown.prevent="removeElement(option)"
        />
      </span>
    </template>
  </Multiselect>
</template>

<script>
  import Multiselect from 'vue-multiselect'
  import CopyButton from '@/components/buttons/CopyButton'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'MultiSelect',

    components: {
      Multiselect,
      CopyButton,
    },

    extends: Multiselect,

    props: {
      allowUnknow: Boolean,
      state: {
        type: Boolean,
        default: null,
      },
    },

    computed: {
      textToCopy() {
        if (!Array.isArray(this.value)) {
          return ''
        }
        const value =
          this.label || this.customLabel
            ? this.value.map((item) =>
                this.label ? item[this.label] : this.customLabel(item),
              )
            : this.value

        return value.join(',')
      },
    },

    methods: {
      addTag(value) {
        if (this.taggable && this.multiple) {
          let valueActual = this.value
          value.split(',').map((item) => {
            const itemTrim = item.trim()
            let valueToAdd = this.options.find((option) => {
              return (
                (this.customLabel && this.customLabel(option) === itemTrim) ||
                (this.label && option[this.label] === itemTrim)
              )
            })

            if (!valueToAdd && this.allowUnknow) {
              valueToAdd = itemTrim
            }

            if (valueToAdd) {
              if (Array.isArray(valueActual)) {
                if (
                  (!this.max || valueActual.length < this.max) &&
                  !valueActual.includes(valueToAdd)
                ) {
                  valueActual.push(valueToAdd)
                }
              } else {
                valueActual = [valueToAdd]
              }
            }
          })
          this.$emit('input', valueActual)
        }
      },
    },
  })
</script>

<style lang="scss" scoped>
  .multiselect {
    position: relative;

    &--valid {
      --border-color: var(--success);
    }

    &--invalid {
      --border-color: var(--danger);
    }

    &_copy {
      position: absolute;
      right: 0;
      margin: 5px;
      z-index: 1;
    }
  }
</style>

<style lang="scss">
  .multiselect.multiselect--disabled .multiselect__select {
    background: none;
  }
</style>
