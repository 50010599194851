import { decode } from 'html-entities'
import mapValues from 'lodash/fp/mapValues'

export const unsanitize = (value: string) => decode(value)
export const unsanitizeString = (value: any) =>
  typeof value === 'string' ? unsanitize(value) : value
export const unsanitizeArray = (values: any[]) => values.map(unsanitize)
export const unsanitizeObject = mapValues<
  Record<string, any>,
  Record<string, any>
>(unsanitizeString)
