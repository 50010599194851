<template>
  <b-alert
    :show="messageShow"
    :dismissible="dismissible"
    class="message-component"
    :class="messageClasses"
    fade
    @dismiss-count-down="countDownChanged"
  >
    <b-icon
      class="notification-icon"
      :icon="icon"
      font-scale="1.5"
    />

    {{ message }}

    <div
      v-if="hasCTA"
      class="message-component_cta"
    >
      <slot name="cta" />
    </div>

    <template
      v-if="dismissible"
      #dismiss
    >
      <b-icon
        class="message-component_close-button"
        icon="x"
      />
    </template>

    <template v-if="progress || progress === 0">
      <p class="message-component_percent">
        {{ new Intl.NumberFormat('en', { style: 'percent' }).format(progress) }}
      </p>
      <progress
        class="message-component_progress"
        :value="progress"
        :max="1"
      />
    </template>
    <progress
      v-else-if="!keepShowing"
      class="message-component_progress"
      :max="dismissSecs"
      :value="dismissCountDown"
    />
  </b-alert>
</template>

<script>
  export default {
    name: 'Message',

    props: {
      message: {
        type: String,
        default: null,
      },
      state: {
        type: String,
        default: null,
        validator: (value) => {
          return ['success', 'error', 'warning', 'info'].includes(value)
        },
      },
      floating: {
        type: Boolean,
        default: true,
      },
      dismissible: {
        type: Boolean,
        default: true,
      },
      keepShowing: {
        type: Boolean,
        default: false,
      },
      dismissSecs: {
        type: Number,
        default: 10,
      },
      isLastNotif: {
        type: Boolean,
        default: false,
      },
      progress: {
        type: Number,
        validator: (value) => {
          return value >= 0 && value <= 1
        },
        default: null,
      },
    },

    data() {
      return {
        dismissCountDown: 0,
      }
    },

    computed: {
      messageClasses() {
        return {
          [`message-component--${this.state}`]: this.state,
          'message-component--floating': this.floating,
        }
      },

      messageShow() {
        return (
          (this.isLastNotif || (this.keepShowing && !this.dismissible)) &&
          (this.keepShowing ? this.keepShowing : this.dismissCountDown)
        )
      },

      hasCTA() {
        return !!this.$slots.cta
      },

      icon() {
        switch (this.state) {
          case 'warning':
            return 'exclamation-octagon'
          case 'success':
            return 'check-circle'
          case 'error':
            return 'x-octagon'
          default:
            return 'info-circle'
        }
      },
    },

    watch: {
      message: {
        handler(newMessage, oldMessage) {
          if (newMessage !== oldMessage && newMessage?.length > 0)
            this.dismissCountDown = this.dismissSecs
        },
      },
    },

    created() {
      this.dismissCountDown = this.dismissSecs
    },

    methods: {
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },

      dismissed() {
        if (this.isLastNotif) this.$emit('dismissed')
      },
    },
  }
</script>

<style lang="scss" scoped>
  .message-component {
    position: relative;
    display: flex;
    padding: 0.75rem 3.75rem 0.75rem 1.25rem;
    align-items: center;
    border-radius: 0;
    border: none;
    color: white;
    font-size: 1rem;
    background: var(--info);
    margin-bottom: 0;

    &_percent {
      position: absolute;
      right: 0;
      bottom: 0;
      font-size: 0.8rem;
      font-weight: 500;
      margin: 0.25rem 0.5rem;
    }

    &--floating {
      position: fixed;
      top: 50px;
      right: 10px;
      z-index: $zindex-message; // z-index modal 1040
      min-height: 75px;
      box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.6);
      border-radius: 5px;
      margin-right: auto;
      min-width: 30vw;
      max-width: 60vw;
    }

    &--error {
      background: var(--danger);
    }

    &--success {
      background: var(--success);
    }

    &--warning {
      background: var(--warning);
      color: var(--warning-font-color);

      .notification-icon {
        color: var(--warning-font-color);
      }
    }
  }

  .notification-icon {
    margin: 3px;
    margin-right: 20px;
  }

  .message-component_cta {
    margin-left: auto;
  }

  progress.message-component_progress {
    $colorProgress: rgba(255, 255, 255, 0.5);

    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;

    color: $colorProgress;

    &::-webkit-progress-value {
      background: $colorProgress;
    }

    &::-moz-progress-value {
      background-color: $colorProgress;
    }
  }
</style>

<style lang="scss">
  .message-component.alert-dismissible .close {
    padding: 0.2rem 0.5rem;
  }
</style>
