var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-management_container container-fluid"},[(!_vm.included)?_c('TitleContainer',{attrs:{"title":_vm.title}}):_vm._e(),_c('b-form',{staticClass:"user-management_form",on:{"submit":_vm.onSubmit,"reset":_vm.onReset}},[_c('div',{staticClass:"user-management_fields"},[_c('b-form-group',{staticClass:"col-5 mb-2 pl-0",attrs:{"label":"Scope name","label-for":"scope-input-name"}},[_c('b-form-input',{attrs:{"id":"scope-input-name","value":_vm.displayNameValue,"disabled":!_vm.allResourcesFetched,"required":""},on:{"input":(displayName) => _vm.setDisplayName({ displayName })}})],1),_c('FireDetails',{staticClass:"col-5 mb-4 pl-0",attrs:{"title":"Description"}},[_c('b-form-textarea',{attrs:{"id":"comments","value":_vm.descriptionValue,"placeholder":"Enter a description","rows":"3","no-resize":"","disabled":_vm.isAppLoading || !_vm.allResourcesFetched},on:{"input":(description) => _vm.setDescription({ description })}})],1),(!_vm.allResourcesFetched)?_c('div',{staticClass:"w-100 text-center my-3"},[_c('b-spinner')],1):[_vm._l((_vm.selects),function(select){return [_c('FieldsGroup',{key:'user-management_select--' + select,attrs:{"title":_vm.computedScope[select].title}},[_c('b-form-group',{staticClass:"col-5",attrs:{"label":'Select ' + select + ' to include',"label-for":'select-included-' + select,"description":_vm.computedScope[select].hasIncorrectTenants
                  ? 'Select the tenant(s) first.'
                  : ''}},[_c('Multiselect',{attrs:{"id":'select-included-' + select,"value":_vm.computedScope[select].includedValue,"placeholder":'Select ' + select + ' to include',"track-by":"id","label":"displayName","multiple":"","searchable":"","options":_vm.computedScope[select].options,"show-labels":false,"disabled":_vm.isAppLoading ||
                  _vm.computedScope[select].allIncludedValue ||
                  _vm.computedScope[select].hasIncorrectTenants},on:{"input":(values) => _vm.setIncludedValues({ key: select, values })}})],1),(_vm.computedScope[select].allIncludedValue)?_c('b-form-group',{staticClass:"col-5",attrs:{"label":'Select ' + select + ' to exclude',"label-for":'select-excluded-' + select}},[_c('Multiselect',{attrs:{"id":'select-excluded-' + select,"value":_vm.computedScope[select].excludedValue,"placeholder":'Select ' + select + ' to exclude',"track-by":"id","label":"displayName","multiple":"","searchable":"","options":_vm.computedScope[select].options,"show-labels":false,"disabled":_vm.isAppLoading || _vm.computedScope[select].hasIncorrectTenants},on:{"input":(values) => _vm.setExcludedValues({ key: select, values })}})],1):_vm._e(),(_vm.computedScope[select].hasAllIncludedScope)?_c('b-form-group',{staticClass:"col-12"},[_c('input',{attrs:{"id":'checkbox-included-' + select,"type":"checkbox","disabled":_vm.computedScope[select].hasIncorrectTenants},domProps:{"checked":_vm.computedScope[select].allIncludedValue},on:{"change":function($event){return _vm.setAllIncludedValue({
                    key: select,
                    allIncludedValue: $event.target.checked,
                  })}}}),_c('label',{staticClass:"ml-2",attrs:{"for":'checkbox-included-' + select}},[_vm._v(_vm._s('Include all ' + select))])]):_vm._e()],1)]})],_c('b-row',[_c('div',{class:_vm.included ? 'action-button_container' : 'mt-4 pl-0'},[_c('b-button',{staticClass:"text-danger",class:!_vm.included ? 'w-100' : '',attrs:{"type":"reset","variant":"link"}},[_vm._v(_vm._s(_vm.edition ? 'Delete' : 'Reset'))]),_c('SubmitButton',{class:_vm.included ? 'w-auto mb-auto ml-2' : '',attrs:{"disabled":_vm.isSubmitDisabled,"loading":_vm.isAppLoading}})],1)])],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }