<template>
  <span
    :style="{
      backgroundColor: color,
      cursor: showPointer ? 'pointer' : 'auto',
    }"
    class="tag"
    @click="$emit('click')"
  >
    {{ text }}
    <span
      v-if="closeButton"
      class="tag_close-button"
      @click="$emit('delete-tag')"
    >
      <b-icon icon="x" />
    </span>
  </span>
</template>

<script>
  export default {
    props: {
      text: {
        type: [String, Number],
        default: '',
      },
      color: {
        type: String,
        default: '#30475e',
      },
      closeButton: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      showPointer: false,
    }),

    created: function () {
      this.showPointer = Boolean(this.$listeners && this.$listeners.click)
    },
  }
</script>

<style lang="scss" scoped>
  .tag {
    display: inline-block;
    border-radius: 10px;
    padding: 2px 8px;
    margin: 5px;
    color: white;
  }

  .tag_close-button {
    margin-left: 5px;
    cursor: pointer;
  }
</style>
