import * as FirebaseService from './FirebaseService'
import db from '@/firebase/firestore'
import { arraysHasSameValues } from '@/service/UtilsService'
import store from '@/store'
import { Profile } from '@/types/Profile'
import { Section } from '@/types/Section'

type Flag = {
  id: string
  label: string
}

/**
 * Function to update the flags of a section.
 *
 * @param {Object} selectedSection - The section tu update.
 * @param {Array} flags - An array of flags.
 */
export function updateFlagsForSection(
  selectedSection: Section,
  flags: Flag[],
): void {
  if (!selectedSection) throw new Error('Missing section.')
  if (flags === undefined || flags === null) throw new Error('Missing flags.')
  const formatedFlags = flags.map((flag) => flag.id)

  // Not updating if the array contains the same values.
  if (!arraysHasSameValues(formatedFlags, selectedSection.flags)) {
    FirebaseService.updateRef(selectedSection.ref, { flags: formatedFlags })
  }

  // Deleting the field if empty.
  if (
    Object.prototype.hasOwnProperty.call(selectedSection, 'flags') &&
    formatedFlags.length === 0
  ) {
    FirebaseService.deleteField(selectedSection.ref, 'flags')
  }
}

/**
 * Format a profile for the tile Vue.
 *
 * @param {Array} profile - The profile to reformat.
 * @param {String} appId - The current app id.
 * @return {Array} The formated profile.
 */
export function normalizeProfile(profile: Profile, appId: string) {
  if (!profile) throw new Error('profile parameter is empty')
  if (!appId) throw new Error('Missing current appRoute id')
  const details = {}
  const criteriaTypeList = [
    'inclusiveCriterias',
    'inclusiveStrictCriterias',
    'inclusiveRootItemCriterias',
    'exclusiveCriterias',
  ]
  const link = {
    name: 'profile-creation-edition',
    params: { app_id: appId, id: profile.id },
  }
  const quickActionButton = [
    {
      label: 'Edit',
      link,
      icon: 'pencil-square',
    },
    {
      label: 'Duplicate',
      variant: 'success',
      icon: 'file-earmark-plus',
      onClick: ['duplicateProfile', profile.ref],
    },
    {
      label: 'Delete',
      variant: 'danger',
      icon: 'trash',
      divider: true,
      onClick: ['deleteProfile', profile.ref],
    },
  ]

  criteriaTypeList.forEach((criteriaType) => {
    if (profile[criteriaType] && profile[criteriaType].length > 0) {
      details[criteriaType] = normalizeCriterias(profile[criteriaType])
    }
  })

  return {
    action: 'Edit',
    active: profile.meta?.active,
    docPath: profile.ref,
    details: {
      ...details,
      hitCount: profile.hit,
      hitPercent: 0,
      ...(!!profile.isDefault && { default: 'Default' }),
    },
    tagsValues: [
      'inclusiveCriterias',
      'inclusiveStrictCriterias',
      'inclusiveRootItemCriterias',
      'exclusiveCriterias',
    ],
    link,
    label: profile.label ? profile.label : profile.id,
    quickActionButton,
    meta: profile.meta,
  }
}

/**
 * Reorder sections depending on the step and the new section's weight.
 *
 * @param {Object} docPath - The document path.
 * @param {Object} newSection - The section.
 * @return {Promise}
 */
export function orderSections(
  docPath: string,
  newSection: Section,
): Promise<string> {
  return new Promise((resolve, reject) => {
    db.doc(docPath)
      .collection('sections')
      .get()
      .then((sectionsCollection) => {
        const sections = {}
        // We do not add back a section that already exists in the same step.
        const sectionExists = sectionsCollection.docs.some(
          (doc) =>
            doc.data().item === newSection.item &&
            doc.data().step === newSection.step &&
            !Object.prototype.hasOwnProperty.call(newSection, 'ref'),
        )
        if (sectionExists)
          return reject(new Error('The section already exists.'))

        // Creating an object with array with steps.
        sectionsCollection.docs.forEach((sec) => {
          if (
            !Object.prototype.hasOwnProperty.call(sections, sec.data().step)
          ) {
            sections[sec.data().step] = []
          }
          sections[sec.data().step].push({
            ref: sec.ref,
            ...sec.data(),
          })
        })

        // If the new section has a new step we add it before adding the section to the object.
        if (!Object.prototype.hasOwnProperty.call(sections, newSection.step))
          sections[newSection.step] = []
        sections[newSection.step].push(newSection)

        // We sort each array in the object.
        Object.keys(sections).forEach((step) => {
          sections[step].sort((a, b) => {
            if (a.weight > b.weight) return 1
            if (a.weight < b.weight) return -1
            return 0
          })

          // And we give each section it's index as a weight.
          sections[step].forEach((sec, index) => {
            sec.weight = index
            if (
              Object.prototype.hasOwnProperty.call(sec, 'item') &&
              Object.prototype.hasOwnProperty.call(newSection, 'item') &&
              sec.item !== newSection.item
            ) {
              FirebaseService.updateRef(sec.ref.path, {
                weight: sec.weight,
              })
            }
          })
        })
        resolve('Order of sections succesfully updated.')
      })
  })
}

type NormalizedCriteria = {
  id: string
  label: string
  color?: string
  order?: string
}

/**
 * Format the array sent by firebase to a vue multiselect friendly format.
 *
 * @param {Array} criteria - The array with all the criterias.
 * @return {String} The formated string.
 */
export function normalizeCriterias(
  criteria: string[],
): NormalizedCriteria[] | Record<string, any> {
  if (criteria === undefined || criteria === null) {
    return {}
  }

  const criterias = criteria
    .map((crit) => {
      const criteriaObject = store.getters.getCriteriaById(crit)
      if (criteriaObject !== undefined && criteriaObject !== null) {
        return {
          id: criteriaObject.id,
          label: criteriaObject.label,
          ...(criteriaObject.color && { color: criteriaObject.color }),
          ...(criteriaObject.order && { order: criteriaObject.order }),
        }
      }
    })
    .filter(Boolean)

  criterias.sort((a, b) => {
    if (a.order > b.order) return 1
    if (a.order < b.order) return -1
    return 0
  })

  return criterias
}

/**
 * Format the array sent by firebase to a vue multiselect friendly format.
 *
 * @param {Array} flags - An array of flags
 * @return {Array} - An array of flags but with objects.
 */
export function normalizeFlags(flags: string[]): Flag[] | Record<string, any> {
  if (flags === undefined || flags === null) {
    return {}
  }

  return flags.map((flag) => {
    return {
      label: flag,
      id: flag,
    }
  })
}
