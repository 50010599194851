<template>
  <transition name="fade">
    <div class="fire_container">
      <div class="fire">
        <div
          v-if="lit"
          class="flames"
        >
          <div
            v-for="index in 4"
            :key="'flame-' + index"
            class="flame"
          />
        </div>

        <div
          v-else
          class="ashes"
        >
          <div
            v-for="index in 6"
            :key="'smoke' + index"
            class="smoke"
          />
        </div>

        <div :class="logsClasses" />
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'FirecampAnimation',

    props: {
      lit: {
        type: Boolean,
        default: true,
      },
    },

    computed: {
      logsClasses() {
        return ['logs', ...(!this.lit ? ['logs--burnt'] : [])]
          .filter(Boolean)
          .join(' ')
      },
    },
  }
</script>

<style lang="scss" scoped>
  $firecamp-animation-black: #111217;
  $firecamp-animation-brownl: #70392f;
  $firecamp-animation-brownd: #612e25;
  $firecamp-animation-burnt-brownl: #201614;
  $firecamp-animation-burnt-brownd: #110b0a;
  $firecamp-animation-yellow: #ffdc01;
  $firecamp-animation-orange: #fdac01;
  $firecamp-animation-red: #f73b01;
  $firecamp-animation-grayl: #838180;
  $firecamp-animation-grayd: #2e2e2e;
  $firecamp-animation-time: 1.5s;
  $firecamp-ashes-animation-time: 4s;

  .fire_container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 375px;
  }

  .fire {
    position: relative;
    width: 250px;
    height: 190px;
    z-index: 1001;

    .flames {
      position: absolute;
      bottom: 40%;
      left: 50%;
      width: 140px;
      height: 140px;
      transform: translateX(-50%) rotate(45deg);

      .flame {
        position: absolute;
        background-color: $firecamp-animation-yellow;
        border-radius: 1vw;

        &:nth-child(2n + 1) {
          animation: flameodd $firecamp-animation-time ease-in infinite;
        }

        &:nth-child(2n) {
          animation: flameeven $firecamp-animation-time ease-in infinite;
        }

        &:nth-child(1) {
          animation-delay: 0s;
        }

        &:nth-child(2) {
          animation-delay: calc($firecamp-animation-time/4);
        }

        &:nth-child(3) {
          animation-delay: calc($firecamp-animation-time/4) * 2;
        }

        &:nth-child(4) {
          animation-delay: calc($firecamp-animation-time/4) * 3;
        }
      }
    }

    .ashes {
      position: absolute;
      bottom: 40%;
      left: 50%;
      width: 60px;
      height: 60px;
      transform: translateX(-50%) rotate(45deg);

      .smoke {
        position: absolute;

        border-radius: 1vw;

        &:nth-child(2n + 1) {
          animation: smokeodd $firecamp-ashes-animation-time ease-in infinite;
        }

        &:nth-child(2n) {
          animation: smokeeven $firecamp-ashes-animation-time ease-in infinite;
        }

        &:nth-child(1) {
          animation-delay: 0s;
        }

        &:nth-child(2) {
          animation-delay: calc($firecamp-ashes-animation-time / 4);
        }

        &:nth-child(3) {
          animation-delay: calc($firecamp-ashes-animation-time / 3);
        }

        &:nth-child(4) {
          animation-delay: calc($firecamp-ashes-animation-time / 2);
        }

        &:nth-child(5) {
          animation-delay: calc($firecamp-ashes-animation-time / 1.5);
        }

        &:nth-child(6) {
          animation-delay: calc($firecamp-ashes-animation-time / 1.2);
        }
      }
    }

    .logs {
      position: absolute;
      bottom: 25%;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 20%;

      &:before,
      &:after {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(15deg);
        height: 100%;
        width: 100%;
        border-radius: 10px;
        background-color: $firecamp-animation-brownl;
      }

      &:before {
        transform: translate(-50%, -50%) rotate(-15deg);
        background-color: $firecamp-animation-brownd;
      }
    }

    .logs--burnt {
      &:before,
      &:after {
        background-color: $firecamp-animation-burnt-brownl;
      }

      &:before {
        background-color: $firecamp-animation-burnt-brownd;
      }
    }
  }

  @keyframes flameodd {
    0%,
    100% {
      width: 0%;
      height: 0%;
    }
    25% {
      width: 100%;
      height: 100%;
    }
    0% {
      background-color: $firecamp-animation-yellow;
      z-index: 1000000;
    }
    40% {
      background-color: $firecamp-animation-orange;
      z-index: 1000000;
    }
    100% {
      background-color: $firecamp-animation-red;
      z-index: -10;
    }
    0% {
      right: 0%;
      bottom: 0%;
    }
    25% {
      right: 1%;
      bottom: 2%;
    }
    100% {
      right: 150%;
      bottom: 170%;
    }
  }

  @keyframes flameeven {
    0%,
    100% {
      width: 0%;
      height: 0%;
    }
    25% {
      width: 100%;
      height: 100%;
    }
    0% {
      background-color: $firecamp-animation-yellow;
      z-index: 1000000;
    }
    40% {
      background-color: $firecamp-animation-orange;
      z-index: 1000000;
    }
    100% {
      background-color: $firecamp-animation-red;
      z-index: -10;
    }
    0% {
      right: 0%;
      bottom: 0%;
    }
    25% {
      right: 2%;
      bottom: 1%;
    }
    100% {
      right: 170%;
      bottom: 150%;
    }
  }

  @keyframes smokeodd {
    0%,
    100% {
      width: 0%;
      height: 0%;
    }
    50% {
      width: 80%;
      height: 80%;
    }
    0% {
      background-color: $firecamp-animation-grayd;
      z-index: 1000000;
    }
    40% {
      background-color: $firecamp-animation-grayd;
      z-index: 1000000;
    }
    100% {
      background-color: $firecamp-animation-grayl;
      z-index: -10;
    }
    0% {
      right: 0%;
      bottom: 0%;
    }
    25% {
      right: 10%;
      bottom: 2%;
    }
    100% {
      right: 200%;
      bottom: 170%;
    }
  }

  @keyframes smokeeven {
    0%,
    100% {
      width: 0%;
      height: 0%;
    }
    50% {
      width: 80%;
      height: 80%;
    }
    0% {
      background-color: $firecamp-animation-grayl;
      z-index: 1000000;
    }
    40% {
      background-color: $firecamp-animation-grayl;
      z-index: 1000000;
    }
    100% {
      background-color: $firecamp-animation-grayd;
      z-index: -10;
    }
    0% {
      right: 0%;
      bottom: 0%;
    }
    25% {
      right: 2%;
      bottom: 10%;
    }
    100% {
      right: 170%;
      bottom: 200%;
    }
  }
</style>
