import set from 'lodash/fp/set'
import isUndefined from 'lodash/fp/isUndefined'
import keyBy from 'lodash/fp/keyBy'
import compact from 'lodash/fp/compact'
import get from 'lodash/fp/get'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { RootState } from '@/store'
import { SCOPE_COLLECTIONS } from '@/constants/UserConstants'
import {
  Application,
  Brand,
  Country,
  Customer,
  Env,
  Scope,
  Tenant,
  Touchpoint,
} from '@/models'
import {
  addDocToCollection,
  database,
  deleteRef,
  getNewServerTimestamp,
} from '@/service/FirebaseService'

type ScopeValues = {
  included: (string | { id: string })[]
  excluded?: { id: string }[]
}

type ScopeConfigValue<T> = {
  includedValue: T[]
  allIncludedValue: boolean
  excludedValue: T[]
}

const defaultEditionScopeValues = {
  includedValue: [],
  allIncludedValue: false,
  excludedValue: [],
}

export type ScopeConfigValues = {
  applications: ScopeConfigValue<Application>
  countries: ScopeConfigValue<Country>
  tenants: ScopeConfigValue<Tenant>
  customers: ScopeConfigValue<Customer>
  brands: ScopeConfigValue<Brand>
  environments: ScopeConfigValue<Env>
  touchpoints: ScopeConfigValue<Touchpoint>
}

export type ScopeState = {
  id: string | null
  displayName: string
  description: string
  meta: Record<string, any> | null
  scopeConfig: ScopeConfigValues
}

export type ScopeConfigValuesKey = keyof ScopeConfigValues

const initialState = {
  id: null,
  displayName: '',
  description: '',
  meta: null,
  scopeConfig: {
    applications: defaultEditionScopeValues,
    countries: defaultEditionScopeValues,
    tenants: defaultEditionScopeValues,
    customers: defaultEditionScopeValues,
    brands: defaultEditionScopeValues,
    environments: defaultEditionScopeValues,
    touchpoints: defaultEditionScopeValues,
  },
}

const getScopeId = (displayName: string) => {
  return displayName
    .trim()
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f!@#$%^&*]/g, '')
    .replace(/ /g, '-')
}

const createIsCompatibleWithSelectedTenants = (
  config: ScopeConfigValue<Tenant>,
) => {
  const { allIncludedValue, includedValue, excludedValue } = config

  const includedValueById = keyBy('id')(includedValue)
  const excludedValueById = keyBy('id')(excludedValue)

  return (tenantId) => {
      return allIncludedValue
        ? !excludedValueById[tenantId]
        : includedValueById[tenantId]
  }
}

const hasAllIncludedScope = (scope: Scope) => {
  const { included, excluded } = scope
  return included?.includes('*') && excluded?.length === 0
}

const getFormattedScope = <T extends { id: string }>(
  field: string,
  values: ScopeConfigValue<T>,
) => {
  const { allIncludedValue, excludedValue, includedValue } = values

  const mapFirebaseIds = ({ id }: { id: string }) =>
    database.doc(`${field}/${id}`)

  return {
    included: allIncludedValue
      ? ['*']
      : includedValue.map(mapFirebaseIds) || [],
    ...(excludedValue?.length > 0 && {
      excluded: excludedValue.map(mapFirebaseIds),
    }),
  }
}

const state: ScopeState = { ...initialState }

const mutations: MutationTree<ScopeState> = {
  SET_SCOPE_ID: (state, scopeId: string | null) => {
    state.id = scopeId
  },

  SET_DISPLAY_NAME: (state, displayName: string) => {
    state.displayName = displayName
  },

  SET_DESCRIPTION: (state, description: string) => {
    state.description = description
  },

  SET_META: (state, meta: Record<string, any> | null) => {
    state.meta = meta
  },

  SET_SCOPE_VALUES: <T>(
    state,
    {
      key,
      values,
    }: { key: ScopeConfigValuesKey; values: Partial<ScopeConfigValue<T>> },
  ) => {
    const { allIncludedValue, includedValue, excludedValue } = values

    if (!isUndefined(allIncludedValue))
      state.scopeConfig = set(
        `${key}.allIncludedValue`,
        allIncludedValue,
        state.scopeConfig,
      )
    if (!isUndefined(includedValue))
      state.scopeConfig = set(
        `${key}.includedValue`,
        includedValue,
        state.scopeConfig,
      )
    if (!isUndefined(excludedValue))
      state.scopeConfig = set(
        `${key}.excludedValue`,
        excludedValue,
        state.scopeConfig,
      )
  },

  RESET_VALUES: (state) => {
    state.id = null
    state.displayName = ''
    state.description = ''
    state.meta = null

    state.scopeConfig = {
      applications: defaultEditionScopeValues,
      countries: defaultEditionScopeValues,
      tenants: defaultEditionScopeValues,
      customers: defaultEditionScopeValues,
      brands: defaultEditionScopeValues,
      environments: defaultEditionScopeValues,
      touchpoints: defaultEditionScopeValues,
    }
  },
}

const getStateKey = (key: ScopeConfigValuesKey) =>
  key === 'environments' ? 'envs' : key

const actions: ActionTree<ScopeState, RootState> = {
  setScopeId({ commit }, { scopeId }: { scopeId: string | null }) {
    commit('SET_SCOPE_ID', scopeId)
  },

  setDisplayName({ commit }, { displayName }: { displayName: string }) {
    commit('SET_DISPLAY_NAME', displayName)
  },

  setDescription({ commit }, { description }: { description: string }) {
    commit('SET_DESCRIPTION', description)
  },

  setMeta({ commit }, { meta }: { meta: Record<string, any> | null }) {
    commit('SET_META', meta)
  },

  initScopeValues(
    { commit, rootState },
    {
      key,
      scopeValues,
    }: {
      key: ScopeConfigValuesKey
      scopeValues: ScopeValues | null | undefined
    },
  ) {
    if (!scopeValues) return

    const { included, excluded } = scopeValues

    const allIncludedValue = included && included[0] === '*'

    const elements = rootState[getStateKey(key)]
    const elementsById = keyBy(get('id'))(elements)

    commit('SET_SCOPE_VALUES', {
      key,
      values: {
        allIncludedValue,
        includedValue: allIncludedValue
          ? []
          : compact(
              (included as { id: string }[]).map((i) => elementsById[i.id]),
            ),
        excludedValue: compact(excluded.map((e) => elementsById[e.id])),
      },
    })
  },

  setAllIncludedValue(
    { commit },
    {
      key,
      allIncludedValue,
    }: { key: ScopeConfigValuesKey; allIncludedValue: boolean },
  ) {
    commit('SET_SCOPE_VALUES', {
      key,
      values: {
        allIncludedValue,
        includedValue: [],
        excludedValue: [],
      },
    })
  },

  async setIncludedValues<T>(
    { commit, dispatch },
    { key, values }: { key: ScopeConfigValuesKey; values: T },
  ) {
    commit('SET_SCOPE_VALUES', {
      key,
      values: {
        includedValue: values,
      },
    })

    if (key === 'tenants') {
      await dispatch('cleanCustomers')
      await dispatch('cleanBrands')
    }
  },

  async setExcludedValues<T>(
    { commit, dispatch },
    { key, values }: { key: ScopeConfigValuesKey; values: T },
  ) {
    commit('SET_SCOPE_VALUES', {
      key,
      values: {
        excludedValue: values,
      },
    })

    if (key === 'tenants') {
      await dispatch('cleanCustomers')
      await dispatch('cleanBrands')
    }
  },

  cleanCustomers({ commit }) {
    const isCompatibleWithSelectedTenants =
      createIsCompatibleWithSelectedTenants(state.scopeConfig.tenants)
    const { allIncludedValue, excludedValue, includedValue } =
      state.scopeConfig.customers

    if (!allIncludedValue) {
      commit('SET_SCOPE_VALUES', {
        key: 'customers',
        values: {
          includedValue: includedValue.filter((customer) =>
            isCompatibleWithSelectedTenants(customer.tenant.id),
          ),
        },
      })
    } else {
      commit('SET_SCOPE_VALUES', {
        key: 'customers',
        values: {
          excludedValue: excludedValue.filter((customer) =>
            isCompatibleWithSelectedTenants(customer.tenant.id),
          ),
        },
      })
    }
  },

  cleanBrands({ commit }) {
    const isCompatibleWithSelectedTenants =
      createIsCompatibleWithSelectedTenants(state.scopeConfig.tenants)
    const { allIncludedValue, excludedValue, includedValue } =
    state.scopeConfig.brands
    
    if (!allIncludedValue) {
      commit('SET_SCOPE_VALUES', {
        key: 'brands',
        values: {
          includedValue: includedValue.filter((brand) =>
          {
            return brand.tenants.some(tenant => isCompatibleWithSelectedTenants(tenant.id))
          },
          ),
        },
      })
    } else {
      commit('SET_SCOPE_VALUES', {
        key: 'brands',
        values: {
          excludedValue: excludedValue.filter((customer) =>
            isCompatibleWithSelectedTenants(customer.tenants),
          ),
        },
      })
    }
  },

  resetValues({ commit }) {
    commit('RESET_VALUES')
  },

  async save({ state, getters }) {
    if (!getters.isValidScope) return

    const isEdition = Boolean(state.id)
    const id = state.id || getScopeId(state.displayName)
    const docPath = `securityscopes/${id}`

    const meta = state.meta
    if (meta) {
      meta.version =
        typeof meta.version === 'string'
          ? Number(meta.version) + 1
          : meta.version + 1
      meta.updatedAt = getNewServerTimestamp
    }

    const formattedScopeValues = SCOPE_COLLECTIONS.reduce(
      (formatted, scope) => {
        return {
          ...formatted,
          [scope]: getFormattedScope(scope, state.scopeConfig[scope]),
        }
      },
      {},
    )

    const formattedScope = {
      displayName: state.displayName,
      ...(state.description && {
        description: state.description,
      }),
      ...formattedScopeValues,
    }

    if (isEdition) {
      // Due firestore rules limit not update, remove and recreate (https://jira.e-loreal.com/browse/MF-23308)
      await deleteRef(docPath)
    }

    await addDocToCollection(null, 'securityscopes', formattedScope, null, id)
  },
}

const getters: GetterTree<ScopeState, RootState> = {
  isValidScope: (state, getters) => {
    const isValidScopeValue = <T>(
      scopeValues: ScopeConfigValue<T>,
      collectionName: string,
    ): boolean => {
      if (!scopeValues) return false

      const { allIncludedValue, excludedValue, includedValue } = scopeValues

      if (!allIncludedValue) return includedValue.length > 0
      return (
        excludedValue.length <
        getters.computedScope[collectionName].options.length
      )
    }

    return (
      Boolean(state.displayName) &&
      SCOPE_COLLECTIONS.every((collectionName) =>
        isValidScopeValue(state.scopeConfig[collectionName], collectionName),
      )
    )
  },

  computedScope: (state, _getters, rootState, rootGetters) => {
    const tenantsState = state.scopeConfig.tenants
    const { allIncludedValue, includedValue } = tenantsState

    const { getUserScopeCollection } = rootGetters

    const atLeastOneTenant = allIncludedValue || includedValue.length > 0
    const isCompatibleWithSelectedTenants =
      createIsCompatibleWithSelectedTenants(tenantsState)

    return {
      applications: {
        hasIncorrectTenants: false,
        options: rootState.applications,
        title: 'Applications',
        hasAllIncludedScope: hasAllIncludedScope(
          getUserScopeCollection('applications'),
        ),
        ...state.scopeConfig.applications,
      },
      countries: {
        hasIncorrectTenants: false,
        options: rootState.countries,
        title: 'Countries',
        hasAllIncludedScope: hasAllIncludedScope(
          getUserScopeCollection('countries'),
        ),
        ...state.scopeConfig.countries,
      },
      tenants: {
        hasIncorrectTenants: false,
        options: rootState.tenants,
        title: 'Tenants',
        hasAllIncludedScope: hasAllIncludedScope(
          getUserScopeCollection('tenants'),
        ),
        ...state.scopeConfig.tenants,
      },
      customers: {
        hasIncorrectTenants: !atLeastOneTenant,
        options: rootState.customers.filter((customer) =>
          isCompatibleWithSelectedTenants(customer?.tenant?.id),
        ),
        title: 'Customers',
        hasAllIncludedScope: hasAllIncludedScope(
          getUserScopeCollection('customers'),
        ),
        ...state.scopeConfig.customers,
      },
      brands: {
        hasIncorrectTenants: !atLeastOneTenant,
        options: rootState.brands.filter((brand) =>
          (brand?.tenants || []).some((tenant) =>
            isCompatibleWithSelectedTenants(tenant.id),
          ),
        ),
        title: 'Brands',
        hasAllIncludedScope: hasAllIncludedScope(
          getUserScopeCollection('brands'),
        ),
        ...state.scopeConfig.brands,
      },
      environments: {
        hasIncorrectTenants: false,
        options: rootState.envs,
        title: 'Environments',
        hasAllIncludedScope: hasAllIncludedScope(
          getUserScopeCollection('environments'),
        ),
        ...state.scopeConfig.environments,
      },
      touchpoints: {
        hasIncorrectTenants: false,
        options: rootState.touchpoints,
        title: 'Touchpoints',
        hasAllIncludedScope: hasAllIncludedScope(
          getUserScopeCollection('touchpoints'),
        ),
        ...state.scopeConfig.touchpoints,
      },
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
