<template>
  <aside class="tree-navigation_container">
    <div class="tree-navigation_sticky-container">
      <h2 class="navigation-title">Navigation</h2>

      <nav class="tree-navigation-items_container">
        <vuescroll
          :ops="scrollbarOps"
          class="tree-navigation_scrollbar"
          @handle-resize="handleResize"
        >
          <ul class="tree-navigation_ul">
            <li
              v-if="!!currentParent"
              @click="handleGoBack"
            >
              <span
                class="navigation-item_content-container navigation-item_content-container--action-back"
              >
                <b-icon
                  class="mr-2"
                  icon="arrow-bar-left"
                />
                Return
              </span>
            </li>
            <li
              v-for="item in currentTree.children"
              :key="item.id"
            >
              <div
                :class="`navigation-item_content-container${
                  currentItem === item.id
                    ? ' navigation-item_content-container--active'
                    : ''
                }`"
                @click="
                  currentItem !== item.id ? handleNavigationItemClick(item) : ''
                "
              >
                <span class="navigation-item">
                  {{ item.label ? item.label : 'Unnamed documentation page' }}
                </span>
              </div>
              <section
                v-if="currentItem === item.id && item.children.length > 0"
              >
                <div
                  v-for="child in item.children"
                  :key="`${item.id}-child-${child.id}`"
                  :class="`navigation-item_content-container navigation-item_content-container--child`"
                  @click="handleNavigateChild(child, item)"
                >
                  <span class="navigation-item">
                    {{
                      child.label ? child.label : 'Unnamed documentation page'
                    }}
                  </span>
                </div>
              </section>
            </li>
            <li v-if="[null, undefined].includes(items) || items.length === 0">
              <p class="no-items_message">No items found ...</p>
            </li>
          </ul>
        </vuescroll>
      </nav>
    </div>
  </aside>
</template>

<script>
  import vuescroll from 'vuescroll'
  import { compareValues } from '@/service/UtilsService'

  export default {
    name: 'TreeNavigation',

    components: {
      vuescroll,
    },

    props: {
      title: {
        type: String,
        default: null,
      },
      items: {
        type: Array,
        required: true,
      },
      overrideCurrentItem: {
        type: String,
        default: null,
      },
      sortable: {
        type: Boolean,
        default: true,
      },
    },

    data() {
      return {
        parentIndexesList: [],
        hierarchy: {
          children: [],
        },
        currentTree: {
          children: [],
        },
        currentParent: null,
        currentItem: null,
        scrollbarOps: {
          vuescroll: {},
          scrollPanel: {
            scrollingX: false,
            speed: 600,
            easing: 'easeInOutQuart',
          },
          rail: {
            background: '#ffffff',
            opacity: 0.2,
            gutterOfSide: '2px',
          },
          bar: {
            onlyShowBarOnScroll: false,
            keepShow: true,
          },
        },
        isBarVisible: false,
      }
    },

    computed: {
      stickyContainerClasses() {
        return {
          'tree-navigation_sticky-container': true,
          'tree-navigation_sticky-container--no-title': this.title === null,
        }
      },
    },

    watch: {
      async items() {
        await this.initTree()
        this.initCurrentItem()
      },

      overrideCurrentItem(newVal) {
        this.currentItem = newVal
        this.currentParent =
          this.items?.find((element) => element?.id === newVal)?.parent ?? null
      },
    },

    async created() {
      await this.initTree()
      this.initCurrentItem()
    },

    methods: {
      async initTree() {
        if (this.items) this.buildHierarchy()
      },

      initCurrentItem() {
        this.currentItem = this.overrideCurrentItem
        const item = this.items?.find(
          (element) => element?.id === this.currentItem,
        )
        if (item?.parent) {
          this.currentTree = this.findItemInHierarchy(item)
          this.currentParent = item?.parent
        }
      },

      buildHierarchy(filter = null) {
        const tree = this.buildSubTree('root', filter)
        this.hierarchy.children = tree
        this.currentTree.children = tree
        this.currentParent = null
      },

      buildSubTree(parentId, filter, parentIndexes = []) {
        let childs = this.items?.filter((item) =>
          parentId === 'root'
            ? this.isFiltered(item, filter) &&
              ([null, undefined].includes(item.parent) ||
                item.parent?.length < 1)
            : item.parent?.id === parentId && this.isFiltered(item, filter),
        )

        const orderedItems = childs?.sort(compareValues('weight'))

        childs = orderedItems?.map((item, index) => {
          const newParentIndexes = [...parentIndexes, index]
          this.parentIndexesList.push({ id: item.id, parentIndexes })
          return {
            id: item.id,
            label: item?.displayName ?? item.id,
            parent: item?.parent ?? undefined,
            children: this.buildSubTree(item.id, filter, newParentIndexes),
          }
        })

        return childs
      },

      isFiltered(item, filter) {
        return filter
          ? item?.label?.toLowerCase().includes(filter.toLowerCase()) ||
              item?.id?.toLowerCase().includes(filter.toLowerCase())
          : true
      },

      handleNavigationItemClick(item) {
        this.$emit('navigationChanged', item)
      },

      handleNavigateChild(item, parent) {
        this.currentTree = {
          children: parent?.children,
        }

        this.currentParent = this.items?.find(
          (element) => parent?.id === element?.id,
        )
        this.currentItem = item?.id

        this.$emit('navigationChanged', item)
      },

      handleGoBack() {
        this.currentTree = this.findItemInHierarchy(this.currentParent)
        this.handleNavigationItemClick(this.currentParent)

        this.currentParent =
          this.items?.find((element) => element?.id === this.currentItem)
            ?.parent ?? null
      },

      findItemInHierarchy(item) {
        if (!item?.parent?.id) return this.hierarchy
        const itemsParentParentIndexes = (this.parentIndexesList || []).find(
          (element) => element?.id === item?.id,
        ).parentIndexes

        let subTree = this.hierarchy

        itemsParentParentIndexes?.forEach((index) => {
          subTree = subTree.children[index]
        })

        return subTree
      },

      handleResize(v /*, h, event */) {
        this.isBarVisible = v.barSize > 0
      },
    },
  }
</script>

<style lang="scss">
  .tree-navigation_container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 270px;
    min-height: 100vh;
    background-color: var(--primary);
    color: var(--primary-font-color);
    margin-left: -15px;
    margin-bottom: -50px;
    border-right: 1px solid var(--low-opacity-border);
    border-top: 1px solid var(--low-opacity-border);
  }

  .tree-navigation_sticky-container {
    position: sticky;
    height: calc(100vh - 100px);
    top: 0;

    &--no-title {
      height: calc(100vh - 55px);
    }
  }

  .navigation-title {
    padding: 10px 10px 10px 10px;
    opacity: 0.9;
    font-size: 1.25rem;
    background-color: var(--background-highlight);
    border-bottom: 1px solid var(--low-opacity-border);
    margin: 0;
  }

  .tree-navigation_scrollbar {
    .__bar-is-vertical {
      background: var(--primary-highlight) !important;
    }
  }

  .tree-navigation-items_container {
    overflow-x: hidden;
    overflow-y: auto;
    height: 99%;
  }

  .tree-navigation_ul {
    list-style: none;
    padding: 0;
    margin: 0;
    border-bottom: 1px solid var(--low-opacity-border);
  }

  .navigation-item_content-container {
    display: flex;
    align-items: center;
    padding: 10px 5px 10px 20px;
    background-color: transparent;
    transition: background-color 0.2s ease-in-out;
    padding-right: 10px;
    font-weight: 350;
    user-select: none;

    &--active {
      background-color: var(--secondary);
      color: var(--secondary-font-color);
      font-weight: 500;
    }

    &--action-back {
      background-color: rgba($color: #fff, $alpha: 0.3);
      border-bottom: 1px solid var(--low-opacity-border);
      border-top: 1px solid var(--low-opacity-border);
      margin-bottom: 3px;
    }

    &--child {
      padding-left: 40px;
      background-color: rgba($color: #ffffff, $alpha: 0.1);
      color: var(--primary-font-color);
      transition: background-color 0.2s ease-in-out;
      border-bottom: 1px solid var(--low-opacity-border);
      margin-bottom: 0;
      line-height: inherit;

      &:hover {
        cursor: pointer;
        background-color: rgba($color: #ffffff, $alpha: 0.2);
      }
    }

    &:not(.navigation-item_content-container--active):not(
        .navigation-item_content-container--child
      ):hover {
      cursor: pointer;
      background-color: var(--primary-highlight);
    }
  }

  .no-items_message {
    margin: 0;
    padding: 10px;
  }
</style>
