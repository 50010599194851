import firebase from 'firebase/compat/app'

export const bmdmValuesConverter = {
  fromFirestore: (
    snapshot: firebase.firestore.DocumentSnapshot,
    options?: firebase.firestore.SnapshotOptions,
  ) => {
    const data = snapshot.data(options)
    const bmdmId = data.id
    delete data.id
    const alternatives = {}
    if (data.alternatives) {
      for (const alternative of data.alternatives) {
        const [id, value] = alternative.split('=')
        alternatives[id] = value
      }
    }
    return {
      bmdmId,
      id: snapshot.id,
      ...data,
      alternatives,
    }
  },
}
