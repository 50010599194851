<template>
  <div class="p-4">
    <CollapseContainer
      title="Product Catalog Configuration"
      variant="secondary"
      border
      :open="true"
    >
      <ProductListNewEmptyCatalog v-if="!isCatalogConfigured" />
      <div
        v-else
        class="p-2"
      >
        <ul>
          <li>Catalog's name: {{ catalog.name }}</li>
          <li v-if="!!withBrandAPI">
            Brand API URL: {{ catalog.brandAPiUrl }}
          </li>
          <li v-if="!!withBrandAPI">Template: {{ catalog.template }}</li>
        </ul>
      </div>
    </CollapseContainer>
    <section
      v-if="isCatalogConfigured"
      class="mt-3"
    >
      <FireButtonGroup
        :options="options"
        :hide-buttons="false"
      />
    </section>
  </div>
</template>

<script>
  import Functions from '@/firebase/functions'

  export default {
    components: {
      ProductListNewEmptyCatalog: () => import('./ProductListNewEmptyCatalog'),
      CollapseContainer: () =>
        import('@/components/containers/CollapseContainer'),
      FireButtonGroup: () => import('@/components/buttons/FireButtonGroup'),
    },
    name: 'ProductListNew',

    async mounted() {
      const res = await Functions.getRouteCatalogSettings(
        this.$route?.params?.app_id,
      )
      this.withBrandAPI = res.response?.catalogConnector?.template !== 'Local'
      if (res.response?.catalogConnector) {
        const { name, apiUri, template } = res.response.catalogConnector
        this.catalog.name = name
        this.catalog.brandAPiUrl = apiUri
        this.catalog.template = template
        this.isCatalogConfigured = true
      } else {
        this.isCatalogConfigured = false
      }
    },

    data() {
      return {
        isCatalogConfigured: false,
        withBrandAPI: false,
        catalog: {
          name: '',
          brandAPiUrl: '',
          template: '',
        },

        options: [
          { routeName: 'prescription-product-list-new', text: 'PRODUCTS' },
          { routeName: 'prescription-product-list-new-looks', text: 'LOOKS' },
          {
            routeName: 'prescription-product-list-new-contents',
            text: 'CONTENTS',
          },
        ],
      }
    },
  }
</script>

<style>
  ul {
    margin-top: 1rem;
  }
</style>
