var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aside',{staticClass:"page-side-menu_container"},[_c('div',{class:_vm.stickyContainerClasses},[_c('div',{staticClass:"actions-section_container"},[_c('Search',{staticClass:"search-action_container",attrs:{"value-model":_vm.searchText,"debounce":0},on:{"update:valueModel":function($event){_vm.searchText=$event},"update:value-model":function($event){_vm.searchText=$event}}}),_c('SortAlphaButtons',{staticClass:"sort-actions_container ml-2",on:{"sortAlphaBy":function($event){return _vm.handleSortAlpha(...arguments)}}})],1),(_vm.title && !_vm.areItemsSubmenus)?_c('h5',{staticClass:"items-list-title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c('nav',{staticClass:"page-side-menu-items_container"},[_c('vuescroll',{staticClass:"page-side-menu_scrollbar",attrs:{"ops":_vm.scrollbarOps},on:{"handle-resize":_vm.handleResize}},[(!_vm.areItemsSubmenus)?_c('article',{class:!_vm.isBarVisible
              ? 'sidemenu-list_container'
              : 'sidemenu-list_container sidemenu-list_container--bar-visible'},[_c('ul',{staticClass:"side-menu_ul"},_vm._l((_vm.filteredSortedItems),function(item){return _c('li',{key:item.id,class:_vm.activeItem === item.id
                  ? 'menu-item menu-item--active'
                  : 'menu-item',on:{"click":function($event){_vm.activeItem !== item.id ? _vm.selectItem(item.id) : ''}}},[_vm._v(" "+_vm._s(item.label ? item.label : 'new unnamed profile')+" ")])}),0)]):_c('article',{class:!_vm.isBarVisible
              ? 'sidemenu-list_container'
              : 'sidemenu-list_container sidemenu-list_container--bar-visible'},_vm._l((_vm.filteredSortedSubMenuItems),function(submenuItem,index){return _c('section',{key:submenuItem.category,staticClass:"sidemenu-category"},[_c('h5',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(['submenu-collapse-' + submenuItem.category]),expression:"['submenu-collapse-' + submenuItem.category]"}],class:_vm.isCollapseShown
                  ? 'menu-category-item'
                  : 'menu-category-item menu-category-item--not-open'},[_vm._v(" "+_vm._s(submenuItem.category)+" "),_c('b-icon',{staticClass:"sidebar-caret-down",attrs:{"icon":"caret-down-fill","font-scale":".65","shift-v":"-15"}})],1),_c('b-collapse',{attrs:{"id":'submenu-collapse-' + submenuItem.category},model:{value:(_vm.collapseShown[index]),callback:function ($$v) {_vm.$set(_vm.collapseShown, index, $$v)},expression:"collapseShown[index]"}},[_c('ul',{staticClass:"side-menu_ul"},[(_vm.isItemListEmpty(submenuItem.items))?_c('li',{staticClass:"menu-item menu-item--submenu-content"},[_vm._v(" No items found ... ")]):_vm._e(),_vm._l((submenuItem.items),function(item){return _c('li',{key:item.id,class:_vm.activeItem === item.id
                      ? 'menu-item menu-item--submenu-content menu-item--active'
                      : 'menu-item menu-item--submenu-content',on:{"click":function($event){_vm.activeItem !== item.id
                      ? _vm.selectItem(item.id, submenuItem.category)
                      : ''}}},[_vm._v(" "+_vm._s(item.label ? item.label : 'new unnamed profile')+" ")])})],2)])],1)}),0)])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }