import { FIRECAMP_ERRORS } from '@/constants/ErrorConstants'

export interface ErrorHandlerInput {
  code?: string
  message?: string
  error?: Error
  messageInformations: Record<string, any>
}

export type ErrorHandlerOutput = {
  show: boolean
  message: string
} & Record<string, any>

/**
 * errorHandler
 * Allow to handle errors returned and to process it depending on the info received.
 *
 * @param {Object} parameters - The object parameters for the error handler
 * @param {String} [parameters.code] - The error code to get the right message
 * @param {String} [parameters.message] - The error message that can be passed
 * @param {Object} parameters.error - The error stack
 * @param {Object} [parameters.messageInformations] - Message informations usefull to set the messageInformations afterwards
 * @return {Object} Returns an object that will tell if we need to show the message and the informations needed.
 */
export function errorHandler({
  code = null,
  message = null,
  error,
  messageInformations = null,
}: ErrorHandlerInput): ErrorHandlerOutput {
  let firecampError = null
  let firecampErrorMessage: string = null
  let firecampMessageInformations = null

  if (error) {
    const { showError, errorMessage } = errorTypeHandler(error)

    if (showError && !message) {
      firecampErrorMessage = errorMessage
    }
  }

  if (code) {
    firecampError = FIRECAMP_ERRORS.find((error) => error.code === code)
    firecampErrorMessage = firecampError.message
  }

  if (message) {
    firecampErrorMessage = message
  }

  if (messageInformations) {
    firecampMessageInformations = {
      ...messageInformations,
      ...(firecampErrorMessage && { message: firecampErrorMessage }),
    }
  }

  return {
    ...(firecampMessageInformations && { ...firecampMessageInformations }),
    show: !!(code || message || firecampErrorMessage || messageInformations),
    message: firecampErrorMessage,
  }
}

export type ErrorTypeHandlerOutput = {
  showError: boolean
  errorMessage: string
}

/**
 * errorTypeHandler
 * Handle the error differently depending on it's type.
 *
 * @param {Object} error - Error stack
 * @return {Object} Returns informations either we show the error and the message associated
 */
function errorTypeHandler(
  error: Error | TypeError | Response,
): ErrorTypeHandlerOutput {
  console.error(error)

  const errorType = error.constructor.name
  let errorMessage = 'An error has occured'
  let showError = true

  switch (errorType) {
    case 'TypeError':
      break
    case 'Response':
      showError = ![500].includes((error as Response).status)
      break
    default:
      errorMessage = (error as Error).message ?? errorMessage
      break
  }

  return {
    showError,
    errorMessage,
  }
}
