<template>
  <form
    class="product-configuration-form"
    @submit="submit"
  >
    <h4>{{ environmentLabel }}</h4>
    <div class="product-configuration-form_form-actions mt-2 mb-4">
      <template
        v-if="
          isValidCustomerCountryCoupleSet && hasStagingData && !isAppLoading
        "
      >
        <b-button
          v-if="isProductionData"
          id="copyStagingBtn"
          variant="success"
          @click="retrieveDataFromStaging"
        >
          Copy data from Staging
        </b-button>
        <b-button
          v-if="isProductionData && formDataChanged"
          variant="success"
          @click="reset"
        >
          Restore original data
        </b-button>
      </template>
    </div>
    <b-form-group
      label="Customer"
      label-cols="auto"
      content-cols="4"
      required
    >
      <Multiselect
        id="customerSelect"
        :value="customer"
        :options="customers"
        :disabled="isComparison || isAppLoading || !fetchedResources.customers"
        :show-labels="false"
        track-by="id"
        label="displayName"
        searchable
        placeholder="Select customer"
        :state="stateFields.customer"
        @input="onChangeCustomer"
      />
    </b-form-group>

    <b-alert
      v-if="customerHasNoInstances"
      variant="info"
      show
    >
      <h4>No instance found for this customer</h4>
      <p>
        Please create an Instance before configuring:
        <router-link to="/app/route/create"> Create Instance </router-link>
      </p>
    </b-alert>

    <div
      v-else-if="customer"
      class="pt-3 pb-3 border-top"
    >
      <b-form-group
        label="Country"
        label-cols-sm="2"
        content-cols="10"
        required
        :state="!stateFields.country"
      >
        <Multiselect
          id="countrySelect"
          :value="country"
          :options="countryByCustomer"
          :show-labels="false"
          searchable
          placeholder="Select Country"
          track-by="id"
          label="displayName"
          :disabled="
            isComparison || isAppLoading || !fetchedResources.countries
          "
          :state="stateFields.country"
          @input="onChangeCountry"
        />
      </b-form-group>

      <b-alert
        v-if="isProductionData && !hasStagingData"
        variant="info"
        show
      >
        <h4>No configuration for staging</h4>
        <p>
          Please create a
          <router-link to="/productapi-configurations/staging/add/">
            staging configuration
          </router-link>
        </p>
      </b-alert>
      <div v-else>
        <b-form-group
          label="Template"
          label-cols-sm="2"
          content-cols="10"
          required
        >
          <Multiselect
            id="templateSelect"
            v-model="formData.template"
            :state="stateFields.template"
            :options="templatesLabels"
            :show-labels="false"
            :disabled="isAppLoading"
            searchable
            placeholder="Select template"
          />
        </b-form-group>
        <template v-if="templateSelected && templateSelected.hasBrandApi">
          <label>Brand API :</label>
          <div class="border-left ml-2 pl-5 pb-1 p-2">
            <b-form-group
              label="API URI"
              label-cols-sm="2"
              content-cols="10"
              :state="stateFields.apiUri"
              invalid-feedback="Invalid URL"
            >
              <b-form-input
                id="apiUriInput"
                v-model="formData.apiUri"
                class="col"
                type="url"
                :disabled="isAppLoading"
                :state="stateFields.apiUri"
              />
            </b-form-group>

            <b-form-group
              label="Query"
              label-cols-sm="2"
              content-cols="10"
            >
              <b-form-input
                id="queryInput"
                v-model="formData.query"
                type="text"
                :disabled="isAppLoading"
                :state="stateFields.query"
              />
            </b-form-group>

            <b-form-group
              label="Choose the authentication mode:"
              class="mb-0"
            >
              <b-form-radio-group
                id="authModeRadioGroup"
                v-model="formData.authModeSelected"
                :options="authModeOptions"
                :disabled="isAppLoading"
              />
            </b-form-group>

            <div class="ml-2 mt-0 pt-2">
              <div
                v-if="formData.authModeSelected === 'username_password'"
                class="border-left p-1 pl-3"
              >
                <b-form-group
                  label="API Username"
                  label-cols-sm="2"
                  content-cols="10"
                  required
                >
                  <b-form-input
                    id="apiUsernameInput"
                    v-model="formData.apiUsername"
                    type="text"
                    :disabled="isAppLoading"
                    :state="stateFields.apiUsername"
                  />
                </b-form-group>

                <b-form-group
                  class="mt-3"
                  label="API Password"
                  label-cols-sm="2"
                  content-cols="10"
                  required
                >
                  <b-form-input
                    id="apiPasswordInput"
                    v-model="formData.apiPassword"
                    :state="stateFields.apiPassword"
                    type="password"
                    :disabled="isAppLoading"
                  />
                </b-form-group>
              </div>

              <div
                v-else-if="formData.authModeSelected === 'header'"
                class="border-left p-1 pl-4"
              >
                <b-form-group
                  label="Name"
                  label-cols-sm="2"
                  content-cols="10"
                  required
                >
                  <b-form-input
                    id="headerNameInput"
                    v-model="formData.headerName"
                    :state="stateFields.headerName"
                    type="text"
                    :disabled="isAppLoading"
                  />
                </b-form-group>
                <b-form-group
                  label="Value"
                  label-cols-sm="2"
                  content-cols="10"
                  required
                >
                  <b-form-input
                    id="headerValueInput"
                    v-model="formData.headerValue"
                    :state="stateFields.headerValue"
                    type="text"
                    :disabled="isAppLoading"
                  />
                </b-form-group>
              </div>
            </div>
          </div>
        </template>
        <template v-if="templateSelected && templateSelected.customParameters">
          <label>Authentication :</label>
          <div class="border-left ml-2 pl-3 mb-4">
            <div
              v-for="input in templateSelected.customParameters"
              :key="`customField${input.key}`"
              class="mt-3"
            >
              <b-form-group
                :label="input.label"
                label-cols-sm="2"
                content-cols="10"
              >
                <b-form-input
                  v-model="formData.customParameters[input.key]"
                  type="text"
                  :disabled="isAppLoading"
                />
              </b-form-group>
            </div>
          </div>
        </template>
        <label class="mt-4"> Airtables configurations: </label>
        <div class="border-left ml-2 pl-3 mb-4">
          <GroupInput
            v-model="formData.airtables"
            group-label="Airtables configurations:"
            :template-value="defaultAirtableConfig"
            :min="1"
            :disabled="isAppLoading"
            class="p-2 pl-4"
          >
            <template #default="{ index, value, updateValue }">
              <div :class="{ 'border-top pt-4': index !== 0 }">
                <b-form-group
                  label="Service Name"
                  label-cols-sm="3"
                  content-cols="9"
                  required
                >
                  <Multiselect
                    :id="`serviceNameSelect${index}`"
                    :value="value.serviceName"
                    :options="applications"
                    :show-labels="false"
                    :disabled="isAppLoading || !fetchedResources.applications"
                    track-by="id"
                    label="displayName"
                    searchable
                    placeholder="Select service"
                    :state="stateFields['airtables' + index + '.serviceName']"
                    @input="updateServiceName(index, $event, updateValue)"
                  />
                </b-form-group>
                <b-form-group
                  label="Airtable URI"
                  label-cols-sm="3"
                  content-cols="9"
                  required
                  invalid-feedback="Invalid Airtable URI"
                >
                  <b-form-input
                    :id="`airtableUriInput${index}`"
                    type="url"
                    :value="value.airtableUri"
                    :disabled="isAppLoading"
                    :state="stateFields['airtables' + index + '.airtableUri']"
                    @input="updateValue('airtableUri', $event)"
                  />
                </b-form-group>
              </div>
            </template>
          </GroupInput>
        </div>

        <GroupInput
          v-model="formData.addedValues"
          hide-label
          class="pl-2 col-10"
          :max="propertiesCouldBeAdded.length"
          :disabled="isAppLoading"
          label-add="Add Field"
        >
          <template #default="{ value, updateValue, index }">
            <div class="row">
              <b-select
                :id="`addedValuesSelect${index}`"
                class="col-5 mr-4"
                :value="value.key"
                :options="getPropertiesCouldBeAdded(value.key)"
                :state="stateFields['addedValues' + index + '.key']"
                :disabled="isAppLoading"
                @input="updateValue('key', $event)"
              />

              <b-form-input
                :id="`addedValuesInput${index}`"
                :value="value.value"
                class="col"
                type="text"
                :state="stateFields['addedValues' + index + '.value']"
                :disabled="isAppLoading"
                @input="updateValue('value', $event)"
              />
            </div>
          </template>
        </GroupInput>
        <div
          v-if="isValidCustomerCountryCoupleSet"
          class="navigation_buttons pr-5"
        >
          <SubmitButton
            type="submit"
            label="Save"
            :loading="isAppLoading"
            :disabled="countryByCustomer.length === 0 || !formDataChanged"
          />

          <b-button
            v-if="(isProductionData && hasProductionData) || !isProductionData"
            :to="{
              name: 'productapi-configurations-add-test',
              params: {
                customerId: customer.id,
                environment,
                countryCode: country.id,
              },
            }"
          >
            Test Query
          </b-button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import { RESOURCES } from '@/service/ResourceService'
  import { firstCharacterToUppercase } from '../../utils/StringHelpers'
  import GroupInput from '../form/GroupInput.vue'
  import Multiselect from '../form/Multiselect.vue'
  import SubmitButton from '../form/SubmitButton.vue'

  const DEFAULT_SERVICE = {
    id: 'defaultService',
    displayName: 'Default',
    meta: {
      active: true,
    },
  }

  const DEFAULT_AIRTABLE_CONFIG = {
    serviceName: DEFAULT_SERVICE,
  }

  const initialFormData = {
    airtables: [DEFAULT_AIRTABLE_CONFIG],
    addedValues: [],
    template: '',
    authModeSelected: 'none',
    customParameters: {},
  }

  export default {
    name: 'ProductAPIConfigurationForm',

    components: {
      Multiselect,
      GroupInput,
      SubmitButton,
    },

    props: {
      environment: {
        type: String,
        required: true,
      },
      isComparison: Boolean,
    },

    data() {
      return {
        formData: { ...initialFormData },
        stateFields: {},
        defaultAirtableConfig: DEFAULT_AIRTABLE_CONFIG,
        formDataChanged: true,
        ignoreWatch: false,
        authModeOptions: [],
      }
    },

    computed: {
      ...mapState({
        customers: (state) => state.customers,
        applications: (state) => {
          return [
            DEFAULT_SERVICE,
            ...(state.applications || [])
              .filter((app) => app.allowedByProductAPIConfig)
              .map((data) => {
                if (data.id === 'vto') {
                  return {
                    ...data,
                    id: 'virtualtryon',
                  }
                }
                return data
              }),
          ]
        },
        countries: (state) => state.countries,
        templates: (state) => state.productApiSettings.templates,
        propertiesCouldBeAdded: (state) =>
          state.productApiSettings.propertiesCouldBeAdded,
        fetchedResources: (state) => state.fetchedResources,
      }),

      ...mapGetters(['getCountriesByCustomers', 'hasModifaceTenant']),

      ...mapGetters('productApiSettings', [
        'customer',
        'country',
        'hasProductionData',
        'hasStagingData',
        'templatesLabels',
      ]),

      environmentLabel() {
        return firstCharacterToUppercase(this.environment)
      },

      countryByCustomer() {
        return this.getCountriesByCustomers(this.customer)
      },

      isProductionData() {
        return this.environment === 'production'
      },

      customerHasNoInstances() {
        return this.customer && this.countryByCustomer.length === 0
      },

      isValidCustomerCountryCoupleSet() {
        return this.customer && this.country && !this.customerHasNoInstances
      },

      templateSelected() {
        if (this.formData.template) {
          return this.templates[this.formData.template]
        }
        return null
      },
    },

    watch: {
      async environment() {
        await this.loadConfiguration(
          this.customer.id,
          this.country.id,
          this.environment,
        )
      },

      customer() {
        this.formData = { ...initialFormData }
      },

      country: {
        async handler(val, old) {
          this.formData = { ...initialFormData }
          if (val?.id && val.id !== old?.id) {
            await this.$nextTick()
            this.loadConfiguration(this.customer.id, val.id, this.environment)
          }
        },
        deep: true,
        immediate: true,
      },

      formData: {
        handler() {
          if (this.ignoreWatch) return
          this.formDataChanged = true
        },
        deep: true,
        immediate: false,
      },
    },

    async mounted() {
      this.authModeOptions = [
        { text: 'No authentication', value: 'none' },
        { text: 'Username and password', value: 'username_password' },
        { text: 'Header', value: 'header' },
      ]

      await Promise.all([
        this.loadResources({
          resourceNames: [
            RESOURCES.COUNTRIES,
            RESOURCES.CUSTOMERS,
            RESOURCES.APPLICATIONS,
          ],
        }),
        this.bindAppRoutes(),
      ])
    },

    methods: {
      ...mapActions('productApiSettings', [
        'loadProductApiConfig',
        'updateProductApiParams',
        'getAirtableURIFromAppRouteConfiguration',
      ]),
      ...mapActions(['loadResources', 'bindAppRoutes']),

      onChangeCustomer(val) {
        this.$router.push({
          name: 'productapi-configurations-add-form',
          params: { customerId: val?.id, environment: this.environment },
        })
      },

      onChangeCountry(val) {
        this.$router.push({
          name: 'productapi-configurations-add-form',
          params: {
            customerId: this.customer.id,
            environment: this.environment,
            countryCode: val?.id,
          },
        })
      },

      async loadConfiguration(customerId, countryCode, envId) {
        if (!customerId || !countryCode) {
          return false
        }

        this.appLoading(true)

        await this.loadResources({ resourceNames: [RESOURCES.APPLICATIONS] })

        try {
          const env = envId ?? this.environment
          const data = await this.loadProductApiConfig({
            customerId,
            countryCode,
            env,
          })
          this.ignoreWatch = true
          this.formData = {
            ...initialFormData,
            ...data,
          }
          this.formDataChanged = false
        } catch (error) {
          this.handleErrors({ error })
        } finally {
          this.appLoading(false)
          this.$nextTick(() => {
            this.ignoreWatch = false
          })
        }
      },

      getPropertiesCouldBeAdded(keyField) {
        const fields = new Set(
          this.propertiesCouldBeAdded.filter(
            (keyCould) =>
              !this.formData.addedValues.find(({ key }) => keyCould === key),
          ),
        )
        if (keyField) fields.add(keyField)
        return Array.from(fields)
      },

      checkFields(validOld, formData, fieldKeys, prefixStateKey = '') {
        let valid = validOld
        fieldKeys.forEach((key) => {
          if (!formData[key]) {
            valid = false
            const stateKey = `${prefixStateKey}${key}`
            this.stateFields[stateKey] = false
          }
        })
        return valid
      },

      async updateServiceName(index, value, updateFun) {
        updateFun('serviceName', value)
        if (value && !this.formData.airtables[index].airtableUri) {
          const airtableId = await this.getAirtableURIFromAppRouteConfiguration(
            {
              customerId: this.customer.id,
              countryCode: this.country.id,
              env: this.environment,
              serviceName: value.id,
            },
          )
          if (airtableId) {
            updateFun(
              'airtableUri',
              `https://api.airtable.com/v0/${airtableId}/Products`,
            )
          }
        }
      },

      checksAllFields() {
        let valid = true
        this.stateFields = {}

        valid = this.checkFields(valid, this, ['customer', 'country'])
        valid = this.checkFields(valid, this.formData, ['template'])

        if (this.templateSelected?.hasBrandApi) {
          if (this.formData.authModeSelected === 'username_password') {
            valid = this.checkFields(valid, this.formData, [
              'apiUsername',
              'apiPassword',
            ])
          } else if (this.formData.authModeSelected === 'header') {
            valid = this.checkFields(valid, this.formData, [
              'headerName',
              'headerValue',
            ])
          }
        }

        this.formData.airtables.forEach((airtable, index) => {
          valid = this.checkFields(
            valid,
            airtable,
            ['serviceName', 'airtableUri'],
            `airtables${index}.`,
          )
        })

        this.formData.addedValues.forEach((addedValue, index) => {
          valid = this.checkFields(
            valid,
            addedValue,
            ['key', 'value'],
            `addedValues${index}.`,
          )
        })
        return valid
      },

      async retrieveDataFromStaging() {
        await this.loadConfiguration(
          this.customer.id,
          this.country.id,
          'staging',
        )
        this.formDataChanged = true
      },

      async reset() {
        await this.loadConfiguration(
          this.customer.id,
          this.country.id,
          this.environment,
        )
        this.formDataChanged = false
      },

      async submit(e) {
        e.preventDefault()
        // Validations
        if (!this.checksAllFields()) {
          this.setMessageInformations({
            message:
              'Please make sure to complete all mandatory fields before saving',
            state: 'error',
          })
          return
        }
        this.appLoading(true)

        try {
          await this.updateProductApiParams({
            customerId: this.customer.id,
            countryCode: this.country.id,
            formData: this.formData,
            env: this.environment,
          })

          this.setMessageInformations({
            message: 'Successfully saved',
            state: 'success',
          })
          this.$router.push({
            name: 'productapi-configurations-add-test',
            params: {
              customerId: this.customer.id,
              countryCode: this.country.id,
            },
          })
        } catch (error) {
          this.handleErrors({ error })
        } finally {
          this.appLoading(false)
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .product-configuration-form {
    max-width: 900px;

    display: flex;
    flex-direction: column;

    border-radius: 5px;
    padding: 1rem;

    &_form-actions {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      min-height: 2.3rem;
    }
  }

  .navigation_buttons {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-end;
  }
</style>
