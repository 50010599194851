import FIREBASE from './firebase'
import {
  connectAuthEmulator,
  EmailAuthProvider,
  fetchSignInMethodsForEmail,
  getAuth,
  getMultiFactorResolver,
  GoogleAuthProvider,
  multiFactor,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  reauthenticateWithCredential,
  MultiFactorResolver,
  User,
  Auth as FirebaseAuth,
  PhoneInfoOptions,
  updateEmail,
  AuthCredential,
} from 'firebase/auth'
import { isLorealDomainEmail } from '@/service/FormsService'

class Auth {
  private auth: FirebaseAuth
  private resolver: MultiFactorResolver = null
  private recaptchaVerifier: RecaptchaVerifier = null
  private recaptchaWidgetId: number = null
  private phoneInfoOptions: PhoneInfoOptions
  private verificationId: string

  constructor() {
    if (process.env.NODE_ENV !== 'test') {
      this.auth = getAuth(FIREBASE)
      if (process.env.VUE_APP_ENV === 'local') {
        connectAuthEmulator(this.auth, 'http://localhost:9099')
      }
    }
  }

  /**
   * Init repatcha verifier on div with id .
   * @param {string} id
   */
  async initRecaptcha(id: string) {
    const recaptchaVerifier = new RecaptchaVerifier(
      id,
      {
        size: 'invisible',
      },
      this.auth,
    )

    await recaptchaVerifier.render().then((widgetId) => {
      this.recaptchaWidgetId = widgetId
    })

    this.recaptchaVerifier = recaptchaVerifier
  }

  /**
   * Firebase sign in method.
   *
   * @param {String} email - The user email.
   * @param {String} password- The user password.
   * @return {Promise} - Return either the user or the error.
   */
  signIn(email: string, password: string): Promise<void> {
    return new Promise((resolve, reject) => {
      signInWithEmailAndPassword(this.auth, email, password)
        .then((userCredential) => {
          return this._handleResolve(userCredential.user, resolve, reject)
        })
        .catch((error) => this._handleError(error, reject))
    })
  }

  /**
   * Firebase Google Sign In method.
   */
  signInWithGoogle() {
    return new Promise((resolve, reject) => {
      const provider = new GoogleAuthProvider()
      provider.setCustomParameters({
        login_hint: 'user@modiface.com',
        prompt: 'select_account',
      })
      signInWithPopup(this.auth, provider)
        .then((result) => {
          return this._handleResolve(result.user, resolve, reject)
        })
        .catch((error) => this._handleError(error, reject))
    })
  }

  /**
   * Firebase sign out method.
   */
  signOut() {
    return new Promise((resolve, reject) => {
      signOut(this.auth)
        .then(() => {
          resolve(true)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  /**
   * Handle the resolve of the promise signIn.
   * @param {User} user
   * @param {Function} resolve
   * @param {Function} reject
   * @private
   */
  async _handleResolve(
    user: User,
    resolve: (params: any) => void,
    reject: (params: any) => void,
  ) {
    if (await this.hasMFAPhoneNumber()) {
      resolve(user)
    } else {
      reject({ code: 'MFA_REQUIRED' })
    }
  }

  /**
   * Handle the error of the promise signIn.
   * @param {Object} error
   * @param {Function} reject
   */
  async _handleError(error: any, reject: (param: any) => void) {
    if (error.code == 'auth/multi-factor-auth-required') {
      const resolver = getMultiFactorResolver(this.auth, error)
      // Ask user which second factor to use.
      if (resolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
        const phoneInfoOptions = {
          multiFactorHint: resolver.hints[0],
          session: resolver.session,
        }
        await this.sendMFACode(phoneInfoOptions)
        this.resolver = resolver
        reject({ code: 'MFA_VALIDATION_REQUIRED', hint: resolver.hints[0] })
      }
    }
    reject(error)
  }

  /**
   * Verify if phoneNumber to enroll.
   * @param {string} phoneNumber
   * @returns
   */
  async validatePhoneNumber(phoneNumber: string) {
    const multiFactorSession = await multiFactor(
      await this.getCurrentUser(),
    ).getSession()

    const phoneInfoOptions = {
      phoneNumber,
      session: multiFactorSession,
    }

    return this.sendMFACode(phoneInfoOptions)
  }

  /**
   * Send the MFA code to the user.
   * @param {Object} phoneInfoOptions (optional)
   * @returns {Promise<string>} - Return the verification id.
   */
  async sendMFACode(phoneInfoOptions: PhoneInfoOptions): Promise<string> {
    if (phoneInfoOptions) this.phoneInfoOptions = phoneInfoOptions

    const phoneAuthProvider = new PhoneAuthProvider(this.auth)
    const verificationId = await phoneAuthProvider.verifyPhoneNumber(
      this.phoneInfoOptions,
      this.recaptchaVerifier,
    )

    this.verificationId = verificationId
    return verificationId
  }

  /**
   * Validate the MFA code.
   * @param {string} verificationCode
   * @return {Promise}.
   */
  async validateMFACode(verificationCode: string): Promise<boolean> {
    if (!this.verificationId) {
      throw new Error('Verification ID is not set')
    }

    const cred = PhoneAuthProvider.credential(
      this.verificationId,
      verificationCode,
    )
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred)

    if (this.resolver) {
      await this.resolver.resolveSignIn(multiFactorAssertion)
    } else {
      await multiFactor(await this.getCurrentUser()).enroll(
        multiFactorAssertion,
      )
    }

    this.resolver = null
    return true
  }

  /**
   * Reauthenticate with google credentials.
   */
  async reauthenticateWithCredentialWithGoogle() {
    const user = await this.getCurrentUser()
    const credentials = GoogleAuthProvider.credential(await user.getIdToken())
    return await this.reauthenticate(user, credentials)
  }

  /**
   * Reauthenticate with handling.
   *
   * @param {User} user - The user to provide.
   * @param {AuthCredential} credentials - The credentials to provide.
   */
  async reauthenticate(user: User, credentials: AuthCredential): Promise<User> {
    return new Promise((resolve, reject) => {
      reauthenticateWithCredential(user, credentials)
        .then((result) => {
          return this._handleResolve(result.user, resolve, reject)
        })
        .catch((error) => this._handleError(error, reject))
    })
  }

  /**
   * Firebase check that the email and password are valid for the currentUser
   * Then reauthenticate it
   *
   * @param {String} email - The email to provide.
   * @param {String} password - The password to provide.
   */
  async checkPassword(email: string, password: string): Promise<User> {
    const credentials = EmailAuthProvider.credential(email, password)
    const user = this.auth.currentUser
    return await this.reauthenticate(user, credentials)
  }

  /**
   * Get the current user from Firebase.
   */
  async getCurrentUser(): Promise<User> {
    if (this.auth.currentUser) {
      return this.auth.currentUser
    } else {
      return new Promise((resolve, reject) => {
        const unsubscribe = this.auth.onAuthStateChanged((user) => {
          unsubscribe()
          resolve(user)
        }, reject)
      })
    }
  }

  /**
   * Firebase check that a email exists.
   *
   * @param {String} email - The email to provide.
   */
  async doesAccountExists(email: string): Promise<boolean> {
    return await fetchSignInMethodsForEmail(this.auth, email)
      .then((signInMethods) => signInMethods?.length > 0)
      .catch((error) => error)
  }

  /**
   *	Returns if the current user has a phone
   * @returns {boolean} false if the user logged has MFA
   */
  async hasMFAPhoneNumber(): Promise<boolean> {
    const user = await this.getCurrentUser()
    return (
      user &&
      (isLorealDomainEmail(user.email) ||
        !!multiFactor(user).enrolledFactors.find(
          (f) => f.factorId === PhoneMultiFactorGenerator.FACTOR_ID,
        ))
    )
  }

  /**
   * Firebase updates the currentUser email
   *
   * @param {String} newEmail - The email to provide.
   */
  async updateEmailUser(newEmail: string) {
    try {
      const currentUser = await this.getCurrentUser()
      await updateEmail(currentUser, newEmail)
    } catch (error) {
      throw new Error(error.message ?? error)
    }
  }

  /**
   * Returns the current user's phone number.
   * @returns {Promise <string>}
   */
  async getPhoneNumber(): Promise<string> {
    const user = await this.getCurrentUser()
    const multiFactorUser = multiFactor(user)

    return (
      multiFactorUser.enrolledFactors.find(
        (factor) => factor.factorId === PhoneAuthProvider.PROVIDER_ID,
      ) as unknown as { phoneNumber: string } | undefined
    )?.phoneNumber
  }

  /**
   * remove Phone Number registered for MFA.
   * @returns {Promise <boolean>}
   */
  async removePhoneNumber(): Promise<boolean> {
    const user = await this.getCurrentUser()
    const multiFactorUser = multiFactor(user)
    await Promise.all(
      multiFactorUser.enrolledFactors.map(async (multiFactorInfo) => {
        return multiFactorUser.unenroll(multiFactorInfo)
      }),
    )
    return true
  }

  /**
   * Send password reset email.
   * @param {string} email
   * @returns Promise
   */
  async sendPasswordResetEmail(email: string): Promise<void> {
    return sendPasswordResetEmail(this.auth, email)
  }
}

export default new Auth()
