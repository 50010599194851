import Dataloader from 'dataloader'

export const sortKeys = <T extends Record<string, any>>(obj: T): T =>
  Object.keys(obj)
    .sort()
    .reduce((result, key) => ({ ...result, [key]: obj[key] }), {} as T)

export type CreateDataloaderOptions = {
  areKeysObjects?: boolean
}

export const createDataloader = <K, V>(
  batchFn: Dataloader.BatchLoadFn<K, V>,
  options: CreateDataloaderOptions = {},
) => {
  const { areKeysObjects } = options

  return new Dataloader(batchFn, {
    cacheKeyFn: areKeysObjects
      ? (key) => JSON.stringify(sortKeys(key))
      : undefined,
  })
}
