<template>
  <BaseManagement
    :filtered-data="filteredVersions"
    :loading="loading"
    search-field-label="Search by name"
    name="version"
    :modal-component="modalComponent"
    @searchInput="searchByName"
    @toCreateVersion="redirectToCreateVersion"
    @toUpdateVersion="redirectToUpdateVersion"
    @toUpdateMapping="redirectToUpdateMapping"
    @toExportVersionData="exportVersionData"
  >
    <template #additional-actions>
      <b-button
        class="action-button ml-3"
        variant="primary"
        :disabled="loading"
        @click="redirectToUpdateVersion"
      >
        <b-icon icon="plus-circle" />
        UPDATE VERSION
      </b-button>
    </template>
  </BaseManagement>
</template>

<script>
  import { mapActions } from 'vuex'
  import Functions from '@/firebase/functions'

  import BaseManagement from '@/components/admin/BaseManagement'

  export default {
    name: 'VersionsManagement',

    components: {
      BaseManagement,
    },

    data() {
      return {
        loading: false,
        versions: [],
        filteredVersions: [],
        modalComponent: 'toCreateVersion',
      }
    },

    async created() {
      await this.init()
    },

    methods: {
      ...mapActions(['bindAppVersions']),

      async init() {
        this.loading = true
        this.versions = await this.bindAppVersions()
        this.filteredVersions = this.setVersionsForList(this.versions)
        this.loading = false
      },

      setVersionsForList(versions) {
        return versions?.map((version) => {
          const { id, application, meta, prescription, releaseNotes } = version

          return {
            id,
            label: id,
            details: {
              ...(application?.id && { applications: application.id }),
              ...(meta?.version && { version: meta?.version }),
              ...(prescription && {
                prescription: prescription
                  ? 'Prescription ON'
                  : 'Prescription OFF',
              }),
              ...(releaseNotes &&
                releaseNotes?.length > 0 && { description: releaseNotes }),
            },
            quickActionButton: [
              {
                label: 'Update version',
                icon: 'pencil-fill',
                variant: 'success',
                onClick: ['toUpdateVersion', version],
              },
              {
                label: 'Update mapping',
                icon: 'map-fill',
                onClick: ['toUpdateMapping', version],
              },
              {
                label: 'Export version',
                icon: 'archive',
                variant: 'info',
                onClick: ['toExportVersionData', version],
              },
            ],
          }
        })
      },

      searchByName(name) {
        const searchInput = name.toLowerCase()
        const versions = this.setVersionsForList(this.versions)
        this.filteredVersions =
          name?.length > 0
            ? versions.filter((version) =>
                version?.id?.toLowerCase().includes(searchInput),
              )
            : versions
      },

      redirectToUpdateVersion(version) {
        this.$router.push({
          name: 'update-version',
          params: {
            versionId: version?.id,
            appId: version?.application?.id,
          },
        })
      },

      redirectToUpdateMapping(version) {
        this.$router.push({
          name: 'version-mapping',
          params: {
            appVersionId: version.id,
          },
        })
      },

      redirectToCreateVersion() {
        this.$router.push({ name: 'create-version' })
      },

      async exportVersionData(version) {
        try {
          this.loading = true
          const { url, filename } = await Functions.exportVersionData(
            version.id,
          )

          const link = document.createElement('a')
          link.href = url
          link.download = filename
          document.body.appendChild(link)
          link.click()
        } catch (error) {
          this.handleErrors({ error })
        } finally {
          this.loading = false
        }
      },
    },
  }
</script>
