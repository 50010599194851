import { Product } from '@/types/Product'
import ApiService from '@/service/ApiService'
import { DEFAULT_SERVICE } from '@/constants/ProductAPIConfigurationConstants'

/**
 * The Product Service.
 */
class ProductService extends ApiService {
  private readonly bHubUrl: string
  private readonly forceItemBrandCode: string
  private readonly headers: Record<string, any>
  private readonly params: Record<string, any>
  private readonly service: string

  constructor(productApiInfo) {
    super()
    this.bHubUrl = productApiInfo.url
    this.forceItemBrandCode = productApiInfo.forceItemBrandCode
    this.headers = productApiInfo.headers
    this.params = productApiInfo.params
    this.service =
      productApiInfo.service === 'vto' ? 'virtualtryon' : productApiInfo.service
  }

  async callProductApi(
    sku = '360000000',
    forceCacheRefresh = false,
  ): Promise<Record<string, any>> {
    const method = 'GET'
    const params = {
      ...this.params,
      service: this.service,
      debug_mode: true, // Use debug mode
      timestamp: Date.now(), // Force clean cache cloudFare
      force_refresh_cache: undefined,
    }
    if (forceCacheRefresh) params.force_refresh_cache = true

    // Adding cache-control and pragma to avoid no cache from Cloudflare.
    const headers = {
      ...this.headers,
      'sec-fetch-site': 'cross-site',
    }

    return super.buildFetchRequest(method, this.bHubUrl + sku, headers, params)
  }

  /**
   * Flush the cache of the product api.
   * @async
   * @return {Promise<void>}
   */
  async flushCache(): Promise<void> {
    const method = 'POST'
    const headers = {
      ...this.headers,
      'sec-fetch-site': 'cross-site',
    }

    return super.buildFetchRequest(
      method,
      this.bHubUrl + 'flushcache',
      headers,
      this.params,
    )
  }

  /**
   * Get the product info by the sku.
   *
   * @async
   * @param {Object} item - The item we get the product from.
   * @param {Number} retry - The number of retry left in case of error.
   * @return {Promise<Object>} An object with the product informations.
   */
  async getProductInfoBySku(
    item,
    retry = 1,
    forceCacheRefresh = false,
  ): Promise<Product> {
    if (!item) throw new Error('item is missing.')
    if (retry === 0) return

    const sku = item.sku ?? '360000000'
    let product = new Product(item.id, item.brand, sku, item.label)

    // Use the brand code of the item if set.
    if (this.forceItemBrandCode) {
      this.params.brand_code = product.brand
    }

    try {
      const data = await this.callProductApi(sku, forceCacheRefresh)

      const description = data.shortDescription.trim() ?? ''
      // If the product api return for label is a number we use the label stored in the Firestore.
      const regex = /^[0-9]*$/g
      const label =
        data.name && !regex.test(data.name.trim()) ? data.name : item.label
      product = new Product(
        item.id,
        item.brand,
        sku,
        label,
        data.packshotImageUrl,
        description,
        data.rating,
        data.debugInfo,
      )

      return product
    } catch (error) {
      if (![200, 400].includes(error?.status)) {
        console.error(`Error ${error?.status} for item ${item?.id}`)
        console.log(`retries left : ${retry}`)
        const newRetry = retry - 1
        return this.getProductInfoBySku(item, newRetry)
      }
    }
  }
}

export default ProductService

export const getDefaultProductApiConfig = (
  brandCode: string,
  countryCode: string,
  env = 'staging',
  service: string = DEFAULT_SERVICE,
) => ({
  headers: {
    api_version: 'product.2.5',
  },
  params: {
    brand_code: brandCode,
    country_code: countryCode,
    description_overridable: true,
    name_overridable: true,
  },
  service,
  forceItemBrandCode: false,
  url:
    env === 'production'
      ? 'https://api-beautyhub-emea.loreal.io/bhub/products/'
      : 'https://api-beautyhub-emea-staging.loreal.io/bhub/products/',
})
