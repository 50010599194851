<template>
  <BaseContainer
    class="error-page_container"
    :reduce-container="false"
    title="Error Page"
  >
    <div class="error-page_message mt-5">
      <h2>{{ message }}</h2>
      <h3>{{ subMessage }}</h3>
    </div>

    <FirecampAnimation
      class="error-page_extinguished"
      :lit="false"
    />

    <div class="error-page_redirect-links mt-5">
      <router-link :to="{ name: 'homepage' }">
        <b-icon
          icon="house"
          shift-h="-4"
          shift-v="0.7"
        />
        Go to home page
      </router-link>

      <a v-if="!isFromParamExists" @click="backToPreviousPage">
        <b-icon
          icon="arrow-return-left"
          shift-h="-4"
          shift-v="-2"
        />
        Return to previous page
      </a>
    </div>
  </BaseContainer>
</template>

<script>
  import BaseContainer from '@/components/containers/BaseContainer'
  import FirecampAnimation from '@/components/misc/FirecampAnimation'

  export default {
    name: 'ErrorPage',

    components: {
      BaseContainer,
      FirecampAnimation,
    },

    data() {
      return {
        errorCode: null,
        message: null,
        subMessage: null,
      }
    },

    computed: {
      title() {
        return this.$route.meta?.displayName ?? 'Error Page'
      },
      isFromParamExists() {
        return !([null, undefined].includes(this.$route.params.from))
      }
    },

    created() {
      this.errorCode = this.$route.params.code ?? 404
      this.message = `Oops! - ${this.errorCode}`
      const unreachablePage = this.isFromParamExists ? decodeURIComponent(this.$route.params.from) : this.$route.fullPath
      if (!this.$route.params.message) {
        switch (this.errorCode) {
          case 403:
            this.subMessage =
              "You tried to reach a page but you don't have the required authorizations"
            break
          default:
            this.subMessage = `You tried to reach the following page: ${unreachablePage} but it doesn't exist`
        }
      } else {
        this.subMessage = this.$route.params.message
      }
    },

    methods: {
      backToPreviousPage() {
        this.$router.back()
      },

      toHomePage() {
        this.$router.push('/')
      },
    },
  }
</script>

<style lang="scss" scoped>
  .error-page_container {
    text-align: center;
  }

  .error-page_message {
    h2 {
      font-size: 4.5rem;
    }

    h3 {
      font-size: 2rem;
    }
  }

  .error-page_extinguished {
    padding-top: 10%;
  }

  .error-page_redirect-links {
    a {
      color: var(--tertiary) !important;
      display: block;
      font-size: 1.4rem;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;

      &:hover {
        cursor: pointer;
        text-decoration: underline !important;
      }
    }
  }
</style>
