<template>
  <div class="main-collection_container container-fluid">
    <TitleContainer
      v-if="!included"
      :title="title"
    />
    <b-form
      class="main-collection_form"
      @submit.prevent="onSubmit"
      @reset.prevent="onReset"
    >
      <div class="main-collection_fields">
        <b-form-group
          class="col-5 mb-2 pl-0"
          label="Category :"
          required
        >
          <Multiselect
            v-model="categoryValue"
            :options="['legal', 'technical', 'commercial', 'other']"
            :disabled="isAppLoading || !fetchedResources.crmcodes"
            required
          />
        </b-form-group>
        <b-form-group
          class="col-5 mb-2 pl-0"
          label="CRM Code id:"
          required
        >
          <b-form-input
            v-model="crmCodeIdValue"
            :disabled="isAppLoading || !fetchedResources.crmcodes"
            :state="crmCodeIdState"
            required
          />
        </b-form-group>

        <b-form-group
          class="col-5 mb-2 pl-0"
          label="Display name:"
        >
          <b-form-input
            :value="displayNameValue"
            disabled
            :state="idState"
          />
          <b-form-invalid-feedback>
            Combinaison ({{ crmCodeIdValue }}_{{ categoryValue }}) already
            exists in crmcodes collection.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-row>
          <div :class="included ? 'action-button_container' : 'mt-4 pl-0'">
            <b-button
              type="reset"
              variant="link"
              class="text-danger"
              :class="!included ? 'w-100' : ''"
              >{{ edition ? 'Delete' : 'Reset' }}</b-button
            >
            <SubmitButton
              :disabled="isSubmitDisabled"
              :loading="isAppLoading"
              :class="included ? 'w-auto mb-auto ml-2' : ''"
            />
          </div>
        </b-row>
      </div>
    </b-form>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import { DictionnariesCreationMixin } from '@/mixins/DictionnariesCreationMixin'
  import { RESOURCES } from '@/service/ResourceService'
  import Multiselect from '../../form/Multiselect.vue'

  const initialData = {
    crmCodeIdValue: '',
    categoryValue: '',
  }

  export default {
    name: 'CRMCodeCreation',
    components: { Multiselect },
    mixins: [DictionnariesCreationMixin],
    props: {
      category: {
        type: String,
        default: null,
      },
      crmCodeId: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        stateKey: 'crmcodes',
        name: 'CRM Code',
        ...initialData,
        initialData,
        defaultErrorMessage:
          'Id must only contains letters without accentuation (lowercase or uppercase), numbers and - character.',
      }
    },
    computed: {
      ...mapState({
        fetchedResources: (state) => state.fetchedResources,
      }),
      displayNameValue() {
        if (this.categoryValue) {
          return `${this.crmCodeIdValue}_${this.categoryValue}`
        }
        return ''
      },
      idValue() {
        return this.displayNameValue.toLowerCase().replace('_', '-')
      },
      crmCodeIdState() {
        return /^\w*$/.test(this.crmCodeIdValue)
      },
      isSubmitDisabled() {
        return (
          [null, false].includes(this.idState) ||
          !this.crmCodeIdValue ||
          !this.crmCodeIdState ||
          !this.categoryValue
        )
      },
      finalObject() {
        return {
          crmCodeId: this.crmCodeIdValue,
          displayName: this.displayNameValue,
          category: this.categoryValue,
        }
      },
    },
    async mounted() {
      if (this.edition) {
        this.crmCodeIdValue = this.crmCodeId
        this.categoryValue = this.category
      }

      await this.loadResources({ resourceNames: [RESOURCES.CRM_CODES] })
    },

    methods: {
      ...mapActions(['loadResources']),
    },
  }
</script>
