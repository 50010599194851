<template>
  <MultistepForm
    v-model="formData"
    :title="title"
    :total-step-number="totalStepNumber"
    success-message="Prescription successfully created !"
    :steps="stepsComponents"
    :is-next-disabled="isNextDisabled"
    :is-submit-success="success"
    loading-message="Prescription creation ..."
    :step.sync="stepIndex"
    @submit="handleSubmit"
  >
    <template #buttons>
      <b-button
        v-if="success"
        variant="primary"
        class="w-100"
        :disabled="!formData.configuration.approute.id"
        @click="$router.push(linkToPrescription)"
      >
        Go to the prescription
      </b-button>
    </template>

    <ConfirmationModal
      v-if="formData.configuration.approute.id"
      modal-id="prescription-creation"
      message="You are about to delete all the prescriptions of the current instance"
      :item-id="formData.configuration.approute.id"
      @deleteConfirmed="submitForm"
    />
  </MultistepForm>
</template>

<script>
  import Functions from '@/firebase/functions'
  import MultistepForm from '@/components/multistep-form/MultistepForm'
  import ConfirmationModal from '@/components/modals/ConfirmationModal'
  import { PRESCRIPTION_TYPES } from '@/constants/PrescriptionConstants'

  const initialData = {
    configuration: {
      json: null,
      approute: {
        application: null,
        id: null,
        eraseAndReplace: false,
        useDefault: false,
        version: null,
      },
      airtableApi: {
        airtableId: null,
        look: false,
        product: false,
        active: false,
      },
      lookApi: {
        active: false,
        brandHashId: null,
        service: null,
        cmsEnvironment: null,
        countryCode: null,
        language: null,
        platform: null,
      },
      productApi: {
        active: false,
        brand_code: null,
        country_code: null,
      },
    },
    brands: [],
    criteria: [],
    events: [],
    steps: [],
    flags: [],
    eventsStepsStructure: [],
  }

  export default {
    name: 'CreatePrescription',

    components: {
      MultistepForm,
      ConfirmationModal,
    },

    data() {
      return {
        stepsLabels: [
          'Instance Configuration',
          'API Configuration',
          'Prescription Configuration',
          'Prescription Routine Configuration',
        ],
        success: false,
        stepIndex: 1,
        isAppConfigurationValid: false,
        formData: JSON.parse(JSON.stringify(initialData)),
      }
    },

    computed: {
      title() {
        return 'New prescription'
      },

      stepsComponents() {
        return [
          'CreatePrescriptionAppConfiguration',
          'CreatePrescriptionApiConfiguration',
          'CreatePrescriptionConfiguration',
          'CreatePrescriptionViewsConfiguration',
        ].map((component, index) => {
          return {
            id: component,
            label: this.stepsLabels[index],
            component: () =>
              import(
                `@/components/prescription/create-prescription/${component}`
              ),
            step: index + 1,
          }
        })
      },

      isNextDisabled() {
        let isDisabled = true

        switch (this.stepIndex) {
          case 1:
            isDisabled =
              this.formData?.configuration?.approute?.id === null ||
              this.formData?.configuration?.approute?.id === undefined
            break
          case 2:
            isDisabled = !this.apiConfigurationValidation
            break
          case 4:
            isDisabled =
              !this.validEventsStepsStructure ||
              (this.formData.configuration.prescriptionType ===
                PRESCRIPTION_TYPES.AI_POWERED &&
                (this.formData.maxDesiredProducts < 1 ||
                  this.formData.maxDesiredProducts > 6))
            break
          default:
            isDisabled = false
            break
        }

        return isDisabled || this.isAppLoading
      },

      apiConfigurationValidation() {
        const { airtableApi, productApi, lookApi } =
          this.formData?.configuration || {}

        // AIRTABLE VALIDATION.
        if (airtableApi?.active === true) {
          const regex = new RegExp('^app[a-zA-Z0-9]{14}$', 'g')
          // isAirtableIdValid
          if (
            airtableApi?.airtableId?.length == 0 ||
            !regex.test(airtableApi?.airtableId)
          ) {
            return false
          }
          if (airtableApi?.look === false && airtableApi?.product === false) {
            return false
          }
        }

        // PRODUCT API VALIDATION.
        if (productApi?.active === true) {
          if (
            productApi?.brand_code === null ||
            productApi?.country_code == null
          ) {
            return false
          }
        }

        // LOOK API VALIDATION.
        if (lookApi?.active === true) {
          if (
            Object.keys(lookApi)
              .filter((key) => key !== 'active')
              .some((key) => !lookApi[key])
          ) {
            return false
          }
        }

        return true
      },

      validEventsStepsStructure() {
        const { events, steps, eventsStepsStructure } = this.formData
        return (
          events?.length > 0 &&
          steps?.length > 0 &&
          eventsStepsStructure?.length > 0 &&
          eventsStepsStructure.every(
            (event) =>
              !!event.id &&
              !!event.label &&
              event.steps?.length > 0 &&
              event.steps.every((step) => !!step.id && !!step.label),
          )
        )
      },

      totalStepNumber() {
        if (
          this.formData?.configuration?.approute?.useDefault ||
          (this.formData?.configuration?.json !== null &&
            this.formData?.configuration?.json !== undefined)
        )
          return 1
        return 4
      },

      linkToPrescription() {
        const route = this.formData?.configuration?.approute
        return route
          ? { name: 'prescription', params: { app_id: route?.id } }
          : '/'
      },
    },

    methods: {
      handleSubmit() {
        if (this.formData?.configuration?.approute?.eraseAndReplace) {
          this.$bvModal.show('modal-delete-confirmation-prescription-creation')
        } else {
          this.submitForm()
        }
      },

      async submitForm() {
        this.appLoading(true)
        const prescriptionForm = {
          ...this.formData,
          criteria: this.formData.criterias,
          eventsStepsStructure: this.formData.eventsStepsStructure?.map(
            (event) => {
              return {
                eventId: event.id,
                steps: event.steps?.map((step) => {
                  return {
                    stepId: step.id,
                  }
                }),
              }
            },
          ),
        }
        delete prescriptionForm.criterias

        try {
          await Functions.createPrescription(prescriptionForm)

          this.success = true
        } catch (error) {
          this.appLoading(false)
          this.handleErrors({ error })
        } finally {
          this.appLoading(false)
        }
      },
    },
  }
</script>
