/**
 * The Look class.
 */
export class Look {
  readonly brand: string
  readonly sku: string
  readonly item: string
  readonly label: string
  readonly type: string
  readonly image: string

  /**
   * Look constructor.
   *
   * @param {String} [brand] - The look's brand.
   * @param {String} [sku] - The look's sku.
   * @param {String} [label] - The look's label.
   * @param {String} [image] - The look's image.
   * @return {Object} Return a Look object.
   */
  constructor({ brand = '', sku = '', label = '', image = '' } = {}) {
    ;(this.brand = brand),
      (this.sku = sku.toString()),
      (this.item = `look_${this.sku}`),
      (this.label = label),
      (this.type = 'look'),
      (this.image = image)
  }
}
