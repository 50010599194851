<template>
  <div
    v-if="isPrescriptionDataLoaded"
    :class="isLocked ? 'prescription-loader_container' : ''"
  >
    <FirecampLoader
      v-if="isLocked"
      :percentage="progressValue"
      :loading-bar="hasLoadingBar"
      :title="loaderMessage"
    />
    <router-view v-else />
  </div>
</template>

<script>
  import { PrescriptionMixin } from '@/mixins/PrescriptionMixin.js'
  import { mapGetters, mapState } from 'vuex'
  import FirecampLoader from '@/components/loader/FirecampLoader'

  export default {
    name: 'Prescription',

    components: {
      FirecampLoader,
    },

    data() {
      return {
        progressValue: 0,
      }
    },

    mixins: [PrescriptionMixin],

    computed: {
      ...mapState({
        isPrescriptionDataLoaded: (state) =>
          state.prescriptions.prescriptionData.isPrescriptionDataLoaded,
      }),

      ...mapGetters(['isLocked', 'isAIComputationRunning', 'progressCopy']),

      hasLoadingBar() {
        return !this.isAIComputationRunning
      },

      loaderMessage() {
        return !this.isAIComputationRunning
          ? 'We are doing a prescription backup. Please wait until the process is complete'
          : 'AI powered prescription computation running.'
      },
    },

    watch: {
      isDBLoaded: {
        async handler(newValue) {
          if (newValue) {
            await this.initState()
          }
        },
        immediate: true,
      },
      progressCopy(newValue) {
        if (newValue > this.progressValue) {
          this.progressValue = newValue
          if (this.isLocked && newValue === 100) {
            this.resetPrescriptionState()
          }
        }
      },
    },
  }
</script>

<style lang="scss">
  .prescription-loader_container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
