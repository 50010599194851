import lodash from 'lodash/fp'
const { isObject } = lodash

/**
 * Dynamic sort function.
 *
 * @param {String} key - The key to sort on.
 * @param {String} [order] - If this is asc or desc.
 */
export function compareValues(key: string = null, order = 'asc') {
  return function innerSort(a: any, b: any): number {
    const aValue = getObjectValueDynamically(key, a)
    const bValue = getObjectValueDynamically(key, b)

    if (
      aValue === undefined ||
      aValue === null ||
      bValue === undefined ||
      bValue === null
    ) {
      return 0
    }

    const varA = typeof aValue === 'string' ? aValue.toUpperCase() : aValue
    const varB = typeof bValue === 'string' ? bValue.toUpperCase() : bValue

    let comparison = 0
    if (varA > varB) {
      comparison = 1
    } else {
      comparison = -1
    }

    return order === 'desc' ? comparison * -1 : comparison
  }
}

/**
 * Get a object value even if this is deeply nested.
 *
 * @param {String} path - The path to get the value separated by a dot.
 * @param {Object} object - The object to search in.
 * @return {Any} The value to return.
 */
export function getObjectValueDynamically(
  path: string | undefined,
  object: Record<string, any>,
): any {
  if (!path && !object) return null
  if (!path && !!object) return object
  return path
    .split('.')
    .reduce(
      (previous, current) => (previous ? previous[current] : null),
      object || self,
    )
}

/**
 * Check if an array has the same values inside.
 * Do not check if this is the same order.
 *
 * @param {Array} array1 - First array.
 * @param {Array} array2 - Second array.
 * @return {Boolean} Return true if the arrays has the same values.
 */
export function arraysHasSameValues(array1: any[], array2: any[]): boolean {
  if (!Array.isArray(array1) || !Array.isArray(array2)) return false
  if (array1.length !== array2.length) return false
  if (array1.length === 0 && array2.length === 0) return true

  return array1.every((value) => array2.includes(value))
}

/**
 * Check if two objects has the same values inside.
 *
 * @param {Object} object1 - First object.
 * @param {Object} object2 - Second object.
 * @return {Boolean} Return true if the two objects have the same values.
 */
export function objectsHasSameValues(object1: any, object2: any): boolean {
  if (typeof object1 !== 'object' || typeof object2 !== 'object') return false
  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)
  if (keys1.length !== keys2.length) return false

  for (const key of keys1) {
    if (object1[key] !== object2[key]) return false
  }

  return true
}

/**
 * Returns a promise that waits for the specified duration
 *
 * @param {Number} durationMs - Duration in milliseconds
 * @return {void}
 */
export function delay(durationMs = 1000): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, durationMs))
}

/**
 * Order an object by key.
 * @param {Object} sourceObj - The object to order.
 * @return {Object} The ordered object.
 */
export function orderObject(sourceObj: Record<string, any>) {
  return Object.keys(sourceObj)
    .sort()
    .reduce((obj, key) => {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        obj[key] = orderObject(sourceObj[key])
      } else {
        obj[key] = sourceObj[key]
      }
      return obj
    }, {})
}

/**
 * Return string formatted with value of tokenMap
 * @param str string to be converted
 * @param tokensMap
 * @return string with tokens replaced
 * @example
 * const tokensMap = {
 * 'token1': 'value1',
 * 'token2': 'value2',
 * }
 * replaceTokens('{token1} {token2}', tokensMap) // return 'value1 value2'
 *
 */
export function replaceStringTokens(
  str: string,
  tokensMap: Record<string, string | number>,
) {
  return Object.entries(tokensMap).reduce(
    (newStr, [token, value]) =>
      newStr.replace(new RegExp(`{${token}}`, 'g'), `${value}`),
    str,
  )
}

/**
 * Return an object without the property passed as parameter
 * @param obj the object to remove the property from
 * @param propertyKey the property to delete
 * @return object The object without the meta property
 * @example
 * const obj = {
 * 'label': 'value1',
 * 'meta': {
 *		'active': true
 * 	},
 * 'c': {
 * 		'meta': 'string',
 * 		'd': true
 * 	}
 * }
 *
 * deletePropertyRec(obj, 'meta') // returns { 'label': 'value1', 'c': { 'd': true } }
 */
export function deletePropertyRec(obj, propertyKey: string) {
  if (!isObject(obj) || Array.isArray(obj)) {
    return Array.isArray(obj)
      ? obj.map((o) => deletePropertyRec(o, propertyKey))
      : obj
  }

  return Object.entries(obj).reduce((acc, [key, value]) => {
    return {
      ...acc,
      ...(key !== propertyKey && {
        [key]: deletePropertyRec(value, propertyKey),
      }),
    }
  }, {})
}
