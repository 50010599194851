import ApiService from '@/service/ApiService'

const AUDIT_ACTION = ['CREATE', 'UPDATE', 'DELETE']

export type Documents = string[] | string

class AuditService extends ApiService {
  constructor() {
    super()
  }

  /**
   * Log action on documents.
   *
   * @async
   * @param {String} action - The type of action to be logged. see AUDIT_ACTION.
   * @param {String|Array} documents - The documents ids.
   * @return {Promise<Object>} Returns an object with a success Boolean.
   */
  async sendAuditLog(action: string, documents: Documents): Promise<void> {
    if (!action || !AUDIT_ACTION.includes(action))
      throw new Error(`Audit Log action not accepted: ${action}`)
    if (!documents || documents?.length === 0)
      throw new Error('Document for Audit Log is missing.')

    const requestPayload = {
      functionName: 'audit',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: { action, documents },
    }

    return await super.cloudFunctionsBase<void>(requestPayload)
  }

  /**
   * Log a CREATE audit log.
   *
   * @async
   * @param {String|Array} documents - The documents ids.
   * @return {Promise<Object>} Returns an object with a success Boolean.
   */
  async create(documents: Documents): Promise<void> {
    return await this.sendAuditLog('CREATE', documents)
  }

  /**
   * Log an UPDATE audit log.
   *
   * @async
   * @param {String|Array} documents - The documents ids.
   * @return {Promise<Object>} Returns an object with a success Boolean.
   */
  async update(documents: Documents): Promise<void> {
    return await this.sendAuditLog('UPDATE', documents)
  }

  /**
   * Log a DELETE audit log.
   *
   * @async
   * @param {String|Array} documents - The documents ids.
   * @return {Promise<Object>} Returns an object with a success Boolean.
   */
  async delete(documents: Documents): Promise<void> {
    return await this.sendAuditLog('DELETE', documents)
  }
}

export default AuditService
