<template>
  <BaseManagement
    :filtered-data="filteredCollection"
    :loading="loading"
    search-field-label="Search by name"
    name="question"
    :modal-component="modalComponent"
    :modal-props="{ documentType, activeQuestionnairePath }"
    @created="init"
    @deleteConfirmed="deleteDocument"
    @searchInput="searchByNameOrId"
  />
</template>

<script>
  import { QuestionnairesManagementMixin } from '@/mixins/QuestionnairesManagementMixin'
  import { TILE_TAGS_COLOR } from '@/constants/ColorConstants'

  import BaseManagement from '@/components/admin/BaseManagement'

  export default {
    name: 'QuestionsManagement',

    components: {
      BaseManagement,
    },

    mixins: [QuestionnairesManagementMixin],

    data() {
      return {
        documentType: 'questions',
      }
    },

    methods: {
      async setCollectionForList(questions) {
        if (!questions) return []

        return questions?.map((question) => {
          const { id, label, value, applications } = question

          return {
            id,
            label: label ?? id,
            details: {
              id: id,
              ...(applications?.length > 0 && {
                applications: applications?.map((application) => {
                  return {
                    color: TILE_TAGS_COLOR,
                    id: application,
                    label: application,
                  }
                }),
              }),
              ...(value && { question: value }),
            },
            tagsValues: ['applications'],
            quickActionButton: [
              {
                label: 'Edit question',
                icon: 'pencil-fill',
                variant: 'success',
                onClick: ['edit', question],
              },
              {
                label: 'Delete question',
                variant: 'danger',
                icon: 'trash',
                divider: true,
                onClick: ['delete', id],
              },
            ],
          }
        })
      },
    },
  }
</script>
