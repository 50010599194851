import { FireComponent, FireHierarchy } from '@/models'
import {
  database,
  updateRef,
  addDocToCollection,
  getNewServerTimestamp,
  getDocumentReference,
  deleteRef,
  getDocumentsInCollection,
  doesDocExist,
} from '@/service/FirebaseService'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import firebase from 'firebase/compat/app'
import auth from '@/firebase/auth'
import { RootState } from '@/store'
import { RESOURCES } from '@/service/ResourceService'

export type Meta = {
  version: string
  createdAt: firebase.firestore.FieldValue
  updatedAt: firebase.firestore.FieldValue
  subcollections?: string[]
  lastModifiedBy?: string
  active: boolean
}

export type Collections = {
  appcontent: Record<string, any>
  appconfigurations: Record<string, any>
  appresources: Record<string, any>
  appcustomisations: Record<string, any>
  appprescriptions: Record<string, any>
}

type Application = {
  id: string
  description?: string
  displayName: string
  meta: Meta
}

type Version<T = Application> = {
  id: string
  application: T
  description?: string
  releaseNotes?: string
  meta: Meta
  prescription?: boolean
}

export type AppVersionState = {
  activeVersions: Version[]
  currentVersion: {
    id: string | null
    hierarchy: string[]
    components: FireComponent[]
    releaseNotes: string | null
    description: string | null
    hasPrescription: boolean
  }
}

const state: AppVersionState = {
  activeVersions: [],
  currentVersion: {
    id: null,
    hierarchy: [],
    components: [],
    releaseNotes: null,
    description: null,
    hasPrescription: false,
  },
}

const mutations: MutationTree<AppVersionState> = {
  setActiveVersion(state, activeVersions) {
    state.activeVersions = activeVersions
  },

  setCurrentVersion(
    state,
    {
      appVersionId,
      hierarchy,
      components,
      hasPrescription,
      releaseNotes,
      description,
    }: {
      appVersionId?: string
      hierarchy?: string[]
      hasPrescription?: boolean
      releaseNotes?: string
      description?: string
      components?: FireComponent[]
    },
  ) {
    if (appVersionId !== undefined) {
      state.currentVersion.id = appVersionId
    }

    if (hierarchy !== undefined) {
      state.currentVersion.hierarchy = hierarchy
    }

    if (components !== undefined) {
      state.currentVersion.components = components
    }

    if (releaseNotes !== undefined) {
      state.currentVersion.releaseNotes = releaseNotes
    }

    if (description !== undefined) {
      state.currentVersion.description = description
    }

    if (hasPrescription !== undefined) {
      state.currentVersion.hasPrescription = hasPrescription
    }
  },
}

const actions: ActionTree<AppVersionState, RootState> = {
  async bindAppVersions({ commit, rootGetters }) {
    const activeVersions = await getDocumentsInCollection('appversions', true)
    const applications = rootGetters.getUserScopeCollection('applications')
    const activeVersionsFilterred = activeVersions
      .filter((a) => {
        return (
          !a.application?.id ||
          (applications.included.includes('*') &&
            !applications.excluded.includes(a.application.id)) ||
          applications.included.includes(a.application.id)
        )
      })
      .sort((a, b) =>
        b.application.id === a.application.id
          ? a.meta.version.localeCompare(b.meta.version, undefined, {
              numeric: true,
            })
          : b.application.id - a.application.id,
      )
    commit('setActiveVersion', activeVersionsFilterred)
    return activeVersionsFilterred
  },

  unbindAppVersions({ commit }) {
    commit('setActiveVersion', { key: 'activeVersions', value: [] })
  },

  async bindCurrentVersion(
    { commit },
    { appVersionId }: { appVersionId: string },
  ) {
    const [components, hierarchy] = await Promise.all([
      getDocumentsInCollection(
        `appversions/${appVersionId}/firecomponents`,
        true,
      ),
      getDocumentsInCollection(
        `appversions/${appVersionId}/firehierarchy`,
        true,
      ),
    ])

    commit('setCurrentVersion', {
      components,
      hierarchy,
    })
  },

  unbindCurrentVersion({ commit }) {
    commit('setCurrentVersion', { components: [], hierarchy: [] })
  },

  async createVersion(
    { dispatch },
    {
      appversion,
      hierarchy,
      components,
      defaultcontent,
    }: {
      hierarchy?: FireHierarchy
      appversion: Version<string>
      components: FireComponent[]
      defaultcontent: Collections[]
    },
  ) {
    if (!appversion) throw new Error('App version settings are missing.')

    const feedback = {
      versionRef: null,
    }

    // Create appversion id
    const appVersionId = `${appversion.application}-${
      appversion.meta.version.split('.')[0]
    }_${appversion.meta.version.split('.')[1]}`

    // Check if appversion already exists
    if (await doesDocExist('appversions', appVersionId)) {
      throw new Error('This app version already exists!')
    }

    const appVersionFirestore: Version<firebase.firestore.DocumentReference> = {
      ...appversion,
      meta: {
        createdAt: getNewServerTimestamp,
        updatedAt: getNewServerTimestamp,
        subcollections: ['firecomponents', 'firehierarchy', 'defaultcontent'],
        active: true,
        lastModifiedBy: (await auth.getCurrentUser()).uid,
        version: appversion.meta.version,
      },
      application: database
        .collection('applications')
        .doc(appversion.application),
    }

    // Create appversion
    await addDocToCollection(
      null,
      'appversions',
      appVersionFirestore,
      null,
      appVersionId,
    )

    if (!hierarchy) {
      hierarchy = <FireHierarchy>{}
    }
    // Add hierarchy
    try {
      hierarchy.meta = {
        active: true,
        createdAt: getNewServerTimestamp,
        updatedAt: getNewServerTimestamp,
        version: 1,
      }

      await addDocToCollection(
        `appversions/${appVersionId}`,
        'firehierarchy',
        hierarchy,
        null,
        `hierarchy-${hierarchy.meta.version}`,
      )
    } catch (error) {
      throw new Error(
        `Error while adding the hierarchy. ${error?.message ?? error} `,
      )
    }

    if (components) {
      // Add components
      try {
        await Promise.all(
          Object.keys(components).map(async (componentKey) => {
            return Promise.allSettled(
              Object.keys(components[componentKey]).map(async (sectionKey) => {
                components[componentKey][sectionKey].meta = {
                  active: true,
                  createdAt: getNewServerTimestamp,
                  updatedAt: getNewServerTimestamp,
                  version: 1,
                  section: componentKey,
                }

                return addDocToCollection(
                  `appversions/${appVersionId}`,
                  'firecomponents',
                  components[componentKey][sectionKey],
                  null,
                  sectionKey,
                )
              }),
            )
          }),
        )
      } catch (error) {
        throw new Error(
          `Error while adding the components. ${error?.message ?? error}`,
        )
      }
    }
    // Add defaultcontent
    try {
      await Promise.all(
        Object.keys(defaultcontent).map(async (contentKey) => {
          if (defaultcontent[contentKey]) {
            defaultcontent[contentKey].meta = {
              active: true,
              createdAt: getNewServerTimestamp,
              updatedAt: getNewServerTimestamp,
              version: 1,
            }

            return addDocToCollection(
              `appversions/${appVersionId}`,
              'defaultcontent',
              defaultcontent[contentKey],
              null,
              contentKey,
            )
          }
        }),
      )
    } catch (error) {
      throw new Error(
        `Error while adding the defaultcontent. ${error?.message ?? error}`,
      )
    }

    // Get the new version reference
    feedback.versionRef = await database
      .collection('appversions')
      .doc(appVersionId)
      .get()
      .then((doc) => {
        return doc.ref
      })
    await dispatch('bindAppVersions')
    return feedback
  },

  async updateVersionDetails(
    _,
    {
      versionPath,
      releaseNotes,
      description,
      prescriptionEnabled,
    }: {
      versionPath: string
      releaseNotes?: string
      description?: string
      prescriptionEnabled?: boolean
    },
  ) {
    try {
      // Update minor version
      if (
        [null, undefined].includes(releaseNotes) &&
        [null, undefined].includes(description) &&
        [null, undefined].includes(prescriptionEnabled)
      )
        await updateRef(versionPath)
    } catch (error) {
      throw new Error(
        `Error while updating the application version: ${
          error?.message ?? error
        }`,
      )
    }

    if (
      ![null, undefined].includes(releaseNotes) ||
      ![null, undefined].includes(description) ||
      ![null, undefined].includes(prescriptionEnabled)
    ) {
      try {
        await updateRef(versionPath, {
          ...(![null, undefined].includes(releaseNotes) && { releaseNotes }),
          ...(![null, undefined].includes(description) && { description }),
          ...(![null, undefined].includes(prescriptionEnabled) && {
            prescription: prescriptionEnabled,
          }),
        })
      } catch (error) {
        throw new Error(
          `Error while updating the version informations: ${
            error?.message ?? error
          }`,
        )
      }
    }
  },

  async updateVersion(
    { dispatch },
    {
      appVersionId,
      releaseNotes,
      description,
      prescriptionEnabled,
      hierarchy,
      components,
      collections,
      simulate,
    }: {
      releaseNotes?: string
      description?: string
      prescriptionEnabled?: boolean
      appVersionId: string
      hierarchy?: FireHierarchy
      simulate?: boolean

      components?: FireComponent
      collections?: Collections
    },
  ) {
    const feedback = {
      routesUpdate: {},
      firecomponentsDifferences: [],
    }

    const versionRef = getDocumentReference(`appversions/${appVersionId}`)
    if (!simulate) {
      await dispatch('updateVersionDetails', {
        releaseNotes,
        description,
        prescriptionEnabled,
        versionPath: versionRef.path,
      })
      await dispatch('updateMappingVersion', {
        versionReference: versionRef,
        appVersionId,
        hierarchy,
        components,
        collections,
      })
    }

    // Show simulation of the future deleted firecomponents documents after a hierarchy update.
    if (hierarchy) {
      // REFACTO: already used in updateMappingVersion
      const firecomponentsDifferences = await getFirecomponentsDifferences(
        hierarchy,
        appVersionId,
      )

      feedback.firecomponentsDifferences = firecomponentsDifferences
    }

    // Update existing routes with default content
    if (collections) {
      feedback.routesUpdate = await dispatch('compareAndUpdateRoutes', {
        versionRef,
        collections,
        simulate,
      })
    }

    return feedback
  },

  async updateMappingVersion(
    { dispatch },
    {
      versionReference,
      appVersionId,
      hierarchy,
      components,
      collections,
    }: {
      versionReference?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
      appVersionId: string
      hierarchy?: FireHierarchy

      components?: FireComponent
      collections?: Collections
    },
  ) {
    let versionRef = versionReference
    if (!versionRef) {
      versionRef = getDocumentReference(`appversions/${appVersionId}`)
    }
    // Update hierarchy if necessary
    if (hierarchy) {
      const firecomponentsDifferences = await getFirecomponentsDifferences(
        hierarchy,
        appVersionId,
      )

      const querySnapshot = await versionRef
        .collection('firehierarchy')
        .where('meta.active', '==', true)
        .get()

      await Promise.all([
        querySnapshot.docs.map(async (doc) => {
          await updateRef(doc.ref.path, {
            meta: {
              version: doc.data().meta.version + 1,
              active: true,
            },
            sections: hierarchy.sections,
          })
        }),
        firecomponentsDifferences.map(async (id) =>
          deleteRef(`appversions/${appVersionId}/firecomponents/${id}`),
        ),
      ])
    }

    // Update components if necessary
    if (components) {
      try {
        await Promise.all(
          Object.keys(components).map(async (componentKey) => {
            await Promise.all(
              Object.keys(components[componentKey]).map(async (sectionKey) => {
                const versionSnap = await versionRef
                  .collection('firecomponents')
                  .doc(sectionKey)
                  .get()

                if (versionSnap.exists) {
                  await updateRef(versionSnap.ref.path, {
                    dependencies:
                      components[componentKey][sectionKey].dependencies || [],
                    fields: components[componentKey][sectionKey].fields,
                    meta: {
                      active: true,
                    },
                  })
                } else {
                  components[componentKey][sectionKey].meta = {
                    section: componentKey,
                  }

                  await addDocToCollection(
                    `appversions/${appVersionId}`,
                    'firecomponents',
                    components[componentKey][sectionKey],
                    null,
                    sectionKey,
                  )
                }
              }),
            )
          }),
        )
      } catch (error) {
        throw new Error(
          `Error while updating the application components: ${
            error?.message ?? error
          }`,
        )
      }
    }

    // Update/create default content.
    if (collections) {
      try {
        // Check if the defaultcontent collection exists.
        const defaultContent = await versionRef
          .collection('defaultcontent')
          .get()

        await Promise.all(
          Object.keys(collections).map(async (contentKey) => {
            // Return early if the collection is null.
            if (collections[contentKey] === null) return
            // Get snapshot of current default content to check if it exists.
            const defaultContentSnapshot = await versionRef
              .collection('defaultcontent')
              .doc(contentKey)
              ?.get()

            // If exists then we only update the right documents
            if (defaultContent.size > 0 && defaultContentSnapshot?.exists) {
              await updateRef(
                `${versionRef.path}/defaultcontent/${contentKey}`,
                collections[contentKey],

                // override because properties with '.' in their name are converted into objects
                // with Firebase SDK using `.update()`
                // `.set()` won't have this problem
                true,
              )
            } else {
              // Else we create all the related documents.
              collections[contentKey].meta = {
                active: true,
                createdAt: getNewServerTimestamp,
                updatedAt: getNewServerTimestamp,
                version: 1,
              }

              await addDocToCollection(
                `appversions/${appVersionId}`,
                'defaultcontent',
                collections[contentKey],
                null,
                contentKey,
              )
            }
          }),
        )
      } catch (error) {
        throw new Error(
          `Error while update the default contents: ${error?.message ?? error}`,
        )
      }
    }

    dispatch('bindAppVersions')
  },

  async compareAndUpdateRoutes(
    { dispatch, rootGetters },
    {
      appVersionId,
      versionRef,
      collections,
      simulate,
    }: {
      appVersionId: string
      versionRef?: firebase.firestore.DocumentReference
      simulate?: boolean
      collections?: Collections
    },
  ) {
    const routesUpdate = {}
    if (!versionRef) {
      versionRef = getDocumentReference(`appversions/${appVersionId}`)
    }
    if (!collections) {
      const defaultcontent = await getDocumentsInCollection(
        `appversions/${appVersionId}/defaultcontent`,
      )
      collections = <Collections>defaultcontent.reduce(
        (acc, defaultcontent) => {
          acc[defaultcontent.id] = defaultcontent
          delete defaultcontent.id
          return acc
        },
        {},
      )
    }

    await dispatch('bindAppRoutes')

    await Promise.all(
      Object.entries(collections).map(async ([collectionKey, collection]) => {
        if (collection) {
          const routes = rootGetters.getAppRoutesByAppVersion(versionRef.id)
          await Promise.all(
            routes.map(async (route) => {
              try {
                const routeFeeback = await dispatch('compareAndUpdateDoc', {
                  collectionId: collectionKey,
                  routeId: route.id,
                  dataToCompare: collections[collectionKey],
                  simulate,
                })

                if (!routesUpdate[route.id]) routesUpdate[route.id] = {}

                routesUpdate[route.id][Object.keys(routeFeeback)[0]] =
                  routeFeeback[Object.keys(routeFeeback)[0]]
              } catch (error) {
                throw new Error(
                  `Error while updating the existing routes: ${
                    error?.message ?? error
                  }`,
                )
              }
            }),
          )
        }
      }),
    )

    return routesUpdate
  },

  async setCurrentVersionById(
    { commit, dispatch, state },
    appVersionId: string,
  ) {
    if (!appVersionId) throw new Error('Missing route id.')
    await dispatch('loadResources', {
      resourceNames: [RESOURCES.APP_VERSIONS],
    })

    const version = state.activeVersions.find(
      (version) => version?.id === appVersionId,
    )
    commit('setCurrentVersion', {
      appVersionId,
      hasPrescription: version.prescription,
      releaseNotes: version.releaseNotes,
      description: version.description,
    })
    await dispatch('bindCurrentVersion', { appVersionId })
  },

  unsetCurrentVersion({ commit, dispatch }) {
    commit('setCurrentVersion', { appVersionId: null })
    dispatch('unbindCurrentVersion')
  },
}

const getters: GetterTree<AppVersionState, RootState> = {
  getAppVersions: (state) => (appID) => {
    if (state.activeVersions.length === 0 || !appID) return []
    return state.activeVersions.filter(
      (version) => version?.application?.id === appID,
    )
  },

  getAppVersionsById: (state) => (appVersionId) => {
    if (state.activeVersions.length === 0 || !appVersionId) return null
    return state.activeVersions.find((version) => version?.id === appVersionId)
  },
}

/**
 * Get firecomponents documents differences between with a new hierarchy.
 * Based on the new hierarchy and it's section and subsections.
 *
 * @async
 * @param {Object} hierarchy - The new hierarchy.
 * @return {Promise<string[]>} - The list of ids that are not in the new hierarchy.
 */
async function getFirecomponentsDifferences(
  hierarchy: FireHierarchy,
  appVersionId: string,
): Promise<string[]> {
  const subsections = hierarchy.sections
    .map((section) => section.subsections.map((subsection) => subsection.id))
    .flat()
  const collectionsSnapshot = await database
    .collection('appversions')
    .doc(appVersionId)
    .collection('firecomponents')
    .get()
  const collectionsDocs = collectionsSnapshot.docs.map((doc) => doc.id)

  return collectionsDocs.filter((doc) => !subsections.includes(doc))
}

export default {
  state,
  mutations,
  actions,
  getters,
}
