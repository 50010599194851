<template>
  <div class="base-fire-management_container">
    <FireTable
      :all-fields="allFields"
      :busy="loading"
      :fields="filteredFields"
      :filter-function="customFilterFunction"
      :items="items"
      :search-label="searchFieldLabel"
      :table-id="name"
      bordered
      enable-custom-columns
      selectable
      sort-icon-left
      @create="initCreationModal($event)"
      @edit="initCreationModal($event, true)"
      @delete="deleteModal"
    >
      <template #after-search>
        <slot name="after-table-search" />
      </template>

      <template #quick-actions>
        <b-button
          v-if="modalComponent && !disallowCreate"
          variant="primary"
          :disabled="loading"
          @click="handleCreate"
        >
          <b-icon icon="plus-circle" />
          {{ mainActionLabel }}
        </b-button>

        <slot name="additional-actions" />
      </template>

      <template
        v-for="{ key } in fieldsWithSlot"
        #[`cell(${key})`]="{ value, field, ...rest }"
      >
        <div :key="key + '_view'">
          <slot
            :name="`cell(${key})`"
            :value="value"
            :field="field"
            v-bind="rest"
          />
        </div>
      </template>
      <template #selections-actions="{ selectedItems }">
        <b-btn
          variant="danger"
          :disabled="loading"
          size="sm"
          class="ml-1"
          @click="onDeleteSelected(selectedItems)"
        >
          DELETE
        </b-btn>
      </template>
      <slot />
    </FireTable>

    <b-modal
      v-if="modalComponent && elementToAdd"
      id="modal-creation"
      v-model="showModalCreation"
      hide-footer
      :title="modalTitle"
      size="lg"
      @close="handleModal('modal-creation')"
    >
      <component
        :is="modalComponent"
        v-if="isModalComponent"
        v-bind="elementToAdd"
        @done="onDone"
        @delete="deleteModal"
      />
    </b-modal>

    <ConfirmationModal
      v-if="elementToDeleteId"
      :item-id="elementToDeleteId"
      :message="messageDeleteConfirmation"
      @cancel="resetDeleteModal"
      @close="resetDeleteModal"
      @deleteConfirmed="onDelete"
    />
  </div>
</template>

<script>
  import { firstCharacterToUppercase } from '@/utils/StringHelpers'
  import { generateShowDataValues } from '@/utils/ModalHelpers'
  import { ModalMixin } from '@/mixins/ModalMixin'

  import FireTable from '@/components/table/FireTable.vue'
  import ConfirmationModal from '@/components/modals/ConfirmationModal'
  import { mapGetters } from 'vuex'

  const showDataValues = generateShowDataValues(['modal-creation'])

  export default {
    name: 'BaseCollectionTableManagement',

    components: {
      FireTable,
      ConfirmationModal,
    },

    mixins: [ModalMixin],

    props: {
      items: {
        type: Array,
        required: true,
      },
      filteredFields: {
        type: Array,
        required: true,
      },
      allFields: {
        type: Array,
        required: true,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      searchFieldLabel: {
        type: String,
        default: 'Search by id or label: ',
      },
      customFilterFunction: {
        type: Function,
        default: null,
      },
      name: {
        type: String,
        default: 'element',
      },
      modalComponent: {
        type: [Object, String],
        required: false,
        default: null,
      },
      modalProps: {
        type: Object,
        default: null,
      },
      disallowCreate: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        elementToAdd: null,
        elementToDeleteId: null,
        searchInput: null,
        ...showDataValues,
        messageDeleteConfirmation: null,
      }
    },

    computed: {
      ...mapGetters(['getTableColumns']),

      mainActionLabel() {
        return `CREATE NEW ${this.name?.toUpperCase()}`
      },

      modalTitle() {
        const prefix = firstCharacterToUppercase(this.name)
        const suffix =
          this.elementToAdd && this.elementToAdd.edition
            ? 'edition'
            : 'creation'
        return `${prefix} ${suffix}`
      },

      isModalComponent() {
        return this.modalComponent && typeof this.modalComponent === 'object'
      },

      fieldsWithSlot() {
        return this.filteredFields.filter(
          (field) => this.$scopedSlots[`cell(${field.key})`],
        )
      },
    },

    methods: {
      initCreationModal(object, isEdition = false) {
        this.showModalCreation = true
        this.elementToAdd = {
          ...object,
          ...(this.modalProps && this.modalProps),
          included: true,
          ...(isEdition && { edition: true }),
        }
      },

      onDone(obj) {
        this.handleModal('modal-creation')
        this.$emit('created', obj)
      },

      onDelete() {
        if (this.selectedItems) {
          this.$emit('deleteSelectedConfirmed', this.selectedItems)
        } else {
          this.$emit('deleteConfirmed', this.elementToDeleteId)
        }
        this.resetDeleteModal()
      },

      deleteModal(item) {
        this.elementToDeleteId = item.id
        this.$nextTick(() => {
          this.$bvModal.show('modal-delete-confirmation-default')
        })
      },

      deleteModalSelected(selectedItems) {
        this.selectedItems = selectedItems
        this.$nextTick(() => {
          this.$bvModal.show('modal-delete-confirmation-default')
        })
      },

      onDeleteSelected(selectedItems) {
        if (selectedItems.length === 1) {
          return this.deleteModal(selectedItems[0])
        }
        this.selectedItems = selectedItems
        this.elementToDeleteId = `I want to delete ${selectedItems.length} users`
        this.messageDeleteConfirmation = `You are about to delete ${selectedItems.length} user(s).`
        this.$nextTick(() => {
          this.$bvModal.show('modal-delete-confirmation-default')
        })
      },

      resetDeleteModal() {
        this.elementToDeleteId = null
        this.selectedItems = null
        this.messageDeleteConfirmation = null
      },

      handleCreate() {
        if (this.isModalComponent) {
          this.initCreationModal({})
        } else {
          this.$emit(this.modalComponent)
        }
      },
    },
  }
</script>
