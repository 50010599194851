<template>
  <div
    class="product-configuration-form_container col"
    :class="{ 'pl-5': showComparisonData }"
  >
    <div class="production-data-info">
      <transition
        name="production-info"
        mode="out-in"
        class="row"
      >
        <b-checkbox
          v-if="comparisonAvailable"
          v-model="showComparisonData"
          switch
        >
          Compare configuration in Staging & Production
        </b-checkbox>
      </transition>
    </div>
    <div
      v-if="isDBLoaded"
      class="row"
    >
      <ProductAPIConfigurationForm
        :class="`product-api-form${
          showComparisonData ? ' product-api-form--no-margin' : ''
        }`"
        class="col"
        :environment="showComparisonData ? 'staging' : environment"
      />
      <div
        v-if="showComparisonData"
        class="forms-separator"
      />
      <transition name="comparison-form">
        <ProductAPIConfigurationForm
          v-if="showComparisonData"
          class="col"
          environment="production"
          is-comparison
        />
      </transition>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions, mapGetters } from 'vuex'
  import ProductAPIConfigurationForm from '@/components/product-api-config/ProductAPIConfigurationForm'

  export default {
    name: 'ProductAPIConfigurationEdition',

    components: {
      ProductAPIConfigurationForm,
    },

    props: {
      customerId: {
        type: String,
        required: false,
        default: '',
      },
      countryCode: {
        type: String,
        required: false,
        default: '',
      },
      environment: {
        type: String,
        default: null,
      },
    },

    data() {
      return {
        dataAvailable: false,
        showComparisonData: false,
      }
    },

    computed: {
      ...mapState(['isDBLoaded']),

      ...mapGetters('productApiSettings', [
        'hasProductionData',
        'hasStagingData',
      ]),

      comparisonAvailable() {
        return this.hasProductionData && this.hasStagingData
      },
    },

    async mounted() {
      await this.loadProductApiParams()
    },

    methods: {
      ...mapActions('productApiSettings', ['loadProductApiParams']),
    },
  }
</script>

<style lang="scss" scoped>
  .product-api-form {
    margin: auto;
    transition: margin 0.9s ease-in-out;

    &--no-margin {
      margin: 0;
    }
  }

  .production-data-info {
    margin: -30px 0 30px auto;
    width: fit-content;
  }

  .production-info-enter-active {
    transition: all 0.2s ease-in-out;
  }
  .production-info-leave-active {
    transition: all 0.2s ease-in-out;
  }
  .production-info-enter,
  .production-info-leave-to {
    transform: translateX(-100px);
    opacity: 0;
  }

  .no-production-data_label {
    padding: 10px;
    border-radius: 5px;
    border: solid 2px var(--secondary);
  }

  .comparison-form-enter-active {
    transition: all 0.3s ease-in-out;
  }
  .comparison-form-leave-active {
    transition: all 0.4s ease-in-out;
  }
  .comparison-form-enter,
  .comparison-form-leave-to {
    transform: translateX(30px);
    opacity: 0;
  }

  .forms-separator {
    margin: 0 40px 20px 40px;
    border-right: solid 1px var(--app-background-font-color);
  }

  .comparison-submit-warning {
    max-width: 75%;
    border-radius: 5px;
    margin: auto;
    padding: 10px;
  }
</style>
