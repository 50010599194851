import { getCurrentInstance } from 'vue'

export function useBvModal() {
  const { $bvModal } = getCurrentInstance().proxy

  const show = (id: string) => {
    $bvModal.show(id)
  }

  const hide = (id: string) => {
    $bvModal.show(id)
  }

  return { show, hide }
}

export default useBvModal
