import { getFieldSettingsOptions } from '@/factories/FieldMappingFactory'
import {
  FireComponentDependency,
  FireComponentField,
  FireComponentFieldRepeater,
  FireComponentFieldWithData,
} from '@/models'
import {
  setObjectInHierarchy,
  convertStringToValue,
} from '@/utils/StringHelpers'
import Vue from 'vue'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { RootState } from '@/store'
import { INVALID_FIELDS } from '../../constants/ErrorConstants'
import omit from 'lodash/fp/omit'

type FireComponentFieldMapping = FireComponentField & {
  index: number
  sectionIndex: number
  subIndex: number
  parentsIndexs: number[]
  parentFieldsKey?: string
  defaultValue?: any
}

type FireComponentMapping = {
  id: string
  label: string
  fields: FireComponentFieldMapping[]
  dependencies: FireComponentDependency[]
}

type SectionTab = {
  id: string
  index: number
  label: string
  permission: string
  subsections: FireComponentMapping[]
}

export type MappingState = {
  sectionsTabs: Array<SectionTab>
}

const state: MappingState = {
  sectionsTabs: [],
}

const mutations: MutationTree<MappingState> = {
  ADD_SECTION_TAB(state, sectionTab: SectionTab) {
    state.sectionsTabs.push(sectionTab)
  },

  ADD_SUB_SECTION_TAB(
    state,
    {
      sectionIndex,
      subSectionTab,
    }: { sectionIndex: number; subSectionTab: FireComponentMapping },
  ) {
    state.sectionsTabs[sectionIndex].subsections.push(subSectionTab)
  },

  SET_SECTIONS_TABS(state, sectionsTabs: Array<SectionTab>) {
    state.sectionsTabs = sectionsTabs
  },

  ADD_FIELD(
    state,
    {
      sectionIndex,
      subIndex,
      parentsIndexs,
      field,
      fieldIndex = null,
      parentFieldsKey = null,
    }: {
      sectionIndex: number
      subIndex: number
      parentsIndexs: number[]
      field: FireComponentFieldMapping
      fieldIndex?: number
      parentFieldsKey?: string
    },
  ) {
    let fields = state.sectionsTabs[sectionIndex].subsections[subIndex].fields
    if (parentsIndexs)
      parentsIndexs.forEach((parentIndex) => {
        fields =
          (<FireComponentFieldRepeater>fields[parentIndex]).fields ??
          fields[parentIndex][parentFieldsKey]
      })

    const index = fieldIndex ?? fields.length
    field.sectionIndex = sectionIndex
    field.subIndex = subIndex
    field.index = index
    field.parentsIndexs = parentsIndexs
    if (parentFieldsKey) field.parentFieldsKey = parentFieldsKey
    fields.splice(index, 0, field)

    resetFieldsIndex(fields)
  },

  UPDATE_FIELD(
    state,
    {
      field: {
        sectionIndex,
        subIndex,
        index,
        parentsIndexs,
        parentFieldsKey = null,
      },
      key,
      value,
    }: {
      field: FireComponentFieldMapping
      key: string
      value: any
    },
  ) {
    let fields = state.sectionsTabs[sectionIndex].subsections[subIndex].fields
    if (parentsIndexs)
      parentsIndexs.forEach((parentIndex) => {
        fields =
          (<FireComponentFieldRepeater>fields[parentIndex]).fields ??
          fields[parentIndex][parentFieldsKey]
      })

    if (!fields) {
      return
    }
    if (value === undefined) {
      delete fields[index][key]
    } else {
      Vue.set(fields[index], key, value)
    }
  },

  REMOVE_FIELD(
    state,
    {
      index,
      sectionIndex,
      subIndex,
      parentsIndexs,
      parentFieldsKey = null,
    }: {
      index: number
      sectionIndex: number
      subIndex: number
      parentsIndexs: number[]
      parentFieldsKey?: string
    },
  ) {
    let fields = state.sectionsTabs[sectionIndex].subsections[subIndex].fields
    if (parentsIndexs)
      parentsIndexs.forEach((parentIndex) => {
        fields =
          (<FireComponentFieldRepeater>fields[parentIndex]).fields ??
          fields[parentIndex][parentFieldsKey]
      })

    fields.splice(index, 1)

    resetFieldsIndex(fields)
  },

  MOVE_TO_FIELD(
    state,
    {
      fromIndex,
      sectionIndex,
      subIndex,
      parentsIndexs,
      fieldIndex,
      parentFieldsKey = null,
    }: {
      fromIndex: number
      sectionIndex: number
      subIndex: number
      parentsIndexs: number[]
      fieldIndex: number
      parentFieldsKey?: string
    },
  ) {
    let fields = state.sectionsTabs[sectionIndex].subsections[subIndex].fields
    if (parentsIndexs)
      parentsIndexs.forEach((parentIndex) => {
        fields =
          (<FireComponentFieldRepeater>fields[parentIndex]).fields ??
          fields[parentIndex][parentFieldsKey]
      })

    const draggedField = fields[fromIndex]

    if (['separator', 'subseparator'].includes(draggedField.settings.type)) {
      // Get last item of the sepator
      const toIndex = fields.findIndex(
        (field, index) =>
          index > fromIndex &&
          [draggedField.settings.type, 'separator'].includes(
            field.settings.type,
          ),
      )
      // Count number of item
      const totalToMove =
        toIndex > fromIndex ? toIndex - fromIndex : fromIndex - toIndex
      // Move items
      const fieldsToMove = fields.splice(fromIndex, totalToMove) // Remove items
      fields.splice(fieldIndex, 0, ...fieldsToMove) // Add items
    } else {
      fields.splice(fieldIndex, 0, fields.splice(fromIndex, 1)[0])
    }

    resetFieldsIndex(fields)
  },

  MOVE_SECTION(
    state,
    {
      fromIndex,
      toIndex,
    }: {
      fromIndex: number
      toIndex: number
    },
  ) {
    const section = state.sectionsTabs.splice(fromIndex, 1)[0]
    state.sectionsTabs.splice(toIndex, 0, section)
    ;[fromIndex, toIndex].forEach((sectionIndex) => {
      resetIndexSection(state, sectionIndex)
    })
  },

  REMOVE_SECTION(state, { sectionIndex }: { sectionIndex: number }) {
    state.sectionsTabs.splice(sectionIndex, 1)
    // generate array from sectionIndex to the end
    const sectionsIndexs = Array.from(
      Array(state.sectionsTabs.length - sectionIndex).keys(),
    ).map((i) => i + sectionIndex)
    sectionsIndexs.forEach((index) => {
      resetIndexSection(state, index)
    })
  },

  REMOVE_SUBSECTION_IN_SECTION(
    state,
    {
      sectionIndex,
      subIndex,
    }: {
      sectionIndex: number
      subIndex: number
    },
  ) {
    const subSections = state.sectionsTabs[sectionIndex].subsections
    subSections.splice(subIndex, 1)
    const subIndexs = Array.from(
      Array(subSections.length - subIndex).keys(),
    ).map((i) => i + subIndex)

    subIndexs.forEach((index) => {
      resetIndexSubsection(subSections, index)
    })
  },

  MOVE_SUBSECTION_IN_SECTION(
    state,
    {
      sectionIndex,
      fromIndex,
      toIndex,
    }: {
      sectionIndex: number
      fromIndex: number
      toIndex: number
    },
  ) {
    const subSections = state.sectionsTabs[sectionIndex].subsections
    const subSection = subSections.splice(fromIndex, 1)[0]
    subSections.splice(toIndex, 0, subSection)
    ;[fromIndex, toIndex].forEach((index) => {
      resetIndexSubsection(subSections, index)
    })
  },

  EDIT_SECTION(
    state,
    {
      sectionIndex,
      key,
      value,
    }: {
      sectionIndex: number
      key: string
      value: any
    },
  ) {
    state.sectionsTabs[sectionIndex][key] = value
  },

  EDIT_SUB_SECTION(
    state,
    {
      sectionIndex,
      subIndex,
      key,
      value,
    }: {
      sectionIndex: number
      subIndex: number
      key: string
      value: any
    },
  ) {
    Vue.set(state.sectionsTabs[sectionIndex].subsections[subIndex], key, value) // Vue.set is used to reload the component if value is array
  },
}

const actions: ActionTree<MappingState, RootState> = {
  addSectionTab({ commit }, sectionTab: SectionTab) {
    commit('ADD_SECTION_TAB', sectionTab)
  },

  moveSection(
    { commit },
    {
      fromIndex,
      toIndex,
    }: {
      fromIndex: number
      toIndex: number
    },
  ) {
    commit('MOVE_SECTION', { fromIndex, toIndex })
  },

  removeSection({ commit }, sectionIndex: number) {
    commit('REMOVE_SECTION', { sectionIndex })
  },

  addSubsection(
    { commit },
    {
      sectionIndex,
      subSectionTab,
    }: {
      sectionIndex: number
      subSectionTab: FireComponentMapping
    },
  ) {
    commit('ADD_SUB_SECTION_TAB', { sectionIndex, subSectionTab })
  },

  moveSubsection(
    { commit },
    {
      sectionIndex,
      fromIndex,
      toIndex,
    }: {
      sectionIndex: number
      fromIndex: number
      toIndex: number
    },
  ) {
    commit('MOVE_SUBSECTION_IN_SECTION', { sectionIndex, fromIndex, toIndex })
  },

  removeSubsection(
    { commit },
    {
      sectionIndex,
      subIndex,
    }: {
      sectionIndex: number
      subIndex: number
    },
  ) {
    commit('REMOVE_SUBSECTION_IN_SECTION', { sectionIndex, subIndex })
  },

  editSection(
    { commit },
    {
      sectionIndex,
      subIndex,
      key,
      value,
    }: {
      sectionIndex: number
      subIndex: number | null
      key: string
      value: any
    },
  ) {
    if (subIndex !== null)
      commit('EDIT_SUB_SECTION', { sectionIndex, subIndex, key, value })
    else commit('EDIT_SECTION', { sectionIndex, key, value })
  },

  updateTypeField(
    { commit },
    {
      field,
      type,
    }: {
      field: FireComponentMapping
      type: string
    },
  ) {
    Object.entries(getFieldSettingsOptions(type)).forEach(([key, value]) => {
      commit('UPDATE_FIELD', {
        field,
        key,
        value,
      })
    })
  },

  updateField(
    { commit },
    {
      field,
      key,
      value,
    }: {
      field: FireComponentMapping
      key: string
      value: any
    },
  ) {
    commit('UPDATE_FIELD', { field, key, value })
  },

  removeField(
    { commit },
    {
      index,
      sectionIndex,
      subIndex,
      parentsIndexs,
      parentFieldsKey,
    }: {
      index: number
      sectionIndex: number
      subIndex: number
      parentsIndexs: number[]
      parentFieldsKey: string
    },
  ) {
    commit('REMOVE_FIELD', {
      index,
      sectionIndex,
      subIndex,
      parentsIndexs,
      parentFieldsKey,
    })
  },

  addField(
    { commit },
    {
      field,
      sectionIndex,
      subIndex,
      parentsIndexs,
      fieldIndex,
      parentFieldsKey,
    }: {
      field: FireComponentMapping
      sectionIndex: number
      subIndex: number
      parentsIndexs: number[]
      fieldIndex: number
      parentFieldsKey: string
    },
  ) {
    commit('ADD_FIELD', {
      field,
      sectionIndex,
      subIndex,
      parentsIndexs,
      fieldIndex,
      parentFieldsKey,
    })
  },

  moveToField(
    { commit },
    {
      fromIndex,
      sectionIndex,
      subIndex,
      parentsIndexs,
      fieldIndex,
      parentFieldsKey,
    }: {
      fromIndex: number
      sectionIndex: number
      subIndex: number
      parentsIndexs: number[]
      fieldIndex: number
      parentFieldsKey: string
    },
  ) {
    commit('MOVE_TO_FIELD', {
      fromIndex,
      sectionIndex,
      subIndex,
      parentsIndexs,
      fieldIndex,
      parentFieldsKey,
    })
  },

  async testField({ dispatch, commit, getters }, field) {
    const errors = []

    if (field.id == '') {
      errors.push('Missing id')
    }

    if (!field.settings || field.settings.type == '') {
      errors.push('Missing type')
    }

    if (
      field.settings &&
      !['seperator', 'subseparator'].includes(field.settings.type)
    ) {
      if (field.settings.collection == '') {
        errors.push('Missing settings collection')
      }
    }

    if (field.settings && field.settings.dependencies) {
      const dependenciesError = field.settings.dependencies.some(
        ({ id, value }) => {
          return id === '' || value === '' || !getters.getFieldById(id)
        },
      )
      if (dependenciesError) {
        errors.push('Incomplete dependencies')
      }
    }

    if (field.fields) {
      let subFieldFieldsHasError = false
      for await (const subField of field.fields) {
        subFieldFieldsHasError =
          (await dispatch('testField', subField)).length > 0 ||
          subFieldFieldsHasError
      }
      if (subFieldFieldsHasError) {
        errors.push('Subfields errors')
      }
    }

    commit('UPDATE_FIELD', { field, key: 'errors', value: errors })

    return errors
  },

  async saveMapping(
    { getters, state, rootGetters, dispatch },
    appVersionId: string,
  ) {
    const defaultContent = {
        ...rootGetters.getAppVersionsById(appVersionId).defaultContent,
      },
      firecomponents = {},
      firehierarchySections = []
    let hasErrors = false

    // Test is field is valid
    await Promise.all(
      state.sectionsTabs.map(async ({ subsections }) => {
        return await Promise.all(
          subsections.map(async ({ fields }) => {
            return await Promise.all(
              fields.map(async (field) => {
                return await dispatch('testField', field).then((errors) => {
                  if (errors.length > 0) {
                    hasErrors = true
                    // to debug easily
                    console.error('Invalid field :', field)
                  }
                })
              }),
            )
          }),
        )
      }),
    )

    if (hasErrors) {
      throw INVALID_FIELDS
    }

    for (const {
      id: idSection,
      label,
      permission,
      subsections,
    } of state.sectionsTabs) {
      firecomponents[idSection] = {}
      const subSections = subsections.map(
        ({ fields, id, label, dependencies = [] }) => {
          const fieldsSections = fields.map((field) => {
            const formatSubField = (subField) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const rest = omit([
                'index',
                'subIndex',
                'sectionIndex',
                'parentsIndexs',
                'errors',
                'fields',
                'parentFieldsKey',
                'eventFields',
                'stepFields',
                'forceId',
              ])(subField)

              const { fields, eventFields, stepFields } = subField
              return {
                ...rest,
                ...(fields ? { fields: fields.map(formatSubField) } : {}),
                ...(eventFields
                  ? { eventFields: eventFields.map(formatSubField) }
                  : {}),
                ...(stepFields
                  ? { stepFields: stepFields.map(formatSubField) }
                  : {}),
              }
            }
            const fieldData: Record<string, any> = formatSubField(field)

            if (
              fieldData.defaultValue &&
              (<FireComponentFieldWithData>field).settings.collection
            ) {
              const paths: string[] = [
                (<FireComponentFieldWithData>field).settings.collection,
              ]
              if ((<FireComponentFieldWithData>field).settings.hierarchy) {
                paths.push(
                  ...(<FireComponentFieldWithData>field).settings.hierarchy,
                )
              }
              const defaultValue =
                field.settings.type === 'input'
                  ? field.defaultValue
                  : convertStringToValue(field.defaultValue)
              setObjectInHierarchy(
                defaultContent,
                paths,
                field.id,
                defaultValue,
              )

              delete fieldData.defaultValue
            }
            // Dependencies
            if (fieldData.settings && fieldData.settings.dependencies) {
              fieldData.settings.dependencies =
                convertDependenciesToObjectDependencies(
                  fieldData.settings.dependencies,
                  getters,
                )
            }
            delete fieldData.forceId

            return fieldData
          })

          firecomponents[idSection][id] = {
            fields: fieldsSections,
            dependencies: convertDependenciesToObjectDependencies(
              dependencies,
              getters,
            ),
          }
          return {
            id,
            label,
          }
        },
      )

      firehierarchySections.push({
        id: idSection,
        label,
        permission: permission || 'edit-app',
        subsections: subSections,
      })
    }

    await dispatch(
      'updateMappingVersion',
      {
        appVersionId,
        hierarchy: {
          sections: firehierarchySections,
        },
        components: firecomponents,
        collections: defaultContent,
      },
      { root: true },
    )
  },
}

const getters: GetterTree<MappingState, RootState> = {
  getFieldData:
    (state) =>
    ({ sectionIndex, subIndex, index, parentsIndexs, parentFieldsKey }) => {
      let fields: any = state.sectionsTabs[sectionIndex].subsections[subIndex]

      if (parentsIndexs)
        parentsIndexs.forEach((parentIndex) => {
          fields = fields.fields[parentIndex]
        })

      return parentFieldsKey
        ? fields[parentFieldsKey][index]
        : fields.fields[index]
    },

  getFieldType:
    (state) =>
    ({ sectionIndex, subIndex, parentsIndexs, parentFieldsKey }, index) => {
      let fields: any = state.sectionsTabs[sectionIndex].subsections[subIndex]

      if (parentsIndexs)
        parentsIndexs.forEach((parentIndex) => {
          fields = fields.fields[parentIndex]
        })

      return parentFieldsKey
        ? fields[parentFieldsKey][index].settings.type
        : fields.fields[index].settings.type
    },

  getSubsectionFields:
    (state) =>
    (sectionIndex, subsectionIndex, parentsIndexs, parentFieldsKey = null) => {
      const subsection =
        state.sectionsTabs[sectionIndex].subsections[subsectionIndex]

      let fields: any = subsection.fields
      if (parentsIndexs) {
        parentsIndexs.forEach((parentIndex) => {
          fields =
            fields[parentIndex].fields ?? fields[parentIndex][parentFieldsKey]
        })
      }
      return fields
    },

  allFieldIdsWithValue: (state) => {
    return state.sectionsTabs
      .map((sectionId) => {
        return sectionId.subsections.map((subsection) => {
          return subsection.fields
            .filter(
              ({ settings: { type } }) =>
                !['subseparator', 'separator'].includes(type),
            )
            .map((field) => field.id)
        })
      })
      .flat(2)
  },

  getFieldById: (state) => (id) => {
    for (const section of state.sectionsTabs) {
      for (const subsection of section.subsections) {
        const field = subsection.fields.find((field) => field.id === id)
        if (field) return field
      }
    }
  },

  getAutomaticFieldId:
    (state) =>
    (sectionIndex, subSectionIndex, fieldIndex, label, inRepeater = false) => {
      if (inRepeater) {
        return `{parentId}{keyNumber}_${stringToCamelCase(label)}`
      }
      const subsection =
        state.sectionsTabs[sectionIndex].subsections[subSectionIndex]
      const fields = subsection.fields

      let dataForAutomaticId = [stringToCamelCase(subsection.label)]

      // search last separator
      for (let i = fieldIndex - 1; i >= 0; i--) {
        if (/separator/g.test(fields[i].settings.type)) {
          dataForAutomaticId = [fields[i].id]
          break
        }
      }

      dataForAutomaticId.push(stringToCamelCase(label))

      return dataForAutomaticId.join('_')
    },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}

//
// UTILS
//
const setIndexInField = (field, attr, index) => {
  field[attr] = index
  if (field.fields) {
    field.fields.forEach((f) => setIndexInField(f, attr, index))
  }
  return field
}

const resetIndexSection = (state, sectionIndex) => {
  state.sectionsTabs[sectionIndex].subsections = state.sectionsTabs[
    sectionIndex
  ].subsections.map((subsection) => {
    return {
      ...subsection,
      fields: subsection.fields.map((field) => {
        return setIndexInField(field, 'sectionIndex', sectionIndex)
      }),
    }
  })
  state.sectionsTabs[sectionIndex].index = sectionIndex
}

const resetIndexSubsection = (subSections, subIndex) => {
  subSections[subIndex].fields = subSections[subIndex].fields.map((field) => {
    return setIndexInField(field, 'subIndex', subIndex)
  })
  subSections[subIndex].index = subIndex
}

const resetFieldsIndex = (fields) => {
  const setParentsIndexsInSubFields = (field) => {
    if (field.fields) {
      field.fields = field.fields.map((f) => {
        f.parentsIndexs = [...(field.parentsIndexs ?? []), field.index]
        setParentsIndexsInSubFields(f)
        return f
      })
    }
    for (const key of ['stepFields', 'eventFields']) {
      if (field[key]) {
        field[key] = field[key].map((f) => {
          f.parentsIndexs = [...(field.parentsIndexs ?? []), field.index]
          f.parentFieldsKey = key
          return f
        })
      }
    }
  }

  return fields.map((f, i) => {
    const field = { ...f, index: i }
    setParentsIndexsInSubFields(field)

    return field
  })
}

/**
 * Search collection and hierarchy in defaultContent
 * @param {{value, id}[]} dependencies
 * @param {object} getters
 * @returns {{id, value, collection, hierarchy}[]}
 */
const convertDependenciesToObjectDependencies = (dependencies, getters) => {
  return dependencies.map(({ value, id }) => {
    const field = getters.getFieldById(id)
    const {
      settings: { collection, hierarchy = [] },
    } = field
    return {
      id,
      value: convertStringToValue(value),
      collection,
      hierarchy,
    }
  })
}

const stringToCamelCase = (str) => {
  return str
    .replace(/\s(.)/g, function ($1) {
      return $1.toUpperCase()
    })
    .replace(/\s/g, '')
    .replace(/^([A-Z])/, (match) => match.toLowerCase())
}
