<template>
  <div class="main-collection_container container-fluid">
    <TitleContainer
      v-if="!included"
      :title="title"
    />
    <b-form
      class="main-collection_form"
      @submit.prevent="onSubmit"
      @reset.prevent="onReset"
    >
      <div class="main-collection_fields">
        <b-form-group
          class="col-9 mb-2 pl-0"
          label="Criteria id:"
          label-for="criteria-input-id"
        >
          <b-form-input
            id="criteria-input-id"
            v-model="idValue"
            :disabled="isAppLoading || edition"
            :debounce="500"
            :state="idState"
            aria-describedby="criteria-input-id-feedback"
            required
          />
          <b-form-invalid-feedback id="criteria-input-id-feedback">
            {{ errorMessage }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          class="col-9 mb-2 pl-0"
          label="Label:"
          label-for="criteria-input-label"
        >
          <b-form-input
            id="criteria-input-label"
            v-model="labelValue"
            :disabled="isAppLoading"
            :debounce="500"
            required
          />
        </b-form-group>
        <b-form-group
          class="col-9 mb-2 pl-0"
          label="Bmdm ref:"
          label-for="criteria-input-bmdm-ref"
        >
          <b-form-input
            id="criteria-input-bmdm-ref"
            v-model="bmdmRefValue"
            :disabled="isAppLoading"
            :debounce="500"
            required
          />
        </b-form-group>
        <b-form-group
          class="col-9 mb-2 pl-0"
          label="Bmdm value:"
          label-for="criteria-input-bmdm-value"
        >
          <b-form-input
            id="criteria-input-bmdm-value"
            v-model="bmdmValueValue"
            :disabled="isAppLoading"
            :debounce="500"
            required
          />
        </b-form-group>
        <b-form-group
          class="col-9 mb-2 pl-0"
          label="Bmdm payload:"
          label-for="criteria-input-bmdm-payload"
        >
          <JSONEditor
            id="criteria-input-bmdm-payload"
            :json-value.sync="bmdmPayloadValue"
            class="json-editor"
            :disabled="isAppLoading"
          />
        </b-form-group>

        <b-form-group
          class="col-9 mb-2 pl-0"
          label="Bmdm Interaction payload:"
          label-for="criteria-input-bmdm-payload-interaction"
        >
          <JSONEditor
            id="criteria-input-bmdm_interaction_payload"
            :json-value.sync="bmdmInteractionPayloadValue"
            class="json-editor"
            :disabled="isAppLoading"
          />
        </b-form-group>
        <b-form-group
          class="col-9 mb-2 pl-0"
          label="Color:"
          label-for="criteria-input-color"
        >
          <b-input-group>
            <b-form-input
              id="criteria-input-color"
              v-model="colorValue"
              :type="pickerMode ? 'color' : 'text'"
              :disabled="isAppLoading"
            />

            <b-input-group-append>
              <b-button
                variant="primary"
                @click="onSwitchColorInputMode"
              >
                <b-icon icon="arrow-repeat" />
                Switch to {{ pickerMode ? 'string' : 'picker' }} mode
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-row>
          <div :class="included ? 'action-button_container' : 'mt-4 pl-0'">
            <b-button
              type="reset"
              variant="link"
              class="text-danger"
              :class="!included ? 'w-100' : ''"
              >{{ edition ? 'Delete' : 'Reset' }}</b-button
            >
            <SubmitButton
              :disabled="isSubmitDisabled"
              :loading="isAppLoading"
              :class="included ? 'w-auto mb-auto ml-2' : ''"
            />
          </div>
        </b-row>
      </div>
    </b-form>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import { DictionnariesCreationMixin } from '@/mixins/DictionnariesCreationMixin'
  import JSONEditor from '@/components/fields/genericFields/JSONEditor'
  import { RESOURCES } from '@/service/ResourceService'

  const initialData = {
    idValue: null,
    labelValue: null,
    bmdmRefValue: null,
    bmdmValueValue: null,
    bmdmPayloadValue: null,
    colorValue: null,
    bmdmInteractionPayloadValue: null,
  }
  export default {
    name: 'CriteriaCreation',

    components: {
      JSONEditor,
    },

    mixins: [DictionnariesCreationMixin],

    props: {
      label: {
        type: String,
        default: null,
      },
      color: {
        type: String,
        default: null,
      },
      bmdmRef: {
        type: String,
        default: null,
      },
      bmdmValue: {
        type: String,
        default: null,
      },
      bmdmPayload: {
        type: String,
        default: null,
      },
      bmdmInteractionPayload: {
        type: String,
        default: null,
      },
    },

    data() {
      return {
        ...initialData,
        initialData,
        stateKey: 'criterias',
        idValidRegex: /^([a-z][a-zA-Z0-9-]+)[\w.\-&]*$/,
        defaultErrorMessage: `Id must contain words (min 2 letters without accentuation lowercase or uppercase or numbers)
					separated by . last word must be preceded by _ instead. exemple: 'analysis.concerns.acne_gea0'.`,
        pickerMode: false,
      }
    },

    computed: {
      isSubmitDisabled() {
        return (
          this.idValue === null ||
          this.idValue?.length === 0 ||
          [null, false].includes(this.idState) ||
          this.labelValue === null ||
          this.labelValue?.length === 0 ||
          this.bmdmRefValue === null ||
          this.bmdmRefValue?.length === 0 ||
          this.bmdmValueValue === null ||
          this.bmdmValueValue?.length === 0 ||
          this.bmdmPayloadValue === null ||
          this.bmdmPayloadValue?.length === 0 ||
          this.bmdmInteractionPayloadValue === null ||
          this.bmdmInteractionPayloadValue?.length === 0
        )
      },

      finalObject() {
        return {
          id: this.idValue,
          label: this.labelValue,
          bmdm_ref: this.bmdmRefValue,
          bmdm_value: this.bmdmValueValue,
          bmdm_payload: this.bmdmPayloadValue,
          bmdm_interaction_payload: this.bmdmInteractionPayloadValue,
          ...(this.colorValue && { color: this.colorValue }),
        }
      },

      displayNameValue() {
        return this.idValue
      },
    },

    async mounted() {
      await this.loadResources({ resourceNames: [RESOURCES.CRITERIAS] })

      if (!this.edition) return

      this.idValue = this.id
      this.labelValue = this.label
      this.bmdmRefValue = this.bmdmRef
      this.bmdmValueValue = this.bmdmValue
      this.bmdmPayloadValue =
        this.bmdmPayload && this.bmdmPayload?.length > 0
          ? JSON.stringify(JSON.parse(this.bmdmPayload), undefined, 4)
          : '{}'
      this.bmdmInteractionPayloadValue =
        this.bmdmInteractionPayload && this.bmdmInteractionPayload?.length > 0
          ? JSON.stringify(
              JSON.parse(this.bmdmInteractionPayload),
              undefined,
              4,
            )
          : '{}'

      this.colorValue = this.color
    },

    methods: {
      ...mapActions(['loadResources']),
      onSwitchColorInputMode() {
        this.pickerMode = !this.pickerMode
      },
    },
  }
</script>

<style lang="scss" scoped>
  .json-editor {
    .CodeMirror {
      height: 300px;
      max-width: 70%;
      border: 2px solid var(--border-color);
    }
  }
</style>
