<template>
  <b-icon
    v-b-tooltip.hover.right.noninteractive="
      'Click to see the related documentation'
    "
    :class="[!variant ? 'documentation-link_button' : '', 'cursor-pointer']"
    icon="info-circle-fill"
    :variant="variant"
    :shift-v="shiftV"
    :scale="scale"
    @click="handleClick"
  />
</template>

<script>
  export default {
    name: 'DocumentationLinkButton',

    props: {
      destination: {
        type: String,
        required: true,
      },
      variant: {
        type: String,
        default: null,
      },
      shiftV: {
        type: Number,
        default: null,
      },
      scale: {
        type: Number,
        default: null,
      },
    },

    methods: {
      handleClick() {
        this.$router.push(this.destination)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .documentation-link_button {
    color: var(--app-background-font-color);
  }
</style>
