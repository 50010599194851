
  import { mapState, mapGetters } from 'vuex'
  import { GoogleAuthProvider } from 'firebase/auth'

  import Auth from '@/firebase/auth'
  import TitleContainer from '@/components/containers/TitleContainer.vue'
  import BaseTab from '@/components/admin/BaseTab.vue'
  import type { UserState } from '../../store/modules/user'

  export default {
    name: 'MyProfile',

    components: {
      TitleContainer,
      BaseTab,
    },

    data() {
      return {
        email: '',
        providers: [],
      }
    },

    computed: {
      ...mapState({
        currentUser: (state: { user: UserState }) => state.user,
      }),

      ...mapGetters(['isModifaceRetailer']),

      userPath() {
        return 'users/' + this.currentUser?.id
      },

      canUpdateSecurityInfo() {
        return this.providers?.find(
          (provider) => provider.providerId !== GoogleAuthProvider.PROVIDER_ID,
        )
      },

      profileTabs() {
        return [
          {
            id: 'info',
            title: 'Personnal Informations',
            component: () =>
              import(
                '@/components/admin/user/profile/UserProfilePersonnalInfo.vue'
              ),
            props: {
              name: this.currentUser?.name,
              firstname: this.currentUser?.firstname,
              email: this.email,
              userPath: this.userPath,
            },
          },
          ...(this.isModifaceRetailer
            ? [
                {
                  id: 'retailers-terms-of-use',
                  title: 'Modiface Terms of Use',
                  component: () =>
                    import(
                      '@/components/admin/user/profile/UserRetailerTermsOfUse.vue'
                    ),
                },
              ]
            : []),
          ...(this.canUpdateSecurityInfo
            ? [
                {
                  id: 'password',
                  title: 'Password',
                  component: () =>
                    import(
                      '@/components/admin/user/profile/UserProfilePassword.vue'
                    ),
                  props: {
                    email: this.email,
                  },
                },
                {
                  id: 'phoneNumber',
                  title: 'Phone Number',
                  component: () =>
                    import(
                      '@/components/admin/user/profile/UserProfilePhoneNumber.vue'
                    ),
                  props: {
                    email: this.email,
                  },
                },
              ]
            : []),
          {
            id: 'themeSelection',
            title: 'Theme Selection',
            component: () =>
              import('@/components/admin/user/profile/Theme.vue'),
          },
          {
            id: 'deletion',
            title: 'Deletion',
            component: () =>
              import('@/components/admin/user/profile/UserProfileDeletion.vue'),
            props: {
              email: this.email,
            },
          },
        ]
      },
    },

    async mounted() {
      const currentUser = await Auth.getCurrentUser()
      this.email = currentUser.email
      this.providers = currentUser.providerData
    },
  }
