<template>
  <svg
    height="100%"
    version="1.1"
    viewBox="0 0 1510 215"
    width="100%"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:serif="http://www.serif.com/"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <transition
      name="fade"
      mode="out-in"
    >
      <g
        v-if="!textOnly"
        id="Firecamp"
      >
        <g id="Flames">
          <path
            id="Flame-3"
            d="M87.94,5.092c-4.446,-4.228 -11.53,-4.092 -15.81,0.304l-8.723,8.961c-4.28,4.396 -4.145,11.398 0.302,15.626l9.534,9.068c4.446,4.228 11.53,4.092 15.81,-0.305l8.723,-8.96c4.28,-4.396 4.145,-11.398 -0.301,-15.627l-9.535,-9.067Z"
            style="fill: #fa7401"
          />
          <path
            id="Flame-2"
            d="M114.259,27.649c-8.041,-7.647 -20.853,-7.4 -28.593,0.55l-25.89,26.595c-7.74,7.951 -7.496,20.614 0.545,28.261l27.22,25.885c8.041,7.647 20.853,7.401 28.593,-0.55l25.89,-26.594c7.74,-7.951 7.495,-20.614 -0.546,-28.261l-27.219,-25.886Z"
            style="fill: #fca201"
          />
          <path
            id="Flame-1"
            d="M34.54,89.013c-8.078,8.22 -8.04,21.53 0.084,29.703l44.163,44.426c8.124,8.174 21.279,8.135 29.357,-0.085l43.941,-44.712c8.078,-8.221 8.04,-21.53 -0.085,-29.703l-44.162,-44.426c-8.125,-8.174 -21.279,-8.136 -29.357,0.085l-43.941,44.712Z"
            style="fill: #febf01"
          />
        </g>
        <g id="Logs">
          <path
            id="Log-2"
            d="M180.066,134.281c-1.088,-3.732 -5.002,-5.877 -8.733,-4.789l-163.474,47.698c-3.732,1.089 -5.877,5.002 -4.788,8.734l3.945,13.522c1.089,3.732 5.002,5.877 8.734,4.789l163.474,-47.698c3.731,-1.089 5.877,-5.003 4.788,-8.734l-3.946,-13.522Z"
            style="fill: #612e25"
          />
          <path
            id="Log-1"
            d="M183.528,188.129c1.137,-3.718 -0.957,-7.659 -4.674,-8.796l-162.841,-49.814c-3.718,-1.138 -7.659,0.957 -8.796,4.674l-4.12,13.47c-1.137,3.717 0.957,7.658 4.674,8.795l162.841,49.815c3.718,1.137 7.659,-0.957 8.796,-4.675l4.12,-13.469Z"
            style="fill: #70392f"
          />
        </g>
      </g>
    </transition>
    <transition
      name="fade"
      mode="out-in"
    >
      <text
        v-if="!logoOnly"
        id="Brand"
        x="315"
        y="185"
        font-family="Blair"
        font-size="200"
        fill="#ffffff"
      >
        Firecamp
      </text>
    </transition>
  </svg>
</template>

<script>
  export default {
    name: 'FirecampLogo',

    props: {
      logoOnly: {
        type: Boolean,
        default: false,
      },
      textOnly: {
        type: Boolean,
        default: false,
      },
      text: {
        type: String,
        default: 'Firecamp',
      },
    },
  }
</script>
