<template>
  <b-button
    v-b-tooltip.hover.top.noninteractive="tooltip"
    class="wysiwyg-menu_button"
    variant="primary"
    size="sm"
    :pressed="isActive"
    :disabled="disabled"
    v-on="$listeners"
  >
    <b-icon
      :icon="icon"
      :rotate="rotate"
    />
  </b-button>
</template>

<script>
  export default {
    name: 'WysiwygMenuButton',

    props: {
      icon: {
        type: String,
        required: true,
      },
      isActive: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      rotate: {
        type: String,
        default: '0',
      },
      tooltip: {
        type: String,
        default: '',
      },
    },
  }
</script>

<style lang="scss" scoped>
  .wysiwyg-menu_button {
    margin: 0 5px 5px 0;
    height: 40px;
    width: 40px;
  }
</style>
