<template>
  <GroupInput
    :values="dependencies"
    label-add="Add dependency"
    :template-value="templateValue"
    @change="change"
  >
    <template slot-scope="{ value: { value, id }, updateValue }">
      <Multiselect
        placeholder="Select dependency id"
        select-label=""
        deselect-label=""
        :value="id"
        :options="allFieldIdsWithValue"
        required
        @input="updateValue('id', $event)"
      />
      <b-input
        class="mt-2"
        :value="value.toString()"
        required
        @input="updateValue('value', $event)"
      />
    </template>
  </GroupInput>
</template>

<script>
  import { mapGetters } from 'vuex'
  import GroupInput from '../form/GroupInput.vue'
  import Multiselect from '../form/Multiselect.vue'

  export default {
    name: 'MappingFieldsFieldSettingsDependencies',
    components: {
      Multiselect,
      GroupInput,
    },

    model: {
      prop: 'dependencies',
      event: 'change',
    },

    props: {
      dependencies: {
        type: Array,
        default: () => [],
      },
    },

    data() {
      return {
        templateValue: {
          id: '',
          value: '',
        },
      }
    },

    computed: mapGetters('mapping', ['allFieldIdsWithValue']),

    methods: {
      change(v) {
        this.$emit('change', v)
      },
    },
  }
</script>
