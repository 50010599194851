<template>
  <div class="container--admin_page">
    <TitleContainer
      :title="title"
      :subtitle="subtitle"
    />
    <BaseTab
      :tabs="components"
      link-hash-with-router
      v-on="$listeners"
    >
      <template
        v-if="!!$slots.adminactionend"
        #actionend
      >
        <slot name="adminactionend" />
      </template>
    </BaseTab>
  </div>
</template>

<script>
  import BaseTab from '@/components/admin/BaseTab'
  import TitleContainer from '@/components/containers/TitleContainer'

  export default {
    name: 'BaseAdminPage',

    components: {
      BaseTab,
      TitleContainer,
    },

    props: {
      components: {
        type: Array,
        required: true,
      },
      title: {
        type: String,
        default: null,
      },
      subtitle: {
        type: String,
        default: null,
      },
      props: {
        type: Object,
        default: () => ({}),
      },
    },
  }
</script>
