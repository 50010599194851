import { getCurrentInstance } from 'vue'

export function useAppLoading() {
  const { $store } = getCurrentInstance().proxy

  return {
    appLoading: (isLoading: boolean) => $store.commit('appLoading', isLoading),
  }
}

export default useAppLoading
