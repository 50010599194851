import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuex from 'vuex'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import BasePlugin from '@/plugins/BasePlugin'
import autoAnimate from '@formkit/auto-animate'

// STYLES
import '@/assets/scss/main.scss'

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Vuex)
Vue.use(BasePlugin, { store })

Vue.directive('auto-animate', {
  inserted: function (el, binding) {
    autoAnimate(el, binding.value)
  },
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
