<template>
  <FireTable
    :busy="loading"
    :fields="fields"
    :filter-included-fields="['label']"
    :items="profiles"
    :small="!!actions"
    hide-export
    search-label="Search by profile name"
    sort-icon-left
    table-id="profiles"
    bordered
  >
    <template #after-search>
      <slot name="after-search" />
    </template>
    <template #quick-actions>
      <slot name="quick-actions" />
    </template>

    <template #cell(isDefault)="{ value }">
      <b-icon
        v-if="value"
        title="Default profile"
        icon="shield-fill"
      />
    </template>

    <template #cell(label)="{ value, item }">
      <router-link
        class="profile-link"
        :to="{
          name: 'profile-creation-edition',
          params: {
            id: item.id,
          },
        }"
      >
        {{ value }}
      </router-link>
    </template>

    <template #cell(criteria)="{ item }">
      <p
        v-for="type in criteriaTypes"
        :key="type.key"
        v-b-tooltip.hover
        class="mb-0"
        :title="type.tooltip"
      >
        <b-icon :icon="type.icon" /> :
        <Tag
          v-for="tag in item[type.key]"
          :key="`${type.key}-${tag}`"
          :tag-fomatted="(tagFomatted = getCriteriaById(tag))"
          :color="tagFomatted.color"
          :text="tagFomatted.label"
          @click="$emit('criteriaClick', getCriteriaById(tag))"
        />
      </p>
    </template>
  </FireTable>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Tag from '@/components/tag/Tag.vue'
  import FireTable from '@/components/table/FireTable.vue'
  import { CRITERIAS_DETAILS } from '@/constants/PrescriptionConstants'
  import { FiretableFieldType } from '@/constants/FiretableFieldType.enum'

  const formatter = new Intl.NumberFormat('en', { style: 'percent' })
  export default {
    name: 'ProfilesListsProfilesTable',

    components: {
      FireTable,
      Tag,
    },

    props: {
      profiles: {
        type: Array,
        default: () => [],
      },
      actions: {
        type: Object,
        default: () => ({}),
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        CRITERIAS_DETAILS,
        fields: [
          {
            key: 'label',
            label: 'Profile Name',
            sortable: true,
            type: FiretableFieldType.Custom,
          },
          {
            key: 'criteria',
            label: 'Criteria',
            type: FiretableFieldType.Custom,
          },
          {
            key: 'hit',
            label: 'Hits',
            sortable: true,
            formatter: (_v, _k, { hit }) =>
              `${hit} (${
                this.profilesTotalHit
                  ? formatter.format(hit / this.profilesTotalHit)
                  : '0%'
              })`,
          },
          {
            key: 'isDefault',
            label: 'Default',
            sortable: true,
            type: FiretableFieldType.Custom,
          },
          this.actions,
        ],
      }
    },

    computed: {
      ...mapGetters([
        'profilesTotalHit',
        'getCriteriaById',
        'acceptItemsProfileCriteria',
      ]),

      criteriaTypes() {
        if (this.acceptItemsProfileCriteria) return CRITERIAS_DETAILS
        else
          return CRITERIAS_DETAILS.filter(
            ({ isItemCondition }) => !isItemCondition,
          )
      },
    },
  }
</script>

<style lang="scss" scoped>
  .profile-link {
    color: var(--app-background-font-color);
  }
</style>
