<template>
  <div class="additional-actions_container">
    <template v-for="action in actions">
      <b-button
        :key="action.label"
        v-b-tooltip.hover.left.noninteractive="action.tooltip"
        class="additional-actions_button"
        variant="outline"
        :href="action.href"
        :target="action.target"
        @click="parentEmitEvent(action.onClick)"
      >
        <b-icon :icon="action.icon" />
      </b-button>
    </template>
  </div>
</template>

<script>
  export default {
    name: 'TileAdditionalActions',

    props: {
      actions: {
        type: Array,
        required: true,
      },
    },

    methods: {
      parentEmitEvent(onClick) {
        if (!onClick) {
          return
        }
        const [eventName, passingValue] = onClick
        if (passingValue === undefined || passingValue === null) {
          this.$emit(eventName)
        } else {
          this.$emit(eventName, passingValue)
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .additional-actions_container {
    order: 2;
  }

  .additional-actions_button {
    padding: 0;
    color: var(--app-background-font-color);

    &:hover,
    &:focus,
    &:active {
      color: var(--primary-highlight);
      box-shadow: none;
    }
  }
</style>
