import Auth from '../firebase/auth'
import { getToken } from 'firebase/app-check'
import { appCheck } from '../firebase/firebase'

const baseUrl: string = process.env.VUE_APP_FIREBASE_FUNCTIONS_DOMAIN
export type HttpResponseStandard = {
  success: boolean
  message?: string
  code?: number
  details?: any
}

export interface CloudFunctionsBaseInput {
  functionName: string
  method: string
  headers?: Record<string, any>
  parameters?: Record<string, any>
  body?: Record<string, any>
}

class ApiService {
  /**
   * Get token for the connected user.
   *
   * @return {Promise<String>} Return the formated token.
   */
  async getAuthToken(): Promise<string> {
    const token = await (await Auth.getCurrentUser()).getIdToken()
    return `Bearer ${token}`
  }

  /**
   * Build a fetch request.
   *
   * @async
   * @param {String} method - The method (GET or POST).
   * @param {String} path - The url to fetch.
   * @param {Object} header - The headers.
   * @param {Object} parameters - The parameters.
   * @param {Object} body - The body
   * @return {Promise<Object>} - The API response (JSON)
   */
  async buildFetchRequest<TOutput>(
    method: string,
    path: string,
    header: Record<string, string> = null,
    parameters: Record<string, any> = null,
    body: Record<string, any> = null,
  ): Promise<HttpResponseStandard & TOutput> {
    let payload = null
    const init = {
      method,
      ...(body && {
        body: body instanceof FormData ? body : JSON.stringify(body),
      }),
      headers: undefined,
    }
    // Building the url.
    const url = new URL(path)

    // Add the parameters to the URL.
    if (parameters) {
      const params = new URLSearchParams()
      for (const parameter of Object.keys(parameters)) {
        const value = parameters[parameter]
        if (Array.isArray(value)) {
          value.forEach((val) => params.append(parameter, val))
        } else {
          params.append(parameter, parameters[parameter])
        }
      }
      url.search = params.toString()
    }

    // Building the headers.
    if (header) {
      const headers = new Headers(header)
      init.headers = headers
    }

    // Fetch the data.
    const response = await fetch(url.toString(), init)

    // Check the content type for json.
    const contentType = response.headers.get('content-type')

    if (contentType && contentType.includes('application/json')) {
      // Return the Json data from the response.
      payload = await response.json()

      if (
        Object.prototype.hasOwnProperty.call(payload, 'status') &&
        payload?.status?.toLowerCase() !== 'success'
      )
        throw payload
    } else {
      // Return the response to be processed after.
      payload = response

      if (!response.ok) throw response
    }

    return payload
  }

  /**
   * A base function to fetch data from Firebase/Firestore HTTPS functions.
   *
   * @async
   * @param {String} functionName - The function name to call.
   * @param {String} method - The HTTP Method.
   * @param {Object} [headers] - Some additionnal headers for the call..
   * @param {Object} [parameters] - The parameters for the GET method.
   * @param {Object} [body] - An object body for POST method.
   * @return {Promise<Object>} Return an Object with the response of fetch.
   */
  async cloudFunctionsBase<TOutput>({
    functionName,
    method,
    headers = null,
    parameters = null,
    body = null,
  }: CloudFunctionsBaseInput): Promise<HttpResponseStandard & TOutput> {
    let payload: HttpResponseStandard & TOutput = null

    const authToken = await this.getAuthToken()
    const sendAppCheckToken = process.env.VUE_APP_ENV !== 'local'

    let appCheckToken
    if (sendAppCheckToken) {
      appCheckToken = await getToken(appCheck, true)
    }

    // Building the headers.
    const formatedHeaders = {
      Authorization: authToken,
      ...(headers && headers),
    }

    if (sendAppCheckToken) {
      formatedHeaders['x-firebase-appcheck'] = appCheckToken.token
    }

    // Fetch the data.
    payload = await this.buildFetchRequest<TOutput>(
      method,
      `${baseUrl}/${functionName}`,
      formatedHeaders,
      parameters,
      body,
    )

    return payload
  }
}

export default ApiService
