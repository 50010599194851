<template>
  <details
    class="firecamp-details"
    :open="open"
  >
    <summary v-if="title || hasSummarySlot">
      <template v-if="title && !hasSummarySlot">
        {{ title }}
      </template>
      <slot name="summary" />
    </summary>

    <slot />
  </details>
</template>

<script>
  export default {
    name: 'FireDetails',

    props: {
      title: {
        type: String,
        default: null,
      },
      open: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      hasSummarySlot() {
        return !!this.$slots.summary
      },
    },
  }
</script>

<style lang="scss" scoped>
  .firecamp-details {
    summary {
      outline: none;
      list-style: none;
      color: var(--app-background-font-color);

      &:after {
        content: '▾';
        margin-left: 5px;
        font-weight: bold;
        color: var(--secondary);
      }

      &::-webkit-details-marker {
        display: none;
      }
      &::marker {
        display: none;
      }
    }

    ::placeholder {
      color: var(--app-background-font-color);
      opacity: 0.85;
    }

    &[open] {
      summary {
        margin-bottom: 0.5rem;

        &:after {
          content: '▴';
        }
      }
    }
  }
</style>
