<template>
  <div class="container--tabs row">
    <b-tabs
      v-model="tabIndex"
      :vertical="vertical"
      :card="vertical"
      :pills="vertical"
      :content-class="
        vertical
          ? 'container--horizontal_content'
          : 'container--vertical_content col'
      "
      :nav-wrapper-class="
        vertical ? 'container--vertical_nav' : 'container--horizontal_nav'
      "
      :nav-class="vertical ? 'tabs_nav--vertical' : 'tabs_nav--horizontal'"
    >
      <b-tab
        v-for="(tab, index) in tabs"
        :key="'base-tab_component--' + tab.id"
      >
        <template #title>
          <b-spinner
            v-if="getLoading(index)"
            small
          />
          {{ tab.title }}
        </template>

        <component
          :is="tab.component"
          v-if="showComponent(tab, index)"
          v-bind="tab.props"
          :active="index === tabIndex"
          @loading="setLoading($event, index)"
          v-on="$listeners"
        />
        <slot />
      </b-tab>

      <template
        v-if="!!$slots.actionend"
        #tabs-end
      >
        <slot name="actionend" />
      </template>
    </b-tabs>
  </div>
</template>

<script>
  export default {
    name: 'BaseTab',

    props: {
      tabs: {
        type: Array,
        required: true,
      },
      vertical: {
        type: Boolean,
        default: false,
      },
      props: {
        type: Object,
        default: () => ({}),
      },
      loader: {
        type: Boolean,
        default: true,
      },
      linkHashWithRouter: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        loadings: {},
        tabIndex: 0,
      }
    },

    watch: {
      tabIndex(newValue) {
        if (!this.getLoaded(newValue)) {
          this.setLoaded(true, newValue)
        }
        if (this.linkHashWithRouter && newValue > -1) {
          const hash = encodeURIComponent(this.tabs[newValue].id)
          if ('#' + hash !== this.$route.hash)
            this.$router.replace({
              hash,
            })
        }
      },

      '$route.hash': {
        handler(newValue) {
          if (this.linkHashWithRouter && newValue) {
            const index = this.tabs.findIndex(
              (tab) => tab.id === decodeURIComponent(newValue.slice(1)),
            )

            if (index > -1) {
              this.tabIndex = index
            }
          }
        },
        immediate: true,
      },
    },

    mounted() {
      this.loadings = this.createLoadings()
    },

    methods: {
      setLoading(loadingValue, index) {
        this.loadings[`tabLoading${index}`] = loadingValue
      },

      getLoading(index) {
        return this.loadings[`tabLoading${index}`]
      },

      setLoaded(loadedValue, index) {
        this.loadings[`tabLoadedOnce${index}`] = loadedValue
      },

      getLoaded(index) {
        return this.loadings[`tabLoadedOnce${index}`]
      },

      createLoadings() {
        return this.tabs.reduce((accumulator, component, index) => {
          const isLazy = component.lazy || component.props?.lazy || !this.loader
          accumulator[`tabLoading${index}`] = !isLazy
          accumulator[`tabLoadedOnce${index}`] =
            index === this.tabIndex || !isLazy

          return accumulator
        }, {})
      },

      showComponent(tab, index) {
        if (!tab || [undefined, null].includes(index) || !tab?.component)
          return false
        const isAlreadyLoaded = this.getLoaded(index)
        const keepAlive = tab?.props?.keepAlive
        const isLazy = tab?.props?.lazy
        if (isLazy)
          return (isAlreadyLoaded && keepAlive) ?? this.tabIndex === index
        return true
      },
    },
  }
</script>

<style lang="scss">
  @import '@/assets/scss/mixins/nav-tabs.scss';
  .tabs {
    width: 100%;
  }

  .container--horizontal_nav {
    @include nav-tabs;
  }

  .container--vertical_nav {
    background: var(--primary);

    .tabs_nav--vertical {
      background-color: var(--primary);
      position: sticky;
      top: 0px;
      height: auto !important; // Only way to override bootstrap height: 100%.

      .nav-link {
        color: var(--primary-font-color);

        &:hover {
          color: var(--primary-highlight);
        }

        &.active {
          color: var(--app-background-font-color);
          background-color: var(--app-background-color);
        }
      }
    }
  }

  .tab-content.container--vertical_content {
    margin-bottom: 2rem;
  }

  .tab-content.container--horizontal_content {
    border: 1px solid var(--primary);
    border-radius: 0 5px 5px 0;
    margin-right: 1rem;
  }
</style>
