<template>
  <BaseTreeNavigationContainer
    :title="documentation ? documentation.displayName : ''"
    :items="pages"
    :current-item="$route.params.id"
    @navigationChanged="handleNavigationChanged"
  >
    <BaseContainer
      class="documentation-page_base-container"
      :title="title"
    >
      <div v-if="invalidPage">
        <div v-if="[null, undefined].includes(pages) || pages.length === 0">
          <h2>This documentation has no pages</h2>
          <h3>
            You can create one
            <a
              class="link"
              href="/documentations/management"
            >
              here</a
            >
          </h3>
        </div>
        <div v-else>
          <h1>Page ID is invalid</h1>
          <span> Verify that you have a correct URL </span>
        </div>
      </div>
      <DocumentationPageCreationEdition
        v-else
        v-bind="page"
        :is-modal="false"
        :edition="true"
        @done="handleModificationDone"
      />
    </BaseContainer>
  </BaseTreeNavigationContainer>
</template>

<script>
  import BaseTreeNavigationContainer from '@/components/containers/BaseTreeNavigationContainer.vue'
  import BaseContainer from '@/components/containers/BaseContainer'
  import DocumentationPageCreationEdition from '@/components/documentation/DocumentationPageCreationEdition'
  import { mapActions, mapGetters, mapState } from 'vuex'

  export default {
    name: 'DocumentationPage',

    components: {
      BaseTreeNavigationContainer,
      BaseContainer,
      DocumentationPageCreationEdition,
    },

    data() {
      return {
        invalidPage: false,
      }
    },

    computed: {
      ...mapState('documentations', ['pages']),

      ...mapGetters('documentations', [
        'getCategoryById',
        'getPageById',
        'getPagesByCategory',
      ]),

      documentation() {
        return this.getCategoryById(this.$route.params.documentation)
      },

      page() {
        return this.getPageById(this.$route.params?.id)
      },

      pages() {
        return this.getPagesByCategory(this.$route.params.documentation)
      },

      title() {
        return this.page?.displayName
          ? this.page?.displayName
          : this.documentation?.displayName
      },
    },

    async created() {
      this.appLoading(true)
      await this.loadCategories()
      await this.loadPages()
      this.appLoading(false)
      if (!this.$route?.params?.id) {
        const newPage = this.pages?.find(
          (element) => !element?.parent && element?.weight === 0,
        )
        this.handleNavigationChanged(newPage, true)
      }
    },

    methods: {
      ...mapActions('documentations', ['loadCategories', 'loadPages']),

      handleNavigationChanged(page, replace = false) {
        const route = {
          name: 'documentation-page--preview',
          params: { documentation: this.documentation.id, id: page?.id },
        }

        replace ? this.$router.replace(route) : this.$router.push(route)
      },

      async handleModificationDone() {
        await this.loadPages()
      },
    },
  }
</script>

<style lang="scss">
  .documentation-page_base-container {
    padding: 0 0 30px 15px;
    height: 100%;
  }

  .link {
    color: var(--primary-highlight);

    &:hover {
      color: var(--primary-highlight);
    }
  }
</style>
