import Firebase from 'firebase/compat/app'
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'
import credentials from './credentials'

const FIREBASE = Firebase.initializeApp(credentials.config)

if (
  process.env.NODE_ENV !== 'production' &&
  process.env.VUE_APP_ENV !== 'local'
) {
  ;(self as any).FIREBASE_APPCHECK_DEBUG_TOKEN =
    process.env.VUE_APP_APPCHECK_DEBUG_TOKEN
}

export const appCheck = initializeAppCheck(FIREBASE, {
  provider: new ReCaptchaV3Provider('6LeIhjUiAAAAAIfg8EAZCzLnGyEG40vgHjhNE92m'),
})

export default FIREBASE
