import { FiretableFieldType } from '@/constants/FiretableFieldType.enum'
import { DocumentMeta } from './_common'
import { BvTableField } from 'bootstrap-vue'
import { CSSProperties } from 'vue/types/jsx'

type ValueType = string | number | boolean

type CollectionName =
  | 'appcontent'
  | 'appcustomisations'
  | 'appconfigurations'
  | 'appresources'

export type FireComponentDependency = {
  id: string
  value: ValueType | ValueType[]
  collection: CollectionName
  hierarchy: string[]
}

export type FireComponentFieldCore = {
  id: string
  label: string
  description?: string
}

export type FireComponentFieldSettingsCore = {
  dependencies?: FireComponentDependency[]
  hierarchy?: string[]
  collection: CollectionName
}

export type FireComponentFieldSeparator = FireComponentFieldCore & {
  settings: {
    type: 'separator' | 'subseparator'
    expanded: boolean
    dependencies?: FireComponentDependency[]
  }
}

export type FireComponentFieldInput = FireComponentFieldCore & {
  placeholder?: string
  settings: FireComponentFieldSettingsCore & {
    type: 'input'
    maxChar: number
    required: boolean
    disabled: boolean
  }
}

export type FireComponentFieldNumber = FireComponentFieldCore & {
  placeholder?: number
  settings: FireComponentFieldSettingsCore & {
    type: 'number'
    minValue: number
    maxValue: number
    stepValue: number
    required: boolean
    disabled: boolean
  }
}

export type FireComponentFieldRange = FireComponentFieldCore & {
  placeholder?: number
  settings: FireComponentFieldSettingsCore & {
    type: 'range'
    minValue: number
    maxValue: number
    stepValue: number
    required: boolean
    disabled: boolean
  }
}

export type FireComponentFieldURL = FireComponentFieldCore & {
  placeholder?: string
  settings: FireComponentFieldSettingsCore & {
    type: 'url'
    required: boolean
  }
}

export type FireComponentFieldSwitch = FireComponentFieldCore & {
  settings: FireComponentFieldSettingsCore & {
    type: 'switch'
    required: boolean
  }
}

export type FireComponentFieldRepeater = FireComponentFieldCore & {
  placeholder?: string
  empty?: string
  settings: FireComponentFieldSettingsCore & {
    type: 'repeater'
    required: boolean
    min?: number
    max?: number
  }
  fields: FireComponentFieldWithData[]
}

export type FireComponentFieldImage = FireComponentFieldCore & {
  placeholder?: string
  settings: FireComponentFieldSettingsCore & {
    type: 'image'
    fileTypes: string
    maxSize?: number
    maxWidth?: number
    maxHeight?: number
    minWidth?: number
    minHeight?: number
    required: boolean
  }
}

export type FireComponentFieldVideo = FireComponentFieldCore & {
  placeholder?: string
  settings: FireComponentFieldSettingsCore & {
    type: 'video'
    fileTypes: string
    maxSize?: number
    required: boolean
  }
}

export type FireComponentFieldFile = FireComponentFieldCore & {
  placeholder?: string
  settings: FireComponentFieldSettingsCore & {
    type: 'file'
    fileTypes: string
    maxSize?: number
    required: boolean
  }
}

export type FireComponentFieldFont = FireComponentFieldCore & {
  placeholder?: string
  settings: FireComponentFieldSettingsCore & {
    type: 'font'
    fileTypes: string
    maxSize?: number
    required: boolean
  }
}

export type FireComponentFieldCSS = FireComponentFieldCore & {
  settings: FireComponentFieldSettingsCore & {
    type: 'css'
    required: boolean
  }
}

export type FireComponentFieldTextarea = FireComponentFieldCore & {
  settings: FireComponentFieldSettingsCore & {
    type: 'textarea'
    maxChar: number
    required: boolean
  }
}

export enum RichTextFieldAllowedOptions {
  'textStyles',
  'bold',
  'italic',
  'underline',
  'strike',
  'headings',
  'heading1',
  'heading2',
  'heading3',
  'lists',
  'bulletList',
  'orderedList',
  'link',
}

export type FireComponentFieldRichText = FireComponentFieldCore & {
  settings: FireComponentFieldSettingsCore & {
    type: 'richText'
    required: boolean
    allowedOptions: RichTextFieldAllowedOptions[]
  }
}

export type FireComponentFieldOption = {
  label: string
  value: string
  disabled?: boolean
}

export type FireComponentFieldSelect = FireComponentFieldCore & {
  placeholder?: string
  options: FireComponentFieldOption[]
  settings: FireComponentFieldSettingsCore & {
    type: 'select'
    required: boolean
  }
}

export type FireComponentFieldCheckbox = FireComponentFieldCore & {
  options: FireComponentFieldOption[]
  settings: FireComponentFieldSettingsCore & {
    type: 'checkbox'
    minChecked: number
    required: boolean
  }
}

export type FireComponentFieldRadio = FireComponentFieldCore & {
  options: FireComponentFieldOption[]
  settings: FireComponentFieldSettingsCore & {
    type: 'radio'
    required: boolean
  }
}

export type FireComponentFieldCode = FireComponentFieldCore & {
  settings: FireComponentFieldSettingsCore & {
    type: 'code'
    required: boolean
  }
}

export type FireComponentFieldBaseAssetUrl = FireComponentFieldCore & {
  settings: FireComponentFieldSettingsCore & {
    type: 'baseUrl'
    required: boolean
  }
}

export type FireComponentFieldApi = FireComponentFieldCore & {
  settings: FireComponentFieldSettingsCore & {
    type: 'api'
    required: boolean
  }
}

export type FireComponentFieldCollection = FireComponentFieldCore & {
  placeholder?: string
  settings: FireComponentFieldSettingsCore & {
    type: 'collection'
    collectionType?: 'prescription' | 'questionnaires' | 'repeater'
    sourceCollection: string
    prefixFilter?: string | string[]
    maxValue?: number
    required: boolean
    single?: boolean
    fullObject?: boolean
  }
}

export type FireComponentFieldPixel = FireComponentFieldCore & {
  placeholder?: number
  settings: FireComponentFieldSettingsCore & {
    type: 'pixel'
    minValue?: number
    maxValue?: number
    baseRem?: number
    rem: boolean
    required: boolean
  }
}

export type FireComponentFieldTable = FireComponentFieldCore & {
  settings: FireComponentFieldSettingsCore & {
    type: 'table'
    tableType: string
    minValue?: number
    maxValue?: number
    required: boolean
  }
}

export type FireComponentFieldEventsStepsEventField = {
  id: string
  label: string
  description: string
  placeholder: string
  settings: {
    type: 'input'
    maxChar: number
    required: boolean
  }
}

export type FireComponentFieldEventsSteps = FireComponentFieldCore & {
  empty: string
  settings: FireComponentFieldSettingsCore & {
    type: 'events'
    maxChar: number
  }
  eventFields: FireComponentFieldWithData[]
  stepFields: FireComponentFieldWithData[]
}
export type FireComponentFieldWithData =
  | FireComponentFieldInput
  | FireComponentFieldNumber
  | FireComponentFieldRange
  | FireComponentFieldURL
  | FireComponentFieldSwitch
  | FireComponentFieldRepeater
  | FireComponentFieldImage
  | FireComponentFieldVideo
  | FireComponentFieldFile
  | FireComponentFieldFont
  | FireComponentFieldCSS
  | FireComponentFieldTextarea
  | FireComponentFieldRichText
  | FireComponentFieldSelect
  | FireComponentFieldCheckbox
  | FireComponentFieldRadio
  | FireComponentFieldCode
  | FireComponentFieldBaseAssetUrl
  | FireComponentFieldApi
  | FireComponentFieldCollection
  | FireComponentFieldPixel
  | FireComponentFieldTable
  | FireComponentFieldEventsSteps

export type FireComponentField = (
  | FireComponentFieldSeparator
  | FireComponentFieldWithData
) & { dependencies?: FireComponentDependency[] }

export type FireComponent = {
  id?: string
  fields: FireComponentField[]
  meta: { section: string } & DocumentMeta
  dependencies?: FireComponentDependency[]
}

export const fireTableEventList = [
  'create',
  'edit',
  'delete',
  'replace',
  'deleteFromInstance',
  'deleteFromCatalog',
  'deleteSections',
  'showDebugInformation',
  'show',
] as const
type FireTableEventName = (typeof fireTableEventList)[number]

export type onClickType = FireTableEventName | ((item?: any) => void)

export type FireTableAction = {
  target?: string
  label: string | ((item?: any) => string)
  icon?: string | ((item?: any) => string)
  tooltip?: string
  onClick: onClickType
  hide?: (item: any) => boolean
  disabled?: boolean | ((item: any) => boolean)
  disabledIcon?: string
  loading?: boolean | ((item: any) => boolean)
  href?: (item?: any) => void | string
  variant?: string | ((item?: any) => string)
  to?: (item?: any) => void | string
  divider?: boolean | ((item?: any) => boolean)
}

export type FireTableField = BvTableField & {
  key: string
  type?: FiretableFieldType
  text?: string
  hidden?: boolean
  selectable?: boolean
  noExport?: boolean
  newTab?: boolean
  divider?: boolean
  undefinedMessage?: string
  additionalActions?: FireTableAction[]
  menuActions?: FireTableAction[]
  cssClasses?: string[]
  splitCellItemStyle?: CSSProperties
  tooltip?: string
}
