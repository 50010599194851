<template>
  <Search
    class="search_container"
    :value-model.sync="searchInput"
    :debounce="1000"
    :disabled="!isDBLoaded"
  />
</template>

<script>
  import { mapState, mapGetters, mapMutations } from 'vuex'
  import Search from '@/components/menus/Search'

  export default {
    name: 'SearchMenu',

    components: {
      Search,
    },

    data() {
      return {
        searchInput: null,
      }
    },

    computed: {
      ...mapState({
        isDBLoaded: (state) => state.isDBLoaded,
      }),

      ...mapGetters(['getSearchMenu']),
    },

    watch: {
      searchInput(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.setSearchMenu(newValue?.trim()?.toLowerCase())
        }
      },

      getSearchMenu(newValue) {
        if (newValue !== this.searchInput) {
          this.searchInput = newValue
        }
      },
    },

    methods: {
      ...mapMutations(['setSearchMenu']),

      resetSearch() {
        this.searchInput = null
      },
    },
  }
</script>

<style lang="scss" scoped>
  .search_container {
    color: var(--app-background-font-color);
  }
</style>
