<template>
  <b-breadcrumb
    v-if="breadcrumb.length > 0"
    :key="breadcrumbKey"
  >
    <router-link
      v-for="navItem in breadcrumb"
      :key="navItem.name"
      :to="{ path: navItem.path }"
      class="breadcrumb_item"
      :class="isActive(navItem.active)"
      disabled="navItem.active"
    >
      {{ navItem.displayName }}
    </router-link>
  </b-breadcrumb>
</template>

<script>
  import { mapState, mapActions } from 'vuex'

  export default {
    computed: {
      ...mapState({
        breadcrumb: (state) => state.navigation.navigation,
      }),
      breadcrumbKey() {
        return this.$route.name
      },
    },

    created() {
      this.setNavigation()
    },

    beforeUpdate() {
      this.setNavigation()
    },

    methods: {
      ...mapActions(['setNavigation']),
      isActive(navItemActive) {
        return navItemActive ? 'breadcrumb_item--active' : ''
      },
    },
  }
</script>

<style lang="scss" scoped>
  .breadcrumb {
    font-size: 0.9rem;
    background-color: transparent;
    padding: 0;
    margin-bottom: 0;
  }

  .breadcrumb_item {
    text-transform: capitalize;
    color: var(--app-background-font-color);

    &:after {
      content: '/';
      margin: 0 5px;
      display: inline-block;
    }

    &--active {
      pointer-events: none;
      font-weight: 500;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }
</style>
