import { render, staticRenderFns } from "./IconProductConfiguration.vue?vue&type=template&id=29163e8f&"
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports