<template>
  <div class="container-fuild">
    <div
      v-for="(value, valueIndex) in values"
      :key="`value-${valueIndex}`"
      class="row pt-2"
    >
      <span
        v-if="!hideLabel"
        class="col-1 text-muted font-italic col-auto align-self-center"
      >
        #{{ valueIndex + 1 }}
      </span>

      <div class="col">
        <slot
          name="default"
          :index="valueIndex"
          :value="value"
          :update-value="(key, value) => updateValue(valueIndex, key, value)"
        />
      </div>
      <div
        v-if="!min || values.length > min"
        class="col-auto align-self-center"
      >
        <b-button
          variant="danger"
          title="Delete"
          :class="{ invisible: disabled }"
          @click.prevent="removeValue(valueIndex)"
        >
          <b-icon icon="trash" />
        </b-button>
      </div>
    </div>
    <div
      v-if="!max || values.length < max"
      class="row"
    >
      <b-button
        class="mt-2"
        :class="{
          'ml-auto mr-3': alignAddBtn === 'right',
          invisible: disabled,
        }"
        variant="success"
        @click="addValue()"
      >
        {{ labelAdd }}
      </b-button>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'GroupInput',

    model: {
      prop: 'values',
      event: 'change',
    },

    props: {
      values: {
        type: Array,
        required: true,
      },
      labelAdd: {
        type: String,
        default: 'Add',
      },
      templateValue: {
        type: Object,
        default: () => ({}),
      },
      alignAddBtn: {
        type: String,
        validator: (v) => ['left', 'right'].includes(v),
        default: 'left',
      },
      hideLabel: {
        type: Boolean,
        default: false,
      },
      max: {
        type: Number,
        default: null,
      },
      min: {
        type: Number,
        default: null,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },

    methods: {
      addValue() {
        const values = this.values ?? []
        const newValues = [...values, this.templateValue]
        this.$emit('change', newValues)
      },

      removeValue(index) {
        const values = this.values ?? []
        const newValues = [
          ...values.slice(0, index),
          ...values.slice(index + 1),
        ]
        this.$emit('change', newValues)
      },

      updateValue(index, key, value) {
        const values = this.values ?? []
        const newValues = [
          ...values.slice(0, index),
          {
            ...values[index],
            [key]: value,
          },
          ...values.slice(index + 1),
        ]
        this.$emit('change', newValues)
      },
    },
  }
</script>
