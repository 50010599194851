<template>
  <CodeEditor
    class="code-editor"
    :editor-value="jsonValue"
    :options="editorOptions"
    :required="true"
    @editorValueUpdated="onEditorValueUpdated"
  />
</template>

<script>
  import { mapGetters } from 'vuex'
  import CodeEditor from '@/components/fields/genericFields/CodeEditor'

  export default {
    name: 'JSONEditor',

    components: {
      CodeEditor,
    },

    props: {
      jsonValue: {
        type: String,
        default: null,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      required: {
        type: Boolean,
        default: false,
      },
      enableLinter: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        editorOptions: {},
      }
    },

    computed: {
      ...mapGetters(['getUserTheme']),

      theme() {
        switch (this.getUserTheme) {
          case 'dark':
            return 'moxer'
          case 'light':
            return 'oceanic-next'
          case 'classic':
            return 'neat'
          default:
            return 'moxer'
        }
      },
    },

    watch: {
      jsonValue(newVal) {
        this.editorOptions.lint = newVal !== null && newVal.length > 0
      },
    },

    mounted() {
      this.editorOptions = {
        mode: 'application/json',
        theme: this.theme,
        lineNumbers: true,
        line: true,
        indentUnit: 4,
        indentWithTabs: true,
        dragDrop: true,
        autoRefresh: true,
        readOnly: this.disabled,
        matchBrackets: true,
        autoCloseBrackets: true,
        lint: this.enableLinter,
        gutters: ['CodeMirror-lint-markers'],
        scrollbarStyle: 'overlay',
      }
    },

    methods: {
      onEditorValueUpdated(newValue) {
        if (this.jsonValue !== newValue)
          this.$emit('update:json-value', newValue)
      },
    },
  }
</script>

<style lang="scss">
  .code-editor {
    .CodeMirror-scrollbar-filler {
      background: transparent;
    }
  }
</style>
