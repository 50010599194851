<template>
  <div>
    <b-form-group
      class="mt-3"
      label="Select the profile list:"
    >
      <b-form-radio-group
        v-model="value"
        name="radio-list-selection"
      >
        <b-form-radio
          v-for="radio in routes"
          :key="`radio${radio.name}`"
          :value="radio.name"
        >
          {{ radio.title }}
        </b-form-radio>
      </b-form-radio-group>
    </b-form-group>
    <router-view />
  </div>
</template>

<script>
  export default {
    name: 'ProfilesLists',

    data() {
      return {
        routes: [
          {
            name: 'prescription-profile-list-profiles',
            title: 'Profiles List',
          },
          {
            name: 'prescription-profile-list-criterias',
            title: 'Profiles List By Criterias',
          },
          {
            name: 'prescription-profile-list-missing-profiles',
            title: 'Missing Profiles',
          },
        ],
      }
    },
    computed: {
      value: {
        get() {
          return this.$route.name
        },
        set(value) {
          this.$router.push({ name: value })
        },
      },
    },
  }
</script>
