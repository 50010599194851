var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-content_container container-fluid"},[_c('b-form',{ref:"form",class:_vm.containerClasses,on:{"submit":[function($event){$event.stopPropagation();$event.preventDefault();},_vm.onSubmit],"reset":_vm.onReset}},[_c('b-form-group',{attrs:{"label":"Select the item type:","label-for":"item-content-type-select","description":"Select an item type to display the linked fields."}},[_c('b-form-select',{attrs:{"name":"item-content-type-select","options":_vm.itemTypes,"disabled":_vm.isAppLoading,"required":""},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Select an item type")])]},proxy:true}]),model:{value:(_vm.selectedItemType),callback:function ($$v) {_vm.selectedItemType=$$v},expression:"selectedItemType"}})],1),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.selectedItemType)?_c('div',{key:`${_vm.selectedItemType}_container`},[_c('h4',{staticClass:"mt-4 mb-4"},[_vm._v(_vm._s(_vm.idToLabel(_vm.selectedItemType)))]),_vm._l((_vm.formData[_vm.selectedItemType]),function(input,name){return _c('b-form-group',{key:`${_vm.selectedItemType}-input-${name}`,attrs:{"label":_vm.idToLabel(name),"label-for":`item-content-${_vm.selectedItemType}-${name}`,"required":_vm.formData[_vm.selectedItemType][name].required}},[(
              ['text', 'url'].includes(_vm.formData[_vm.selectedItemType][name].type)
            )?_c('b-form-input',{attrs:{"debounce":"500","state":_vm.formData[_vm.selectedItemType][name].type === 'url'
                ? _vm.urlInputState
                : null,"disabled":_vm.isAppLoading,"type":_vm.formData[_vm.selectedItemType][name].type},model:{value:(_vm.formData[_vm.selectedItemType][name].value),callback:function ($$v) {_vm.$set(_vm.formData[_vm.selectedItemType][name], "value", $$v)},expression:"formData[selectedItemType][name].value"}}):_vm._e(),(
              ['textarea'].includes(_vm.formData[_vm.selectedItemType][name].type)
            )?_c('b-form-textarea',{attrs:{"placeholder":"Begin typing ...","debounce":"500","rows":"3","max-rows":"6","disabled":_vm.isAppLoading},model:{value:(_vm.formData[_vm.selectedItemType][name].value),callback:function ($$v) {_vm.$set(_vm.formData[_vm.selectedItemType][name], "value", $$v)},expression:"formData[selectedItemType][name].value"}}):_vm._e(),(['editor'].includes(_vm.formData[_vm.selectedItemType][name].type))?_c('WysiwygEditor',{attrs:{"disabled":_vm.isAppLoading,"allowed-options":_vm.allWysiwygOptions},model:{value:(_vm.formData[_vm.selectedItemType][name].value),callback:function ($$v) {_vm.$set(_vm.formData[_vm.selectedItemType][name], "value", $$v)},expression:"formData[selectedItemType][name].value"}}):_vm._e()],1)}),_c('b-row',[_c('div',{class:_vm.included ? 'action-button_container' : 'mt-4 col-12'},[_c('b-button',{staticClass:"text-danger",attrs:{"type":"reset","variant":"link"}},[_vm._v(" "+_vm._s(_vm.edition ? 'Delete' : 'Reset')+" ")]),_c('SubmitButton',{staticClass:"w-auto ml-2",class:_vm.included ? 'mb-auto' : 'mb-0',attrs:{"label":`${_vm.edition ? 'Edit' : 'Create'} item`,"type":"submit","disabled":_vm.isSubmitDisabled,"loading":_vm.isAppLoading}})],1)])],2):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }