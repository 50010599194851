<template>
  <div class="list-customers_container">
    <List
      v-for="customer in filteredCustomerList"
      :id="`customer${customer.id}`"
      :key="customer.id"
      :title="customer.displayName"
      :items="customer.apps"
      :is-tile-list="true"
      :hide-actions="true"
      :reduce-container="true"
      :reduced="areAppRoutesReduced"
      :loading="!isDBLoaded || !fetchedResources.customers"
      class="customer--box"
      @duplicate="
        handleCustomerModals('modal-duplicate-app-default', ...arguments)
      "
      @delete="
        handleCustomerModals('modal-delete-confirmation-default', ...arguments)
      "
    />
    <div
      v-if="
        isDBLoaded &&
        fetchedResources.customers &&
        filteredCustomerList.length == 0
      "
      class="col mt-2 d-flex justify-content-center align-items-center"
    >
      No results found ...
    </div>

    <DuplicateAppModal
      v-if="showModalDuplicateAppDefault"
      :approute-id="app.id"
      :version="app.versionId"
      :app="app.application"
      @hidden="handleCustomerModals('modal-duplicate-app-default', null)"
    />
    <ConfirmationModal
      v-if="showModalDeleteConfirmationDefault"
      :item-id="app.id"
      @deleteConfirmed="deleteApp(app.id)"
    />
  </div>
</template>

<script>
  import { mapState, mapGetters, mapActions } from 'vuex'

  import { generateShowDataValues } from '@/utils/ModalHelpers'
  import { ModalMixin } from '@/mixins/ModalMixin'

  import List from '@/components/list/List'
  import DuplicateAppModal from '@/components/modals/DuplicateAppModal'
  import ConfirmationModal from '@/components/modals/ConfirmationModal'
  import { RIGHTS } from '@/constants/UserConstants'

  const showDataValues = generateShowDataValues([
    'modal-delete-confirmation-default',
    'modal-duplicate-app-default',
  ])

  export default {
    name: 'ListCustomers',

    components: {
      DuplicateAppModal,
      ConfirmationModal,
      List,
    },

    mixins: [ModalMixin],

    data() {
      return {
        ...showDataValues,
        app: null,
      }
    },

    computed: {
      ...mapState({
        customers: (state) => state.customers,
        isDBLoaded: (state) => state.isDBLoaded,
        currentUser: (state) => state.user,
        fetchedResources: (state) => state.fetchedResources,
        fetchedRoutes: (state) => state.approutes.fetchedRoutes,
      }),

      ...mapGetters(['listCustomerApps', 'getSearchMenu', 'canUserDo']),

      filteredCustomerList() {
        return this.filteredCustomers?.map((customer) => {
          return {
            ...customer,
            apps: this.customerAppsFormated(customer.displayName),
          }
        })
      },

      filteredCustomers() {
        const searchMenuInput = this.getSearchMenu
        return this.customers?.filter((customer) =>
          searchMenuInput
            ? (customer?.displayName
                ?.toLowerCase()
                .includes(searchMenuInput.toLowerCase()) ||
                this.filteredApps(customer?.displayName)?.length > 0) &&
              this.isCustomerVisible(customer?.displayName)
            : this.isCustomerVisible(customer?.displayName),
        )
      },

      areAppRoutesReduced() {
        return (
          this.filteredCustomers?.filter((customer) =>
            this.isCustomerVisible(customer?.displayName),
          ).length > 10
        )
      },
    },

    async mounted() {
      const channel = 'homepage'
      const loadedResources =
        this.fetchedResources &&
        this.fetchedResources.customers &&
        this.fetchedRoutes
      if (!loadedResources)
        this.setLoadingInformations({
          title: 'Firecamp initialization ...',
          show: true,
          bar: true,
          channel,
        })
      await this.bindAppRoutes()
      if (!loadedResources)
        this.setLoadingInformations({ show: false, channel })
    },

    methods: {
      ...mapActions(['deleteRoute', 'loadResources', 'bindAppRoutes']),

      filteredApps(customerName) {
        const searchMenuInput = this.getSearchMenu
        const filteredApps = this.customerAppsVisible(customerName)?.filter(
          (app) =>
            searchMenuInput
              ? app?.application?.displayName
                  ?.toLowerCase()
                  .includes(searchMenuInput) ||
                app.id?.toLowerCase().includes(searchMenuInput)
              : true,
        )
        return filteredApps?.length === 0 &&
          searchMenuInput &&
          customerName?.toLowerCase().includes(searchMenuInput)
          ? this.customerAppsVisible(customerName)
          : filteredApps
      },

      customerAppsFormated(customerName) {
        return this.filteredApps(customerName)
          ?.map((app) => {
            const formatedAppData = {
              id: app?.id,
              label: app?.application?.displayName,
              application: app?.application?.id,
              versionId: app?.appversion?.id,
            }
            const previewLink = this.linkToApp(app)

            return {
              ...formatedAppData,
              details: {
                ...(app.appversion?.meta?.version && {
                  version: app.appversion?.meta?.version,
                }),
                ...(app?.type?.displayName && { type: app?.type?.displayName }),
                ...(app?.country?.displayName && {
                  countries: {
                    label: app?.country?.displayName,
                    id: app?.country?.id,
                  },
                }),
                ...(app?.touchpoint?.displayName && {
                  touchpoints: app?.touchpoint?.displayName,
                }),
                ...(app?.environment?.displayName && {
                  environments: app?.environment?.displayName,
                }),
                ...(app.id &&
                  this.canUserDo(RIGHTS.SHOWS_IDS) && { id: app.id }),
              },
              ...(previewLink && {
                additionalActions: [
                  {
                    label: 'OnlineApp',
                    icon: 'eye-fill',
                    tooltip: 'Open app in another tab',
                    href: previewLink,
                    target: '_blank',
                  },
                ],
              }),
              quickActionButton: [
                ...(this.canUserDo(RIGHTS.SETUP)
                  ? [
                      {
                        dataTest: 'setup',
                        label: 'Setup',
                        icon: 'gear-fill',
                        link: { name: 'edit-app', params: { app_id: app.id } },
                      },
                    ]
                  : []),
                ...(this.hasPrescription(app?.meta?.subcollections)
                  ? [
                      {
                        dataTest: 'prescription',
                        label: 'Prescription',
                        icon: 'briefcase-fill',
                        link: {
                          name: 'prescription',
                          params: { app_id: app.id },
                        },
                      },
                    ]
                  : []),
                ...(this.canUserDo(RIGHTS.DUPLICATE_ROUTE)
                  ? [
                      {
                        dataTest: 'duplicate',
                        label: 'Duplicate',
                        icon: 'files',
                        variant: 'info',
                        divider: true,
                        onClick: ['duplicate', formatedAppData],
                      },
                    ]
                  : []),
                ...(this.canUserDo(RIGHTS.DELETE_ROUTE)
                  ? [
                      {
                        dataTest: 'delete',
                        label: 'Delete',
                        icon: 'trash',
                        variant: 'danger',
                        onClick: ['delete', formatedAppData],
                      },
                    ]
                  : []),
              ],
            }
          })
          .sort((a, b) => {
            const aVersion = a?.details?.version
            const bVersion = b?.details?.version
            const aLabel = a?.label
            const bLabel = b?.label
            return aVersion === bVersion
              ? aLabel - bLabel
              : aVersion?.localeCompare(bVersion, undefined, { numeric: true })
          })
      },

      customerAppsVisible(customerName) {
        return this.listCustomerApps(customerName)
      },

      isCustomerVisible(customerName) {
        const isCustomerAppsListEmpty =
          this.customerAppsVisible(customerName)?.length === 0
        return !isCustomerAppsListEmpty
      },

      hasPrescription(routeSubcollections) {
        return (
          routeSubcollections?.includes('appprescriptions') &&
          this.canUserDo(RIGHTS.PRESCRIPTION)
        )
      },

      handleCustomerModals(modalId, app) {
        this.app = app
        this.handleModal(modalId)
      },

      async deleteApp(appId) {
        await this.deleteRoute(appId)
        this.handleCustomerModals('modal-delete-confirmation-default', null)
      },

      redirectTo(name, appId) {
        this.$router.push({ name, params: { app_id: appId } })
      },

      linkToApp(app) {
        if (
          !app ||
          !app.environment?.baseUrl ||
          !app.tenant?.id ||
          !app.application?.id ||
          !app.customer?.id ||
          !app.country?.id ||
          !app.environment.id ||
          !app.touchpoint?.id ||
          !app.type?.id
        ) {
          return null
        }
        if (process.env.VUE_APP_ENV === 'development') {
          return `https://wecdostodvfront.z6.web.core.windows.net/${app.application.id}/standalone.html?tenant=${app.tenant.id}&customer=${app.customer.id}&country=${app.country.id}&touchpoint=${app.touchpoint.id}&environment=${app.environment.id}&type=${app.type.id}`
        }
        return `${app.environment.baseUrl}standalone.html?tenant=${app.tenant.id}&application=${app.application.id}&customer=${app.customer.id}&country=${app.country.id}&environment=${app.environment.id}&touchpoint=${app.touchpoint.id}&type=${app.type.id}`
      },
    },
  }
</script>

<style scoped lang="scss">
  .customer--box {
    margin-bottom: 2rem;
  }
</style>
