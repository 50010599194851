import store from '@/store'
import Vue from 'vue'
import VueRouter from 'vue-router'

import Auth from '@/firebase/auth'
import ApplicationsVersionsManagement from '@/views/ApplicationsVersionsManagement'
import Dashboard from '@/views/private/Dashboard'
import EditApp from '@/views/private/EditApp'
import ForgottenPassword from '@/views/ForgottenPassword'
import CreateAppVersion from '@/views/private/CreateAppVersion'
import UpdateAppVersion from '@/views/private/UpdateAppVersion'
import CreateRoute from '@/views/private/CreateRoute'
import UpgradeRoute from '@/views/private/UpgradeRoute'
import Login from '@/views/Login'
import Logout from '@/views/Logout'
import MyProfile from '@/views/private/MyProfile'
import Prescription from '@/views/Prescription'
import ProfileCreationEdition from '@/components/prescription/ProfileCreationEdition'
import DashboardPrescription from '@/components/prescription/DashboardPrescription'
import UserAdmin from '@/views/UserAdmin'
import MainCollectionAdmin from '@/views/MainCollectionAdmin'
import QuestionnaireCollectionsAdmin from '@/views/QuestionnaireCollectionsAdmin'
import CreatePrescription from '@/views/private/CreatePrescription'
import ErrorPage from '@/views/ErrorPage'
import VersionMapping from '@/views/private/VersionMapping'
import DocumentationPage from '@/components/documentation/DocumentationPage'
import DocumentationBoard from '@/views/DocumentationBoard.vue'
import DocumentationManagement from '@/views/DocumentationManagement'
import TestSku from '@/components/product-api-config/TestSku'
import ProductApiConfigurationFormPage from '@/views/ProductApiConfigurationFormPage'
import ProductAPIConfigurationEdition from '@/components/product-api-config/ProductAPIConfigurationEdition.vue'
import ProductAPIConfiguration from '@/views/ProductAPIConfiguration.vue'
import CatalogConfigurationList from '@/views/CatalogConfigurationList.vue'
import CatalogConfigurationView from '@/views/CatalogConfigurationView.vue'
import CatalogProductList from '@/components/catalog-configuration/CatalogProductList.vue'
import { RIGHTS } from '@/constants/UserConstants'
import { User } from '@/types/User'
import ProductList from '@/components/prescription/ProductList.vue'
import ProductDetails from '@/components/prescription/ProductDetails.vue'
import ProductListNavigation from '@/components/prescription/ProductListNavigation.vue'
import ProductListNew from '@/components/prescription/ProductListNew.vue'
import LookList from '@/components/prescription/LookList.vue'
import ContentList from '@/components/prescription/ContentList.vue'
import EditPrescription from '@/components/prescription/EditPrescription.vue'
import ProfilesListsCriterias from '@/components/prescription/ProfilesListsCriterias.vue'
import ProfilesListsMissing from '@/components/prescription/ProfilesListsMissing.vue'
import ProfilesListsProfiles from '@/components/prescription/ProfilesListsProfiles.vue'
import ItemsList from '@/components/prescription/ItemsList.vue'
import ProfilesLists from '@/components/prescription/ProfilesLists.vue'
import BrandLogosPage from '@/components/brands/BrandLogosPage.vue'
import ProductListV3 from '@/components/prescription/ProductListV3.vue'
import ProductDetailsV3 from '@/components/prescription/ProductDetailsV3.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'homepage',
    component: Dashboard,
    meta: {
      requiresAuth: true,
      displayName: 'Home',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      displayName: 'Login Page',
    },
  },
  {
    path: '/forgotten-password',
    name: 'forgotten-password',
    component: ForgottenPassword,
    meta: {
      displayName: 'Forgotten Password Page',
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
    meta: {
      requiresAuth: true,
      displayName: 'Logout Page',
    },
  },
  {
    path: '/user/profile',
    name: 'user-profile',
    component: MyProfile,
    meta: {
      requiresAuth: true,
      displayName: 'User profile',
    },
  },
  {
    path: '/app/version/new',
    name: 'create-version',
    component: CreateAppVersion,
    meta: {
      requiresAuth: true,
      permission: RIGHTS.APPLICATIONS_MANAGEMENT,
      displayName: 'Install new app version',
    },
  },
  {
    path: '/app/version/update/:appId?/:versionId?/:simulation?',
    name: 'update-version',
    component: UpdateAppVersion,
    meta: {
      requiresAuth: true,
      permission: RIGHTS.APPLICATIONS_MANAGEMENT,
      displayName: 'Update app version',
    },
  },
  {
    path: '/app/version/:appVersionId/mapping',
    name: 'version-mapping',
    component: VersionMapping,
    meta: {
      requiresAuth: true,
      permission: RIGHTS.APPLICATIONS_MANAGEMENT,
      displayName: 'Version mapping',
    },
  },
  {
    path: '/app/route/create',
    name: 'create-route',
    component: CreateRoute,
    meta: {
      requiresAuth: true,
      permission: RIGHTS.CREATE_ROUTE,
      displayName: 'Create instance',
    },
  },
  {
    path: '/app/route/upgrade',
    name: 'upgrade-route',
    component: UpgradeRoute,
    meta: {
      requiresAuth: true,
      permission: RIGHTS.UPGRADE_ROUTE,
      displayName: 'Upgrade instance',
    },
  },
  {
    path: '/app/install/prescription',
    name: 'install-app-prescription',
    component: CreatePrescription,
    meta: {
      requiresAuth: true,
      permission: RIGHTS.PRESCRIPTION_CREATE,
      displayName: 'Install App Prescription',
    },
  },
  {
    path: '/app/:app_id/edit',
    name: 'edit-app',
    component: EditApp,
    meta: {
      requiresAuth: true,
      permission: RIGHTS.SETUP,
      displayName: 'Edit App',
    },
  },
  {
    path: '/app/:app_id/prescription',
    name: 'prescription',
    redirect: { name: 'prescription-profile-list-profiles' },
    component: Prescription,
    meta: {
      requiresAuth: true,
      permission: RIGHTS.PRESCRIPTION,
    },
    children: [
      {
        path: '',
        name: 'prescription-dashboard',
        redirect: { name: 'prescription-profile-list-profiles' },
        component: DashboardPrescription,
        meta: {
          requiresAuth: true,
          displayName: 'Prescription',
        },
        children: [
          {
            path: 'settings',
            name: 'prescription-settings',
            component: EditPrescription,
            meta: {
              requiresAuth: true,
              permission: RIGHTS.PRESCRIPTION_SETTINGS,
              displayName: 'Prescription Settings',
            },
          },
          {
            name: 'prescription-item-list',
            path: 'items',
            component: ItemsList,
            meta: {
              requiresAuth: true,
              permission: RIGHTS.PRESCRIPTION_ITEMS_LIST,
              displayName: 'Prescription Items',
            },
          },
          {
            path: 'products-list',
            name: 'products-list',
            component: ProductListNavigation,
            meta: {
              requiresAuth: true,
              permission: RIGHTS.PRESCRIPTION_PRODUCT_LIST,
              displayName: 'Prescription Products',
            },
            children: [
              {
                path: 'products',
                name: 'prescription-product-list',
                component: ProductList,
              },
              {
                path: 'looks',
                name: 'prescription-product-list-looks',
                component: LookList,
              },
              {
                path: 'contents',
                name: 'prescription-product-list-contents',
                component: ContentList,
              },
              {
                path: 'details/:id',
                name: 'prescription-product-details',
                component: ProductDetails,
              },
            ],
          },
          {
            path: 'products-list-new',
            name: 'products-list-new',
            component: ProductListNew,
            meta: {
              requiresAuth: true,
              permission: RIGHTS.IGNORE_MAINTENANCE_MODE,
              displayName: 'Prescription Products New',
            },
            children: [
              {
                path: 'products',
                name: 'prescription-product-list-new',
                component: ProductListV3,
              },
              {
                path: 'looks',
                name: 'prescription-product-list-new-looks',
                component: LookList,
              },
              {
                path: 'contents',
                name: 'prescription-product-list-new-contents',
                component: ContentList,
              },
              {
                path: 'details/:sku',
                name: 'prescription-product-details-new',
                component: ProductDetailsV3,
              },
            ],
          },
          {
            path: 'profiles',
            name: 'prescription-profile-list',
            component: ProfilesLists,
            redirect: { name: 'prescription-profile-list-profiles' },
            meta: {
              requiresAuth: true,
              permission: RIGHTS.PRESCRIPTION_PROFILES_LIST,
              displayName: 'Prescription Profiles',
            },
            children: [
              {
                path: 'criterias',
                name: 'prescription-profile-list-criterias',
                component: ProfilesListsCriterias,
              },
              {
                path: 'missing-profiles',
                name: 'prescription-profile-list-missing-profiles',
                component: ProfilesListsMissing,
              },
              {
                path: '/',
                name: 'prescription-profile-list-profiles',
                component: ProfilesListsProfiles,
              },
            ],
          },
        ],
      },
      {
        path: 'profile/:id?',
        name: 'profile-creation-edition',
        component: ProfileCreationEdition,
        meta: {
          requiresAuth: true,
          displayName: 'Profile',
          parent: 'prescription',
        },
      },
    ],
  },
  {
    path: '/admin/users',
    name: 'admin-users',
    component: UserAdmin,
    meta: {
      requiresAuth: true,
      permission: RIGHTS.USER_MANAGEMENT,
      displayName: 'Users Management',
    },
  },
  {
    path: '/admin/dictionnaries',
    name: 'admin-dictionnaries',
    component: MainCollectionAdmin,
    meta: {
      requiresAuth: true,
      permission: RIGHTS.DICTONARIES_MANAGEMENT,
      displayName: 'Dictionaries Management',
    },
  },
  {
    path: '/admin/dictionnaries/brands/:brandId/logos',
    name: 'brand-logos-page',
    component: BrandLogosPage,
    meta: {
      requiresAuth: true,
      permission: RIGHTS.DICTONARIES_MANAGEMENT,
      displayName: 'Dictionaries Management',
    },
  },
  {
    path: '/admin/questionnaires',
    name: 'admin-questionnaires',
    component: QuestionnaireCollectionsAdmin,
    meta: {
      requiresAuth: true,
      permission: RIGHTS.QUESTIONNAIRES_MANAGEMENT,
      displayName: 'Questionnaires Management',
    },
  },
  {
    path: '/admin/applications',
    name: 'admin-applications-versions',
    component: ApplicationsVersionsManagement,
    meta: {
      requiresAuth: true,
      permission: RIGHTS.APPLICATIONS_MANAGEMENT,
      displayName: 'Applications Management',
    },
  },
  {
    path: '/documentations/management',
    name: 'documentation-management',
    component: DocumentationManagement,
    meta: {
      requiresAuth: true,
      permission: RIGHTS.DOCUMENTATION_MANAGEMENT,
      displayName: 'Documentation Management',
    },
  },
  {
    path: '/documentations/board',
    name: 'documentation-board',
    component: DocumentationBoard,
    meta: {
      requiresAuth: true,
      permission: RIGHTS.DOCUMENTATION_BOARD,
      displayName: 'Documentation Board',
    },
  },
  {
    path: '/documentations/view/:documentation/:id?',
    component: DocumentationPage,
    meta: {
      requiresAuth: true,
      permission: RIGHTS.DOCUMENTATION_BOARD,
      displayName: 'Documentation Page',
    },
    children: [
      {
        path: '',
        name: 'documentation-page--preview',
        meta: {
          permission: RIGHTS.DOCUMENTATION_BOARD,
        },
      },
      {
        path: 'edit',
        name: 'documentation-page',
        meta: {
          permission: RIGHTS.DOCUMENTATION_BOARD_EDITION,
        },
      },
    ],
  },
  {
    path: '/productapi-configurations/:environment/add/:customerId?/:countryCode?',
    component: ProductApiConfigurationFormPage,
    meta: {
      permission: RIGHTS.PRODUCT_API_CONFIG,
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        props: true,
        name: 'productapi-configurations-add-form',
        component: ProductAPIConfigurationEdition,
        meta: {
          displayName: 'Create Product API Configuration',
          parent: 'product-api-config',
        },
      },
      {
        path: 'test',
        props: true,
        name: 'productapi-configurations-add-test',
        component: TestSku,
        meta: {
          displayName: 'Test Product API',
          parent: 'productapi-configurations-add-form',
        },
      },
    ],
  },
  {
    path: '/productapi-configurations/:environment?',
    name: 'product-api-config',
    component: ProductAPIConfiguration,
    meta: {
      requiresAuth: true,
      permission: RIGHTS.PRODUCT_API_CONFIG,
      displayName: 'ProductAPI Configuration',
    },
    props: true,
  },
  {
    path: '/catalog-configurations/:environment/:tenant?',
    name: 'catalog-api-config',
    component: CatalogConfigurationList,
    meta: {
      requiresAuth: true,
      displayName: 'Catalog Configuration',
      permission: RIGHTS.IGNORE_MAINTENANCE_MODE,
    },
    props: true,
  },
  {
    path: '/catalog-configurations/:environment/:tenant/:id',
    name: 'catalog-config',
    component: CatalogConfigurationView,
    meta: {
      requiresAuth: true,
      displayName: 'View Catalog Configuration',
      permission: RIGHTS.IGNORE_MAINTENANCE_MODE,
    },
    children: [
      {
        path: 'products',
        name: 'catalog-products-list',
        component: CatalogProductList,
        meta: {
          requiresAuth: true,
          displayName: 'Products New',
        },
      },
    ],
  },
  {
    path: '/catalog-configurations/:environment/:tenant/:id/products/details/:sku',
    name: 'catalog-product-details',
    component: ProductDetailsV3,
    meta: {
      requiresAuth: true,
      displayName: 'View Catalog Configuration',
    },
  },
  {
    alias: '*',
    path: '/error',
    name: 'error-page',
    component: ErrorPage,
    meta: {
      displayName: 'Error Page',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  let isUserSet = store.getters.isUserSet

  let currentUser = await Auth.getCurrentUser()
  if (!currentUser) {
    isUserSet = false
  }
  if (
    !isUserSet &&
    ![null, undefined].includes(currentUser) &&
    (await Auth.hasMFAPhoneNumber())
  ) {
    store.dispatch('setLoadingInformations', {
      title: 'User Initialization ...',
      show: true,
      bar: true,
    })
    const user = new User({
      id: currentUser.uid,
      profilePic: currentUser.photoURL,
    })
    try {
      await store.dispatch('setCurrentUser', user)
      isUserSet = true
    } catch (error) {
      currentUser = null
    }
  }

  if (store.getters.getSearchMenu !== null && to.name !== from.name) {
    store.commit('setSearchMenu', null)
  }

  const canUserDo = store.getters.canUserDo

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  const permissions = to.matched.map((record) => record?.meta?.permission)

  switch (true) {
    // If the page requires authentication and the user is not connected.
    case requiresAuth && !isUserSet:
      next({ name: 'login', query: { redirect: to.fullPath } })
      break
    // When user wants to go to an page and don't have the right.
    case permissions.some((permission) => permission && !canUserDo(permission)):
      next({ name: 'error-page', params: { code: '403' } })
      break
    // When user is logged in but want to go to Login Page.
    case to.name === 'login' && isUserSet:
      next({ name: 'homepage' })
      break
    default:
      next()
      break
  }
})
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title =
      (to.meta.displayName ? to.meta.displayName + ' | ' : '') + 'Firecamp'
  })
})
export default router
