<template>
  <b-button
    :type="type"
    :variant="variant"
    :disabled="disabled || loading"
    v-on="$listeners"
  >
    <b-icon
      v-if="loading"
      icon="arrow-clockwise"
      animation="spin"
    />
    <template v-if="!hasSlot">
      {{ label }}
    </template>
    <slot />
  </b-button>
</template>

<script>
  export default {
    name: 'SubmitButton',

    props: {
      disabled: {
        type: Boolean,
        default: true,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      label: {
        type: String,
        default: 'Submit',
      },
      type: {
        type: String,
        default: 'submit',
      },
      variant: {
        type: String,
        default: 'primary',
      },
    },

    computed: {
      hasSlot() {
        return !!this.$slots.default
      },
    },
  }
</script>
