<template>
  <div class="notification-display_container">
    <Message
      v-for="(notif, index) in notifications"
      :key="`notification-${index}`"
      :message="notif.message"
      :state="notif.state"
      :floating="notif.floating"
      :dismissible="notif.dismissible"
      :keep-showing="notif.keepShowing"
      :dismiss-secs="notif.dismissSecs"
      :progress="notif.progress"
      :is-last-notif="index + 1 === notifications.length"
      @dismissed="dismissedNotification(notif)"
    >
      <template
        v-if="notif.actionLink"
        #cta
      >
        <b-button
          v-if="notif.actionLink"
          :href="notif.actionLink"
          class="message-link_button"
          variant="dark"
          size="sm"
        >
          {{ notif.actionLabel }}
        </b-button>
      </template>
    </Message>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'

  import Message from '@/components/menus/message/Message'

  export default {
    name: 'NotificationsDisplay',

    components: {
      Message,
    },

    computed: {
      ...mapState({
        notifications: (state) => state.notifications.notifications,
      }),
    },

    async created() {
      await this.loadNotifications()
    },

    methods: {
      ...mapActions({
        loadNotifications: 'notifications/loadNotifications',
        removeNotification: 'notifications/removeNotification',
      }),

      dismissedNotification(notif) {
        this.removeNotification(notif)
      },
    },
  }
</script>
