<template>
  <div>
    <FireTable
      table-id="profilesCriterias"
      :items="criteriasByProfiles"
      :fields="fields"
      :busy="!isPrescriptionLoaded"
      :filter-included-fields="['label']"
    >
      <template #quick-actions>
        <div>
          Coverage :
          <b-badge
            v-if="isPrescriptionLoaded"
            :variant="
              criteriasCoverage < 0.5
                ? 'danger'
                : criteriasCoverage < 0.9
                ? 'warning'
                : 'success'
            "
          >
            {{
              new Intl.NumberFormat('en', { style: 'percent' }).format(
                criteriasCoverage,
              )
            }}
          </b-badge>
        </div>
      </template>

      <template #cell(label)="{ item }">
        <Tag
          :color="item.color"
          :text="item.label"
        />
      </template>
    </FireTable>

    <b-modal
      v-if="modalItem && showModal"
      v-model="showModal"
      title="Profiles List"
      size="xl"
      hide-footer
      @hidden="modalItem = null"
    >
      <ProfilesListsProfilesTable :profiles="modalItem" />
    </b-modal>
  </div>
</template>

<script>
  import { mapState, mapGetters, mapActions } from 'vuex'
  import Tag from '@/components/tag/Tag'
  import FireTable from '@/components/table/FireTable'
  import ProfilesListsProfilesTable from './ProfilesListsProfilesTable.vue'
  import { RESOURCES } from '@/service/ResourceService'
  import { FiretableFieldType } from '@/constants/FiretableFieldType.enum'

  export default {
    name: 'ProfilesListsCriterias',

    components: {
      FireTable,
      Tag,
      ProfilesListsProfilesTable,
    },

    data() {
      return {
        modalItem: null,
        showModal: false,
      }
    },

    computed: {
      ...mapState({
        isPrescriptionLoaded: (state) => state.prescriptions.isLoaded,
      }),

      ...mapGetters([
        'getCriteriaById',
        'criteriasByProfiles',
        'criteriasCoverage',
      ]),

      fields() {
        return [
          {
            key: 'label',
            label: 'Criteria',
            type: FiretableFieldType.Custom,
            sortable: true,
          },
          {
            key: 'countinclusiveStrictCriterias',
            label: 'MUST',
            type: FiretableFieldType.Count,
            sortable: true,
          },
          {
            key: 'countinclusiveCriterias',
            label: 'SHOULD',
            type: FiretableFieldType.Count,
            sortable: true,
          },
          {
            key: 'countexclusiveCriterias',
            label: 'MUST NOT',
            type: FiretableFieldType.Count,
            sortable: true,
          },
          {
            key: 'actions',
            label: 'Actions',
            menuActions: [
              {
                label: 'Create profile',
                variant: 'success',
                icon: 'plus-circle',
                onClick: (criteria) => this.createProfileWithCriteria(criteria),
              },
              {
                hide: (item) =>
                  item.countinclusiveStrictCriterias +
                    item.countinclusiveCriterias +
                    item.countexclusiveCriterias ===
                  0,
                label: 'Show profiles',
                icon: 'eye-fill',
                divider: true,
                onClick: (criteria) => this.setModalItem(criteria),
              },
            ],
          },
        ]
      },
    },

    async mounted() {
      await this.loadResources({ resourceNames: [RESOURCES.CRITERIAS] })
    },

    methods: {
      ...mapActions(['createPartialProfile', 'loadResources']),

      createProfileWithCriteria(criteria) {
        this.createPartialProfile({
          label: `New route with ${criteria.label}`,
          inclusiveCriterias: [criteria.id],
        })
      },

      setModalItem({
        inclusiveCriterias,
        inclusiveStrictCriterias,
        exclusiveCriterias,
      }) {
        this.modalItem = inclusiveCriterias.concat(
          inclusiveStrictCriterias,
          exclusiveCriterias,
        )
        this.showModal = true
      },
    },
  }
</script>
