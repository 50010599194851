<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <b-button
      class="to-top-button"
      variant="info"
      @click="$emit('click')"
    >
      <b-icon icon="chevron-up" />
    </b-button>
  </transition>
</template>

<script>
  export default {
    name: 'ToTopButton',
  }
</script>

<style lang="scss" scoped>
  .to-top-button {
    position: fixed;
    bottom: 30px;
    right: 30px;
    border-radius: 50%;
    height: 45px;
    width: 45px;
    background-color: var(--primary);
    border: 1px solid var(--app-background-font-color);
    z-index: $zindex-message;

    &:hover {
      border-color: var(--primary-highlight);
      color: var(--primary-highlight);
      background-color: var(--primary);
      box-shadow: 0 2px 10px 2px rgba($color: #000000, $alpha: 0.5);
    }

    &:focus,
    &:active {
      box-shadow: none !important;
      background-color: var(--primary) !important;
      border-color: var(--primary) !important;
    }
  }
</style>
