<template>
  <AuthContainer
    title="Forgotten Password"
    :on-submit-callback="onSubmit"
    :loading="isAppLoading"
  >
    <b-form-group
      id="forgotten-password-group-email"
      label="Email address"
      label-for="forgotten-password-email-input"
    >
      <b-input-group>
        <b-input-group-prepend
          is-text
          class="forgotten-password_input-preprend"
        >
          <b-icon icon="person-circle" />
        </b-input-group-prepend>
        <b-form-input
          id="forgotten-password-email-input"
          v-model="form.email"
          type="email"
          :state="form.isEmailValid"
          required
          placeholder="Enter email"
          @blur="emailValidation"
        />
      </b-input-group>
    </b-form-group>
    <SubmitButton
      :disabled="isSubmitDisabled"
      :loading="isAppLoading"
      label="Reset password"
      class="forgotten-password_submit"
    />
    <hr />
    <b-button
      :to="{ name: 'login' }"
      class="w-100"
      variant="outline-secondary"
      ><b-icon
        icon="arrow-left"
        class="mr-10"
      />Back to Login</b-button
    >
  </AuthContainer>
</template>

<script>
  import Auth from '@/firebase/auth'
  import AuthContainer from '@/components/auth/AuthContainer.vue'
  import SubmitButton from '@/components/form/SubmitButton'

  import { emailValidation } from '@/service/FormsService'

  export default {
    name: 'ForgottenPassword',

    components: {
      AuthContainer,
      SubmitButton,
    },

    data() {
      return {
        form: {
          email: '',
          isEmailValid: null,
        },
      }
    },

    computed: {
      isSubmitDisabled() {
        return this.form.email.length === 0 || this.isAppLoading
      },
    },

    methods: {
      emailValidation,

      async onSubmit(event) {
        event.preventDefault()
        this.appLoading(true)
        try {
          await Auth.sendPasswordResetEmail(this.form.email)
          this.setMessageInformations({
            message:
              'You reset link has been sent to your email. You will be redirected in 3 seconds.',
            state: 'success',
          })
          setTimeout(() => {
            this.$router.push({ name: 'login' })
          }, 3000)
        } catch (error) {
          this.handleErrors({ error })
        } finally {
          this.appLoading(false)
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .forgotten-password {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh;
  }

  .forgotten-password_submit {
    width: 100%;
    font-weight: 600;
  }
</style>
