import { mapState, mapGetters } from 'vuex'

export const AppMixin = {
  computed: {
    ...mapGetters(['getAppRoute', 'getComponent', 'canUserDo']),

    ...mapState({
      isDBLoaded: (state) => state.isDBLoaded,
      isAppRouteLoaded: (state) => state.isAppRouteLoaded,
      appData: (state) => state.approutes.currentRoute.data,
      hierarchy: (state) => state.approutes.currentRoute.firecamp.hierarchy[0],
      fireComp: (state) => state.approutes.currentRoute.firecamp.components,
    }),

    appDetails() {
      return this.getAppRoute(this.$route.params.app_id)
    },
  },
}
