<template>
  <div class="profile-picture_container">
    <img
      v-if="displayImage"
      class="profile-picture_avatar"
      :src="profilePicPath"
      @error="handleImageError"
      @load="onLoad"
    />

    <svg
      class="profile-picture_initials"
      viewBox="0 0 625 625"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <text
        :x="numberOfInitials > 1 ? '100px' : '210px'"
        y="425px"
        style="font-size: 350px"
        font-family="Lucida Console, Monaco, monospace"
      >
        {{ userInitials }}
      </text>
    </svg>

    <template v-if="!displayMode">
      <input
        class="profile-picture_input"
        type="file"
        @change="onFileChange($event)"
      />
      <div class="profile-picture_hover">
        <b-icon
          icon="image"
          font-scale="1.8"
          shift-h="2"
          shift-v="-1.5"
        />
        <b-icon
          icon="plus"
          font-scale="1.6"
          shift-h="-0.5"
          shift-v="4"
        />
      </div>
    </template>
  </div>
</template>

<script>
  import {
    FILE_ERROR_NOT_ALLOWED_TYPE,
    FILE_ERROR_TOO_LARGE,
  } from '@/constants/ErrorConstants'
  import { formatAssetLink } from '@/utils/StringHelpers'

  export default {
    name: 'UserProfilePicture',

    props: {
      imagePath: {
        type: String,
        default: null,
      },
      displayMode: {
        type: Boolean,
        default: false,
      },
      userName: {
        type: String,
        default: null,
      },
    },

    data() {
      return {
        currentImagePath: null,
        imageError: false,
      }
    },

    computed: {
      profilePicPath() {
        if (this.imagePath === null && this.currentImagePath === null) {
          return null
        } else if (
          this.currentImagePath !== null &&
          this.currentImagePath !== this.imagePath
        ) {
          return this.currentImagePath
        } else {
          return /^https?:.*/.test(this.imagePath)
            ? this.imagePath
            : formatAssetLink(this.imagePath)
        }
      },

      userInitials() {
        if (this.userName?.length === 0) {
          return 'PP'
        } else if (this.userName?.includes(' ')) {
          const spaceIndex = this.userName?.indexOf(' ')
          return (
            this.userName?.substring(0, 1) +
            this.userName?.substring(spaceIndex + 1, spaceIndex + 2)
          )
        } else {
          return this.userName?.substring(0, 1)
        }
      },

      numberOfInitials() {
        return this.userInitials?.length
      },

      displayImage() {
        return this.profilePicPath?.length > 0 && !this.imageError
      },
    },

    methods: {
      onFileChange(event) {
        const file = event.target.files[0]

        if (!file) return

        this.currentImagePath = URL.createObjectURL(file)

        if (this.validateFile(file)) {
          this.$emit('updateProfilePic', file)
        }
      },

      validateFile(file) {
        const allowedExtensions = ['jpg', 'png', 'jpeg', 'svg']
        const sizeLimit = 2000000

        const { name: fileName, size: fileSize } = file

        const fileExtension = fileName.split('.').pop()

        if (!allowedExtensions.includes(fileExtension.toLowerCase())) {
          this.handleErrors({ code: FILE_ERROR_NOT_ALLOWED_TYPE })
          return false
        } else if (fileSize > sizeLimit) {
          this.handleErrors({ code: FILE_ERROR_TOO_LARGE })
          return false
        }

        return true
      },

      handleImageError() {
        this.imageError = true
      },

      onLoad() {
        this.imageError = false
      },
    },
  }
</script>

<style lang="scss">
  .profile-picture_container {
    display: flex;
    position: relative;
    max-width: 125px;
    max-height: 125px;
    border-radius: 50%;
    background-color: var(--secondary);
    overflow: hidden;
    transition: box-shadow 0.2s ease-in-out;

    &:hover {
      box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.2);
    }
  }

  .profile-picture_avatar {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
  }

  .profile-picture_input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;

    &:hover {
      cursor: pointer;

      & + .profile-picture_hover {
        opacity: 0.8;
      }
    }
  }

  .profile-picture_hover {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.2s ease;
    color: white;
  }

  .profile-picture_initials {
    width: 100%;
    height: 100%;
    fill: var(--secondary-font-color);
  }
</style>
