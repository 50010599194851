<template>
  <BaseContainer
    class="documentation_base-container"
    :title="title"
  >
    <CollectionBoard
      :collection-data="categories"
      :format-function="formatFunction"
    />
  </BaseContainer>
</template>

<script>
  import { mapActions, mapState } from 'vuex'

  import BaseContainer from '@/components/containers/BaseContainer.vue'
  import CollectionBoard from '@/components/board/CollectionBoard.vue'

  export default {
    name: 'DocumentationBoard',

    components: {
      BaseContainer,
      CollectionBoard,
    },

    computed: {
      ...mapState('documentations', ['categories']),

      title() {
        return this.$router.displayName ?? 'Documentation Board'
      },
    },

    async created() {
      this.appLoading(true)
      await this.loadCategories()
      this.appLoading(false)
    },

    methods: {
      ...mapActions('documentations', ['loadCategories']),

      formatFunction(element) {
        return {
          ...element,
          title: element?.displayName ?? element?.id,
          destination: {
            name: 'documentation-page--preview',
            params: { documentation: element.id },
          },
        }
      },
    },
  }
</script>
