<template>
  <codemirror
    :value="editorValue ? editorValue : ''"
    :options="options"
    @changes="onCodeChange"
  />
</template>

<script>
  import { codemirror } from 'vue-codemirror'

  // import basic style
  import 'codemirror/lib/codemirror.css'

  // import addons and language support
  import 'codemirror/mode/javascript/javascript.js'
  import 'codemirror/addon/display/autorefresh.js'
  import 'codemirror/addon/edit/matchbrackets.js'
  import 'codemirror/addon/edit/closebrackets.js'
  import 'codemirror/addon/lint/lint.js'
  import 'codemirror/addon/lint/json-lint.js'
  import 'codemirror/addon/lint/lint.css'
  import 'codemirror/addon/scroll/simplescrollbars.js'
  import 'codemirror/addon/scroll/simplescrollbars.css'

  window.jsonlint = require('jsonlint-mod')

  // import theme style
  import 'codemirror/theme/moxer.css'
  import 'codemirror/theme/oceanic-next.css'
  import 'codemirror/theme/neat.css'

  export default {
    name: 'CodeEditor',

    components: {
      codemirror,
    },

    props: {
      editorValue: {
        type: String,
        default: '',
      },
      options: {
        type: Object,
        default() {
          return {
            lineNumbers: true,
            line: true,
            indentUnit: 4,
            indentWithTabs: true,
            autoRefresh: true,
            matchBrackets: true,
            autoCloseBrackets: true,
          }
        },
      },
    },

    methods: {
      onCodeChange(cm) {
        if (cm.getValue() !== this.editorValue)
          this.$emit('editorValueUpdated', cm.getValue())
      },
    },
  }
</script>
