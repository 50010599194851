//
// EMAIL
//

/**
 * Simple email validation method.
 *
 * @param {String} email - The string to check.
 * @return {Boolean} Returns true if this is an email.
 */
export function emailValidation(email: any): boolean {
  const regex = new RegExp(/^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/)
  return typeof email === 'string' && email.length > 0 && regex.test(email)
}

const lOrealGroupDomains = [
  'loreal.com',
  'kiehls-usa.com',
  'itcosmetics.com',
  'nyxcosmetics.com',
]

export function isLorealDomainEmail(email: string): boolean {
  return (
    emailValidation(email) &&
    lOrealGroupDomains.includes(email.toLowerCase().split('@')[1])
  )
}

//
// PASSWORD
//

/**
 * Simple password validation method.
 *
 * @param {String} password - The String to check.
 * @return {Boolean} Returns true if the password check the requirements.
 */
export function passwordValidation(password: string): boolean {
  return password?.length > 0 ?? false
}

//Generate Password Function
export function generatePassword(passwordLength = 12): string {
  let generatedPassword = ''
  const randomFunction = [
    getRandomUpperCase,
    getRandomLowerCase,
    getRandomNumber,
    getRandomSymbol,
  ]

  do {
    passwordLength -= 1
    const randomId = Math.floor(Math.random() * Math.floor(3))
    generatedPassword += randomFunction[randomId]()
  } while (passwordLength > 0)

  return generatedPassword
}

/**
 * Get a random uppercase character.
 *
 * @return {String} Returns a single uppercase character.
 */
function getRandomUpperCase(): string {
  return String.fromCharCode(Math.floor(Math.random() * 26) + 65)
}

/**
 * Get a random lowercase character.
 *
 * @return {String} Returns a single lowercase character.
 */
function getRandomLowerCase(): string {
  return String.fromCharCode(Math.floor(Math.random() * 26) + 97)
}

/**
 * Get a random number character.
 *
 * @return {String} Returns a single number character.
 */
function getRandomNumber(): string {
  return String.fromCharCode(Math.floor(Math.random() * 10) + 48)
}

/**
 * Get a random symbol.
 *
 * @return {String} Returns a single symbol.
 */
function getRandomSymbol(): string {
  const symbol = '!@#$%^&*(){}[]=<>/,.|~?'
  return symbol[Math.floor(Math.random() * symbol.length)]
}
