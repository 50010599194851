<template>
  <div class="container-title row">
    <h1
      class="fullsize-title col"
      :class="{
        'fullsize-title--wrap': subtitle,
      }"
    >
      {{ title }}
      <span
        v-if="subtitle"
        class="subtitle"
        >{{ subtitle }}</span
      >
      <span class="right-part">
        <slot />
      </span>
    </h1>
  </div>
</template>

<script>
  export default {
    name: 'TitleContainer',

    props: {
      title: {
        type: String,
        required: true,
      },
      subtitle: {
        type: String,
        default: null,
      },
    },
  }
</script>

<style lang="scss" scoped>
  .container-title {
    .fullsize-title {
      display: flex;
      justify-content: space-between;
      margin: 0;
      background: var(--secondary);
      color: var(--secondary-font-color);
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      position: relative;
      font-size: 2rem;
      font-weight: 300;
      line-height: 1.2;

      &--wrap {
        flex-wrap: wrap;
      }

      button {
        background: transparent;
        border: none;
        margin-left: auto;
        color: var(--secondary-font-color);
        outline: none;
      }

      .subtitle {
        width: 100%;
        margin-top: 5px;
        font-size: 1.5rem;
      }

      .right-part {
        display: flex;
      }
    }
  }
</style>
