<template>
  <div :class="appClasses">
    <FirecampLoader
      v-if="showFirecampLoader"
      :title="loadingInformation.title"
      fixed
    />
    <MaintenancePage
      v-if="isMaintenanceModeOn"
      :class="
        !canIgnoreMaintenanceMode ? 'container--main container-fluid' : ''
      "
    />

    <LiabilityPopIn @terms-accepted="retailersTermsAccepted" />

    <main
      v-if="
        (!isMaintenanceModeOn ||
          (isMaintenanceModeOn && canIgnoreMaintenanceMode)) &&
        !showLiabilityPopIn
      "
      :class="mainContentClass"
    >
      <Sidebar
        v-if="isMenuVisible && isUserSet"
        class="container_sidebar"
      />
      <PrivateMenu
        v-if="isMenuVisible && isUserSet"
        class="container_topbar"
      />
      <NotificationsDisplay v-else />
      <div
        ref="scrollContainer"
        class="container_main container-fluid"
      >
        <router-view />
        <ToTopButton
          v-if="canGoToTop"
          @click="goToTop"
        />
      </div>
    </main>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
  import { generateShowDataValues } from '@/utils/ModalHelpers'

  import MaintenancePage from '@/views/MaintenancePage'
  import PrivateMenu from '@/components/menus/PrivateMenu'
  import FirecampLoader from '@/components/loader/FirecampLoader'
  import Sidebar from '@/components/menus/Sidebar'
  import LiabilityPopIn from '@/components/modals/LiabilityPopIn.vue'

  import ToTopButton from '@/components/buttons/ToTopButton'

  import { ModalMixin } from '@/mixins/ModalMixin'

  import { RIGHTS } from '@/constants/UserConstants'
  import NotificationsDisplay from '@/components/menus/message/NotificationsDisplay.vue'

  const showDataValues = generateShowDataValues([
    'modal-liability-pop-in',
    'modal-register-phone-number',
  ])

  export default {
    name: 'Firecamp',

    components: {
      PrivateMenu,
      FirecampLoader,
      Sidebar,
      ToTopButton,
      MaintenancePage,
      LiabilityPopIn,
      NotificationsDisplay,
    },

    mixins: [ModalMixin],

    data() {
      return {
        ...showDataValues,
        canGoToTop: false,
      }
    },

    computed: {
      ...mapState(['isDBLoaded', 'loadingInformation', 'showLiabilityPopIn']),

      ...mapGetters([
        'getUserTheme',
        'canUserDo',
        'isMaintenanceModeOn',
        'getVersion',
        'isUserSet',
        'isModifaceRetailer',
        'hasAcceptedRetailersTermsOfUse',
      ]),

      isMenuVisible() {
        return ![
          'login',
          'logout',
          'forgotten-password',
          'error-page',
        ].includes(this.$route.name)
      },

      appClasses() {
        return {
          app: true,
          'app-container': this.isMenuVisible,
        }
      },

      mainContentClass() {
        return {
          'main-content': true,
          'main-content--no-menu': !this.isMenuVisible,
        }
      },

      themeName() {
        return this.getUserTheme
      },

      showFirecampLoader() {
        const hasMaintenanceMode =
          (this.isMaintenanceModeOn && this.canIgnoreMaintenanceMode) ||
          !this.isMaintenanceModeOn
        return (
          hasMaintenanceMode &&
          this.isMenuVisible &&
          this.loadingInformation.show
        )
      },

      canIgnoreMaintenanceMode() {
        return this.canUserDo(RIGHTS.IGNORE_MAINTENANCE_MODE)
      },
    },

    watch: {
      themeName(newTheme) {
        if (newTheme) document.body.setAttribute('theme', newTheme)
      },

      getVersion(newValue, oldValue) {
        if (newValue && oldValue && newValue !== oldValue) {
          this.setMessageInformations({
            message: `New version ${newValue} available. Please reload your page to update to the latest version. `,
            state: 'warning',
            keepShowing: true,
            dismissible: false,
            floating: false,
            actionLink: '/',
            actionLabel: 'Reload',
          })
        }
      },

      isUserSet: {
        immediate: true,
        async handler() {
          await this.init()
        },
      },
    },

    mounted() {
      this.$refs.scrollContainer.addEventListener('scroll', this.handleScroll)
    },

    destroyed() {
      this.$refs.scrollContainer.removeEventListener(
        'scroll',
        this.handleScroll,
      )

      this.unbindApp()
      this.dbLoaded(false)
    },

    async updated() {
      if (!this.showLiabilityPopIn) await this.init()
    },

    methods: {
      ...mapActions([
        'initApp',
        'unbindApp',
        'setShowLiabilityPopIn',
        'setShowPhoneNumberPopIn',
        'setHasAcceptedRetailersTermsOfUse',
      ]),

      ...mapMutations(['dbLoaded', 'appLoading']),

      async init() {
        const routeName = this.$route.name
        if (
          this.isMaintenanceModeOn ||
          ['login', 'logout'].includes(routeName) ||
          !this.isUserSet
        )
          return
        if (!this.isDBLoaded && !this.isAppLoading) {
          try {
            this.appLoading(true)

            if (
              this.isModifaceRetailer &&
              !this.hasAcceptedRetailersTermsOfUse
            ) {
              this.appLoading(false)
              this.setLoadingInformations({ title: null, show: false })
              this.setShowLiabilityPopIn(true)
              this.handleModal('modal-liability-pop-in')
            } else {
              await this.initApp()
            }
          } catch (error) {
            this.handleErrors({ error })
            if (routeName !== 'error-page') {
              this.$router.push({
                name: 'error-page',
                params: { code: 500, message: 'Error while retrieving data.' },
              })
            }
          } finally {
            if (this.isAppLoading) this.appLoading(false)
          }
        }
      },

      retailersTermsAccepted() {
        this.setShowLiabilityPopIn(false)
        this.setHasAcceptedRetailersTermsOfUse(true)
        this.handleModal('modal-liability-pop-in')
        if (this.$route.name !== 'homepage')
          this.$router.push({ name: 'homepage' })
      },

      handleScroll(el) {
        this.canGoToTop = el.target.scrollTop > 200
      },

      goToTop() {
        this.$refs.scrollContainer.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  .app-container {
    height: 100vh;
  }

  .main-content {
    display: grid;
    height: 100%;
    grid-template-areas: 'sidebar breadcrumb' 'sidebar main';
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
  }

  .main-content--no-menu {
    display: block;
  }

  .container {
    &_topbar {
      grid-area: breadcrumb;
    }

    &_main {
      grid-area: main;
      padding-bottom: 50px;
      overflow-y: auto;
    }

    &_sidebar {
      grid-area: sidebar;
    }
  }
</style>
