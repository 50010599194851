<template>
  <div class="firecamp_fields-group">
    <span
      v-if="title"
      class="firecamp_fields-group_title"
      >{{ title }}</span
    >
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'FieldsGroup',

    props: {
      title: {
        type: String,
        default: null,
        required: false,
      },
    },
  }
</script>

<style lang="scss" scoped>
  .firecamp_fields-group {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding: 10px;
    border: 1px solid var(--primary);
    border-radius: 5px;
    margin-bottom: 40px;
    color: var(--app-background-font-color);

    .firecamp_fields-group_title {
      position: absolute;
      top: -10px;
      left: 5px;
      background: var(--app-background-color);
      padding: 0 5px;
      font-style: italic;
      font-size: 0.9rem;
      line-height: 0.9rem;
      color: var(--app-background-font-color);
    }
  }
</style>
