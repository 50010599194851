<template>
  <div
    :class="tileContainerClass"
    @dragstart="emitEvent('dragstart', $event)"
  >
    <b-card :class="tileClass">
      <div
        v-if="mock"
        class="tile_mock"
      >
        <span class="tile_mock_title loading" />
        <div class="tile_mock_description">
          <span
            v-for="index in 4"
            :key="`mock-description-loading-${index}`"
            class="loading"
          />
        </div>
      </div>
      <div
        v-else
        class="card-text"
      >
        <div
          v-if="!!infoTooltip"
          v-b-tooltip.hover
          class="tile_action-info-tooltip"
          :title="infoTooltip"
        >
          <b-icon
            icon="info-circle-fill"
            variant="primary"
          />
        </div>

        <div class="tile_action-icons_container">
          <slot name="action-icons" />
        </div>

        <div
          v-if="!!showPicture"
          class="tile_picture_container"
        >
          <UserProfilePicture
            v-if="!!picturePath"
            class="tile_picture"
            :image-path="picturePath"
            :display-mode="true"
          />

          <b-avatar
            v-else
            class="tile_picture tile_picture--avatar"
          />
        </div>

        <h4
          v-if="label || title"
          class="tile_title_container"
          :class="!details ? 'tile_title_container--full' : ''"
        >
          <CountryFlag
            v-if="showFlag"
            :country="id.match(/^(\w+)-?/)[1]"
            rounded
            size="normal"
          />
          <span
            v-if="title && !titleTagColor"
            class="tile_title"
            >{{ shortenString(title) }}</span
          >
          <Tag
            v-if="title && titleTagColor"
            :color="titleTagColor"
            :text="shortenString(title)"
          />
          <router-link
            v-if="link"
            :to="link"
            title="Edit profile"
          >
            {{ shortenString(label) }}
          </router-link>
          <template v-else>
            <span class="tile_title_label">{{ shortenString(label) }}</span>
          </template>
          <span
            v-if="rating"
            class="tile_rating"
            >{{ rating }}</span
          >
        </h4>

        <TileInformation
          :show="!!details"
          :inline="list"
          :details="details"
          :tags-values="tagsValues"
        />

        <TileAdditionalActions
          v-if="!!additionalActions"
          :actions="additionalActions"
          v-on="$listeners"
        />

        <QuickActionButton
          v-if="!!quickActionButton"
          :actions="quickActionButton"
          v-on="$listeners"
        />

        <div
          v-if="!!image"
          class="tile_image"
        >
          <img :src="image" />
        </div>

        <p
          v-if="!!description"
          class="tile_description"
        >
          {{ description }}
        </p>
      </div>
    </b-card>
  </div>
</template>

<script>
  import Tag from '@/components/tag/Tag'
  import QuickActionButton from '@/components/tile/QuickActionButton'
  import TileInformation from '@/components/tile/TileInformation'
  import TileAdditionalActions from './TileAdditionalActions.vue'
  import UserProfilePicture from '@/components/admin/user/profile/UserProfilePicture'
  import CountryFlag from 'vue-country-flag'

  const propsBuilder = (fields, type) => {
    return fields.reduce(
      (accumulator, field) => ({
        ...accumulator,
        [field]: { type, default: type === Boolean ? false : null },
      }),
      {},
    )
  }
  const booleanFields = propsBuilder(
    ['list', 'small', 'highlighted', 'mock', 'showPicture', 'showFlag'],
    Boolean,
  )
  const stringFields = propsBuilder(
    [
      'id',
      'title',
      'titleTagColor',
      'label',
      'rating',
      'image',
      'description',
      'docPath',
      'infoTooltip',
      'containerClass',
      'cardClass',
      'hightlightVariant',
      'picturePath',
    ],
    String,
  )

  export default {
    components: {
      CountryFlag,
      Tag,
      QuickActionButton,
      TileInformation,
      TileAdditionalActions,
      UserProfilePicture,
    },

    props: {
      link: {
        type: Object,
        default: null,
      },
      details: {
        type: Object,
        default: null,
      },
      additionalActions: {
        type: Array,
        default: null,
      },
      quickActionButton: {
        type: Array,
        default: null,
      },
      tagsValues: {
        type: Array,
        default: null,
      },
      ...stringFields,
      ...booleanFields,
    },

    computed: {
      hasDeleteListener() {
        return Boolean(this.$listeners?.deleteItem)
      },

      hasFlagListener() {
        return Boolean(this.$listeners?.openFlag)
      },

      tileContainerClass() {
        const baseClass = 'tile_container'
        return [
          baseClass,
          ...(!this.list && !this.small && !this.containerClass
            ? ['col-xs-6 col-sm-6 col-lg-3']
            : [this.containerClass]),
          ...(!this.small && !this.list ? [`${baseClass}--thumbnail`] : []),
          ...(this.small ? [`${baseClass}--small`] : []),
          ...(this.list ? [`col-12 ${baseClass}--list`] : []),
          ...(this.highlighted ? [`${baseClass}--highlighted`] : []),
          ...[
            this.highlighted && this.hightlightVariant
              ? [`${baseClass}--highlighted--${this.hightlightVariant}`]
              : [],
          ],
          ...(this.mock ? [`${baseClass}--mock`] : []),
        ]
          .join(' ')
          .trim()
      },

      tileClass() {
        const baseClass = 'tile'
        return [
          baseClass,
          ...(this.cardClass ? [this.cardClass] : []),
          ...(this.list ? [`${baseClass}--list`] : []),
          ...(this.highlighted ? [`${baseClass}--highlighted`] : []),
          ...(this.highlighted && this.hightlightVariant
            ? [`${baseClass}--highlighted--${this.hightlightVariant}`]
            : []),
          ...(this.mock ? [`${baseClass}--mock`] : []),
        ]
          .join(' ')
          .trim()
      },
    },

    methods: {
      emitEvent(eventName, passingValue) {
        if (passingValue === undefined || passingValue === null) {
          this.$emit(eventName)
        } else {
          this.$emit(eventName, passingValue)
        }
      },

      shortenString(string) {
        return string && string.length > 42
          ? string.substring(0, 42) + '...'
          : string
      },
    },
  }
</script>

<style lang="scss">
  .tile_container {
    margin-bottom: 30px;

    &:nth-child(even) {
      .tile--list,
      .tile.card {
        background-color: var(--list-even-background);
      }
    }

    &[draggable] {
      user-select: none;

      &:hover {
        cursor: move;
      }

      &:active {
        .card {
          border-style: dashed;
        }
      }
    }

    &--small {
      width: 125px;
      min-width: 125px;
      height: 125px;
      margin-bottom: 10px;

      .tile {
        min-height: 100%;

        .card-body {
          padding: 5px;
          background-color: var(--app-background-color);
          border-radius: 0.25rem;
        }

        .card-text {
          padding: 0;
        }

        .tile_title_container {
          position: relative;
          margin: auto 0 0;
          font-size: 13px;
          flex: 1 1 100%;
          font-weight: 400;
          order: 0;
          z-index: 1;

          &:after {
            content: '';
            display: block;
            width: calc(100% - 2px);
            height: 100%;
            position: absolute;
            bottom: 0;
            left: 1px;
            background: var(--app-background-color);
            background: linear-gradient(
              0deg,
              var(--app-background-color) 0%,
              rgba(0, 0, 0, 0) 100%
            );
            z-index: -1;
            transition: all 0.2s ease-in-out;
          }

          span:only-child {
            font-weight: 700;
            color: var(--app-background-font-color);
          }
        }

        .tile_title_label {
          color: var(--app-background-font-color);
        }

        .tile_image {
          background: white;
          padding: 0;
          transform: translateY(-50%);
          clip-path: circle(40% at center);
          top: 50%;
          user-select: none;

          &:after {
            display: none;
          }

          img {
            height: 100%;
            width: auto;
            user-select: none;
          }
        }

        .tile_rating {
          margin-left: auto;
          font-size: 12px;
          text-align: right;
          color: var(--list-even-background);
        }
      }
    }

    &--list {
      min-height: 55px;
      margin-bottom: 10px;
    }

    &--demo {
      margin-top: 20px;
      border: none;

      .tile {
        border: none;

        &:hover {
          box-shadow: none;
        }
      }

      .tile .tile_image {
        width: 100%;
        position: relative;
        height: 150px;
        transform: unset;
        top: 0;
        margin: 15px 0;
      }

      .tile_description {
        margin-bottom: 0;
      }
    }

    &--highlighted,
    &--highlighted:nth-child(even) {
      .tile--list {
        background-color: var(--secondary);
        color: var(--secondary-font-color);
      }

      &.tile_container--highlighted {
        .tile--list {
          background: var(--secondary);
          border-color: var(--secondary);
        }
      }

      &.tile_container--highlighted--success {
        .tile--list {
          background: var(--success);
          border-color: var(--success);
        }
      }

      &.tile_container--highlighted--error {
        .tile--list {
          background: var(--danger);
          border-color: var(--danger);
          color: white;
        }
      }

      &.tile_container--highlighted--warning {
        .tile--list {
          background: var(--warning);
          border-color: var(--warning);
        }
      }
    }

    .tile_picture_container {
      width: 45px;
    }

    .tile_picture {
      max-height: 34px;
      max-width: 34px;
      border: 2px solid var(--primary-highlight);

      &--avatar {
        background-color: var(--primary);
      }
    }
  }

  .tile {
    min-height: 175px;
    height: 100%;
    transition: all ease-in-out 0.2s;

    &:hover,
    &:focus,
    &:active {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }

    &.card {
      border-color: var(--border-color);
      background-color: var(--app-background-color);
    }

    .card-body {
      display: flex;
      padding: 0;
    }

    .card-text {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      width: 100%;
      padding: 1.25rem;
      align-items: flex-start;
      background-position: bottom;
      background-size: 75%;
      background-repeat: no-repeat;
      z-index: 1;
    }

    .tile_title_container {
      text-align: left;
      flex: 0 0 calc(100% - 40px);
      margin-top: 0;
      margin-bottom: 0;
      font-size: 1rem;
      word-break: break-word;

      a {
        color: var(--app-background-font-color);
        text-decoration: none;
      }
    }

    .tile_title {
      display: block;
      text-transform: uppercase;
      font-weight: bolder;
      margin-bottom: 2px;
    }

    .tile_title_label {
      word-break: break-all;
    }

    .tile_description {
      font-size: 0.9rem;
      margin-bottom: 0;
    }

    .tile_action-info-tooltip {
      cursor: auto;

      .b-icon {
        color: var(
          --app-background-font-color
        ) !important; // override bootstrap .text-primary !important
      }
    }

    .tile_action-icons_container {
      display: flex;
      flex-wrap: wrap;
      flex: 0 0 100%;
      flex-direction: column;
      width: 20px;
      position: absolute;
      right: 0;

      &:empty {
        display: none;
      }

      .btn {
        width: 100%;
        padding: 0;
        margin-left: auto;
        margin-bottom: 5px;
        background: none;
        border: none;

        &-danger {
          color: var(--danger);

          &:hover,
          &:active,
          &:focus {
            color: var(--danger);
          }
        }

        &-success {
          color: var(--success);

          &:hover,
          &:active,
          &:focus {
            color: var(--success);
          }
        }

        &:hover,
        &:active,
        &:focus {
          background: none;
          border: none;
        }
      }
    }

    .tile_image {
      width: 100%;
      height: 100%;
      text-align: center;
      overflow: hidden;
      position: absolute;
      z-index: -1;
      left: 0;
      top: 0;
      padding: 1.25rem;
      border-radius: 0.25rem;

      &:after {
        content: '';
        background: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .tile_rating {
      margin-left: auto;
      text-align: right;

      &:before {
        content: '★';
        font-size: 18px;
        line-height: 12px;
        color: #edc988;
        margin-right: 3px;
      }
    }

    .tile_action {
      background: transparent;
      border: none;
      color: white;
      outline: none;

      &:after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    &--list {
      min-height: 0;

      .card-body {
        padding: 0 0.8rem;
      }

      .card-text {
        padding: 0;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: left;
        z-index: unset;

        &:after {
          display: none;
        }
      }

      .tile_title_container {
        flex: 0 15 20%;
        margin: 0;

        &--full {
          flex: 0 0 calc(100% - 40px);
        }
      }

      .tile-information_container {
        flex: 0 0 calc(75% - 60px);
        margin: 10px 30px 0;
      }

      .tile_list {
        display: inline-flex;
        flex-wrap: wrap;
        margin-right: 20px;
        margin-bottom: 0;

        &:last-child {
          margin-right: 20px;
        }
      }

      .quick-actions-button {
        flex: 0 0 35px;
        order: 3;
        margin-left: auto;

        .btn-link {
          color: var(--app-background-font-color);
        }
      }
    }

    &--mock {
      .tile_mock {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 1.25rem;
      }

      .tile_mock_title {
        display: block;
        height: 20px;
        width: 80%;
      }

      .tile_mock_description {
        span {
          display: block;
          width: 100%;
          height: 12px;
          margin: 10px 0;
        }
      }
    }

    &--highlighted {
      &.card {
        background: var(--secondary);
      }

      &--success {
        &.card {
          background: var(--success);
          border-color: var(--success);
        }
      }

      &--warning {
        &.card {
          background: var(--warning);
          border-color: var(--warning);
        }
      }

      &--error {
        &.card {
          background: var(--danger);
          border-color: var(--danger);
        }
      }

      .quick-actions-button > .btn-link {
        color: var(--secondary-font-color);
      }
    }
  }

  .tile--list.tile--mock {
    .tile_mock {
      flex-wrap: wrap;
      align-items: center;
      flex-direction: row;
      padding: 0;
    }

    .tile_mock_title {
      width: 20%;
      margin-bottom: 0;
    }

    .tile_mock_description {
      width: calc(80% - 40px);
      margin: auto 20px;

      span:nth-child(n + 3) {
        display: none;
      }
    }
  }
</style>
