<template>
  <CollapseContainer
    title="Options"
    class="mapping-field_options"
    variant="info"
    border
    :open.sync="isOpen"
  >
    <div class="mapping-field_wrapper">
      <div
        v-for="(option, optionIndex) in optionsInternal"
        :key="`field-options-${optionIndex}`"
        class="mapping-field_options_option"
        draggable
        @dragstart="optionDragStart($event, optionIndex)"
        @dragleave.prevent="optionDragLeave($event, optionIndex)"
        @dragover.prevent="optionDragOver($event, optionIndex)"
        @drop.prevent="optionDrop($event, optionIndex)"
        @dragenter.prevent
      >
        <span class="mapping-field_option_move text-muted">
          <b-icon icon="grip-vertical" />
          #{{ optionIndex + 1 }}
        </span>
        <b-form-group
          label="Label: "
          class="mapping-field_options_option_field"
        >
          <b-input
            v-model="optionsInternal[optionIndex].label"
            :debounce="500"
          />
        </b-form-group>

        <b-form-group
          label="Value : "
          class="mapping-field_options_option_field"
        >
          <b-input
            :value="optionsInternal[optionIndex].value.toString()"
            :debounce="500"
            @input="onInputValue(optionIndex, $event)"
          />
        </b-form-group>

        <b-button
          class="mapping-field_options_remove"
          variant="danger"
          :title="`Delete option #${optionIndex}`"
          @click.prevent="removeOption(optionIndex)"
        >
          <b-icon icon="trash" />
        </b-button>
      </div>
      <div class="mapping-field_options_actions">
        <b-button @click.prevent="addOption()">
          <b-icon
            icon="plus"
            class="mr-1"
          />
          Add an option
        </b-button>
      </div>
    </div>
  </CollapseContainer>
</template>

<script>
  import { OPTIONS_TEMPLATE } from '@/constants/FieldsMappingConstants'

  import CollapseContainer from '@/components/containers/CollapseContainer'
  import { convertStringToValue } from '@/utils/StringHelpers'

  const topClass = 'mapping-field_options_option--dragged-over--top'
  const bottomClass = 'mapping-field_options_option--dragged-over--bottom'
  const removeClass = (parent, classToRemove) => {
    if (!parent) return
    parent.classList.remove(classToRemove)
  }
  const addClass = (parent, classToAdd) => {
    if (!parent) return
    parent.classList.add(classToAdd)
  }
  export default {
    name: 'MappingFieldsOptions',

    components: {
      CollapseContainer,
    },

    props: {
      options: {
        type: Array,
        required: true,
      },
    },

    data() {
      return {
        id: this._uid,
        optionsInternal: [],
        draggedOption: null,
        isDraggedTop: false,
        isOpen: false,
      }
    },

    watch: {
      optionsInternal: {
        deep: true,
        handler(value) {
          this.$emit('update:options', value)
        },
      },
    },

    mounted() {
      this.optionsInternal = this.options.map((option) => ({
        ...option,
      }))
    },

    methods: {
      addOption() {
        this.optionsInternal.push({ ...OPTIONS_TEMPLATE })
      },

      removeOption(optionIndex) {
        this.optionsInternal.splice(optionIndex, 1)
      },

      optionDragStart(event, optionIndex) {
        this.draggedOption = { optionIndex }
        event.dataTransfer.effectAllowed = 'move'
      },

      optionDragOver(event, optionIndex) {
        if (!this.draggedOption) return
        const isDraggedElement = optionIndex === this.draggedOption.optionIndex
        const optionParent = event.target.closest(
          '.mapping-field_options_option',
        )
        const optionRect = optionParent?.getBoundingClientRect()

        if (isDraggedElement) return

        this.isDraggedTop =
          event.clientY < optionRect.top + optionRect.height / 2

        addClass(optionParent, 'mapping-field_options_option--dragged-over')
        if (this.isDraggedTop) {
          removeClass(optionParent, bottomClass)
          addClass(optionParent, topClass)
        } else {
          removeClass(optionParent, topClass)
          addClass(optionParent, bottomClass)
        }
      },

      optionDragLeave(event, optionIndex) {
        if (!this.draggedOption) return
        const isDraggedElement = optionIndex === this.draggedOption.optionIndex

        const optionParent = event.target.closest(
          '.mapping-field_options_option',
        )
        if (!isDraggedElement) {
          this.resetClasses(optionParent)
        }
      },

      optionDrop(event, optionIndex) {
        if (!this.draggedOption) return

        const isDraggedElement = optionIndex === this.draggedOption.optionIndex
        const optionParent = event.target.closest(
          '.mapping-field_options_option',
        )
        const newIndex = this.isDraggedTop ? optionIndex : optionIndex + 1

        if (isDraggedElement) {
          this.resetClasses(optionParent)
          return
        }

        this.optionsInternal.splice(
          newIndex,
          0,
          this.optionsInternal.splice(this.draggedOption.optionIndex, 1)[0],
        )

        this.resetClasses(optionParent)

        this.draggedOption = null
      },

      resetClasses(optionParent) {
        removeClass(optionParent, bottomClass)
        removeClass(optionParent, topClass)
        removeClass(optionParent, 'mapping-field_options_option--dragged-over')
      },

      onInputValue(optionIndex, value) {
        const formattedValue = convertStringToValue(value)
        this.optionsInternal[optionIndex].value = formattedValue
      },
    },
  }
</script>

<style lang="scss" scoped>
  .mapping-field_option_move {
    cursor: move;
    margin-top: 15px;
  }

  .mapping-field_wrapper {
    padding: 1em 2em;
  }

  .mapping-field_options_option {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border: 1px solid transparent;

    &:not(:first-of-type) {
      margin-top: 10px;
    }

    &:not(:last-of-type):not(.mapping-field_options_option--dragged-over) {
      border-bottom: 1px solid var(--border-color);
    }

    &--dragged-over {
      * {
        pointer-events: none;
      }

      &--top {
        border-top: 1px dashed var(--border-color);
      }

      &--bottom {
        border-bottom: 1px dashed var(--border-color);
      }
    }
  }

  .mapping-field_options_remove {
    margin-top: 15px;
  }
  .mapping-field_options_actions {
    padding-top: 1em;
    display: flex;
    justify-content: flex-end;
  }
</style>
