const RIGHTS_SETUP_ADMIN = {
  SETUP_ADMIN_DEPLOY_TO_PROD: 'setup-admin-deploy-to-prod',
  SETUP_ADMIN_BACKUP_AND_RESTORE: 'setup-admin-backup-and-restore',
}
const RIGHTS_SETUP = {
  SETUP_CONTENT: 'setup-content',
  SETUP_DESIGN: 'setup-design',
  SETUP_SETTING: 'setup-setting',
  SETUP_API: 'setup-api',
  SETUP_UNMAPPED: 'setup-unmapped',
  SETUP_EXPORTS: 'setup-exports',
  SETUP_EXPORTS_PLUS: 'setup-exports-+',
  SETUP_TESTING: 'setup-testing',
  ...RIGHTS_SETUP_ADMIN,
  SETUP_ADMIN: Object.values(RIGHTS_SETUP_ADMIN),
}

const RIGHTS_PRESCRIPTION = {
  PRESCRIPTION_PROFILES_LIST: 'prescription-profiles-list',
  PRESCRIPTION_PROFILES_DELETE_ALL: 'prescription-profiles-delete-all',
  PRESCRIPTION_ITEMS_LIST: 'prescription-items-list',
  PRESCRIPTION_ITEMS_LIST_FLUSH_CACHE: 'prescription-items-list-flush-cache',
  PRESCRIPTION_SETTINGS: 'prescription-settings',
  PRESCRIPTION_EVENTS_STEPS_DELETE: 'prescription-delete-events-steps',
  PRESCRIPTION_ADVANCED_SETTINGS: 'prescription-advanced-settings',
  PRESCRIPTION_PRODUCT_LIST: 'prescription-product-list',
}

export const RIGHTS = {
  CREATE_ROUTE: 'create-route',
  DUPLICATE_ROUTE: 'duplicate-route',
  UPGRADE_ROUTE: 'upgrade-route',
  DELETE_ROUTE: 'delete-route',
  SHOWS_IDS: 'shows-ids',

  ...RIGHTS_SETUP,
  SETUP: Object.values(RIGHTS_SETUP),

  PRESCRIPTION_CREATE: 'prescription-create',
  PRESCRIPTION_COPY: 'prescription-copy',
  ...RIGHTS_PRESCRIPTION,
  PRESCRIPTION: Object.values(RIGHTS_PRESCRIPTION),

  PRODUCT_API_CONFIG: 'product-api-config',

  USER_MANAGEMENT: 'user-management',
  DICTONARIES_MANAGEMENT: 'dictionnaries-management',
  DICTONARIES_CRM_CODES: 'dictionaries-crm-codes',
  CREATE_BRAND: 'can-create-brand',
  APPLICATIONS_MANAGEMENT: 'admin-applications-management',
  QUESTIONNAIRES_MANAGEMENT: 'questionnaires-management',

  DOCUMENTATION_BOARD: 'documentation-board',
  DOCUMENTATION_BOARD_EDITION: 'documentation-board-edition',
  DOCUMENTATION_MANAGEMENT: 'documentation-management',

  IGNORE_MAINTENANCE_MODE: 'ignore-maintenance-mode',
}

export const SCOPE_COLLECTIONS = [
  'applications',
  'countries',
  'tenants',
  'customers',
  'brands',
  'environments',
  'touchpoints',
]
