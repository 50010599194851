<template>
  <AuthContainer
    title="Login"
    :on-submit-callback="onSubmit"
    :loading="isAppLoading"
  >
    <b-form-group
      id="login-group-direct"
      class="login_buttons-container"
    >
      <b-button
        class="login_button login_button--google"
        variant="danger"
        :disabled="isGoogleDisabled"
        @click="googleSignIn"
      >
        <b-icon
          v-if="isGoogleDisabled && isAppLoading"
          icon="arrow-clockwise"
          animation="spin"
        />
        Login with <span>Google</span>
      </b-button>
    </b-form-group>
    <b-form-group
      id="login-group-email"
      label="Email address"
      label-for="login-email-input"
    >
      <b-input-group>
        <b-input-group-prepend
          is-text
          class="login_input-preprend"
        >
          <b-icon icon="person-circle" />
        </b-input-group-prepend>
        <b-form-input
          id="login-email-input"
          v-model="form.email"
          type="email"
          autocomplete="email"
          :state="form.isEmailValid"
          required
          placeholder="Enter email"
          @blur="emailValidation"
        />
      </b-input-group>
    </b-form-group>
    <b-form-group
      id="login-group-password"
      label="Password"
      label-for="login-password-input"
    >
      <b-input-group>
        <b-input-group-prepend
          is-text
          class="login_input-preprend"
        >
          <b-icon icon="lock-fill" />
        </b-input-group-prepend>
        <b-form-input
          id="login-password-input"
          v-model="form.password"
          type="password"
          aria-describedby="login-password-input-feedback"
          :state="form.isPasswordValid"
          autocomplete="current-password"
          required
          placeholder="Enter password"
          @blur="passwordValidation"
        />
      </b-input-group>
      <router-link
        :to="{ name: 'forgotten-password' }"
        class="login_forgot-password"
        >Forgot password ?</router-link
      >
    </b-form-group>

    <div id="recaptcha-container" />
    <SubmitButton
      label="Login"
      :disabled="isSubmitDisabled"
      :loading="isAppLoading"
    />
    <RegisterPhoneNumberModal
      v-model="needPhoneRegister"
      :phone-registered="phoneRegistered"
      @verified="verified"
    />
  </AuthContainer>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import { User } from '@/types/User'
  import AuthContainer from '@/components/auth/AuthContainer'
  import SubmitButton from '@/components/form/SubmitButton'
  import { emailValidation, passwordValidation } from '@/service/FormsService'
  import Auth from '@/firebase/auth'
  import RegisterPhoneNumberModal from '@/components/admin/user/mfa/RegisterPhoneNumberModal.vue'

  export default {
    name: 'Login',

    components: {
      AuthContainer,
      SubmitButton,
      RegisterPhoneNumberModal,
    },

    data() {
      return {
        form: {
          email: '',
          password: '',
          isEmailValid: null,
          isPasswordValid: null,
        },
        isGoogleDisabled: false,
        phoneRegistered: null,
        needPhoneRegister: false,
        validationModeModal: false,
      }
    },

    computed: {
      ...mapState({
        currentUser: (state) => state.user,
      }),

      isSubmitDisabled() {
        return (
          this.form.email.length === 0 ||
          this.form.password.length === 0 ||
          this.isAppLoading
        )
      },
    },

    mounted() {
      Auth.initRecaptcha('recaptcha-container')
    },

    methods: {
      ...mapActions(['setCurrentUser']),

      async googleSignIn(event) {
        event.preventDefault()
        this.appLoading(true)
        this.isGoogleDisabled = true
        try {
          await Auth.signInWithGoogle()
          await this.verified()
        } catch (error) {
          this.handleErrorsLogin(error)
        } finally {
          this.appLoading(false)
          this.isGoogleDisabled = false
        }
      },

      async verified() {
        this.appLoading(true)
        try {
          const user = await Auth.getCurrentUser()

          const userFormated = new User({
            id: user.uid,
            email: user.email,
            username: user.displayName,
            profilePic: user.photoURL,
          })
          await this.setCurrentUser(userFormated)
          this.$router.push(this.$route.query.redirect || { name: 'homepage' })
        } catch (error) {
          this.handleErrors({ error })
        } finally {
          this.appLoading(false)
        }
      },

      async onSubmit(event) {
        event.preventDefault()
        this.appLoading(true)

        try {
          await Auth.signIn(this.form.email, this.form.password)
          await this.verified()
        } catch (error) {
          this.handleErrorsLogin(error)
        } finally {
          this.appLoading(false)
        }
      },

      handleErrorsLogin(error) {
        const errorCode = error.code
        const errorMessage = error.message

        if (errorCode === 'MFA_VALIDATION_REQUIRED') {
          this.phoneRegistered = error.hint.phoneNumber
          this.needPhoneRegister = true
        } else if (errorCode === 'MFA_REQUIRED') {
          this.needPhoneRegister = true
        } else {
          if (errorCode === 'auth/user-not-found')
            this.form.isEmailValid = false
          if (errorCode === 'auth/wrong-password')
            this.form.isPasswordValid = false

          const errorMessageSanitized = errorMessage.match(
            /"message":"((\\"|[^"])*)"/i,
          )?.[1]

          this.handleErrors({ message: errorMessageSanitized || errorMessage })
        }
      },

      emailValidation() {
        this.form.isEmailValid = emailValidation(this.form.email)
      },

      passwordValidation() {
        this.form.isPasswordValid = passwordValidation(this.form.password)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .login_submit {
    width: 100%;
    font-weight: 600;
  }

  .login_forgot-password {
    font-size: 0.8rem;
    text-decoration: underline;
  }

  .login_buttons-container {
    width: 100%;
    position: relative;
    margin-bottom: 20px;

    &:before {
      content: 'or';
      position: absolute;
      bottom: -10px;
      left: 0;
      right: 0;
      margin: auto;
      background-color: var(--app-background-color);
      width: 45px;
      text-align: center;
      color: lightgrey;
    }

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      margin: 25px auto 0;
      background: lightgrey;
    }

    .login_button {
      width: 100%;
      padding-left: 0;
      margin-bottom: 5px;
      text-align: left;

      span {
        font-weight: 600;
      }

      &:before {
        content: '';
        padding: 0 calc(1rem - 1px);
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.5;
        margin-right: 0.75rem;
        border-right: 1px solid white;
      }

      &--google {
        &:before {
          content: 'G';
        }
      }
    }
  }
</style>
