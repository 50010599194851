<template>
  <div class="product-api-config-selection_container">
    <b-form v-if="!listEmpty">
      <b-form-row>
        <b-form-group
          class="product-api_multiselect col-3"
          label="Select a customer:"
        >
          <Multiselect
            id="product-api-customer-selection"
            v-model="customer"
            placeholder="Select the customer"
            track-by="id"
            label="displayName"
            :options="customers"
            :show-labels="false"
            :disabled="loading || !fetchedResources.customers"
            @input="selectedConfigChanged"
          />
        </b-form-group>

        <b-form-group
          class="product-api_multiselect col-3"
          label="Select a country:"
        >
          <Multiselect
            id="product-api-country-selection"
            v-model="country"
            placeholder="Select the country"
            track-by="id"
            label="displayName"
            :options="countries"
            :show-labels="false"
            :disabled="loading || !fetchedResources.countries"
            @input="selectedConfigChanged"
          />
        </b-form-group>
      </b-form-row>
    </b-form>
    <div
      v-else-if="!loading"
      class="no-configs_message mb-3"
    >
      <h3>No customers configured yet.</h3>
      <div class="text-left">
        <strong>Process to follow:</strong>
        <ul>
          <li>Create an instance</li>
          <li>Add a STAGING ProductAPI Configuration</li>
          <li>
            Add a PRODUCTION ProductAPI Configuration (you may fetch data from
            staging)
          </li>
        </ul>
      </div>
    </div>
    <div
      v-else
      class="loading_message mb-3"
    >
      <h3>Loading...</h3>
    </div>
    <b-button
      id="addCustomerBtn"
      class="product-api-config-add-customer_button mb-2"
      @click="onAddCustomer"
    >
      <b-icon icon="plus-circle" />
      ADD A CUSTOMER
    </b-button>
  </div>
</template>

<script>
  import { RESOURCES } from '@/service/ResourceService'
  import Multiselect from '@/components/form/Multiselect'
  import { mapState, mapActions } from 'vuex'

  export default {
    name: 'ProductAPIConfigurationSelection',

    components: {
      Multiselect,
    },

    props: {
      listEmpty: {
        type: Boolean,
        default: true,
      },
      loading: {
        type: Boolean,
        default: true,
      },
    },

    data() {
      return {
        customer: null,
        country: null,
      }
    },

    computed: {
      ...mapState({
        customers: (state) => state.customers,
        countries: (state) => state.countries,
        fetchedResources: (state) => state.fetchedResources,
      }),
    },

    async mounted() {
      await this.loadResources({
        resourceNames: [RESOURCES.COUNTRIES, RESOURCES.CUSTOMERS],
      })
    },

    methods: {
      ...mapActions(['loadResources']),

      selectedConfigChanged() {
        this.$emit('selectedConfigChanged', {
          customer: this.customer,
          country: this.country,
        })
      },

      onAddCustomer() {
        this.$emit('addCustomer', this.customer)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .product-api-config-selection_container {
    width: 100%;
    margin: 10px 0;
  }

  .no-configs_message {
    background-color: var(--warning);
    color: var(--warning-font-color);
    text-align: center;
    line-height: 1rem;
    margin: 15px;
    padding: 15px;
  }

  .loading_message {
    background-color: var(--tertiary);
    color: var(--tertiary-font-color);
    text-align: center;
    line-height: 1rem;
    padding: 15px;
  }
</style>
