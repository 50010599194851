import { mapState } from 'vuex'

export const UserManagementMixin = {
  data() {
    return {
      loading: true,
    }
  },

  computed: {
    ...mapState({
      securityGroups: (state) => state.scopesrolesgroups.groups,
      securityScopes: (state) => state.scopesrolesgroups.scopes,
      securityRoles: (state) => state.scopesrolesgroups.roles,
    }),
  },

  async created() {
    await this.init()
  },

  watch: {
    loading(newLoadingValue) {
      this.emitLoading(newLoadingValue)
    },
  },

  methods: {
    emitLoading(loadingValue) {
      this.$emit('loading', loadingValue)
    },
  },
}
