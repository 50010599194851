<template>
  <section class="mapping-field_container">
    <template v-for="(field, fieldIndex) of fields">
      <MappingAddFieldButton
        v-if="showAddFieldButton(fieldIndex)"
        :key="`button-${sectionIndex}-${subIndex}-${parentsIndexs}-${fieldIndex}-${field.index}`"
        @create-field="onAddField($event, fieldIndex)"
      />
      <MappingFieldsField
        v-if="!field.hidden"
        :id="`field-${parentsIndexs}-${fieldIndex}`"
        :key="`field-${sectionIndex}-${subIndex}-${parentsIndexs}-${fieldIndex}-${field.index}`"
        :separator-collapsed="hiddenFieldsSeparatorIndexs.has(fieldIndex)"
        :field="field"
        :field-index="fieldIndex"
        :collections-hierarchy="collectionsHierarchy"
        :in-repeater="inRepeater"
        :event-subfields="eventSubfields"
        :step-subfields="stepSubfields"
        draggable
        @drop.prevent="dropField($event, fieldIndex)"
        @dragstart="dragStartField($event, fieldIndex)"
        @dragleave.prevent="dragLeaveField($event, fieldIndex)"
        @dragover.prevent="dragOverField($event, fieldIndex)"
        @dragenter.prevent
        @toogle-separator="onToogleSeparator(fieldIndex)"
      />
    </template>
    <MappingAddFieldButton
      :label="addFieldLabel"
      @create-field="onAddField"
    />
    <span v-show="false">{{ lastUpdated }}</span>
  </section>
</template>

<script>
  import MappingAddFieldButton from '@/components/mapping/MappingAddFieldButton'
  import MappingFieldsField from '@/components/mapping/MappingFieldsField'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'MappingFields',

    components: {
      MappingAddFieldButton,
      MappingFieldsField,
    },

    props: {
      sectionIndex: {
        type: Number,
        required: true,
      },
      subIndex: {
        type: Number,
        required: true,
      },
      parentsIndexs: {
        type: Array,
        required: false,
        default: () => [],
      },
      collectionsHierarchy: {
        type: Object,
        required: true,
      },
      inRepeater: Boolean,
      eventSubfields: Boolean,
      stepSubfields: Boolean,
    },

    data() {
      return {
        draggedField: null,
        lastUpdated: null,
        hiddenFieldsSeparatorIndexs: new Set(),
        parentFieldsKey: this.eventSubfields
          ? 'eventFields'
          : this.stepSubfields
          ? 'stepFields'
          : 'fields',
      }
    },

    computed: {
      ...mapGetters({
        getSubsectionFields: 'mapping/getSubsectionFields',
        getFieldType: 'mapping/getFieldType',
      }),

      fields() {
        const fields = this.getSubsectionFields(
          this.sectionIndex,
          this.subIndex,
          this.parentsIndexs,
          this.parentFieldsKey,
        )

        let hiddenType = false
        return fields?.map((field, index) => {
          if (this.hiddenFieldsSeparatorIndexs.has(index)) {
            hiddenType = field.settings.type
            return {
              ...field,
              hidden: false,
            }
          }
          if (
            hiddenType &&
            [hiddenType, 'separator'].includes(field.settings.type || '')
          ) {
            hiddenType = false
          }
          return {
            ...field,
            hidden: !!hiddenType,
          }
        })
      },

      fieldLocation() {
        return {
          sectionIndex: this.sectionIndex,
          subIndex: this.subIndex,
          parentsIndexs: this.parentsIndexs,
          ...(this.parentFieldsKey && {
            parentFieldsKey: this.parentFieldsKey,
          }),
        }
      },

      addFieldLabel() {
        switch (true) {
          case !!this.eventSubfields:
            return 'Add a field for Events'
          case !!this.stepSubfields:
            return 'Add a field for Steps'
          default:
            return 'Add a new field'
        }
      },
    },

    methods: {
      ...mapActions('mapping', ['addField', 'moveToField']),

      dragStartField(event, fieldIndex) {
        this.draggedField = { event, fieldIndex }

        event.target.classList.add('mapping-field--ghost')
        setTimeout(
          (function () {
            return function () {
              //Set style here
              event.target.classList.remove('mapping-field--ghost')
            }
          })(),
          1,
        )
      },

      dragOverField(event, fieldIndex) {
        if (!this.draggedField) return

        const isDraggedElement = fieldIndex === this.draggedField.fieldIndex
        const fieldParent = event.target

        if (isDraggedElement) return

        if (
          fieldParent.classList.contains('mapping-field') &&
          !fieldParent.classList.contains('mapping-field--dragover')
        ) {
          fieldParent.classList.add('mapping-field--dragover')
        }
      },

      dragLeaveField(event, fieldIndex) {
        if (!this.draggedField) return

        const isDraggedElement = fieldIndex === this.draggedField.fieldIndex
        const fieldParent = event.target

        if (
          isDraggedElement &&
          fieldParent.classList.contains('mapping-field--dragover')
        ) {
          fieldParent.classList.remove('mapping-field--dragover')
        }
      },

      dropField(event, fieldIndex) {
        if (!this.draggedField) return

        const fieldParent = event.target
        const fromIndex = this.draggedField.fieldIndex

        this.moveToField({
          fromIndex,
          fieldIndex,
          ...this.fieldLocation,
        })
        if (!this.hiddenFieldsSeparatorIndexs.has(fieldIndex))
          this.hiddenFieldsSeparatorIndexs.delete(fromIndex)
        this.hiddenFieldsSeparatorIndexs.add(fieldIndex)

        fieldParent.classList.remove('mapping-field--dragover')
        this.draggedField = null
        this.lastUpdated = Date.now()
      },

      showAddFieldButton(fieldIndex) {
        return (
          fieldIndex > 0 &&
          this.getFieldType(this.fieldLocation, fieldIndex) === 'separator'
        )
      },

      onAddField(field, fieldIndex = null) {
        this.addField({
          ...this.fieldLocation,
          field,
          fieldIndex,
          ...(this.parentFieldsKey !== 'fields' && {
            parentFieldsKey: this.parentFieldsKey,
          }),
        })
        this.lastUpdated = Date.now()
      },

      onToogleSeparator(fieldIndex) {
        if (this.hiddenFieldsSeparatorIndexs.has(fieldIndex)) {
          this.hiddenFieldsSeparatorIndexs.delete(fieldIndex)
        } else {
          this.hiddenFieldsSeparatorIndexs.add(fieldIndex)
        }
        this.hiddenFieldsSeparatorIndexs = new Set(
          this.hiddenFieldsSeparatorIndexs,
        )
      },
    },
  }
</script>
