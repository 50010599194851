<template>
  <div>
    <TitleContainer :title="title">
      <ProductAPIConfigurationSelectEnvironment show-all-envs />
    </TitleContainer>
    <ProductAPIConfigurationSelection
      class="ml-1"
      :list-empty="isHierarchyEmpty"
      :loading="loading"
      @selectedConfigChanged="onSelectedConfigChanged"
      @addCustomer="onAddCustomer"
    />
    <CollapsableContainersTileList
      class="ml-1 mt-3"
      :items="formatedProductSettings"
      :loading="loading"
      :border="false"
    />
  </div>
</template>

<script>
  import { mapGetters, mapState, mapActions } from 'vuex'
  import { RESOURCES } from '@/service/ResourceService'
  import { compareValues } from '@/service/UtilsService'

  import ProductAPIConfigurationSelection from '@/components/product-api-config/ProductAPIConfigurationSelection.vue'
  import TitleContainer from '@/components/containers/TitleContainer.vue'
  import CollapsableContainersTileList from '@/components/list/CollapsableContainersTileList.vue'
  import ProductAPIConfigurationSelectEnvironment from '@/components/product-api-config/ProductAPIConfigurationSelectEnvironment.vue'

  export default {
    name: 'ProductAPIConfiguration',

    components: {
      TitleContainer,
      ProductAPIConfigurationSelection,
      CollapsableContainersTileList,
      ProductAPIConfigurationSelectEnvironment,
    },

    props: {
      environment: {
        type: String,
        default: 'staging',
      },
    },

    data() {
      return {
        customer: null,
        country: null,
        loading: false,
      }
    },

    computed: {
      ...mapState({
        hierarchyStaging: (state) => state.productApiSettings.hierarchyStaging,
        hierarchyProduction: (state) =>
          state.productApiSettings.hierarchyProduction,
      }),

      ...mapGetters(['getRootStateValueById']),

      hierarchy() {
        return this.environment === 'production'
          ? this.hierarchyProduction
          : this.hierarchyStaging
      },

      title() {
        return this.$route.meta?.displayName ?? 'ProductAPI Configuration'
      },

      isHierarchyEmpty() {
        return !(this.hierarchy?.length > 0)
      },

      formatedProductSettings() {
        return this.hierarchy
          ?.reduce((acc, customerSetting) => {
            if (
              ![null, undefined].includes(this.customer) &&
              customerSetting.customerId !== this.customer.id
            )
              return acc

            const subitems = customerSetting.countriesIds
              ?.reduce((accumulator, countryId) => {
                if (
                  ![null, undefined].includes(this.country) &&
                  countryId !== this.country.id
                )
                  return accumulator
                const country = this.getRootStateValueById({
                  stateKey: 'countries',
                  id: countryId,
                })
                return [
                  ...accumulator,
                  {
                    id: countryId,
                    label: country?.displayName ?? countryId,
                    subItemDestination: `/productapi-configurations/${this.environment}/add/${customerSetting.customerId}/${countryId}`,
                  },
                ]
              }, [])
              .sort(compareValues('label'))

            const customer = this.getRootStateValueById({
              stateKey: 'customers',
              id: customerSetting.customerId,
            })
            return [
              ...acc,
              ...(subitems.length > 0 && customer
                ? [
                    {
                      id: customerSetting.customerId,
                      label: customer?.displayName,
                      addItemDestination: `/productapi-configurations/${this.environment}/add/${customerSetting.customerId}/`,
                      subitems,
                    },
                  ]
                : []),
            ]
          }, [])
          .sort(compareValues('label'))
      },
    },

    async created() {
      await this.loadConfigurations()
    },

    async mounted() {
      await this.loadResources({
        resourceNames: [RESOURCES.COUNTRIES, RESOURCES.CUSTOMERS],
      })
    },

    methods: {
      ...mapActions('productApiSettings', ['loadProductApiParams']),

      ...mapActions(['loadResources']),

      async loadConfigurations() {
        this.loading = true
        await this.loadProductApiParams()
        this.loading = false
      },

      onSelectedConfigChanged(productAPIConfig) {
        if (!productAPIConfig) return

        this.customer = productAPIConfig.customer
        this.country = productAPIConfig.country
      },

      onAddCustomer() {
        const route = this.customer
          ? {
              path: `/productapi-configurations/${this.environment}/add/${
                this.customer.id
              }/${this.country ? this.country.id : ''}`,
            }
          : { path: `/productapi-configurations/${this.environment}/add` }
        this.$router.push(route)
      },
    },
  }
</script>
