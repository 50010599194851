import db from '@/firebase/firestore'
import Vue from 'vue'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { RootState } from '@/store'
import { getCounterCollectionName } from '../../service/FirebaseService'

type NotificationType = 'release' | 'info' | 'warning' | 'error'
export class Notification {
  message: string
  state: string
  actionLink: string
  actionLabel: string
  floating: boolean
  dismissSecs: number
  dismissible: boolean
  keepShowing: boolean
  id: number
  progress: number
  type: NotificationType
  scope?: string
  constructor(
    message: string,
    state: string,
    actionLink?: string,
    actionLabel?: string,
    floating?: boolean,
    dismissSecs?: number,
  ) {
    this.message = message
    this.state = state
    this.actionLink = actionLink
    this.actionLabel = actionLabel
    this.floating = floating
    this.dismissSecs = dismissSecs
    this.dismissible = false
    this.keepShowing = true
    this.id = undefined
    this.progress = undefined
  }
}

type NotificationState = {
  notifications: Notification[]
}

const initialState: NotificationState = {
  notifications: [],
}

const state: NotificationState = { ...initialState }

const mutations: MutationTree<NotificationState> = {
  INIT_NOTIFICATIONS(state, notifications: Notification[]) {
    state.notifications = notifications
  },

  ADD_NOTIFICATION(state, notification: Notification) {
    if (!notification.id) notification.id = state.notifications.length
    state.notifications.push(notification)
  },

  REMOVE_NOTIFICATION(state, notification: Notification) {
    const index = state.notifications.findIndex((n) => n.id === notification.id)
    state.notifications.splice(index, 1)
  },

  REMOVE_NOTIFICATION_FROM_SCOPE(state, scope: string) {
    state.notifications = state.notifications.filter((n) => n.scope !== scope)
  },

  MODIFY_NOTIFICATION(state, notification: Notification) {
    const index = state.notifications.findIndex((n) => n.id === notification.id)
    Vue.set(state.notifications, index, notification)
  },

  SET_ATTRIBUT_NOTIFCATION(
    state,
    {
      id,
      value,
      attribut,
    }: {
      id: number
      attribut: keyof Notification
      value: any
    },
  ) {
    const index = state.notifications.findIndex((n) => n.id === id)
    Vue.set(state.notifications[index], attribut, value)
  },
}

const actions: ActionTree<NotificationState, RootState> = {
  async loadNotifications({ commit }) {
    db.collection('notifications')
      .where('meta.active', '==', true)
      .onSnapshot(async (notificationsSnapshot) => {
        notificationsSnapshot.docChanges().forEach(async (change) => {
          const notification = change.doc.data()
          notification.id = change.doc.id
          switch (change.type) {
            case 'added':
              commit('ADD_NOTIFICATION', notification)
              break
            case 'removed':
              commit('REMOVE_NOTIFICATION', notification)
              break
            case 'modified':
              commit('MODIFY_NOTIFICATION', notification)
              break
          }
        })
      })
  },

  removeNotification({ commit }, notification: Notification) {
    commit('REMOVE_NOTIFICATION', notification)
  },

  listenCopyCollection(
    { commit },
    { documentPath, label }: { documentPath: string; label: string },
  ) {
    const notif = new Notification(
      label +
        ' in progress. Some pages may not be accessible until the process is complete.',
      'info',
    )
    notif.floating = false
    notif.progress = 0
    commit('ADD_NOTIFICATION', notif)
    const { id } = notif

    let waitProgress = true // wait 4s for progress to be updated
    setTimeout(() => {
      waitProgress = false
    }, 4000)

    let collectionToCopy = 0

    const unsubscribeToCopyDoc = db
      .doc(documentPath)
      .collection(getCounterCollectionName('collectionToCopy'))
      .onSnapshot((snapshot) => {
        let count = 0
        snapshot.forEach((doc) => {
          count += doc.data().count
        })
        collectionToCopy = count
      })

    let lastProgress = 0
    const unsubscribeCopiedDoc = db
      .doc(documentPath)
      .collection(getCounterCollectionName('collectionCopied'))
      .onSnapshot((snapshot) => {
        if (waitProgress) return

        let collectionCopied = 0
        snapshot.forEach((doc) => {
          collectionCopied += doc.data().count
        })

        let progress = collectionCopied / collectionToCopy

        if (lastProgress > progress) return

        lastProgress = progress

        if (progress > 0.9 && progress !== 1) progress = 0.9

        commit('SET_ATTRIBUT_NOTIFCATION', {
          id,
          attribut: 'progress',
          value: progress,
        })

        if (progress === 1) {
          commit('SET_ATTRIBUT_NOTIFCATION', {
            id,
            attribut: 'dismissible',
            value: true,
          })
          commit('SET_ATTRIBUT_NOTIFCATION', {
            id,
            attribut: 'state',
            value: 'success',
          })
          commit('SET_ATTRIBUT_NOTIFCATION', {
            id,
            attribut: 'message',
            value: label + ' finished',
          })
          unsubscribeToCopyDoc()
          unsubscribeCopiedDoc()
        }
      })
  },
}

const getters: GetterTree<NotificationState, RootState> = {
  getNotificationByType: (state) => (type: NotificationType) => {
    return state.notifications.filter((n) => n.type === type)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
