import { Scope } from '@/models'

export class User {
  readonly id: string
  readonly email: string
  readonly firstname: string
  readonly name: string
  readonly profilePic: string
  readonly role: Record<string, any>
  readonly scope: Scope
  readonly theme: string

  constructor({
    id = '',
    email = '',
    firstname = '',
    name = '',
    profilePic = '',
    role = {},
    scope = {},
    theme = '',
  } = {}) {
    this.id = id
    this.email = email
    this.firstname = firstname
    this.name = name
    this.profilePic = profilePic
    this.role = role
    this.scope = scope
    this.theme = theme
  }
}
