<template>
  <b-modal
    :id="`modal-delete-confirmation-${modalId}`"
    ref="modal"
    :visible="visible"
    :title="title || 'Deletion confirmation'"
    :ok-disabled="!inputConfirmationState"
    size="lg"
    header-bg-variant="danger"
    ok-variant="danger"
    cancel-variant="link"
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
    @change="$emit('change', $event)"
    v-on="$listeners"
  >
    <b-form
      ref="form"
      @submit="handleOk"
    >
      <slot v-if="slotOnly" />
      <div v-else>
        <p>
          <template v-if="message">{{ message }}</template>
          <template v-if="htmlmessage">
            <div v-html="htmlmessage"></div>
          </template>
          <template v-else
            >You are about to delete <strong>{{ itemId }}</strong
            >.</template
          >
        </p>
        <p>
          Please enter <strong>{{ itemId }}</strong> to confirm.
          <CopyButton
            v-if="allowCopyButton"
            :text-to-copy="itemId"
          />
        </p>
      </div>

      <b-form-group
        :state="inputConfirmationState"
        label-for="app-id"
        invalid-feedback="The value does not match."
      >
        <b-form-input
          v-model="inputConfirmation"
          type="text"
          required
        />
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
  import CopyButton from '@/components/buttons/CopyButton'

  export default {
    name: 'ConfirmationModal',

    components: {
      CopyButton,
    },

    model: {
      event: 'change',
      prop: 'visible',
    },

    props: {
      visible: Boolean,
      modalId: {
        type: String,
        default: 'default',
      },
      itemId: {
        type: String,
        required: true,
      },
      message: {
        type: String,
        default: null,
      },
      htmlmessage: {
        type: String,
        default: null,
      },
      title: {
        type: String,
        default: null,
      },
      allowCopyButton: {
        type: Boolean,
        default: true,
      },
      slotOnly: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        inputConfirmation: '',
      }
    },

    computed: {
      inputConfirmationState() {
        return this.inputConfirmation?.length > 0
          ? this.inputConfirmation === this.itemId
          : null
      },
    },

    methods: {
      checkFormValidity() {
        let valid = true

        Array.from(this.$refs.form).forEach((field) => {
          if (!field.checkValidity()) {
            valid = false
            this[field.dataset.valid] = false
          } else {
            this[field.dataset.valid] = true
          }
        })

        return valid
      },

      resetModal() {
        this.inputConfirmation = ''
      },

      async handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()

        // Check form validity
        if (!this.checkFormValidity()) return

        if (this.inputConfirmationState) {
          this.$emit('deleteConfirmed')
          this.$nextTick(() => {
            this.$bvModal.hide(`modal-delete-confirmation-${this.modalId}`)
          })
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .allow-white-spaces {
    white-space: pre-wrap;
    line-height: 1rem;
  }

  .is-invalid {
    background-color: initial;
  }
</style>
