<template>
  <BaseAdminPage
    :title="title"
    :components="components"
  />
</template>

<script>
  import BaseAdminPage from '@/components/admin/BaseAdminPage'
  import { getFirstActiveDocument } from '@/service/FirebaseService'
  import QuestionsManagement from '@/components/admin/questionnaires/QuestionsManagement'
  import AnswersManagement from '@/components/admin/questionnaires/AnswersManagement'
  import StepsManagement from '@/components/admin/questionnaires/StepsManagement'

  export default {
    name: 'QuestionnaireCollectionsAdmin',

    components: {
      BaseAdminPage,
    },

    data() {
      return {
        components: [
          {
            id: 'QuestionsManagement',
            title: 'Questions Management',
            component: QuestionsManagement,
            props: {
              activeQuestionnairePath: '',
            },
            lazy: true,
          },
          {
            id: 'AnswersManagement',
            title: 'Answers Management',
            component: AnswersManagement,
            props: {
              activeQuestionnairePath: '',
            },
            lazy: true,
          },
          {
            id: 'StepsManagement',
            title: 'Steps Management',
            component: StepsManagement,
            props: {
              activeQuestionnairePath: '',
            },
            lazy: true,
          },
        ],
      }
    },

    computed: {
      title() {
        return (
          this.$route.meta?.displayName ?? 'Questionnaire Collections Admin'
        )
      },
    },

    async created() {
      const doc = await getFirstActiveDocument('questionnaires')
      this.components.forEach((component) => {
        component.props.activeQuestionnairePath = `/questionnaires/${doc.id}`
      })
    },
  }
</script>
