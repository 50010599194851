// Files error codes [001-009].
export const FILE_ERROR_MUST_BE_JSON = '001'
export const FILE_ERROR_NOT_ALLOWED_TYPE = '002'
export const FILE_ERROR_TOO_LARGE = '003'
export const FILE_ERROR_SERVER_REJECT = '004'

export const FILES_ERRORS = [
  { code: FILE_ERROR_MUST_BE_JSON, message: 'File must be a .json' },
  {
    code: FILE_ERROR_NOT_ALLOWED_TYPE,
    message: 'The type of file is not allowed',
  },
  {
    code: FILE_ERROR_TOO_LARGE,
    message: 'The file you try to upload is too large',
  },
  {
    code: FILE_ERROR_SERVER_REJECT,
    message: 'The file you tried to upload has been rejected by the server',
  },
]

// Prescription error codes [010-019].
export const PRESCRIPTION_ERROR_NO_DEFAULT_PROFILE = '010'
export const PRESCRIPTION_ERROR_MORE_THAN_ONE_PROFILE = '011'
export const PRESCRIPTION_ERROR_UNSUPPORTED_ITEM_TYPE = '012'
export const PRESCRIPTION_ERROR_MISSING_AIRTABLEAPI = '013'
export const PRESCRIPTION_ERROR_NO_DEFAULT_VIEW = '015'
export const PRESCRIPTION_ERROR_MORE_THAN_ONE_DEFAULT_VIEW = '016'
export const PRESCRIPTION_ERROR_AIRTABLE_NOT_AUTHORIZED = '017'
export const PRESCRIPTION_ERROR_GET_PROFILES_FROM_STEPS_FAILED = '018'
export const PRESCRIPTION_ERROR_DELETE_EVENT_STEPS = '019'

export const PRESCRIPTION_ERRORS = [
  {
    code: PRESCRIPTION_ERROR_NO_DEFAULT_PROFILE,
    message: 'There is no default profile in this prescription',
  },
  {
    code: PRESCRIPTION_ERROR_MORE_THAN_ONE_PROFILE,
    message: 'There is more than one default profile in this prescription',
  },
  {
    code: PRESCRIPTION_ERROR_UNSUPPORTED_ITEM_TYPE,
    message: 'This item type is not supported',
  },
  {
    code: PRESCRIPTION_ERROR_MISSING_AIRTABLEAPI,
    message:
      'Missing airtableApi configuration in appconfigurations. Please add the correct configuration to show this screen.',
  },
  {
    code: PRESCRIPTION_ERROR_NO_DEFAULT_VIEW,
    message: 'There must be one default view in this prescription, none found.',
  },
  {
    code: PRESCRIPTION_ERROR_MORE_THAN_ONE_DEFAULT_VIEW,
    message: 'There is more than one default view in this prescription',
  },
  {
    code: PRESCRIPTION_ERROR_AIRTABLE_NOT_AUTHORIZED,
    message: 'Firecamp is not authorized to access the Airtable.',
  },
  {
    code: PRESCRIPTION_ERROR_GET_PROFILES_FROM_STEPS_FAILED,
    message: 'Retrieving profiles with items for this event or step failed',
  },
  {
    code: PRESCRIPTION_ERROR_DELETE_EVENT_STEPS,
    message: 'Something went wrong while deleting an event or step',
  },
]

// Auth error codes [020-029].
export const AUTH_ERROR_LOGOUT = '020'

export const AUTH_ERRORS = [
  {
    code: AUTH_ERROR_LOGOUT,
    message:
      'There was an error while logging you out. Redirecting to Dashboard ...',
  },
]

// Generic error codes [030-...].
export const GENERIC_ERROR_NO_COPY_FUNCTIONNALITY = '030'
export const GENERIC_ERROR_MISSING_APP_ROUTE_ID = '031'
export const GENERIC_ERROR_MISSING_PARAMETERS = '032'
export const GENERIC_ERROR_NO_DATA_MATCH = '033'

export const GENERIC_ERRORS = [
  {
    code: GENERIC_ERROR_NO_COPY_FUNCTIONNALITY,
    message: 'The copy functionnality is not available in your browser',
  },
  { code: GENERIC_ERROR_MISSING_APP_ROUTE_ID, message: 'Missing app route id' },
  { code: GENERIC_ERROR_MISSING_PARAMETERS, message: 'Missing parameter' },
  { code: GENERIC_ERROR_NO_DATA_MATCH, message: 'No data matched the query' },
]

// Mapping error codes [040-...].
export const INVALID_FIELDS = '040'

export const MAPPING_ERRORS = [
  {
    code: INVALID_FIELDS,
    message: 'Some fields are invalid. Fix them before save',
  },
]

// All the errors are exported into the same array.
export const FIRECAMP_ERRORS = [
  ...FILES_ERRORS,
  ...PRESCRIPTION_ERRORS,
  ...AUTH_ERRORS,
  ...GENERIC_ERRORS,
  ...MAPPING_ERRORS,
]
