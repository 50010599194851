import firebase from 'firebase/compat/app'
import { ItemType } from '@/constants/ItemType.enum'
import { getMetaData } from '@/service/FirebaseService'
import { DocumentData } from '@firebase/firestore'

const Url = (item: DocumentData) => {
  return {
    description: item.description ?? null,
    label: item.label,
    url: item.url,
    type: item.type,
    content: item.content,
    item: item.item,
  }
}

const Content = (item: DocumentData) => {
  return {
    description: item.description ?? null,
    label: item.label,
    content: item.content,
    type: item.type,
    item: item.item,
  }
}

const Default = (item: DocumentData) => {
  return {
    brand: item.brand,
    sku: item.sku,
    label: item.label,
    type: item.type,
    item: item.item,
  }
}

/**
 * The Item Model Type.
 */
export class Item {
  readonly brand: string
  readonly content?: string
  readonly description?: string
  readonly id: string
  readonly item?: string
  readonly label: string
  readonly sku: string
  readonly type: ItemType
  readonly url?: string
  meta: Record<string, any>
  readonly ref?: string

  /**
   * Item constructor.
   *
   * @return {Object} Return an Item object.
   */
  constructor(args: any) {
    const fields = [
      'brand',
      'content',
      'description',
      'id',
      'label',
      'sku',
      'type',
      'url',
      'item',
      'ref',
    ]
    const parameters = args
    fields.forEach((field) => {
      if (parameters[field]) {
        this[field] = parameters[field]
      }
    })

    const { meta } = parameters
    this.meta = meta && Object.keys(meta).length > 0 ? meta : getMetaData
  }
}

/**
 * The item converter for Firestore.
 */
export const itemConverter = {
  toFirestore: (item: Item) => {
    let itemData
    switch (item.type) {
      case 'url':
        itemData = Url(item)
        break
      case 'content':
        itemData = Content(item)
        break
      default:
        itemData = Default(item)
    }

    return {
      ...itemData,
      meta: {
        ...item.meta,
      },
    }
  },
  fromFirestore: (
    snapshot: firebase.firestore.DocumentSnapshot,
    options?: firebase.firestore.SnapshotOptions,
  ) => {
    const data = snapshot.data(options)
    let itemData: any
    switch (data.type) {
      case 'url':
        itemData = Url(data)
        break
      case 'content':
        itemData = Content(data)
        break
      default:
        itemData = Default(data)
        break
    }

    return new Item({
      ...itemData,
      id: snapshot.id,
      meta: data.meta,
    })
  },
}
