export type Item = {
  id?: string
  label?: string
}

export type FormattedListMenuItems = {
  category: string
  items: Item[]
}

/*
 * Takes an Array of objects and formats it for InPageSideMenu use
 * Objects in the list should have an id and a label or displayname or name (a value to display)
 */
export function formatListSameActions(
  listElements: Record<string, any>[],
): Item[] {
  return listElements?.map((element) => {
    return formatElement(element)
  })
}

/*
 * Takes an Array of objects and a String for the category name and formats them for InPageSideMenu use as a list of submenus
 * To use a list of multiple categories and submenus you should add each to an Array
 * Objects in the list should have an id and a label or displayname or name (a value to display)
 */
export function formatListMenuItems(
  menuLabel: string,
  listElements: Record<string, any>[],
): FormattedListMenuItems {
  return {
    category: menuLabel,
    items: listElements?.map((element) => {
      return formatElement(element)
    }),
  }
}

/*
 * Formats the Object of the list for InPageSideMenu use
 * If an Object have a display value not present in this method, add it
 */
function formatElement(element: Record<string, any>): Item {
  return {
    ...(element?.id && { id: element.id }),
    ...(element?.label && { label: element.label }),
    ...(element?.displayName &&
      !element?.label && { label: element.displayName }),
    ...(element?.name &&
      !element?.label &&
      !element?.displayName && { label: element.name }),
  }
}
