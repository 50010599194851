<template>
  <section class="test-sku-page">
    <b-form
      inline
      class="mb-4 align-items-end col-8 m-auto"
      @submit="submit"
    >
      <h3>{{ environmentLabel }}</h3>

      <b-form-group
        label="SKU"
        class="m-auto"
      >
        <b-form-input
          id="skuInput"
          v-model="testSku"
          placeholder="Enter SKU"
        />
        <SubmitButton
          class="ml-2 mr-2"
          type="submit"
          label="Test Query"
          :loading="isAppLoading"
          :disabled="isAppLoading || !testSku"
        />
      </b-form-group>
    </b-form>
    <div
      v-if="response"
      class="test-sku-page__response"
    >
      <ItemCard
        :item="item"
        class="item-card--large"
      />
      <h4 class="test-sku-page__response-label">Response</h4>
      <div>
        <JSONEditor
          class="test-sku__editor"
          :json-value="responseString"
          :disabled="true"
        />
      </div>
    </div>
  </section>
</template>

<script>
  import SubmitButton from '@/components/form/SubmitButton.vue'
  import {
    default as ProductService,
    getDefaultProductApiConfig,
  } from '@/service/ProductService'
  import ItemCard from '@/components/prescription/ItemCard.vue'
  import JSONEditor from '@/components/fields/genericFields/JSONEditor.vue'
  import { Product } from '@/types/Product'
  import { firstCharacterToUppercase } from '../../utils/StringHelpers'

  export default {
    components: {
      SubmitButton,
      ItemCard,
      JSONEditor,
    },

    props: {
      customerId: {
        type: String,
        default: null,
      },
      countryCode: {
        type: String,
        default: null,
      },
      environment: {
        type: String,
        default: null,
      },
    },

    data() {
      return {
        testSku: '',
        response: null,
      }
    },

    computed: {
      environmentLabel() {
        return firstCharacterToUppercase(this.environment)
      },
      responseString() {
        return JSON.stringify(this.response, null, 2)
      },
      item() {
        return new Product(
          this.response.sku,
          this.response.brandLabel,
          this.response.sku,
          this.response.name,
          this.response.packshotImageUrl,
          this.response.shortDescription,
          this.response.rating,
        )
      },
    },

    methods: {
      async submit(e) {
        e.preventDefault()
        this.appLoading(true)

        const productService = new ProductService(
          getDefaultProductApiConfig(
            this.customerId,
            this.countryCode,
            this.environment,
          ),
        )
        this.response = await productService.callProductApi(this.testSku, true)

        this.appLoading(false)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .test-sku-page {
    &_alert {
      width: 60vw;
      margin: auto;
      margin-top: 1.25rem;
      margin-bottom: 1.25rem;
    }
    &__response {
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      &-label {
        align-self: flex-start;
        margin-left: 20%;
        margin-bottom: 1rem;
      }
    }
    margin: auto;
    .item-card--large {
      width: calc(175px + 20px);
      min-width: calc(175px + 20px);
      height: 175px;
    }
  }
</style>

<style lang="scss">
  .test-sku__editor {
    .CodeMirror {
      height: 425px;
      max-width: 70vw;
      border: 2px solid var(--border-color);
    }
  }
</style>
