<template>
  <BaseManagement
    :filtered-data="filteredCollectionDatas"
    :loading="loading || isDataLoading"
    :show-flag="showFlag"
    search-field-label="Search by name"
    :name="name"
    :disallow-create="disallowCreate"
    :modal-component="modalComponent"
    :message-delete-confirmation="messageDeleteConfirmation"
    @deleteConfirmed="deleteData"
    @searchInput="setSearchInput"
    v-on="$listeners"
  >
    <template #additional-top>
      <div class="collection-management-top-actions">
        <div
          v-for="topAction in topActions"
          :key="`top-action-${topAction.name}`"
          class="top-action"
        >
          <component
            :is="topAction.component"
            v-bind="topAction.props"
            v-on="$listeners"
          />
        </div>
      </div>
    </template>
  </BaseManagement>
</template>

<script>
  import { mapState, mapMutations, mapActions } from 'vuex'
  import { deleteRef } from '@/service/FirebaseService'
  import {
    formatAssetLink,
    firstCharacterToUppercase,
  } from '@/utils/StringHelpers'
  import {
    getResourceName,
    RESOURCES,
    resourcesConfig,
  } from '@/service/ResourceService'

  import BaseManagement from '@/components/admin/BaseManagement'

  export default {
    name: 'CollectionManagement',

    components: {
      BaseManagement,
    },

    props: {
      name: {
        type: String,
        required: true,
      },
      collectionName: {
        type: String,
        default: null,
        required: false,
      },
      values: {
        type: Array,
        default: null,
        required: false,
      },
      topActions: {
        type: Array,
        default: () => [],
        required: false,
      },
      idKey: {
        type: String,
        required: false,
        default: 'id',
      },
      labelKey: {
        type: String,
        required: false,
        default: 'displayName',
      },
      detailsFunction: {
        type: Function,
        required: false,
        default: null,
      },
      editFormatFunction: {
        type: Function,
        required: false,
        default: null,
      },
      deleteFormatFunction: {
        type: Function,
        required: false,
        default: null,
      },
      modalComponent: {
        type: Object,
        required: false,
        default: null,
      },
      showFlag: Boolean,
      tagsValues: {
        type: Array,
        default: null,
      },
      itemHasCRUD: {
        type: Function,
        required: false,
        default: null,
      },
      disallowCreate: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      messageDeleteConfirmation: {
        type: String,
        default: null,
      },
      hasEdition: {
        type: Boolean,
        default: true,
      },
    },

    data() {
      return {
        loadingData: false,
        searchInput: '',
      }
    },

    computed: {
      collectionDatas() {
        return this.values ?? this.$store.state[this.collectionName]
      },
      filteredCollectionDatas() {
        const rows = this.setDatasForList(this.collectionDatas)
        if (!this.searchInput) return rows

        const lowercaseSearchInput = this.searchInput.toLowerCase()
        return rows.filter((r) => {
          const fields = [r?.label, r?.id].map((v) => `'${v}'`.toLowerCase())
          return fields.some((v) => v.includes(lowercaseSearchInput))
        })
      },
      isDataLoading() {
        const collectionsToLoad = [
          RESOURCES.CRITERIAS,
          RESOURCES.HERO_INGREDIENTS,
          RESOURCES.CRM_CODES,
          RESOURCES.FLAGS,
          RESOURCES.COMMERCIAL_FLAGS,
          RESOURCES.BRANDS,
          RESOURCES.COUNTRIES,
          RESOURCES.CUSTOMERS,
        ].map((resourceName) => resourcesConfig[resourceName].collectionName)

        if (!collectionsToLoad.includes(this.collectionName)) return false
        return !this.fetchedResources[this.collectionName]
      },
      ...mapState({
        fetchedResources: (state) => state.fetchedResources,
      }),
    },

    watch: {
      loadingData(newLoadingValue) {
        this.emitLoading(newLoadingValue)
      },

      loading: {
        handler(newLoadingValue) {
          this.emitLoading(newLoadingValue)
        },
        immediate: true,
      },
    },

    async mounted() {
      const resourceName = getResourceName(this.collectionName)
      if (resourceName) {
        await this.loadResources({ resourceNames: [resourceName] })
      }
    },

    methods: {
      ...mapActions(['loadResources']),
      ...mapMutations(['deleteRootStateValue']),
      emitLoading(loadingValue) {
        this.$emit('loading', loadingValue)
      },

      setDatasForList(datas) {
        if (!datas) return []

        return datas?.map((data) => {
          const details = this.detailsFunction ? this.detailsFunction(data) : {}

          return {
            id: data[this.idKey],
            label: data[this.labelKey] || data[this.idKey],
            details,
            ...(data.logo && { image: formatAssetLink(data.logo) }),
            tagsValues: this.tagsValues,
            ...(!this.modalComponent ||
            (this.itemHasCRUD && !this.itemHasCRUD(data))
              ? {}
              : {
                  quickActionButton: [
                    ...(this.hasEdition
                      ? [
                          {
                            label: 'Edit ' + this.name,
                            icon: 'pencil-fill',
                            variant: 'success',
                            onClick: [
                              'edit',
                              this.editFormatFunction
                                ? this.editFormatFunction(data)
                                : data,
                            ],
                          },
                        ]
                      : []),
                    {
                      label: 'Delete ' + this.name,
                      variant: 'danger',
                      icon: 'trash',
                      divider: this.hasEdition,
                      onClick: [
                        'delete',
                        this.deleteFormatFunction
                          ? this.deleteFormatFunction(data)
                          : data[this.idKey],
                      ],
                    },
                  ],
                }),
          }
        })
      },

      async deleteData(id) {
        this.loadingData = true
        try {
          if (this.collectionName) {
            await deleteRef(`/${this.collectionName}/${id}`)
            if (!this.values)
              this.deleteRootStateValue({ id, key: this.collectionName })
          } else {
            await deleteRef(id)
          }

          this.$nextTick(() => {
            this.$bvModal.hide('modal-creation')
          })

          this.$emit('deleted', this.name)

          this.setMessageInformations({
            message: `${firstCharacterToUppercase(this.name)} ${
              this.collectionName
                ? id
                : id.substring(id.lastIndexOf('/') + 1, id.length)
            } successfully deleted.`,
            state: 'success',
          })
        } catch (error) {
          this.handleErrors({ error })
        } finally {
          this.loadingData = false
        }
      },

      setSearchInput(name) {
        this.searchInput = name
      },
    },
  }
</script>
