<template>
  <b-card
    class="position-relative brand-card"
    align="center"
  >
    <b-card-body>
      <b-row
        align-h="end"
        class="close-button"
      >
        <b-button
          variant="link"
          class="pl-0 text-danger"
          @click="handleDeleteLogo"
        >
          <b-icon icon="x" />
        </b-button>
      </b-row>
      <b-row
        v-if="logoPreview"
        class="mt-2"
        align-h="center"
      >
        <b-col style="height: 90px">
          <MediaPreview
            type="image"
            :src="logoPreview"
            :alt="`logo_${logo.id}_${logo.customerId}_${logo.countryId}`"
          />
        </b-col>
      </b-row>
      <b-row
        class="mt-2"
        align-h="center"
      >
        {{ countryName }}
      </b-row>
      <b-row
        class="mt-2"
        align-h="center"
      >
        <CountryFlag
          :country="countryFlagPreview"
          rounded
          size="normal"
        />
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
  import CountryFlag from 'vue-country-flag'
  import { formatAssetLink } from '@/utils/StringHelpers'
  import MediaPreview from '../media/MediaPreview.vue'

  export default {
    components: { CountryFlag, MediaPreview },

    props: {
      logo: {
        type: Object,
        required: true,
      },
      countryName: {
        type: String,
        required: true,
      },
    },

    data() {
      return {
        logoValue: null,
        storedLogo: null,
      }
    },

    mounted() {
      this.storedLogo = this.logo?.customLogo
    },

    computed: {
      countryFlagPreview() {
        return this.logo.countryId?.match(/^(\w+)-?/)[1]
      },
      logoPreview() {
        return this.getLogoPreview(this.logoValue, this.storedLogo)
      },
    },

    methods: {
      getLogoPreview(logoValue, logo) {
        if (logoValue) {
          return URL.createObjectURL(logoValue)
        }

        if (logo !== null) {
          return formatAssetLink(logo)
        }

        return null
      },
      handleDeleteLogo() {
        this.$emit('delete-logo', this.logo)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .card-body {
    background-color: var(--primary);
    color: var(--primary-font-color);
  }

  .brand-card {
    min-height: 200px;
    & > * {
      padding: 2px 0;
    }
  }

  .close-button {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0 2px;
  }
</style>
