<template>
  <b-modal
    id="modal-copy-app-prescription"
    ref="modal"
    size="xl"
    title="Copy prescription"
    :cancel-disabled="isAppLoading"
    :ok-disabled="isAppLoading || !source.approuteId || !destination.approuteId"
    ok-title="Copy prescription"
    @hidden="resetModal"
    @ok="handleOk"
    v-on="$listeners"
  >
    <b-form
      ref="form"
      class="row"
      @submit.stop.prevent
    >
      <div
        v-for="key in initialKeys"
        :key="'container--' + key"
        class="form-container col-md-6 col-sm-12"
      >
        <h3 class="mb-3">{{ key }}</h3>
        <b-form-group
          label="Application:"
          invalid-feedback="Application is required"
          class="mb-3"
          required
        >
          <b-form-select
            v-model="$data[key].application"
            :options="getAppsWithPrescription"
            :disabled="isAppLoading || !fetchedResources.applications"
            value-field="id"
            text-field="displayName"
            required
            @change="getAppVersionsByKey(key)"
          >
            <template #first>
              <b-form-select-option
                :value="null"
                disabled
                >Select an application</b-form-select-option
              >
            </template>
          </b-form-select>
        </b-form-group>

        <b-form-group
          label="Version:"
          invalid-feedback="Version is required"
          class="mb-3"
          required
        >
          <b-form-select
            v-model="$data[key].version"
            :options="$data[key + 'Versions']"
            value-field="id"
            text-field="meta.version"
            required
            :disabled="
              !$data[key].application ||
              isAppLoading ||
              !$data[key + 'Versions']
            "
            @change="getAppRoutesByKey(key)"
          >
            <template #first>
              <b-form-select-option
                :value="null"
                disabled
                >Select a version</b-form-select-option
              >
            </template>
          </b-form-select>
        </b-form-group>

        <b-form-group
          label="Application instance: "
          invalid-feedback="An application route is required"
          class="mb-3"
          required
        >
          <b-form-select
            v-model="$data[key].approuteId"
            :options="$data[key + 'Routes']"
            value-field="id"
            text-field="id"
            required
            :disabled="
              !$data[key].version || isAppLoading || !$data[key + 'Routes']
            "
            @change="getAppRoutesByKey(initialKeys.find((k) => k !== key))"
          >
            <template #first>
              <b-form-select-option
                :value="null"
                disabled
                >Select an instance</b-form-select-option
              >
            </template>
          </b-form-select>
        </b-form-group>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
  import { mapGetters, mapActions, mapState } from 'vuex'
  import Functions from '@/firebase/functions'
  import { RESOURCES } from '@/service/ResourceService'

  const initialKeys = ['source', 'destination']
  const initialData = initialKeys.reduce((accu, key) => {
    return {
      ...accu,
      [key]: {
        application: null,
        version: null,
        approuteId: null,
      },
      [`${key}Versions`]: null,
      [`${key}Routes`]: null,
    }
  }, {})

  export default {
    name: 'CopyPrescriptionModal',

    data() {
      return {
        ...JSON.parse(JSON.stringify(initialData)),
        initialKeys,
        loadingMessage: 'Starting prescription copy ... This may take a while.',
        loadingPercentage: 0,
      }
    },

    computed: {
      ...mapState({
        fetchedResources: (state) => state.fetchedResources,
      }),

      ...mapGetters([
        'getAppVersions',
        'listRoutes',
        'getAppsWithPrescription',
      ]),
    },

    async mounted() {
      await Promise.all([
        this.loadResources({
          resourceNames: [RESOURCES.APPLICATIONS, RESOURCES.APP_VERSIONS],
        }),
        this.bindAppRoutes(),
      ])
    },

    methods: {
      ...mapActions(['loadResources', 'bindAppRoutes']),

      getAppRoutesByKey(key) {
        const { version, application } = this.$data[key]
        this.$data[`${key}Routes`] = this.listRoutes(
          application,
          version,
        ).filter((route) =>
          key === 'source'
            ? route.id !== this.destination.approuteId
            : route.id !== this.source.approuteId,
        )
      },

      getAppVersionsByKey(key) {
        const { application } = this.$data[key]
        this.$data[`${key}Versions`] = this.getAppVersions(application)
      },

      resetModal() {
        Object.keys(initialData).forEach((key) => {
          this.$data[key] = initialData[key]
        })
      },

      async handleOk(event) {
        event.preventDefault()
        this.appLoading(true)

        try {
          await Functions.copyPrescription(
            this.source.approuteId,
            this.destination.approuteId,
            'Prescription copy',
            true,
          )
        } catch (error) {
          this.handleErrors({ error })
        } finally {
          this.appLoading(false)
          this.$bvModal.hide('modal-copy-app-prescription')
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  h3 {
    text-transform: capitalize;
    color: var(--app-background-font-color);
  }
</style>
