import { FILE_ERROR_MUST_BE_JSON } from '@/constants/ErrorConstants'

export const AppManagementMixin = {
  methods: {
    checkFormValidity() {
      let valid = true

      Object.keys(this.$refs).map((form) => {
        Array.from(this.$refs[form]).forEach((field) => {
          if (!field.checkValidity()) {
            valid = false
            this.errors[field.dataset.valid] = false
          } else {
            this.errors[field.dataset.valid] = true
          }
        })
      })

      return valid
    },

    async onFileChange(e, col, section, subsection) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      const fileData = await this.readFile(files[0])

      if (col == 'firehierarchy') {
        this.$data[col] = fileData
        this.uploadedHierarchy = true
        await this.handleUploadChanges()
        await this.handleHierarchyChanges()
      } else {
        if (this.$data[col] == null) this.$data[col] = {}

        if (typeof this.$data[col][section] == 'undefined')
          this.$data[col][section] = {}

        if (subsection) {
          this.$data[col][section][subsection] = fileData
        } else {
          this.$data[col][section] = fileData
        }

        if (this.uploadedContent && !this.uploadedContent.includes(section)) {
          this.uploadedContent.push(section)
        }

        if (
          this.uploadedComponents &&
          !this.uploadedComponents.includes(subsection)
        ) {
          this.uploadedComponents.push(subsection)
        }

        if (this.handleConfigurationChanges !== undefined) {
          await this.handleConfigurationChanges()
        }

        if (this.handleUploadChanges !== undefined) {
          await this.handleUploadChanges()
        }
      }
    },

    readFile(file) {
      const reader = new FileReader()

      return new Promise((resolve, reject) => {
        reader.onload = (e) => {
          try {
            const fileData = JSON.parse(e.target.result)
            resolve(fileData)
          } catch (error) {
            reject()
            this.handleErrors({ code: FILE_ERROR_MUST_BE_JSON, error })
          }
        }

        reader.readAsText(file)
      })
    },
  },
}
