import { generateShowDataModalId } from '@/utils/ModalHelpers'

export const ModalMixin = {
  methods: {
    // Avoid always loaded Modals.
    handleModal(modalId) {
      const showModalId = generateShowDataModalId(modalId)
      this.$data[showModalId] = !this.$data[showModalId]
      this.$nextTick(() => {
        if (this.$data[showModalId]) {
          this.$bvModal.show(modalId)
        } else {
          this.$bvModal.hide(modalId)
        }
      })
    },
  },
}
