<template>
  <BaseAdminPage
    :title="title"
    :components="components"
    @created="reloadLists"
    @deleted="reloadLists"
    @filters-changed="manageFilters"
  />
</template>

<script>
  import BaseAdminPage from '@/components/admin/BaseAdminPage.vue'
  import CollectionManagement from '@/components/admin/mainCollections/CollectionManagement.vue'
  import DocumentationCreationEdition from '@/components/documentation/DocumentationCreationEdition.vue'
  import DocumentationPageCreationEdition from '@/components/documentation/DocumentationPageCreationEdition.vue'
  import FilterButton from '@/components/buttons/FilterButton.vue'
  import { mapActions, mapGetters, mapState } from 'vuex'

  export default {
    name: 'DocumentationManagement',

    components: {
      BaseAdminPage,
    },

    data() {
      return {
        activePageFilters: [],
      }
    },

    computed: {
      ...mapState({
        categories: (state) => state.documentations.categories,
      }),
      ...mapGetters('documentations', [
        'getPagesByCategories',
        'getCategoryById',
      ]),

      title() {
        return this.$route.meta?.displayName ?? 'Documentation Admin'
      },

      pagesFilters() {
        return this.categories.map((element) => {
          return { id: element?.id, label: element?.displayName ?? element?.id }
        })
      },

      components() {
        return [
          {
            id: 'DocumentationPagesManagement',
            title: 'Pages Management',
            component: CollectionManagement,
            lazy: true,
            props: {
              name: 'page',
              values: this.getPagesByCategories(this.activePageFilters),
              detailsFunction: this.pageDetailsFunction,
              deleteFormatFunction: this.pageDeleteFormatFunction,
              modalComponent: DocumentationPageCreationEdition,
              loading: this.isAppLoading,
              messageDeleteConfirmation:
                'Warning, please associate all child pages to another page before deleting this page',
              topActions: [
                {
                  id: 'DocumentationPagesFilters',
                  component: FilterButton,
                  props: {
                    name: 'page',
                    label: 'documentation',
                    filters: this.pagesFilters,
                  },
                },
              ],
            },
          },
          {
            id: 'CategoryManagement',
            title: 'Category Management',
            component: CollectionManagement,
            lazy: true,
            props: {
              name: 'documentation',
              collectionName: 'documentations',
              values: this.categories,
              detailsFunction: this.documentationDetailsFunction,
              modalComponent: DocumentationCreationEdition,
              messageDeleteConfirmation:
                'Warning delete category will delete all pages associated to this category',
              tagsValues: ['role'],
              loading: this.isAppLoading,
            },
          },
        ]
      },

      documentationsIds() {
        return this.documentations?.reduce((accumulator, documentation) => {
          return [documentation?.id, ...accumulator]
        }, [])
      },
    },

    async mounted() {
      this.appLoading(true)
      await this.loadCategories()
      await this.loadPages()
      this.appLoading(false)
    },

    methods: {
      ...mapActions('documentations', ['loadCategories', 'loadPages']),

      documentationDetailsFunction: ({ id, meta }) => ({
        id,
        role: meta.allowedRoles.map(({ id }) => ({ label: id })),
      }),

      pageDetailsFunction({ id, parent, sourceDocumentation }) {
        return {
          id,
          ...(parent && { parent: parent?.displayName ?? parent?.id }),
          ...(sourceDocumentation && {
            documentation:
              this.getCategoryById(sourceDocumentation)?.displayName,
          }),
        }
      },

      pageDeleteFormatFunction(data) {
        return `documentations/${data.sourceDocumentation}/pages/${data.id}`
      },

      async manageFilters(filterPayload) {
        switch (filterPayload?.from) {
          case 'page':
            this.activePageFilters = filterPayload?.filters
            break
        }
      },

      async reloadLists(type) {
        this.appLoading(true)
        switch (type) {
          case 'page':
            await this.loadPages()
            break
          case 'documentations':
            await this.loadCategories()
            break
        }
        this.appLoading(false)
      },
    },
  }
</script>
