<template>
  <ListCustomers />
</template>

<script>
  import ListCustomers from '@/components/customers/ListCustomers'

  export default {
    name: 'Dashboard',

    components: {
      ListCustomers,
    },
  }
</script>
