<template>
  <b-form-group :label="groupLabel">
    <Multiselect
      id="filter-button_multiselect"
      v-model="filterValues"
      placeholder="Select filters"
      :searchable="true"
      :multiple="true"
      track-by="id"
      label="label"
      :options="filters"
      :show-labels="false"
      :disabled="isAppLoading || disabled"
    />
  </b-form-group>
</template>

<script>
  import { firstCharacterToUppercase } from '@/utils/StringHelpers'
  import Multiselect from '@/components/form/Multiselect.vue'

  export default {
    name: 'FilterButton',

    components: {
      Multiselect,
    },

    props: {
      name: {
        type: String,
        required: true,
      },
      label: {
        type: String,
        default: null,
      },
      filters: {
        type: Array,
        required: true,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        filterValues: [],
      }
    },

    computed: {
      groupLabel() {
        return `${firstCharacterToUppercase(this.label)} filters`
      },

      filterValuesId() {
        return this.filterValues?.map((filter) => {
          return filter?.id
        })
      },
    },

    watch: {
      filterValues() {
        this.$emit('filters-changed', {
          from: this.name,
          filters: this.filterValuesId,
        })
      },
    },
  }
</script>
