const SEPARATOR_SETTINGS = {
  expanded: false,
  type: 'separator',
}

const SUBSEPARATOR_SETTINGS = {
  expanded: false,
  type: 'subseparator',
}

const SEPARATORS_SETTINGS = [SEPARATOR_SETTINGS, SUBSEPARATOR_SETTINGS]

const INPUT_SETTINGS = {
  collection: 'appcontent',
  hierarchy: [],
  disabled: false,
  maxChar: 30,
  required: false,
  type: 'input',
}

const NUMBER_SETTINGS = {
  collection: 'appcustomisations',
  hierarchy: ['appSettings'],
  disabled: false,
  maxValue: 20,
  minValue: 5,
  required: false,
  stepValue: 5,
  type: 'number',
}

const RANGE_SETTINGS = {
  collection: 'appcustomisations',
  hierarchy: ['appSettings'],
  disabled: false,
  maxValue: 20,
  minValue: 5,
  required: false,
  stepValue: 5,
  type: 'range',
}

const TEXTAREA_SETTINGS = {
  collection: 'appcontent',
  hierarchy: [],
  maxChar: 250,
  required: false,
  type: 'textarea',
}

const RICHTEXT_SETTINGS = {
  collection: 'appcontent',
  hierarchy: [],
  required: false,
  type: 'richText',
  allowedOptions: [],
}

const INPUTS_SETTINGS = [
  INPUT_SETTINGS,
  NUMBER_SETTINGS,
  RANGE_SETTINGS,
  TEXTAREA_SETTINGS,
  RICHTEXT_SETTINGS,
]

const IMAGE_SETTINGS = {
  collection: 'appresources',
  hierarchy: [],
  fileTypes: 'png, jpg',
  maxHeight: 1080,
  maxSize: 500,
  maxWidth: 1920,
  required: false,
  type: 'image',
}

const VIDEO_SETTINGS = {
  collection: 'appresources',
  hierarchy: [],
  fileTypes: 'mp4, mkv',
  maxSize: 10000,
  required: false,
  type: 'video',
}

const FILE_SETTINGS = {
  collection: 'appresources',
  hierarchy: [],
  fileTypes: 'zip, otf, ttf, woff, woff2',
  maxSize: 2000,
  required: false,
  type: 'file',
}

const FONT_SETTINGS = {
  collection: 'appresources',
  fileTypes: 'zip, otf, ttf, woff, woff2',
  maxSize: 2000,
  required: false,
  type: 'font',
}

const URL_SETTINGS = {
  collection: 'appcontent',
  hierarchy: [],
  required: false,
  type: 'url',
}

const MEDIA_SETTINGS = [
  IMAGE_SETTINGS,
  VIDEO_SETTINGS,
  FILE_SETTINGS,
  FONT_SETTINGS,
  URL_SETTINGS,
]

export const FIELDS_WITH_OPTIONS = ['select', 'checkbox', 'radio']

export const OPTIONS_TEMPLATE = {
  value: '',
  label: '',
}

const SELECT_SETTINGS = {
  collection: 'appcontent',
  hierarchy: [],
  required: true,
  type: 'select',
}

const COLLECTION_SETTINGS = {
  collection: 'appcontent',
  hierarchy: [],
  collectionType: 'questionnaires',
  maxValue: 2,
  required: false,
  sourceCollection: 'answers',
  type: 'collection',
  prefixFilter: '',
  single: false,
  fullObject: false,
}

const CHECKBOX_SETTINGS = {
  collection: 'appcustomisations',
  hierarchy: ['appSettings'],
  required: false,
  type: 'checkbox',
  minChecked: 0,
}

const RADIO_SETTINGS = {
  collection: 'appcustomisations',
  hierarchy: ['appSettings'],
  required: false,
  type: 'radio',
}

const SELECTION_SETTINGS = [
  SELECT_SETTINGS,
  COLLECTION_SETTINGS,
  CHECKBOX_SETTINGS,
  RADIO_SETTINGS,
]

const CSS_SETTINGS = {
  type: 'css',
  required: false,
  collection: 'appcustomisations',
  hierarchy: ['appSettings'],
}

const PIXEL_SETTINGS = {
  baseRem: 12,
  collection: 'appcustomisations',
  hierarchy: ['css'],
  maxValue: 20,
  minValue: 5,
  rem: true,
  required: false,
  type: 'pixel',
}

const STYLING_SETTINGS = [CSS_SETTINGS, PIXEL_SETTINGS]

const REPEATER_SETTINGS = {
  collection: 'appcustomisations',
  hierarchy: ['appSettings'],
  max: 3,
  min: 1,
  type: 'repeater',
}

const BASEURL_SETTINGS = {
  collection: 'appconfigurations',
  hierarchy: [],
  required: false,
  type: 'baseUrl',
}

const API_SETTINGS = {
  collection: 'appconfigurations',
  hierarchy: [],
  required: false,
  type: 'api',
}

const CODE_SETTINGS = {
  collection: 'appcustomisations',
  hierarchy: ['appSettings'],
  required: false,
  type: 'code',
}

const SWITCH_SETTINGS = {
  collection: 'appcustomisations',
  hierarchy: ['appSettings'],
  required: false,
  type: 'switch',
}

const TABLE_SETTINGS = {
  collection: 'appcustomisations',
  hierarchy: ['appSettings'],
  maxValue: 50,
  minValue: 0,
  required: false,
  tableType: 'input',
  type: 'table',
}

const EVENTS_STEPS_SETTINGS = {
  collection: 'appcustomisations',
  hierarchy: ['appSettings'],
  maxChar: 30,
  required: false,
  type: 'events',
}

const OTHERS_SETTINGS = [
  REPEATER_SETTINGS,
  BASEURL_SETTINGS,
  API_SETTINGS,
  CODE_SETTINGS,
  SWITCH_SETTINGS,
  TABLE_SETTINGS,
  EVENTS_STEPS_SETTINGS,
]

export const FIELDS_SETTINGS = [
  ...SEPARATORS_SETTINGS,
  ...INPUTS_SETTINGS,
  ...MEDIA_SETTINGS,
  ...SELECTION_SETTINGS,
  ...STYLING_SETTINGS,
  ...OTHERS_SETTINGS,
]

export const FILES_TYPES = [
  'zip',
  'otf',
  'eot',
  'ttf',
  'woff',
  'woff2',
  // Image
  'png',
  'jpg',
  'gif',
  'svg',
  // Video
  'mp4',
  'mkv',
  'webm',
]

export const RICH_TEXT_ALLOWED_OPTIONS = [
  {
    id: 'link',
    sub: ['link'],
  },

  //group textStyles includes below
  {
    id: 'textStyles',
    sub: ['bold', 'italic', 'underline', 'strike'],
  },

  //headings textStyles includes below
  {
    id: 'headings',
    sub: ['heading1', 'heading2', 'heading3'],
  },

  //lists textStyles includes below
  {
    id: 'lists',
    sub: ['bulletList', 'orderedList'],
  },
]
