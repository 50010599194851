<template>
  <b-form
    class="multistep_container"
    @submit.stop.prevent
  >
    <TitleContainer
      :key="'form-title-step--' + stepIndex"
      :title="title"
      :subtitle="stepLabel"
    />

    <nav
      v-if="stepIndex !== 0 && !isSubmitSuccess"
      class="multistep_navigation"
    >
      <ol>
        <li
          v-for="stepN in totalStepNumber"
          :key="'multistep_navigation_step--' + stepN"
          :class="stepNavigationClasses(stepN)"
          :disabled="
            stepIndex === stepN ||
            (stepN > stepIndex && isNextDisabled) ||
            isAppLoading
          "
          :title="getNavLabel(stepN - 1)"
          @click="stepIndex = stepN"
        >
          {{ stepN }}
        </li>
      </ol>
    </nav>

    <transition
      name="fade"
      mode="out-in"
    >
      <b-container
        :key="'form-step--' + stepIndex"
        :class="stepsClasses"
      >
        <b-overlay
          v-if="!isSubmitSuccess"
          :show="isAppLoading"
          :bg-color="overlayBackgroundColor"
          rounded
          class="multistep_loading"
        >
          <slot name="steps" />
          <slot />

          <template v-if="steps">
            <div
              v-for="(stepN, index) in steps"
              :id="`step-${index + 1}`"
              :key="`${stepN.id}-${index + 1}`"
            >
              <component
                :is="stepN.component"
                v-if="stepN.component && stepIndex === index + 1"
                v-model="formData"
                v-bind="stepN.props"
                v-on="$listeners"
              />
            </div>
          </template>
          <template #overlay>
            <FirecampLoader :title="loadingMessage" />
          </template>
        </b-overlay>
        <div
          v-else
          class="multistep_result"
        >
          <slot name="success-message">
            <div class="multistep_result_message">
              <b-icon
                icon="check-circle-fill"
                class="mr-3"
              />
              {{ successMessage }}
            </div>
          </slot>

          <slot name="success" />
        </div>

        <div class="multistep_buttons">
          <b-button
            v-if="
              stepIndex > 1 && stepIndex <= totalStepNumber && !isSubmitSuccess
            "
            id="multistepBackButton"
            variant="link"
            class="mb-3 mr-4 p-0"
            :disabled="isAppLoading"
            @click="stepIndex = stepIndex - 1"
          >
            Back
          </b-button>
          <b-button
            v-if="
              stepIndex < totalStepNumber && stepIndex !== 0 && !isSubmitSuccess
            "
            id="multistepNextButton"
            variant="secondary"
            class="mb-3"
            :disabled="isNextDisabled"
            @click="stepIndex = stepIndex + 1"
          >
            <b-icon
              v-if="isAppLoading"
              icon="arrow-clockwise"
              animation="spin"
            />
            Continue
          </b-button>
          <SubmitButton
            v-if="stepIndex === totalStepNumber && !isSubmitSuccess"
            id="mutlistepSubmit"
            :label="submitLabel"
            variant="secondary"
            type="button"
            :disabled="isSubmitDisabled"
            :loading="isAppLoading"
            class="w-auto mb-3"
            @click.native="$emit('submit')"
          />
          <slot name="buttons" />
        </div>
      </b-container>
    </transition>
  </b-form>
</template>

<script>
  import TitleContainer from '@/components/containers/TitleContainer'
  import SubmitButton from '@/components/form/SubmitButton'
  import FirecampLoader from '@/components/loader/FirecampLoader'
  import { getMainColorCSSVariable } from '@/utils/ColorHelper'

  export default {
    name: 'MultistepForm',

    components: {
      TitleContainer,
      SubmitButton,
      FirecampLoader,
    },

    props: {
      title: {
        type: String,
        default: null,
      },
      totalStepNumber: {
        type: Number,
        default: 0,
      },
      isNextDisabled: {
        type: Boolean,
        default: true,
      },
      isSubmitSuccess: {
        type: Boolean,
        default: false,
      },
      successMessage: {
        type: String,
        default: 'Success!',
      },
      steps: {
        type: Array,
        default: () => [],
      },
      stepsLabels: {
        type: Array,
        default: () => [],
      },
      submitLabel: {
        type: String,
        default: 'Create',
      },
      loadingMessage: {
        type: String,
        default: null,
      },
      step: {
        type: Number,
        default: 1,
      },
    },

    data() {
      return {
        formData: null,
      }
    },

    computed: {
      stepIndex: {
        get() {
          return this.step
        },
        set(value) {
          this.$emit('update:step', value)
        },
      },

      isSubmitDisabled() {
        return this.stepIndex !== this.totalStepNumber || this.isNextDisabled
      },

      stepLabel() {
        return !this.isSubmitSuccess
          ? `Step ${this.stepIndex} of ${this.totalStepNumber}: ${
              this.steps[this.stepIndex - 1]?.label
            }`
          : 'Success!'
      },

      stepsClasses() {
        const size = this.steps?.[this.stepIndex - 1]?.size ?? 'small'

        return ['multistep_steps', `multistep_steps--${size}`].join(' ')
      },

      overlayBackgroundColor() {
        return getMainColorCSSVariable('--app-background-color')
      },
    },

    watch: {
      formData(newData) {
        this.$emit('input', newData)
      },

      stepIndex(newStepIndex) {
        this.$emit('stepChange', newStepIndex)
      },
    },

    created() {
      if (this.$attrs.value) {
        this.formData = this.$attrs.value
      }
    },

    methods: {
      stepNavigationClasses(step) {
        return [
          'multistep_navigation_step',
          ...(step === this.stepIndex
            ? ['multistep_navigation_step--active']
            : []),
        ].join(' ')
      },

      getNavLabel(index) {
        return this.steps?.length > 0
          ? this.steps[index]?.label
          : this.stepsLabels?.[index]
      },
    },
  }
</script>

<style lang="scss" scoped>
  .multistep_container {
    padding-bottom: 80px;
  }

  .multistep_steps {
    position: relative;
    margin: 0 auto;
    padding: 0 15px;

    &--small {
      max-width: 550px;
    }

    &--medium {
      max-width: 1200px;
    }

    &--large {
      max-width: 100%;
    }
  }

  .multistep_buttons {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 3rem auto 0;
  }

  .multistep_navigation {
    display: flex;
    flex-wrap: wrap;
    position: sticky;
    top: 0px;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
    background: var(--app-background-color);
    z-index: 3;

    ol {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      counter-reset: stepCounter;
      list-style-type: none;
      margin: 0;
      padding: 0;
      user-select: none;
    }

    .multistep_navigation_step {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      position: relative;
      counter-increment: stepCounter;
      margin-right: 60px;
      width: 45px;
      height: 45px;
      align-self: center;
      align-content: center;
      justify-content: center;
      background: var(--primary);
      border: 1px solid var(--primary);
      color: var(--white);
      font-size: 1.2rem;
      border-radius: 50%;
      transition: all 0.2s ease-in-out;
      cursor: pointer;

      &[disabled] {
        cursor: default;
        pointer-events: none;
      }

      &:before {
        content: '';
        width: calc(100% + 20px);
        height: 2px;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 100%;
        margin: auto;
        border: 1px solid var(--primary);
        opacity: 1;
        z-index: -1;
        transition: all 0.2s ease-in-out;
      }

      &:first-child {
        &:before {
          display: none;
        }
      }

      &--active {
        background: var(--secondary);
        border-color: var(--secondary);
      }

      &--active ~ * {
        background: var(--white);
        color: var(--primary);

        &:before {
          border: 1px dashed var(--primary);
          opacity: 0.7;
        }
      }

      &:active,
      &:hover,
      &:focus {
        opacity: 1;
        background: var(--primary);
        border-color: var(--primary);
        color: var(--white);
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .multistep_result {
    margin: 3rem 0;
  }

  .multistep_result_message {
    color: var(--success);
    font-size: 1.7rem;
    text-align: center;
  }

  .fire-loader_container {
    transform: scale(0.3);
  }
</style>
