<template>
  <BaseManagement
    :filtered-data="filteredApplications"
    :loading="loading"
    search-field-label="Search by name"
    name="application"
    :modal-component="modalComponent"
    @created="init"
    @deleteConfirmed="deleteApplication"
    @searchInput="searchByName"
  />
</template>

<script>
  import { mapMutations } from 'vuex'
  import { deleteRef } from '@/service/FirebaseService'
  import { getDocumentsInCollection } from '@/service/FirebaseService'

  import BaseManagement from '@/components/admin/BaseManagement'
  import CreateAppModal from '@/components/modals/CreateAppModal'

  export default {
    name: 'ApplicationsManagement',

    components: {
      BaseManagement,
    },

    data() {
      return {
        loading: false,
        applications: [],
        filteredApplications: [],
        modalComponent: CreateAppModal,
      }
    },

    async created() {
      await this.init()
    },

    methods: {
      ...mapMutations(['setState', 'deleteRootStateValue']),

      async init() {
        this.loading = true
        this.applications = await getDocumentsInCollection('applications')
        this.setState({ key: 'applications', value: this.applications })
        this.filteredApplications = this.setApplicationsForList(
          this.applications,
        )
        this.loading = false
      },

      setApplicationsForList(applications) {
        return applications?.map((application) => {
          const { id, displayName, description, meta } = application

          return {
            id,
            label: displayName ?? id,
            details: {
              ...(description && { description }),
              ...(meta?.active !== null &&
              meta?.active !== undefined &&
              meta?.active
                ? { active: 'Active' }
                : { disabled: 'Not active' }),
            },
            quickActionButton: [
              {
                label: 'Edit application',
                icon: 'pencil-fill',
                variant: 'success',
                onClick: ['edit', application],
              },
              {
                label: 'Delete application',
                variant: 'danger',
                icon: 'trash',
                divider: true,
                onClick: ['delete', id],
              },
            ],
          }
        })
      },

      async deleteApplication(id) {
        this.loading = true
        try {
          await deleteRef(`/applications/${id}`)
          this.deleteRootStateValue({ id, key: 'applications' })

          this.$nextTick(() => {
            this.$bvModal.hide('modal-creation')
          })

          this.setMessageInformations({
            message: `Application ${id} successfully deleted.`,
            state: 'success',
          })
        } catch (error) {
          this.handleErrors({ error })
        } finally {
          await this.init()
          this.loading = false
        }
      },

      searchByName(name) {
        const searchInput = name.toLowerCase()
        const applications = this.setApplicationsForList(this.applications)
        this.filteredApplications =
          name?.length > 0
            ? applications.filter(
                (application) =>
                  application?.label?.toLowerCase().includes(searchInput) ||
                  application?.id?.toLowerCase().includes(searchInput),
              )
            : applications
      },
    },
  }
</script>
