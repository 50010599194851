import Functions from '@/firebase/functions'
import { database, auditLog } from '@/service/FirebaseService'

const SUB_COLLECTIONS = [
  'appconfigurations',
  'appcontent',
  'appcustomisations',
  'appresources',
]

type DuplicateRouteData = {
  app: string
  country: string
  customer: string
  env: string
  tenant: string
  touchpoint: string
  type: string
  version: string
}

export async function duplicateRoute(
  routeDocData: DuplicateRouteData,
  sourceRouteId: string,
  hasPrescription: boolean,
): Promise<string> {
  try {
    // Create a copy of the route data
    const routeToCreateData = {
      ...routeDocData,
      collections: {},
    }

    // Copy the collections from the source route
    await Promise.all(
      SUB_COLLECTIONS.map(async (collection) => {
        const querySnapshot = await database
          .collection('approutes')
          .doc(sourceRouteId)
          .collection(collection)
          .where('meta.active', '==', true)
          .limit(1)
          .get()
        const data = querySnapshot?.docs[0]?.data()

        routeToCreateData.collections[collection] = {
          ...(data && { ...data }),
        }
      }),
    )

    // Create the new route
    const newRouteId = await Functions.createRoute(routeToCreateData)

    const promises = []
    // Copy the assets
    // Copy asset function is not working locally.
    if (process.env.VUE_APP_ENV !== 'local') {
      promises.push(Functions.copyAssets(sourceRouteId, newRouteId))
    }

    // Copy the prescription from source route
    if (hasPrescription) {
      promises.push(
        Functions.copyPrescription(
          sourceRouteId,
          newRouteId,
          'Instance duplication',
        ),
      )
    }

    await Promise.all(promises)

    await auditLog.create(`approutes/${newRouteId}`)

    return newRouteId
  } catch (error) {
    console.error(error)
    throw new Error(error?.message ?? error)
  }
}
