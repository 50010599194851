<template>
  <b-button
    variant="link"
    class="mapping-field_add"
    @click.prevent="createFieldEvent()"
  >
    <span class="mapping-field_add-text">
      <b-icon
        icon="plus"
        class="mr-1"
      />
      {{ label }}
    </span>
  </b-button>
</template>

<script>
  import { getFieldMapping } from '@/factories/FieldMappingFactory'

  export default {
    name: 'MappingAddFieldButton',

    props: {
      label: {
        type: String,
        default: 'Add a new field',
      },
    },

    methods: {
      createFieldEvent() {
        const field = getFieldMapping()
        this.$emit('create-field', field)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .mapping-field_add {
    margin-top: 0;
    opacity: 0.5;
    transition: all 0.2s ease-in-out;

    &:focus,
    &:active,
    &:hover {
      outline: none;
      text-decoration: none;
      box-shadow: none;
      opacity: 1;
    }

    &:focus,
    &:active {
      border: 1px dashed var(--app-background-font-color);
    }
  }
</style>
