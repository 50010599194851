<template>
  <div class="sort-alpha-buttons_containers">
    <button
      v-if="!descOnly"
      class="mr-1"
      :class="sortActionAlphaAscClass"
      @click="setSortAlpha('alphaAsc')"
    >
      <b-icon
        icon="sort-alpha-down"
        scale="1.4"
      />
    </button>
    <button
      :class="sortActionAlphaDescClass"
      @click="setSortAlpha('alphaDesc')"
    >
      <b-icon
        icon="sort-alpha-down-alt"
        scale="1.4"
      />
    </button>
  </div>
</template>

<script>
  export default {
    name: 'SortAlphaButtons',

    props: {
      initialValue: {
        type: String,
        default: null,
      },
      descOnly: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        sortMode: null,
      }
    },

    computed: {
      sortActionAlphaDescClass() {
        return {
          'sort-alpha-button': true,
          'sort-alpha-button--selected': this.sortMode === 'alphaDesc',
        }
      },

      sortActionAlphaAscClass() {
        return {
          'sort-alpha-button': true,
          'sort-alpha-button--selected': this.sortMode === 'alphaAsc',
        }
      },
    },

    created() {
      this.sortMode = this.initialValue
    },

    methods: {
      setSortAlpha(type) {
        this.sortMode = this.sortMode === type ? null : type
        this.$emit('sortAlphaBy', this.sortMode)
      },
    },
  }
</script>

<style lang="scss">
  .sort-alpha-buttons_containers {
    display: flex;
  }

  .sort-alpha-button {
    border-radius: 15%;
    border: none;
    background-color: transparent;
    color: var(--primary-font-color);
    transition: background-color 0.2s ease-in-out;
    height: 30px;
    width: 30px;

    &--selected {
      background-color: rgba($color: #ffffff, $alpha: 0.1);
    }
  }
</style>
