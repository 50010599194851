<template>
  <div
    :class="containerClasses"
    v-on="$listeners"
  >
    <div
      class="collapse_header"
      @click="onInput(!isVisible)"
    >
      <div
        v-if="title && !hasSlotTitle"
        class="collapse_header_title"
        :class="{ 'collapse_header--bigger': biggerFont }"
      >
        {{ title }}
      </div>
      <template v-if="hasSlotTitle">
        <slot name="title" />
      </template>
      <div class="collapse_header_actions_container">
        <slot name="left-close-button" />
        <button
          class="collapse_header_button"
          size="sm"
        >
          <b-icon
            :icon="isVisible ? 'chevron-up' : 'chevron-down'"
            :scale="biggerFont ? '1.5' : '1'"
          />
        </button>
      </div>
    </div>

    <b-collapse
      :visible="isVisible"
      class="collapse_content"
      @input="onInput"
    >
      <slot />
    </b-collapse>
  </div>
</template>

<script>
  export default {
    name: 'CollapseContainer',

    props: {
      title: {
        type: String,
        default: null,
      },
      variant: {
        type: String,
        default: 'regular',
        validator(value) {
          return [
            'primary',
            'secondary',
            'tertiary',
            'regular',
            'success',
            'danger',
            'warning',
            'info',
          ].includes(value)
        },
      },
      border: {
        type: Boolean,
        default: false,
      },
      open: {
        type: Boolean,
        default: true,
      },
      biggerFont: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        isVisible: this.open,
      }
    },

    computed: {
      containerClasses() {
        return {
          collapse_container: true,
          [`collapse_container--${this.variant}`]: true,
          'collapse_container--border': this.border,
        }
      },

      hasSlotTitle() {
        return !!this.$slots.title
      },
    },

    watch: {
      open(value) {
        this.isVisible = value
      },
    },
    methods: {
      onInput(e) {
        this.isVisible = e
        this.$emit('update:open', e)
      },
    },
  }
</script>

<style lang="scss" scoped>
  @mixin header-variant($variant) {
    &--#{$variant} {
      border-color: var(--#{$variant});

      .collapse_header {
        color: var(--#{$variant}-font-color);
        background: var(--#{$variant});
        user-select: none;
      }

      .collapse_header_button {
        color: var(--#{$variant}-font-color);
      }
    }
  }

  .collapse_container {
    margin-bottom: 10px;

    &--regular {
      border-top: 1px solid var(--border-color);
    }

    &--border {
      border-left: 1px solid var(--border-color);
      border-right: 1px solid var(--border-color);
      border-bottom: 1px solid var(--border-color);
    }

    @include header-variant(primary);
    @include header-variant(secondary);
    @include header-variant(tertiary);
    @include header-variant(success);
    @include header-variant(warning);
    @include header-variant(danger);
    @include header-variant(info);
  }

  .collapse_header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 5px 10px;
    cursor: pointer;
    background: var(--app-background-color);
    color: var(--app-background-font-color);

    &--bigger {
      font-size: x-large;
    }
  }

  .collapse_header_actions_container {
    margin-left: auto;
    display: flex;
    gap: 1rem;
  }

  .collapse_header_button {
    margin-left: auto;
    background: transparent;
    border: none;
  }
</style>
