import { Look } from '@/types/Look'
import ApiService from '@/service/ApiService'

type ApiLookSimpleResponse = {
  lookId: string
  name: string
  thumbnail?: string
}

type ApiLookResponse = {
  lookId: string
  name: Record<string, string>
  thumbnail?: string
}

/**
 * The Look Service.
 */
class LookService extends ApiService {
  private readonly language: string
  private readonly url: string
  private readonly method: string
  private readonly config: Record<string, any>

  constructor(lookApiInfo) {
    super()
    this.language = lookApiInfo?.params?.language
    this.url = lookApiInfo?.url
    this.method = lookApiInfo?.method
    this.config = {
      params: lookApiInfo.params,
    }
  }

  /**
   * Get the lists of looks available for the brand.
   *
   * @async
   * @return {Promise<Array>} - An array of formatted looks.
   */
  async getLooksSimple(): Promise<Look[]> {
    let looksFormated: Look[] = []
    const looks = await super.buildFetchRequest<{
      data: ApiLookSimpleResponse[]
    }>(this.method, this.url + 'getLooksSimple', null, this.config?.params)
    looksFormated = looks?.data?.map((look) => {
      return new Look({
        sku: look.lookId.toString(),
        label: look.name,
        image: look.thumbnail ?? null,
      })
    })
    return looksFormated
  }

  /**
   * Get the list of looks available for the brand.
   * More detailled request that getLooksSimple.
   *
   * @async
   * @return {Promise<Array>} - An array of formatted looks.
   */
  async getLooks(): Promise<Look[]> {
    let looksFormated: Look[] = []
    const looks = await super.buildFetchRequest<{
      data: { looks: ApiLookResponse[] }
    }>(this.method, this.url + 'getLooks', null, this.config?.params)
    looksFormated = looks?.data?.looks?.map((look) => {
      return new Look({
        sku: look.lookId.toString(),
        label: look.name[this.language],
        image: look.thumbnail ?? null,
      })
    })
    return looksFormated
  }
}

export default LookService
