<template>
  <div class="collapse-list_container">
    <CollapseContainer
      v-for="item in items"
      :key="`collapse-container_${item.label}`"
      class="list-collapse-container"
      :title="item.label"
      variant="tertiary"
      :open="true"
      :border="border"
      bigger-font
    >
      <div class="subitems_container row">
        <BoardCard
          v-for="subitem in item.subitems"
          :key="`${item.label}-subitem-${subitem.label}`"
          class="container-subitem_card"
          :destination="subitem.subItemDestination"
        >
          <template #card-content>
            <CountryFlag
              v-if="isCountryValid(subitem.id)"
              class="mb-1"
              :country="subitem.id.match(/^(\w+)-?/)[1]"
              rounded
              size="big"
            />
            <b-icon
              v-else
              class="mb-1"
              icon="globe"
              font-scale="2"
            />
            <span> {{ subitem.label }} </span>
          </template>
        </BoardCard>
        <BoardCard
          class="container-subitem_card"
          :destination="item.addItemDestination"
          square-card
        >
          <template #card-content>
            <b-icon
              class="add-subitem_card"
              icon="plus"
              scale="3"
            />
          </template>
        </BoardCard>
      </div>
    </CollapseContainer>
  </div>
</template>

<script>
  import CountryFlag from 'vue-country-flag'
  import CollapseContainer from '@/components/containers/CollapseContainer.vue'
  import BoardCard from '@/components/board/BoardCard.vue'

  const EXCLUDED_FLAGS = ['dmi', 'int']

  export default {
    name: 'CollapsableContainersTileList',

    components: {
      CollapseContainer,
      CountryFlag,
      BoardCard,
    },

    props: {
      items: {
        type: Array,
        default: () => [],
      },
      border: {
        type: Boolean,
        default: true,
      },
    },

    methods: {
      isCountryValid(countryId) {
        if (!countryId) return false
        return !EXCLUDED_FLAGS.includes(countryId.match(/^(\w+)-?/)[1])
      },
    },
  }
</script>

<style lang="scss" scoped>
  .subitems_container {
    display: flex;
    width: 100%;
    margin: 10px;
  }

  .container-subitem_card {
    margin-top: 10px;
  }

  .add-subitem_card {
    margin: auto;
    color: var(--primary-font-color);
  }
</style>
