import { getCurrentInstance } from 'vue'
import VueRouter from 'vue-router'
import { Notification } from '@/store/modules/notifications.store'

type BasePluginMethods = {
  removeAppIdNotifications: (from, to) => void
  handleErrors: ({
    code,
    message,
    error,
    messageInformations,
  }: {
    code?: string
    message?: string
    error?: Error
    messageInformations?: Partial<Notification>
  }) => void
  setMessageInformations: (notification: Partial<Notification>) => void
  setLoadingInformations: (payload) => void
  appLoading: (isLoading: boolean) => void
  getAppIdFromRoute: (router: VueRouter) => string
}

export function useBasePlugin() {
  const instance = getCurrentInstance().proxy
  const methods = Object.keys(instance.$options.methods).reduce((acc, key) => {
    acc[key] = instance.$options.methods[key].bind(instance)
    return acc
  }, {})

  const beforeRouteUpdate =
    instance.$options.beforeRouteUpdate[0].bind(instance)
  const beforeRouteLeave = instance.$options.beforeRouteLeave[0].bind(instance)

  return {
    ...(methods as BasePluginMethods),
    beforeRouteUpdate,
    beforeRouteLeave,
  }
}
