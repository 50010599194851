<template>
  <form>
    <div class="d-flex justify-content-between mb-3">
      <input
        v-for="index in codeLength ?? 1"
        :key="`code-input-${index}`"
        :ref="`code-input-${index}`"
        class="form-control"
        :class="statusClass"
        type="text"
        maxlength="1"
        :disabled="disabled"
        @input="handleInput(index, $event)"
        @paste.prevent="handlePaste"
        @keydown="handleKeyDown(index, $event)"
      />
    </div>
    <p
      v-if="canReSend"
      class="text-right text-info user-select-none cursor-pointer"
      @click="handleReSendCode"
    >
      Send me a new code
    </p>
    <p
      v-else-if="reSendEnabled"
      class="text-warning"
    >
      {{ reSendWaitingText }}
    </p>
  </form>
</template>

<script>
  export default {
    name: 'VerifyCodeInput',

    model: {
      prop: 'verifyCodeValue',
    },

    props: {
      verifyCodeValue: {
        type: String,
        default: '',
      },
      codeLength: {
        type: Number,
        default: 6,
      },
      autofocus: {
        type: Boolean,
        default: true,
      },
      status: {
        type: Boolean,
        default: null,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      reSendEnabled: {
        type: Boolean,
        default: true,
      },
      reSendDelay: {
        type: Number,
        default: 60,
      },
    },

    data() {
      return {
        refsArray: [],
        valuesArray: [],
        fullCode: '',
        reSendTimeout: 0,
        intervalId: null,
      }
    },

    computed: {
      canReSend() {
        return this.reSendEnabled && this.reSendTimeout === 0
      },

      reSendWaitingText() {
        return `You have to wait ${this.reSendTimeout}s before sending another code.`
      },

      statusClass() {
        return this.status === false
          ? 'form-control--invalid'
          : this.status
          ? 'form-control--valid'
          : ''
      },
    },

    watch: {
      reSendTimeout(newVal) {
        if (newVal === 0) clearInterval(this.intervalId)
      },
    },

    mounted() {
      this.initRefsArray()
      if (this.autofocus) this.refsArray[0]?.focus()

      if (this.verifyCodeValue) {
        this.fillInput(this.verifyCodeValue)
      }
    },

    methods: {
      initRefsArray() {
        this.refsArray = []
        for (let i = 1; i <= this.codeLength; i++) {
          this.refsArray.push(this.$refs[`code-input-${i}`][0])
        }
      },

      computeFullCode() {
        this.fullCode = this.valuesArray.join('')
        this.$emit('input', this.fullCode)
      },

      handleInput(index, event) {
        const input = event.target
        this.valuesArray[index - 1] = input.value

        const nextInput = input.nextElementSibling

        if (nextInput && input.value) {
          nextInput.focus()
          if (nextInput.value) {
            nextInput.select()
          }
        }
        this.computeFullCode()
      },

      handlePaste(event) {
        const paste = event.clipboardData.getData('text')
        this.fillInput(paste)
      },

      fillInput(code) {
        this.refsArray.forEach((input, i) => {
          const val = code[i] || ''
          input.value = val
          this.valuesArray[i] = val
        })
        this.refsArray[this.codeLength - 1]?.focus()
        this.computeFullCode()
      },

      handleBackspace(index, event) {
        const input = event.target
        if (input.value) {
          input.value = ''
          this.valuesArray[index - 1] = ''
          return
        }
        this.computeFullCode()

        if (input.previousElementSibling) input.previousElementSibling.focus()
      },

      handleKeyDown(index, event) {
        switch (event.keyCode) {
          case 8:
            this.handleBackspace(index, event)
            break
          case 13:
            index === this.codeLength && this.$emit('validate')
            break
          default:
            break
        }
      },

      handleReSendCode() {
        if (this.reSendTimeout > 0) return
        this.$emit('reSendCode')
        this.reSendTimeout = this.reSendDelay
        this.resetValues()
        this.intervalId = setInterval(() => {
          this.reSendTimeout -= 1
        }, 1000)
      },

      resetValues() {
        this.valuesArray = []
        this.fillInput('')
        setTimeout(() => {
          this.refsArray[0]?.focus()
        }, 500)
      },
    },
  }
</script>

<style lang="scss" scoped>
  form {
    .form-control {
      display: block;
      height: 50px;
      margin-right: 5px;
      max-width: 50px;
      text-align: center;
      font-size: 25px;
      font-weight: 600;

      &--valid {
        border-color: var(--success);
      }

      &--invalid {
        border-color: var(--danger);
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
</style>
