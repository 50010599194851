
  import keyBy from 'lodash/fp/keyBy'
  import difference from 'lodash/fp/difference'
  import { RESOURCES } from '@/service/ResourceService'
  import { mapActions, mapState } from 'vuex'
  import TitleContainer from '../containers/TitleContainer.vue'
  import Multiselect from '../form/Multiselect.vue'
  import BrandList from './BrandList.vue'
  import groupBy from 'lodash/fp/groupBy'
  import AddBrandLogo from '@/components/brands/AddBrandLogo.vue'
  import Functions from '@/firebase/functions'
  import ConfirmationModal from '@/components/modals/ConfirmationModal.vue'

  const reduceDisplayName = (
    elements: { id: string; displayName: string }[],
  ): Record<string, string> => {
    if (!elements) return {}

    return elements.reduce((indexed, { id, displayName }) => {
      indexed[id] = displayName
      return indexed
    }, {})
  }

  export default {
    components: {
      TitleContainer,
      Multiselect,
      BrandList,
      AddBrandLogo,
      ConfirmationModal,
    },
    name: 'BrandLogosPage',

    data() {
      return {
        dataLoaded: false,
        selectedCountryValue: null,
        selectedCustomerValue: null,
        customerIdModal: null,
        countryIdModal: null,
        reduced: true,
        unableToAddLogoText:
          'All the logos associated to your customers and countries are uploaded',
        showAddBrandLogoModal: false,
        showDeleteBrandLogoModal: false,
        brandsDetails: [],
        logoToDelete: {
          countryId: null,
          customerId: null,
        },
      }
    },

    computed: {
      ...mapState({
        fetchedResources: (state: { fetchedResources: any }) =>
          state.fetchedResources,
        customers: (state: { customers: any }) => state.customers,
        countries: (state: { countries: any }) => state.countries,
        brands: (state: { brands: any }) => state.brands,
      }),

      brandId() {
        return this.$route.params.brandId
      },

      brandTitle() {
        return (
          this.brands?.find((brand: { id: any }) => brand?.id === this.brandId)
            ?.displayName || ''
        )
      },

      logoId() {
        return `${this.logoToDelete.customerId}_${this.logoToDelete.countryId}_customLogo`
      },

      canAddLogo() {
        if (!this.dataLoaded) {
          return false
        }
        if (this.selectedCustomerValue && this.selectedCountryValue) {
          return (
            this.remainingLogosByCustomerId[
              this.selectedCustomerValue.id
            ]?.includes(this.selectedCountryValue.id) || false
          )
        } else if (this.selectedCustomerValue) {
          return !!this.remainingLogosByCustomerId[
            this.selectedCustomerValue.id
          ]
        } else if (this.selectedCountryValue) {
          return !!this.remainingLogosByCountryId[this.selectedCountryValue.id]
        }
        return Object.values(this.remainingLogosByCustomerId).some(
          (missingCountriesLogo: Array<string>) =>
            missingCountriesLogo.length !== 0,
        )
      },

      filteredBrandByCustomerId() {
        if (!this.dataLoaded) {
          return {}
        }
        return groupBy('customerId')(this.filteredBrandDetails)
      },

      filteredBrandByCountryId() {
        if (!this.dataLoaded) {
          return {}
        }
        return groupBy('countryId')(this.filteredBrandDetails)
      },

      noFilteredResultText() {
        const conditionalText = `${
          this.selectedCustomerValue
            ? this.selectedCustomerValue.displayName
            : ''
        }${
          this.selectedCustomerValue && this.selectedCountryValue
            ? ' and '
            : ' '
        }${
          this.selectedCountryValue ? this.selectedCountryValue.displayName : ''
        }`
        return `No match found for ${conditionalText}. Click on 'Add a logo' to associate a ${this.brandTitle} logo to ${conditionalText}.`
      },

      customerDisplayName() {
        return reduceDisplayName(this.customers)
      },

      countryDisplayName() {
        return reduceDisplayName(this.countries)
      },

      filteredBrandDetails() {
        if (!this.dataLoaded) return []

        return this.brandsDetails.filter(
          (brand: { customerId: any; countryId: any }) => {
            if (
              this.selectedCustomerValue &&
              brand.customerId !== this.selectedCustomerValue.id
            )
              return false
            if (
              this.selectedCountryValue &&
              brand.countryId !== this.selectedCountryValue.id
            )
              return false

            return true
          },
        )
      },

      remainingLogosByCustomerId() {
        return this.remainingLogosBy('customerId', this.brandsDetails)
      },

      remainingLogosByCountryId() {
        return this.remainingLogosBy('countryId', this.brandsDetails)
      },

      existingLogos() {
        return (
          this.brandsDetails?.map((detail) => {
            return `${detail.customerId}_${detail.countryId}_customLogo`
          }) ?? []
        )
      },
    },

    watch: {
      selectedCustomerValue(customerValue) {
        if (customerValue) {
          this.customerIdModal = customerValue.id
        } else {
          this.resetModal()
        }
      },

      selectedCountryValue(countryValue) {
        if (countryValue) {
          this.countryIdModal = countryValue.id
        } else {
          this.resetModal()
        }
      },
    },

    methods: {
      ...mapActions(['loadResources']),

      async loadData() {
        this.appLoading(true)
        try {
          await this.loadResources({
            resourceNames: [
              RESOURCES.CUSTOMERS,
              RESOURCES.COUNTRIES,
              RESOURCES.BRANDS,
            ],
          })
          this.brandsDetails = await Functions.getBrandLogos(this.brandId)
          this.dataLoaded = true
        } catch (error) {
          this.handleErrors(error)
        } finally {
          this.appLoading(false)
        }
      },

      expandAllCustomers() {
        this.$refs.brandList.map((list: any) => list.expand())
      },

      remainingLogosBy(
        type: 'customerId' | 'countryId',
        details: Array<object>,
      ): Record<string, number> {
        let brandDetailsByKey: Record<string, Array<object>>
        if (details.length > 0) {
          // Group brand details by the specified type ('customerId' or 'countryId')
          brandDetailsByKey = groupBy(type)(details)
        } else {
          brandDetailsByKey = this[
            type === 'customerId' ? 'customers' : 'countries'
          ].reduce((acc, item) => {
            return { ...acc, [item.id]: [] }
          }, {})
        }

        // Calculate the number of missing logos for each brand key
        const missingLogosByKey: Record<string, number> = Object.values(
          brandDetailsByKey,
        ).reduce((acc, items) => {
          // Create a key-value map using the specified type ('customerId' or 'countryId')
          const itemByKey: Record<string, object> = keyBy(type)(items)

          // Get the first key in the itemByKey map, it should be containing one key all the time.
          const key: string = Object.keys(itemByKey)[0]

          // Create a key-value map using the other type ('countryId' or 'customerId')
          const itemByOtherKey: Record<string, object> = keyBy(
            type === 'customerId' ? 'countryId' : 'customerId',
          )(items)

          // Find the missing other key logos by comparing the available keys with the itemByOtherKey keys
          const missingOtherKeyLogos: string[] = difference(
            Object.keys(
              this[
                type === 'customerId'
                  ? 'countryDisplayName'
                  : 'customerDisplayName'
              ],
            ),
            Object.keys(itemByOtherKey),
          )

          // Assign the number of missing other key logos to the corresponding brand key in the accumulator object
          acc[key] = missingOtherKeyLogos
          return acc
        }, {})

        return missingLogosByKey
      },

      canCustomerAddLogo(customerId: string) {
        return this.remainingLogosByCustomerId[customerId].length !== 0
      },

      openAddLogo(customerId: string | undefined) {
        if (!customerId) {
          this.showAddBrandLogoModal = true
        } else if (this.canCustomerAddLogo(customerId)) {
          this.customerIdModal = customerId
          this.showAddBrandLogoModal = true
        }
      },

      async addLogo(logoData) {
        this.appLoading(true)
        if (logoData.logo) {
          try {
            const logoPath = await this.uploadImageAndGetPath(
              logoData.logo,
              this.brandId,
              logoData.logoId,
            )
            await Functions.createBrandLogo(
              this.brandId,
              logoData.countryId,
              logoData.customerId,
              logoPath,
            )
            await this.loadData()
            this.setMessageInformations({
              message: `Logo for ${this.brandId} (${logoData.customerId}-${logoData.countryId}) successfully added.`,
              state: 'success',
            })
            this.$refs.brandList.forEach((list: any) => {
              if (list.logos[0].customerId === logoData.customerId) {
                list.expand()
              }
            })
          } catch (error) {
            this.handleErrors({ error })
          } finally {
            this.appLoading(false)
          }
        }
      },

      async uploadImageAndGetPath(logoFile, brandId, fileName) {
        const extension = logoFile?.name?.split('.').pop()
        const path = `brands/${brandId}/${fileName}.${extension}`
        try {
          const result = await Functions.uploadImage(path, logoFile)
          return result.path
        } catch (error) {
          this.handleErrors({ error })
        }
      },

      resetModal() {
        this.customerIdModal = this.selectedCustomerValue
          ? this.selectedCustomerValue.id
          : null
        this.countryIdModal = this.selectedCountryValue
          ? this.selectedCountryValue.id
          : null
      },

      resetLogoToDelete() {
        this.logoToDelete = {
          countryId: null,
          customerId: null,
        }
      },

      openDeleteLogo(logo) {
        if (logo.customerId && logo.countryId) {
          this.logoToDelete = logo
          this.showDeleteBrandLogoModal = true
        }
      },

      async deleteLogo(logo) {
        this.appLoading(true)
        try {
          await Functions.deleteBrandLogo(
            this.brandId,
            logo.countryId,
            logo.customerId,
          )
          await this.loadData()
          this.setMessageInformations({
            message: `Logo for ${this.brandId} (${logo.customerId}-${logo.countryId}) successfully removed.`,
            state: 'success',
          })
        } catch (error) {
          this.handleErrors({ error })
        } finally {
          this.appLoading(false)
        }
      },
    },

    async mounted() {
      await this.loadData()
    },
  }
