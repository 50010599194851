<template>
  <BaseAdminPage
    :title="title"
    :components="components"
  />
</template>

<script>
  import BaseAdminPage from '@/components/admin/BaseAdminPage'
  import ApplicationsManagement from '@/components/admin/applicationVersionManagement/ApplicationsManagement'
  import VersionsManagement from '@/components/admin/applicationVersionManagement/VersionsManagement'

  export default {
    name: 'ApplicationsVersionsManagement',

    components: {
      BaseAdminPage,
    },

    data() {
      return {
        components: [
          {
            id: 'VersionsManagement',
            title: 'Versions Management',
            component: VersionsManagement,
            lazy: true,
          },
          {
            id: 'ApplicationsManagement',
            title: 'Applications Management',
            component: ApplicationsManagement,
            lazy: true,
          },
        ],
      }
    },

    computed: {
      title() {
        return this.$route.meta?.displayName ?? 'Applications Management'
      },
    },
  }
</script>
