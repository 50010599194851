<template>
  <b-button
    :variant="state.copyVariant"
    size="sm"
    class="copy-button"
    :disabled="!isCopyAvailable"
    @click="copyLink"
  >
    <b-icon :icon="state.copyIcon" />
    <span class="copy-button_label">{{ state.copyText }}</span>
  </b-button>
</template>

<script>
  import { GENERIC_ERROR_NO_COPY_FUNCTIONNALITY } from '@/constants/ErrorConstants'

  const states = {
    copy: {
      copyText: 'Copy',
      copyVariant: 'primary',
      copyIcon: 'files',
    },
    copied: {
      copyText: 'Copied!',
      copyVariant: 'success',
      copyIcon: 'check',
    },
    error: {
      copyText: 'Error',
      copyVariant: 'danger',
      copyIcon: 'x',
    },
  }

  export default {
    name: 'CopyButton',

    props: {
      textToCopy: {
        type: String,
        required: true,
      },
    },

    data() {
      return {
        isCopyAvailable: true,
        state: states.copy,
      }
    },

    methods: {
      async copyLink() {
        const isFirefox = navigator.userAgent.toLowerCase().includes('firefox')

        if (!isFirefox) {
          try {
            const result = await navigator.permissions.query({
              name: 'clipboard-write',
            })
            if (!['granted', 'prompt'].includes(result?.state)) {
              this.isCopyAvailable = false
              return
            }
          } catch (error) {
            this.isCopyAvailable = false
            this.handleErrors({ code: GENERIC_ERROR_NO_COPY_FUNCTIONNALITY })
            this.state = states.error
            return
          }
        }

        if (this.isCopyAvailable && navigator?.clipboard) {
          try {
            await navigator.clipboard.writeText(this.textToCopy)
            this.state = states.copied
            setTimeout(() => {
              this.state = states.copy
            }, 2000)
          } catch (error) {
            this.state = states.error
          }
        } else {
          this.handleErrors({ code: GENERIC_ERROR_NO_COPY_FUNCTIONNALITY })
          this.state = states.error
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .copy-button {
    display: inline-flex;
    align-items: center;
    transition: all 0.2s ease-in-out;
    overflow: hidden;
    white-space: nowrap;

    &:hover,
    &:active,
    &:focus {
      .copy-button_label {
        width: 100%;
        padding-left: 10px;
      }
    }
  }

  .copy-button_label {
    width: 0;
    overflow: hidden;
    padding-left: 0;
    transition: padding-left 0.2s ease-in-out, width 0.2s ease-in-out;
  }
</style>
