<template>
  <b-form-group label="Default Value :">
    <Multiselect
      v-if="useMultiSelect"
      :value="internalValue"
      :options="[]"
      placeholder="Please provide defaults values for this field."
      taggable
      multiple
      allow-unknow
      @input="$emit('input', $event)"
    />
    <b-form-input
      v-else
      :value="internalValue"
      placeholder="Please provide a default value for this field."
      @input="$emit('input', $event)"
    />
  </b-form-group>
</template>

<script>
  import Multiselect from '@/components/form/Multiselect'
  export default {
    name: 'MappingFieldsFieldDefaultValue',
    components: { Multiselect },
    props: {
      value: {
        type: [String, Array],
        default: () => '',
      },
      type: {
        type: String,
        required: false,
        default: '',
      },
    },
    computed: {
      useMultiSelect() {
        return ['table', 'collection'].includes(this.type)
      },
      internalValue() {
        let newContent

        if (this.useMultiSelect && !Array.isArray(this.value)) {
          newContent = []
          if (this.value !== '') {
            newContent.push(this.value)
          }
        } else if (!this.useMultiSelect && Array.isArray(this.value)) {
          newContent = this.value.join(',')
        }

        if (newContent) {
          this.$emit('input', newContent)
          return newContent
        }
        return this.value
      },
    },
  }
</script>

<style></style>
