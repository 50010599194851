<template>
  <div class="container--nextstep">
    <h2 class="mb-3">What's next ?</h2>
    <b-list-group>
      <b-list-group-item
        v-if="routePath && $route.name == 'create-route'"
        :to="`/app/${routePath}/edit`"
      >
        Go to new instance's Setup
      </b-list-group-item>
      <b-list-group-item to="/admin/applications"
        >Applications and Versions management</b-list-group-item
      >
      <b-list-group-item
        v-if="$route.name != 'create-route'"
        to="/app/route/create"
        >Create instance</b-list-group-item
      >
      <b-list-group-item
        v-if="$route.name != 'upgrade-route'"
        to="/app/route/upgrade"
        >Upgrade instance</b-list-group-item
      >
    </b-list-group>
  </div>
</template>

<script>
  export default {
    name: 'NextStep',

    props: {
      routePath: {
        type: String,
        default: null,
      },
    },
  }
</script>

<style scoped lang="scss">
  .container--nextstep {
    padding: 1rem;
    margin: 1rem auto;
    position: relative;
    max-width: 550px;

    .list-group-item-action {
      color: var(--primary-font-color);
      background: var(--primary);
      &:after {
        content: '>';
        right: 1rem;
        position: absolute;
      }
    }
  }
</style>
