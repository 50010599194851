var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex",class:_setup.actionsClasses},[_vm._l((_setup.additionalActions),function(action,index){return _c('b-button',{key:`action_${index}`,staticClass:"additional-actions_button d-flex flex-column pr-2",attrs:{"variant":`outline${action.variant ? '-' + action.variant : ''}`,"href":typeof action.href === 'function' ? action.href(_vm.item) : action.href,"target":action.target,"disabled":action.disabled || action.loading},on:{"click":function($event){return _setup.handleClick(action.onClick, _vm.item)}}},[(action.loading)?_c('b-spinner',{attrs:{"small":""}}):_c('div',[_c('b-icon',{attrs:{"icon":action.disabled ? action.disabledIcon : action.icon}})],1),_c('span',[_vm._v(" "+_vm._s(action.label)+" ")])],1)}),(_setup.menuActions.length > 0)?_c('b-dropdown',{attrs:{"dropright":"","right":"","variant":"link","size":"sm","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-icon',{staticClass:"menu-action-icon",attrs:{"icon":"three-dots-vertical"}})]},proxy:true}],null,false,2631566543)},_vm._l((_setup.menuActions),function(action,index){return _c('div',{key:'menu-action-button_' + index},[(
          typeof action.divider === 'function'
            ? action.divider(_vm.item)
            : action.divider
        )?_c('b-dropdown-divider'):_vm._e(),_c('b-dropdown-item',{attrs:{"variant":action.variant,"to":typeof action.to === 'function' ? action.to(_vm.item) : action.to,"href":typeof action.href === 'function' ? action.href(_vm.item) : action.href,"disabled":action.disabled},on:{"click":function($event){return _setup.handleClick(action.onClick, _vm.item)}}},[(action.icon)?_c('b-icon',{attrs:{"icon":typeof action.icon === 'function'
              ? action.icon(_vm.item)
              : action.icon}}):_vm._e(),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(typeof action.label === 'function' ? action.label(_vm.item) : action.label))])],1)],1)}),0):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }