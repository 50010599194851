<template>
  <BaseContainer
    ref="baseContainer"
    class="m-4"
    :title="title"
    :reduce-container="reduceContainer"
    :reduced="reduced"
  >
    <template>
      <b-container fluid>
        <b-row align-h="start">
          <b-col
            v-for="logo in logos"
            class="col-sm-6 col-md-4 col-lg-3 my-3"
            :key="`${logo.id}_${logo.customerId}_${logo.countryId}`"
          >
            <BrandDisplayCard
              :logo="logo"
              :country-name="
                countryById[logo?.countryId]?.displayName || logo?.countryId
              "
              @delete-logo="deleteLogo(logo)"
            />
          </b-col>
          <slot />
        </b-row>
      </b-container>
    </template>
  </BaseContainer>
</template>

<script>
  import keyBy from 'lodash/fp/keyBy'
  import { mapState } from 'vuex'
  import BaseContainer from '../containers/BaseContainer.vue'
  import BrandDisplayCard from './BrandDisplayCard.vue'

  export default {
    components: { BaseContainer, BrandDisplayCard },

    props: {
      title: {
        type: String,
        required: true,
      },
      logos: {
        type: Array,
        required: true,
      },
      reduceContainer: {
        type: Boolean,
        default: true,
      },
      reduced: {
        type: Boolean,
        default: true,
      },
    },

    computed: {
      ...mapState({
        fetchedResources: (state) => state.fetchedResources,
        customers: (state) => state.customers,
        countries: (state) => state.countries,
        brands: (state) => state.brands,
      }),

      countryById() {
        return keyBy('id')(this.countries)
      },
    },

    methods: {
      deleteLogo(logo) {
        this.$emit('delete-logo', {
          customerId: logo.customerId,
          countryId: logo.countryId,
        })
      },
      expand() {
        this.$refs.baseContainer.showList = true
      },
    },
  }
</script>
