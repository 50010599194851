<template>
  <MavonEditor
    ref="mavnEdit"
    class="markdown-editor"
    :class="getUserTheme === 'dark' ? 'markdown-editor--dark' : ''"
    :value="markdownText"
    language="en"
    :subfield="editing"
    :editable="editing"
    default-open="preview"
    :placeholder="null"
    :tab-size="2"
    :toolbars="toolbarButtons"
    @change="onMarkdownChange"
    @imgAdd="onImageAdd"
  />
</template>

<script>
  import Functions from '@/firebase/functions'
  import { formatAssetLink } from '@/utils/StringHelpers'
  import { mapGetters } from 'vuex'

  import mavonEditor from 'mavon-editor'
  import 'mavon-editor/dist/css/index.css'

  export default {
    name: 'MarkdownEditor',

    components: {
      MavonEditor: mavonEditor.mavonEditor,
    },

    model: {
      prop: 'markdownText',
      event: 'text-changed',
    },

    props: {
      markdownText: {
        type: String,
        default: '',
      },
      editing: {
        type: Boolean,
        default: false,
      },
      toolbarOptions: {
        type: Object,
        default: () => ({}),
      },
      imageUploadPath: {
        type: String,
        required: true,
      },
    },

    data() {
      return {
        toolbars: {
          bold: true,
          italic: true,
          header: true,
          underline: true,
          strikethrough: true,
          mark: true,
          superscript: true,
          subscript: true,
          quote: true,
          ol: true,
          ul: true,
          link: true,
          imagelink: true,
          code: true,
          table: true,
          fullscreen: true,
          readmodel: true,
          htmlcode: true,
          help: true,
          /* 1.3.5 */
          undo: true,
          redo: true,
          trash: true,
          save: true,
          /* 1.4.2 */
          navigation: false,
          /* 2.1.8 */
          alignleft: true,
          aligncenter: true,
          alignright: true,
          /* 2.2.1 */
          subfield: true,
          preview: true,
        },
      }
    },

    computed: {
      ...mapGetters(['getUserTheme']),

      toolbarButtons() {
        return {
          ...this.toolbars,
          ...this.toolbarOptions,
        }
      },
    },

    methods: {
      onMarkdownChange(value) {
        this.$emit('text-changed', value)
      },

      async onImageAdd(filename, imgFile) {
        const uniqueFilename = Date.now() + '-' + imgFile.name
        const imagePath = `${this.imageUploadPath}/${uniqueFilename}`
        const uploadResult = await Functions.uploadImage(imagePath, imgFile)
        const uploadedImgUrl = formatAssetLink(uploadResult?.path)

        // step 2. replace url ![...](./0) -> ![...](url)
        // $vm.$img2Url. The details at the end of this page
        this.$refs.mavnEdit.$img2Url(filename, uploadedImgUrl)
      },
    },
  }
</script>

<style lang="scss">
  .markdown-editor {
    textarea {
      background-color: var(--app-background-color);

      &:active,
      &:focus {
        background-color: var(--app-background-color);
      }
    }

    &--dark {
      textarea,
      .v-note-edit,
      .content-input-wrapper,
      .v-note-op,
      .add-image-link {
        background-color: var(
          --app-background-color
        ) !important; // Because of module element.style
        color: var(
          --app-background-font-color
        ) !important; // Because of module element.style
      }
      .add-image-link {
        ::placeholder {
          color: var(--app-background-font-color);
          opacity: 0.5;
        }
      }
    }
  }
</style>
