<template>
  <MultistepForm
    v-model="formData"
    :title="title"
    :total-step-number="totalStepNumber"
    :is-submit-success="success"
    :steps="stepsComponents"
    :is-next-disabled="isNextDisabled"
    submit-label="Create instance"
    :step.sync="stepIndex"
    @submit="submitForm"
    @isStep2Disabled="isStep2Disabled = $event"
  >
    <template #success>
      <NextSteps
        v-if="success"
        :route-path="routePath"
      />
    </template>
  </MultistepForm>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { AppManagementMixin } from '@/mixins/AppManagementMixin.js'
  import {
    getDocumentSnapshot,
    getDocumentData,
  } from '@/service/FirebaseService'
  import { duplicateRoute } from '@/service/AppRouteService'
  import Functions from '@/firebase/functions'

  import MultistepForm from '@/components/multistep-form/MultistepForm'
  import NextSteps from '@/components/apps/forms/NextSteps'

  const fieldsConfiguration = [
    'app',
    'version',
    'type',
    'tenant',
    'customer',
    'country',
    'touchpoint',
    'env',
  ]
  const initialData = {
    configuration: fieldsConfiguration.reduce((accumulator, field) => {
      return {
        ...accumulator,
        [field]: null,
      }
    }, {}),
    setup: {
      approuteCopy: null,
      firedefaultcontent: {
        appconfigurations: null,
        appcontent: null,
        appcustomisations: null,
        appresources: null,
      },
      uploadedContent: [],
    },
  }
  export default {
    name: 'CreateRoute',

    components: {
      MultistepForm,
      NextSteps,
    },

    mixins: [AppManagementMixin],

    data() {
      return {
        stepsLabels: ['Instance configuration', 'Instance setup'],
        success: false,
        stepIndex: 1,
        totalStepNumber: 2,
        formData: JSON.parse(JSON.stringify(initialData)),
        isDestinationAppRouteIdOk: false,
        title: 'Create Instance',
        loadingMessage: 'Loading ...',
        isStep2Disabled: true,
        routePath: null,
      }
    },

    computed: {
      ...mapGetters(['getAppRoute']),

      stepsComponents() {
        return ['CreateRouteConfiguration', 'CreateRouteSetup'].map(
          (component, index) => {
            return {
              id: component,
              label: this.stepsLabels[index],
              component: () => import(`@/components/apps/forms/${component}`),
              step: index + 1,
            }
          },
        )
      },

      isNextDisabled() {
        let isDisabled = true

        switch (this.stepIndex) {
          case 1: {
            const configurationKeys = Object.keys(this.formData.configuration)
            isDisabled = configurationKeys.some(
              (key) => this.formData.configuration[key] === null,
            )
            break
          }
          case 2:
            isDisabled = this.isStep2Disabled
            break
          default:
            isDisabled = false
            break
        }

        return isDisabled || this.isAppLoading
      },
    },

    async mounted() {
      await this.bindAppRoutes()
    },

    methods: {
      ...mapActions(['bindAppRoutes']),

      async submitForm() {
        this.appLoading(true)
        const { approuteCopy, firedefaultcontent } = this.formData?.setup || {}
        const routeDocData = this.formData?.configuration
        const hasPrescription = Object.prototype.hasOwnProperty.call(
          firedefaultcontent,
          'appprescriptions',
        )
        let appprescriptions = {}
        this.routePath = `${routeDocData?.app}-${routeDocData?.tenant}-${routeDocData?.customer}-${routeDocData?.country}-${routeDocData?.touchpoint}-${routeDocData?.env}-${routeDocData?.type}`

        try {
          // When  copy is set we use duplicateRoute.
          if (approuteCopy) {
            const sourceRouteHasPrescription =
              this.getAppRoute(
                this.formData?.setup?.approuteCopy,
              )?.meta?.subcollections?.includes('appprescriptions') ?? false

            this.success = !!(await duplicateRoute(
              routeDocData,
              this.formData?.setup?.approuteCopy,
              sourceRouteHasPrescription,
            ))
            return
          }

          if (hasPrescription) {
            appprescriptions = Object.freeze(
              firedefaultcontent.appprescriptions,
            )
            delete firedefaultcontent.appprescriptions
          }

          // Populate from default content from jsons
          const hasJsons = Object.keys(firedefaultcontent).every(
            (key) => firedefaultcontent[key] !== null,
          )

          if (!approuteCopy && hasJsons) {
            routeDocData.collections = firedefaultcontent
          }

          // Populate with default content data from version.
          if (!approuteCopy && !hasJsons) {
            // Get the defaultcontent from version.
            const versionPath = `appversions/${routeDocData.version}`
            const versionSnapshot = await getDocumentSnapshot(versionPath)
            const defaultContentCollectionReference = await versionSnapshot.ref
              .collection('defaultcontent')
              .get()

            if (defaultContentCollectionReference.docs.length === 0) {
              throw new Error('No default content for this version.')
            }

            const [
              appconfigurations,
              appcontent,
              appcustomisations,
              appresources,
            ] = await Promise.all([
              getDocumentData(
                `${versionPath}/defaultcontent/appconfigurations`,
              ),
              getDocumentData(`${versionPath}/defaultcontent/appcontent`),
              getDocumentData(
                `${versionPath}/defaultcontent/appcustomisations`,
              ),
              getDocumentData(`${versionPath}/defaultcontent/appresources`),
            ])

            routeDocData.collections = {
              appconfigurations,
              appcontent,
              appcustomisations,
              appresources,
            }
          }

          const routeId = await Functions.createRoute(routeDocData)

          if (hasPrescription && !approuteCopy) {
            const prescriptionForm = {
              ...appprescriptions,
              configuration: {
                ...(appprescriptions && appprescriptions.configuration
                  ? appprescriptions.configuration
                  : {}),
                approute: {
                  id: routeId,
                  useDefault: !hasJsons,
                  eraseAndReplace: false,
                },
              },
            }

            await Functions.createPrescription(prescriptionForm)
          }

          this.success = true
        } catch (error) {
          this.handleErrors({ error })
        } finally {
          this.appLoading(false)
        }
      },
    },
  }
</script>
