import findLast from 'lodash/fp/findLast'
import router from '@/router'
import { Route, RouteConfig } from 'vue-router'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { RootState } from '@/store'

const baseRoute: RouteConfig | Record<string, never> = router
  ? router.options.routes.find((route) => route.path === '/')
  : {}

export type NavigationState = {
  navigation: NavigationItem[]
}

export type NavigationItem = {
  name: string
  path: string
  displayName: string
  order: number
  active: boolean
}

const initialState = {
  navigation: [
    {
      name: baseRoute.name ?? 'homepage',
      path: baseRoute.path ?? '/',
      displayName: baseRoute.meta?.displayName ?? 'Home',
      order: 0,
      active: true,
    },
  ],
}

const state: NavigationState = { ...initialState }

const mutations: MutationTree<NavigationState> = {
  /**
   * Mutator to set the navigation state.
   *
   * @function navigationMutator
   */
  navigationMutator: (state, navigation: NavigationItem[]) => {
    state.navigation = navigation
  },
}

const actions: ActionTree<NavigationState, RootState> = {
  /**
   *	Update the navigation depending on the page we currently are.
   *
   * @function setNavigation
   */
  setNavigation: ({ commit, getters }) => {
    let navigation = Array.from(initialState.navigation)
    const currentRoute = router.currentRoute

    // Getting the informed parent's route of currentRoute.
    if (currentRoute?.meta?.parent) {
      const parentRoute = getters.getParentRoute(currentRoute)
      if (parentRoute) navigation.push(getters.formatRoute(parentRoute))
    }

    // In case we are on homepage we do not push anything.
    if (currentRoute.name !== 'homepage') {
      navigation.push(getters.formatRoute(currentRoute))
    }

    // Remove the active class of non active pages.
    navigation = navigation.map((navItem) => {
      if (navItem.active && navItem.name !== currentRoute.name) {
        navItem.active = false
      }
      return navItem
    })

    // Reordering by order just in case.
    navigation.sort((a, b) => {
      return a.order - b.order
    })
    commit('navigationMutator', navigation)
  },
}

const getters: GetterTree<NavigationState, RootState> = {
  getParentRoute: () => (route: Route) => {
    const parentName = route.meta?.parent
    if (!parentName) return {}
    const parentRoute = router.resolve({
      name: parentName,
      params: route.params,
    })?.resolved

    return parentRoute ?? {}
  },

  formatRoute: (state) => (route: Route) => {
    const navigation = Array.from(state.navigation)
    if (!route) return {}

    // Will be fixed in TS 5 https://github.com/microsoft/TypeScript/issues/48829
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const routeFormatted: Route =
      findLast(({ meta }) => meta.displayName, route.matched) ?? route

    return {
      name: routeFormatted.name,
      path: router.resolve(routeFormatted)?.resolved?.path,
      displayName: routeFormatted.meta?.displayName,
      order: navigation.length,
      active: true,
    }
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
