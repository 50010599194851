export const CRITERIAS_DETAILS = [
  {
    key: 'inclusiveStrictCriterias',
    tooltip: 'Inclusive Strict criterias : MUST',
    icon: 'exclamation-circle',
  },
  {
    key: 'inclusiveCriterias',
    tooltip: 'Inclusive criterias : SHOULD',
    icon: 'check-circle',
  },
  {
    key: 'exclusiveCriterias',
    tooltip: 'Exclusive criterias : MUST NOT',
    icon: 'dash-circle',
  },
  {
    key: 'inclusiveRootItemCriterias',
    tooltip: 'Inclusive Strict item criterias : MUST',
    icon: 'exclamation-square',
    isItemCondition: true,
  },
  {
    key: 'inclusiveItemCriterias',
    tooltip: 'Inclusive item criterias : SHOULD',
    icon: 'check-square',
    isItemCondition: true,
  },
  {
    key: 'exclusiveItemCriterias',
    tooltip: 'Exclusive item criterias : MUST NOT',
    icon: 'dash-square',
    isItemCondition: true,
  },
]

export const PRESCRIPTION_TYPES = {
  STANDARD: 'standard',
  AI_POWERED: 'ai-powered',
}

export const DEFAULT_SCORING_MODE = 'weightedMust'
