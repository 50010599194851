<template>
  <b-modal
    id="modal-register-phone-number"
    size="lg"
    :cancel-disabled="isAppLoading"
    :ok-disabled="isSubmitDisabled"
    ok-only
    centered
    no-close-on-esc
    no-close-on-backdrop
    @hidden="resetModal"
  >
    <template #modal-header>
      <h3 class="m-auto">{{ modalTitle }}</h3>
    </template>

    <div
      v-if="!verifyMode"
      class="text-center mt-4"
    >
      <h3 class="mb-4">Enter your phone number</h3>

      <p :class="phoneNumberStatusClasses">{{ phoneNumberStatusText }}</p>

      <VueTelInput
        v-model="phoneNumber"
        class="w-50 m-auto"
        :class="vueTelInputClasses"
        required
        :auto-default-country="false"
        :default-country="defaultCountry"
        :custom-validate="customValidate"
        @input="updatePhoneObject"
        @blur="inputBlur"
        @enter="onEnter"
      />

      <p class="info-text_small mt-3">
        Your phone number will be saved in your account once verified.
        <br />
        We will never ask it again and you will be able to change it from your
        Profile.
      </p>
    </div>

    <div
      v-else
      class="text-center mt-4"
    >
      <h3>Please enter the code you recieved by message</h3>
      <p class="mb-4">
        We just sent a message to <strong> {{ phoneNumber }} </strong> with a
        code for you to enter below.
      </p>

      <p :class="verifStatusClasses">{{ verifStatusText }}</p>

      <VerifyCodeInput
        v-model="code"
        class="w-50 m-auto"
        :status="validVerifCode"
        autofocus
        @reSendCode="reSendCode"
        @validate="verifyCode"
      />
    </div>

    <template #modal-footer>
      <div
        v-if="!verifyMode"
        class="d-flex justify-content-end w-100"
      >
        <b-button
          variant="primary"
          :disabled="isSubmitDisabled"
          @click="sendSmsToVerify()"
        >
          SEND SMS
        </b-button>
      </div>
      <div
        v-else
        class="d-flex justify-content-between w-100"
      >
        <div>
          <b-button
            v-if="!phoneRegistered"
            variant="link"
            @click="verifyMode = false"
          >
            <b-icon
              icon="arrow-left-circle"
              scale="0.8"
            />
            GO BACK
          </b-button>
          <small
            v-else
            class="w-50 overflow-auto"
          >
            If you've lost access to the phone number associated with your
            Firecamp account, please contact your administrator or Scale Manager
          </small>
        </div>
        <b-button
          variant="primary"
          :disabled="isVerifyDisabled"
          @click="verifyCode"
        >
          VERIFY
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
  import { VueTelInput } from 'vue-tel-input'
  import { isValidPhoneNumber } from 'libphonenumber-js'

  import VerifyCodeInput from '@/components/fields/genericFields/VerifyCodeInput.vue'
  import Auth from '@/firebase/auth'

  export default {
    name: 'RegisterPhoneNumberModal',

    components: {
      VueTelInput,
      VerifyCodeInput,
    },

    model: {
      prop: 'show',
      event: 'update:show',
    },

    props: {
      phoneRegistered: {
        type: String,
        default: '',
      },
      show: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        verifyMode: !!this.phoneRegistered,
        phoneNumber: this.phoneRegistered,
        phoneObject: null,
        statusInvalidEntry: false,

        code: '',
        validVerifCode: null,

        expectedCode: null,
        codeReSent: false,

        defaultCountry: window.navigator.language.split('-')[1] || '',
      }
    },

    computed: {
      modalTitle() {
        return !this.verifyMode
          ? 'Increase your Firecamp account security'
          : 'Verify your identity'
      },

      isSubmitDisabled() {
        return this.isAppLoading || !this.validPhoneNumber
      },

      isVerifyDisabled() {
        return this.isAppLoading || this.code.length < 6
      },

      customValidate() {
        return isValidPhoneNumber(this.phoneNumber ?? '')
      },

      validPhoneNumber() {
        return !!this.phoneObject && this.phoneObject.valid
      },

      vueTelInputClasses() {
        return {
          'vue-tel-input--invalid': this.statusInvalidEntry,
          'vue-tel-input--valid': this.validPhoneNumber,
        }
      },

      verifStatusText() {
        switch (true) {
          case this.validVerifCode === false:
            return "You've entered an invalid code. Please try again."
          case this.codeReSent:
            return 'New code sent. Please enter the last one you received below.'
          default:
            return 'This code is a one-time code which expires after 5 minutes.'
        }
      },

      verifStatusClasses() {
        return this.validVerifCode === false
          ? 'text-danger'
          : this.codeReSent
          ? 'text-success'
          : ''
      },

      phoneNumberStatusText() {
        switch (true) {
          case this.validPhoneNumber:
            return 'Valid phone number, you can continue.'
          case !this.statusInvalidEntry:
            return 'Please select your country before entering your phone number.'
          default:
            return "You've entered an invalid number. Please try again."
        }
      },

      phoneNumberStatusClasses() {
        return this.validPhoneNumber
          ? 'text-success'
          : !this.statusInvalidEntry
          ? ''
          : 'text-danger font-weight-bold'
      },
    },

    watch: {
      show: {
        handler(value) {
          if (value) {
            this.$bvModal.show('modal-register-phone-number')
          } else {
            this.$bvModal.hide('modal-register-phone-number')
          }
        },
        immediate: true,
      },

      phoneRegistered: {
        handler(value) {
          this.verifyMode = !!value
        },
      },
    },

    methods: {
      updatePhoneObject(_, phoneObject) {
        this.phoneObject = phoneObject
        this.defaultCountry = phoneObject.countryCode
      },

      inputBlur() {
        this.statusInvalidEntry = !this.validPhoneNumber
      },

      onEnter() {
        if (!this.isSubmitDisabled) this.sendSmsToVerify()
      },

      resetModal() {
        this.phoneNumber = null
        this.verifyMode = !!this.phoneRegistered
      },

      async verifyCode() {
        this.appLoading(true)
        try {
          await Auth.validateMFACode(this.code)
          this.$emit('update:show', false)
          this.$emit('verified')
        } catch (error) {
          this.validVerifCode = false
        } finally {
          this.appLoading(false)
        }
      },

      async sendSmsToVerify() {
        try {
          await Auth.validatePhoneNumber(this.phoneObject.number)
          this.verifyMode = true
        } catch (error) {
          this.handleErrors({ error })
        }
      },

      reSendCode() {
        Auth.sendMFACode()
        this.codeReSent = true
        this.validVerifCode = null
      },
    },
  }
</script>

<style lang="scss">
  @import '~vue-tel-input/dist/vue-tel-input.css';

  .vue-tel-input {
    position: relative;
    //border-color: var(--input-color);

    &:focus-within {
      box-shadow: none;
    }

    &--valid {
      border-color: var(--success);
    }

    &--invalid {
      border-color: var(--danger);
    }

    .vti__input {
      font-size: var(--font-size--small);
    }

    .vti__dropdown {
      position: static;
      padding: 10px 7px;
    }

    .vti__dropdown-list {
      width: 100%;
      background-color: var(--app-background-color);
      color: var(--app-background-font-color);
    }

    .vti__dropdown-item {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .vti__dropdown-item.highlighted {
      background-color: var(--primary);
      color: var(--primary-font-color);
    }
  }

  .info-text_small {
    font-size: 0.8rem;
    opacity: 0.8;
  }
</style>
