import { errorHandler } from '@/service/ErrorService'

export default {
  install(Vue, { store }) {
    if (!store) {
      throw new Error('Please provide vuex store')
    }

    Vue.mixin({
      beforeRouteUpdate(to, from, next) {
        this.removeAppIdNotifications(from, to)
        next()
      },

      beforeRouteLeave(to, from, next) {
        this.removeAppIdNotifications(from, to)
        next()
      },

      computed: {
        isAppLoading() {
          return this.$store.state.isAppLoading
        },
      },

      methods: {
        removeAppIdNotifications(from, to) {
          const fromAppId = this.getAppIdFromRoute(from)
          const toAppId = this.getAppIdFromRoute(to)

          const shouldRemoveNotifications = fromAppId && fromAppId !== toAppId
          if (shouldRemoveNotifications) {
            this.$store.commit(
              'notifications/REMOVE_NOTIFICATION_FROM_SCOPE',
              fromAppId,
            )
          }
        },

        handleErrors({ code, message, error, messageInformations }) {
          const formatedError = errorHandler({
            code,
            message,
            error,
            messageInformations,
          })

          if (formatedError.show) {
            const finalMessageInformations = {
              message: formatedError.message,
              state: 'error',
              ...(!!formatedError.keepShowing && {
                keepShowing: formatedError.keepShowing,
              }),
              ...(!!formatedError.dismissible && {
                dismissible: formatedError.dismissible,
              }),
              ...(!formatedError.floating && {
                floating: formatedError.floating,
              }),
            }

            return this.setMessageInformations(finalMessageInformations)
          }
        },

        setMessageInformations({
          message,
          state,
          keepShowing,
          dismissible,
          floating,
          dismissSecs,
          actionLink,
          actionLabel,
          scope,
        }) {
          const values = [undefined, null]
          const appId = this.getAppIdFromRoute(this.$route)

          const notification = {
            ...(!values.includes(message) && { message }),
            ...(!values.includes(state) && { state }),
            ...(!values.includes(dismissSecs) && { dismissSecs }),
            ...(!values.includes(keepShowing) && { keepShowing }),
            ...(!values.includes(floating) && { floating }),
            ...(!values.includes(dismissible) && { dismissible }),
            ...(!values.includes(actionLink) && { actionLink }),
            ...(!values.includes(actionLabel) && { actionLabel }),
            ...(!values.includes(scope) ? { scope } : { scope: appId }),
          }
          if (!notification.id) notification.id = new Date().toISOString()
          this.$store.commit('notifications/ADD_NOTIFICATION', notification)

          return () => {
            this.$store.commit(
              'notifications/REMOVE_NOTIFICATION',
              notification,
            )
          }
        },

        setLoadingInformations(payload) {
          this.$store.dispatch('setLoadingInformations', payload)
        },

        appLoading(isLoading) {
          this.$store.commit('appLoading', isLoading)
        },

        getAppIdFromRoute(router) {
          return router?.params?.app_id
        },
      },
    })
  },
}
