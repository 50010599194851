<template>
  <div class="questionnaire-management_container container-fluid">
    <TitleContainer
      v-if="!included"
      :title="title"
    />
    <b-form
      class="questionnaire-management_form"
      @submit.prevent="onSubmit"
      @reset.prevent="onReset"
    >
      <div class="questionnaire-management_fields">
        <b-form-group
          class="col-5 mb-2 pl-0"
          :label="formatedSingularDocType + ' id'"
        >
          <b-form-input
            v-model="idValue"
            :disabled="isAppLoading || edition"
            :debounce="500"
            :state="idState"
            aria-describedby="questionnaire-input-id-feedback"
            required
          />
          <b-form-invalid-feedback id="questionnaire-input-id-feedback">
            {{ invalidIdErrorMessage }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="formatedSingularDocType + ' label'"
          class="col-5 mb-2 pl-0"
        >
          <b-form-input
            v-model="labelValue"
            required
          />
        </b-form-group>

        <b-form-group
          :label="formatedSingularDocType + ' value'"
          class="col-5 mb-2 pl-0"
        >
          <b-form-input
            v-model="valueValue"
            required
          />
        </b-form-group>

        <b-form-group
          class="col-5 mb-2 pl-0"
          label="Application(s):"
        >
          <Multiselect
            v-model="applicationValues"
            placeholder="Select the application(s)"
            searchable
            multiple
            track-by="id"
            label="displayName"
            :options="stateApplications"
            :show-labels="false"
            :disabled="isAppLoading || !fetchedResources.applications"
          />
        </b-form-group>

        <b-row>
          <div :class="included ? 'action-button_container' : 'mt-4 pl-0'">
            <b-button
              type="reset"
              variant="link"
              class="text-danger"
              :class="!included ? 'w-100' : ''"
              >{{ edition ? 'Delete' : 'Reset' }}</b-button
            >
            <SubmitButton
              :disabled="isSubmitDisabled"
              :loading="loading"
              :class="included ? 'w-auto mb-auto ml-2' : ''"
            />
          </div>
        </b-row>
      </div>
    </b-form>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import {
    getDocumentsInCollection,
    getNewServerTimestamp,
    updateRef,
    addDocToCollection,
  } from '@/service/FirebaseService'
  import { RESOURCES } from '@/service/ResourceService'
  import {
    pluralToSingular,
    firstCharacterToUppercase,
  } from '@/utils/StringHelpers'

  import TitleContainer from '@/components/containers/TitleContainer'
  import Multiselect from '@/components/form/Multiselect'
  import SubmitButton from '@/components/form/SubmitButton'

  const initialData = {
    idValue: null,
    labelValue: null,
    valueValue: null,
    applicationValues: null,
  }

  export default {
    name: 'QuestionnaireCreation',

    components: {
      TitleContainer,
      Multiselect,
      SubmitButton,
    },

    props: {
      included: {
        type: Boolean,
        default: false,
      },
      edition: {
        type: Boolean,
        default: false,
      },
      documentType: {
        type: String,
        required: true,
      },
      activeQuestionnairePath: {
        type: String,
        required: true,
      },
      id: {
        type: String,
        default: null,
      },
      label: {
        type: String,
        default: null,
      },
      value: {
        type: String,
        default: null,
      },
      applications: {
        type: Array,
        default: () => [],
      },
      meta: {
        type: [Object, String],
        default: null,
      },
    },

    data() {
      return {
        documents: [],
        loading: false,
        idRegex: /^[0-9A-z-_:]+$/,
        ...initialData,
      }
    },

    computed: {
      ...mapState({
        stateApplications: (state) => state.applications,
        fetchedResources: (state) => state.fetchedResources,
      }),

      title() {
        return `${this.formatedSingularDocType} ${
          this.edition ? 'edition' : 'creation'
        }`
      },

      formatedSingularDocType() {
        const singular = pluralToSingular(this.documentType)
        return firstCharacterToUppercase(singular)
      },

      idState() {
        if (this.edition) return true
        if (this.idValue === null || this.idValue.length === 0) return null
        return (
          !this.documentsIds?.includes(this.idValue) &&
          this.idRegex.test(this.idValue)
        )
      },

      invalidIdErrorMessage() {
        return !this.idRegex.test(this.idValue)
          ? 'Id must only contains letters without accentuation, numbers and - _ : characters.'
          : 'This id already exists.'
      },

      isSubmitDisabled() {
        return !(
          this.idValue?.length > 0 &&
          this.idState &&
          this.labelValue?.length > 0 &&
          this.valueValue?.length > 0 &&
          this.applicationValues?.length > 0
        )
      },

      documentsIds() {
        return this.documents?.map((document) => document?.id)
      },

      pathToCollection() {
        return `${this.activeQuestionnairePath}/${this.documentType}`
      },
    },

    async created() {
      await this.init()

      if (this.edition) {
        this.initEdition()
      }
    },

    async mounted() {
      await this.loadResources({ resourceNames: [RESOURCES.APPLICATIONS] })
    },

    methods: {
      ...mapActions(['loadResources']),

      async init() {
        this.loading = true
        this.documents = await getDocumentsInCollection(this.pathToCollection)

        this.loading = false
      },

      initEdition() {
        this.idValue = this.id
        this.labelValue = this.label
        this.valueValue = this.value
        this.applicationValues = this.stateApplications?.filter((application) =>
          this.applications?.includes(`applications/${application.id}`),
        )
      },

      async onSubmit() {
        this.loading = true

        try {
          const docPath = `${this.pathToCollection}/${this.idValue}`

          const finalObject = {
            label: this.labelValue,
            value: this.valueValue,
            ...((this.applicationValues?.length > 0 ||
              this.applications?.length > 0) && {
              applications: this.applicationValues.map(
                (application) => `applications/${application.id}`,
              ),
            }),
            ...(this.edition && {
              meta: {
                ...this.meta,
                version: +this.meta.version + 1,
                updatedAt: getNewServerTimestamp,
              },
            }),
          }

          if (this.edition) {
            await updateRef(docPath, finalObject)
          } else {
            await addDocToCollection(
              null,
              this.pathToCollection,
              finalObject,
              null,
              this.idValue,
            )
          }

          this.setMessageInformations({
            message:
              `${this.formatedSingularDocType} '${this.idValue}' has been successfully ` +
              (this.edition ? 'updated!' : 'created!'),
            state: 'success',
          })

          this.$emit('done')
        } catch (error) {
          this.handleErrors({ error })
        } finally {
          this.loading = false
        }
      },

      resetValues() {
        Object.keys(initialData).forEach((key) => {
          this.$data[key] = initialData[key]
        })
      },

      onReset() {
        if (this.edition && this.id) {
          this.$emit('delete', this.id)
        } else {
          this.resetValues()
        }
      },
    },
  }
</script>
