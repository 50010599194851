<template>
  <div class="main-collection_container container-fluid">
    <TitleContainer
      v-if="!included"
      title="Brand edition"
    />
    <b-form
      class="main-collection_form"
      @submit.prevent="onSubmit"
      @reset.prevent="onReset"
    >
      <div class="main-collection_fields">
        <b-form-group
          class="col-7 mb-2 pl-0"
          label="Brand id:"
          label-for="brand-input-id"
          required
        >
          <b-form-input
            id="brand-input-id"
            v-model="idValue"
            :disabled="edition"
            required
          />
        </b-form-group>
        <b-form-group
          class="col-7 mb-2 pl-0"
          label="Display name:"
          label-for="brand-input-display-name"
          required
        >
          <b-form-input
            id="brand-input-display-name"
            v-model.trim="displayNameValue"
            :disabled="edition"
            required
          />
        </b-form-group>
        <b-form-group
          class="col-7 mb-2 pl-0"
          label="Analytics name:"
          label-for="brand-input-analytics-name"
          required
        >
          <b-form-input
            id="brand-input-analytics-name"
            v-model.trim="analyticsNameValue"
            :disabled="edition"
            required
          />
        </b-form-group>
        <b-form-group
          class="col-7 mb-2 pl-0"
          label="Tenants:"
          label-for="brand-input-tenant"
        >
          <Multiselect
            id="brand-input-tenant"
            v-model="tenantValues"
            placeholder="Select tenant"
            multiple
            track-by="id"
            label="displayName"
            :options="tenantsState"
            :disabled="!fetchedResources.tenants"
            :show-labels="false"
          />
        </b-form-group>
        <b-form-group
          class="col-7 mb-2 pl-0"
          label="Default logo:"
          label-for="brand-input-logo"
        >
          <b-form-file
            v-model="logoValue"
            placeholder="Choose a file ..."
            drop-placeholder="Drop file here..."
            accept=".jpg, .png, .jpeg, .svg"
            :disabled="isAppLoading"
          >
            <template #placeholder>
              <span v-if="storedLogo && !logoValue">{{ storedLogo }}</span>
            </template>
          </b-form-file>
          <b-button
            v-if="logoValue || storedLogo"
            variant="link"
            class="pl-0 text-danger"
            :disabled="isAppLoading"
            @click="deleteLogo('storedLogo', 'logoValue')"
          >
            <b-icon icon="x" /> Delete
          </b-button>
        </b-form-group>
        <b-row v-if="logoPreview">
          <b-col cols="7">
            <MediaPreview
              type="image"
              :src="logoPreview"
            />
          </b-col>
        </b-row>
        <div v-if="edition">
          <b-button
            class="px-4 mt-4"
            variant="primary"
            :disabled="isAppLoading"
            @click="redirectToLogoPage"
          >
            <b-icon
              icon="plus-circle"
              class="mx-2"
            />
            Add new logos
          </b-button>
        </div>
        <b-row>
          <div :class="included ? 'action-button_container' : 'mt-4 pl-0'">
            <b-button
              type="reset"
              variant="link"
              class="text-danger"
              :class="!included ? 'w-100' : ''"
              >{{ edition ? 'Delete' : 'Reset' }}</b-button
            >
            <SubmitButton
              :disabled="false"
              :loading="isAppLoading"
              :class="included ? 'w-auto mb-auto ml-2' : ''"
            />
          </div>
        </b-row>
      </div>
    </b-form>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import {
    updateRef,
    addDocToCollection,
    getDocumentReference,
  } from '@/service/FirebaseService'
  import Multiselect from '@/components/form/Multiselect'
  import Functions from '@/firebase/functions'
  import { formatAssetLink } from '@/utils/StringHelpers'
  import { RESOURCES } from '@/service/ResourceService'

  import { DictionnariesCreationMixin } from '@/mixins/DictionnariesCreationMixin'

  import MediaPreview from '@/components/media/MediaPreview'

  const initialData = {
    idValue: null,
    displayNameValue: null,
    analyticsNameValue: null,
    tenantValues: null,
    logoValue: null,
    storedLogo: null,
  }
  export default {
    name: 'BrandCreation',

    components: {
      MediaPreview,
      Multiselect,
    },

    mixins: [DictionnariesCreationMixin],

    props: {
      analyticsName: {
        type: String,
        default: null,
      },
      tenants: {
        type: Array,
        default: null,
      },
      logo: {
        type: String,
        default: null,
      },
    },

    data() {
      return {
        ...initialData,
        initialData,
        stateKey: 'brands',
      }
    },

    computed: {
      ...mapState({
        tenantsState: (state) => state.tenants,
        fetchedResources: (state) => state.fetchedResources,
      }),

      logoPreview() {
        return this.getLogoPreview(this.logoValue, this.storedLogo)
      },
    },

    async mounted() {
      if (this.edition) {
        this.idValue = this.id
        this.displayNameValue = this.displayName
        this.analyticsNameValue = this.analyticsName
        this.tenantValues = this.tenants
        this.storedLogo = this.logo
      }

      await this.loadResources({ resourceNames: [RESOURCES.TENANTS] })
    },

    methods: {
      ...mapActions(['loadResources']),

      getLogoPreview(logoValue, logo) {
        if (logoValue) {
          return URL.createObjectURL(logoValue)
        }

        if (logo !== null) {
          return formatAssetLink(logo)
        }

        return null
      },

      async onSubmit() {
        this.appLoading(true)

        try {
          const finalObject = {
            logo: this.storedLogo,
          }


          if (this.tenantValues?.length > 0 || this.tenants?.length > 0) {
            finalObject.tenants =
              this.tenantValues?.map((tenant) =>
                getDocumentReference(`tenants/${tenant.id}`),
              ) ?? []
          }

          if (this.logoValue) {
            finalObject.logo = await this.uploadImageAndGetPath(
              this.logoValue,
              this.id,
              'logo',
            )
          }

          const stateFinalObject = {
            ...finalObject,
            tenants: this.tenantValues,
            id: this.edition ? this.id : this.idValue,
            analyticsName: this.edition
              ? this.analyticsName
              : this.analyticsNameValue,
            displayName: this.edition
              ? this.displayName
              : this.displayNameValue,
          }

          if (this.edition) {
            await updateRef(this.docPath, finalObject)
            this.updateRootStateValue({
              id: this.id,
              newData: stateFinalObject,
              key: this.stateKey,
            })
          } else {
            const createFinalObject = {
              ...finalObject,
              analyticsName: this.analyticsNameValue,
              displayName: this.displayNameValue,
            }
            await addDocToCollection(
              null,
              this.stateKey,
              createFinalObject,
              null,
              this.idValue,
            )
            this.addRootStateValue({
              newData: stateFinalObject,
              key: this.stateKey,
            })
          }

          this.$emit('done')
          this.setMessageInformations({
            message: `Brand ${this.displayNameValue} has been successfully ${this.edition ? "updated" : "created"}`,
            state: 'success',
          })
        } catch (error) {
          this.handleErrors({ error })
        } finally {
          this.appLoading(false)
        }
      },

      async uploadImageAndGetPath(logoValue, idValue, fileName) {
        const extension = logoValue?.name?.split('.').pop()
        const path = `brands/${idValue}/${fileName}.${extension}`
        try {
          const result = await Functions.uploadImage(path, logoValue)
          return result.path
        } catch (error) {
          this.handleErrors({ error })
        }
      },

      deleteLogo(storedLogoFieldName, logoValueFieldName) {
        this[storedLogoFieldName] = null
        this[logoValueFieldName] = null
      },

      redirectToLogoPage() {
        this.$router.push({
          name: 'brand-logos-page',
          params: { brandId: this.id },
        })
      },
    },
  }
</script>
