<template>
  <List
    v-if="
      loading ||
      itemsListLoading ||
      (appConfiguration && hasAirtableConfiguration)
    "
    :is-tile-list="true"
    :items="filteredItems"
    :loading="loading || itemsListLoading"
    @replace="handleItemsListModals('modal-search-replace-item', ...arguments)"
    @delete="handleDeletionModal([...arguments], true)"
    @deleteSections="handleDeletionModal([...arguments], true, true)"
    @edit="handleItemsListModals('modal-item-content', ...arguments)"
    @showDebugInformation="
      handleItemsListModals('modal-item-debug-info', ...arguments)
    "
    @show="setModalItem"
  >
    <template #top>
      <b-row>
        <b-col
          sm="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Search item (sku, name, brand)"
            label-for="search-items"
          >
            <b-input-group>
              <b-input-group-prepend is-text>
                <b-icon icon="search" />
              </b-input-group-prepend>
              <b-form-input
                id="search-items"
                type="search"
                :value="searchItemsInput"
                :disabled="loading"
                @input="searchItem"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2">
          <FireDetails v-if="airtableId">
            <template #summary>
              <b-icon
                icon="hash"
                class="mr-1"
              />
              Airtable Id
            </template>
            {{ airtableId }}
          </FireDetails>
        </b-col>
        <b-col md="4">
          <FireDetails v-if="airtableTables">
            <template #summary>
              <b-icon
                icon="list"
                class="mr-1"
              />
              Views
            </template>
            <ul>
              <li
                v-for="airtableTable in airtableTables"
                :key="'view-' + airtableTable.table"
              >
                {{ airtableTable.table }}: {{ airtableTable.view }}
              </li>
            </ul>
          </FireDetails>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <CollapseContainer
            v-if="
              detailedItems &&
              detailedItems.unknownBrands &&
              detailedItems.unknownBrands.length > 0
            "
            class="mt-3"
            title="Error - The following brands from airtable don't match any brand in Firecamp"
            variant="warning"
            border
            :open="false"
          >
            <p class="m-3">
              During the synchronization with Airtable some items had the
              following brand_code that didn't match any brand in Firecamp.
            </p>
            <ul>
              <li
                v-for="brand in detailedItems.unknownBrands"
                :key="`airtable-unknown-brand-${brand}`"
                class="error-brands_list-element"
              >
                {{ brand }}
              </li>
            </ul>
          </CollapseContainer>
        </b-col>
      </b-row>
    </template>
    <template #main-action>
      <b-button
        v-b-tooltip.hover
        title="Create a new item in Firecamp"
        variant="primary"
        :disabled="loading"
        @click="handleItemsListModals('modal-item-content')"
      >
        <b-icon icon="plus-circle" />
        CREATE NEW ITEM
      </b-button>
      <b-button
        v-b-tooltip.hover
        title="Sync items between Airtable and Firecamp"
        variant="success"
        :disabled="loading"
        @click="sync"
      >
        <b-icon icon="arrow-repeat" />
        SYNC
      </b-button>
      <b-button
        v-if="firecampSurplus && firecampSurplus.length > 0"
        v-b-tooltip.hover
        variant="danger"
        title="Remove items that are not in Airtable"
        :disabled="loading"
        @click="
          handleDeletionModal(
            firecampSurplus.map((item) => item.id),
            true,
          )
        "
      >
        <b-icon icon="trash" />
        CLEAN
      </b-button>
      <b-button
        v-b-tooltip.hover
        title="Search and replace item"
        :disabled="loading"
        @click="handleItemsListModals('modal-search-replace-item', null)"
      >
        <b-icon icon="search" />
        SEARCH AND REPLACE
      </b-button>
      <b-button
        v-if="airtableLink"
        :href="airtableLink"
        target="_blank"
        :disabled="loading"
        variant="primary"
      >
        <b-icon
          icon="link45deg"
          class="mr-1"
        />
        AIRTABLE
      </b-button>
      <b-button
        v-if="canFlushCache"
        :disabled="loading"
        title="Flush cache of the Product Api"
        @click="flushCacheProductAPI"
      >
        FLUSH CACHE
      </b-button>
    </template>

    <SearchReplaceItemModal
      :visible.sync="showModalSearchReplaceItem"
      :initial-selected-item="item"
      :all-items="filteredItems"
      @success="searchReplaceSuccess"
      @cancel="
        showModalSearchReplaceItem = false
        item = null
      "
    />

    <ItemDebugInfoModal
      v-if="showModalItemDebugInfo"
      id="modal-item-debug-info"
      :item="item"
      :debug-info="itemDebugInfo"
      @hidden="handleItemsListModals('modal-item-debug-info')"
    />

    <b-modal
      v-if="showModalItemContent"
      id="modal-item-content"
      v-model="showModalItemContent"
      hide-footer
      :title="item ? 'Item edit' : 'Item creation'"
      size="lg"
      @close="handleModal('modal-item-content')"
    >
      <ItemContent
        :item="item"
        included
        :edition="!!item"
        @delete="handleDeletionModal([...arguments])"
        @close="handleModal('modal-item-content')"
        @success="itemEditSuccess()"
      />
    </b-modal>

    <b-modal
      v-if="profileModalItem && showProfilesModal"
      id="corresponding-profiles"
      v-model="showProfilesModal"
      title="Profiles List"
      size="xl"
      :hide-footer="true"
      @hidden="profileModalItem = null"
    >
      <Tile
        v-for="item in profileModalItem"
        :key="item.id"
        v-bind="item"
        :list="true"
      />
    </b-modal>

    <ConfirmationModal
      v-if="showModalDeleteConfirmationDefault && itemsToDelete.length > 0"
      :item-id="deleteId"
      :message="deleteMessage"
      @deleteConfirmed="deleteItems"
      @cancel="handleModal('modal-delete-confirmation-default')"
    >
      <b-alert
        v-if="warning"
        variant="warning"
        show
      >
        <strong>Warning !</strong>
        <hr />
        {{ warning }}
      </b-alert>
    </ConfirmationModal>
  </List>
  <section
    v-else
    class="item-list--error"
  >
    <p>Missing airtableApi configuration in appconfigurations.</p>
    <p>Please add the correct configuration to show this screen.</p>
  </section>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import { deleteRef } from '@/service/FirebaseService'
  import { PrescriptionMixin } from '@/mixins/PrescriptionMixin.js'
  import Functions from '@/firebase/functions'
  import { compareValues } from '@/service/UtilsService'
  import { normalizeProfile } from '@/service/ProfileService'
  import { generateShowDataValues } from '@/utils/ModalHelpers'
  import { ModalMixin } from '@/mixins/ModalMixin'
  import {
    PRESCRIPTION_ERROR_MISSING_AIRTABLEAPI,
    PRESCRIPTION_ERROR_AIRTABLE_NOT_AUTHORIZED,
  } from '@/constants/ErrorConstants'

  import List from '@/components/list/List'
  import Tile from '@/components/tile/Tile'
  import ConfirmationModal from '@/components/modals/ConfirmationModal'
  import SearchReplaceItemModal from '@/components/modals/SearchReplaceItemModal'
  import ItemDebugInfoModal from '@/components/modals/ItemDebugInfoModal.vue'
  import ItemContent from '@/components/prescription/ItemContent'
  import FireDetails from '@/components/containers/FireDetails'
  import CollapseContainer from '@/components/containers/CollapseContainer'

  import { RIGHTS } from '@/constants/UserConstants'

  const initialData = {
    item: null,
    itemsToDelete: [],
    warning: null,
    deleteSections: false,
    deleteItem: false,
    importedItems: [],
  }

  const showModals = generateShowDataValues([
    'modal-delete-confirmation-default',
    'modal-search-replace-item',
    'modal-item-debug-info',
    'modal-item-content',
  ])

  // Timer identifier.
  let typingTimer

  export default {
    name: 'ItemsList',

    components: {
      List,
      Tile,
      ConfirmationModal,
      SearchReplaceItemModal,
      ItemDebugInfoModal,
      ItemContent,
      FireDetails,
      CollapseContainer,
    },

    mixins: [PrescriptionMixin, ModalMixin],

    data() {
      return {
        searchItemsInput: null,
        detailedItems: {},
        ...JSON.parse(JSON.stringify(initialData)),
        ...JSON.parse(JSON.stringify(showModals)),
        showProfilesModal: false,
        profileModalItem: null,
        itemsListLoading: false,
      }
    },

    computed: {
      ...mapState({
        stateItems: (state) => state.prescriptions.items,
        profiles: (state) => state.prescriptions.profiles,
      }),

      ...mapGetters(['canUserDo']),

      canFlushCache() {
        return this.canUserDo(RIGHTS.PRESCRIPTION_ITEMS_LIST_FLUSH_CACHE)
      },

      airtableLink() {
        return this.detailedItems?.airtableConfig?.airtableId
          ? `https://airtable.com/${this.detailedItems?.airtableConfig?.airtableId}`
          : null
      },

      airtableId() {
        return this.detailedItems?.airtableConfig?.airtableId ?? null
      },

      airtableTables() {
        return this.detailedItems?.airtableConfig?.airtableTables ?? null
      },

      appConfiguration() {
        return this.currentRoute?.data?.appconfigurations ?? null
      },

      hasAirtableConfiguration() {
        if (!this.appConfiguration || !this.appConfiguration.id) return null
        return (
          Object.prototype.hasOwnProperty.call(
            this.appConfiguration,
            'airtableApi',
          ) ?? false
        )
      },

      deleteId() {
        if (!this.itemsToDelete) return null
        return this.itemsToDelete?.length > 1
          ? 'delete-confirmation'
          : this.itemsToDelete[0]
      },

      deleteMessage() {
        if (!this.itemsToDelete) return null
        return this.itemsToDelete?.length > 1
          ? `Are you sure you want to delete theses ${this.itemsToDelete.length} items ?`
          : null
      },

      searchItemsList() {
        const firecampSurplus = this.detailedItems.firecampSurplus ?? []
        const itemsListForSearch = firecampSurplus.concat(
          this.detailedItems?.firecampAirtableMerge,
        )
        return itemsListForSearch
          .map((item) => {
            const hitCount =
              this.detailedItems?.sectionsItems[item.id]?.total ?? 0
            if (hitCount > 0) return item
          })
          .filter(Boolean)
          .sort(compareValues('label'))
      },

      replaceItemsList() {
        return this.importedItems
          ?.concat(this.detailedItems?.firecampAirtableMerge)
          .sort(compareValues('label'))
      },

      notInFirecampItems() {
        return this.detailedItems?.notInFirecampItems?.length > 0
          ? this.formatHighlightedItems(
              this.detailedItems.notInFirecampItems,
              'Not in Firecamp',
              'warning',
            )
          : []
      },

      firecampSurplus() {
        return this.detailedItems?.firecampSurplus?.length > 0
          ? this.formatHighlightedItems(
              this.detailedItems.firecampSurplus,
              'Not in Airtable',
              'error',
            ).sort((a, b) => b.details.hitCount - a.details.hitCount)
          : []
      },

      filteredItems() {
        if (!this.detailedItems) return []
        let importedItems = []
        let filteredItems = this.formatItems(this.detailedItems)
        if (this.importedItems?.length > 0) {
          importedItems = this.formatHighlightedItems(
            this.importedItems,
            'Newly added',
            'success',
          )
          filteredItems = filteredItems?.filter(
            (filteredItem) =>
              !importedItems.find((item) => item.id === filteredItem.id),
          )
        }

        filteredItems = importedItems
          .concat(this.firecampSurplus)
          .concat(this.notInFirecampItems)
          .concat(filteredItems)

        if (this.searchItemsInput) {
          filteredItems =
            this.searchItemsInput?.length > 0
              ? filteredItems.filter(
                  (item) =>
                    item?.label
                      ?.toLowerCase()
                      .includes(this.searchItemsInput) ||
                    item?.details?.sku
                      ?.toLowerCase()
                      .includes(this.searchItemsInput) ||
                    item?.details?.brands
                      ?.toLowerCase()
                      .includes(this.searchItemsInput),
                )
              : filteredItems
        }

        return filteredItems
      },

      itemDebugInfo() {
        const itemFromState = this.item
          ? this.stateItems?.find((item) => item?.sku === this.item.sku)
          : false
        return itemFromState.debugInfo ?? {}
      },
    },

    watch: {
      async hasAirtableConfiguration(newValue) {
        if (newValue) await this.sync()
      },

      profileModalItem(newItem) {
        if (newItem !== null) this.showProfilesModal = true
      },
    },

    async mounted() {
      await this.sync()
    },

    methods: {
      ...mapActions(['getItems', 'flushProductApi']),

      async init() {
        if (this.currentRouteId) {
          try {
            this.detailedItems = await Functions.getDetailedItemsList(
              this.currentRouteId,
            )
          } catch (error) {
            this.handleErrors({ error })
          }
        }
      },

      async airtableImport() {
        try {
          const airtableImport = await Functions.importItemsFromAirtable({
            appRouteId: this.currentRouteId,
          })
          if (!airtableImport.success) throw airtableImport
          if (airtableImport?.itemsImported?.length > 0)
            this.importedItems = airtableImport.itemsImported
          const message =
            airtableImport.totalItemsImported === 0
              ? 'No items imported. Everything is synced.'
              : `${airtableImport.totalItemsImported} items imported`

          this.setMessageInformations({
            message,
            ...(airtableImport.totalItemsImported > 0 && { state: 'success' }),
          })
        } catch (error) {
          if (error?.error?.statusCode === 403) {
            this.handleErrors({
              code: PRESCRIPTION_ERROR_AIRTABLE_NOT_AUTHORIZED,
            })
          } else if (
            error?.error?.code === 500 ||
            error?.error?.statusCode === 500
          ) {
            this.handleErrors({ code: PRESCRIPTION_ERROR_MISSING_AIRTABLEAPI })
          } else if (error?.error) {
            this.handleErrors({ error: error.error })
          } else {
            this.handleErrors({ error })
          }
        }
      },

      formatHighlightedItems(items, reason, variant) {
        return items.map((item) => {
          const profiles = this.getProfilesByItemID(item.id)
          const hitCount =
            this.detailedItems?.sectionsItems?.[item.id]?.total ?? 0
          const quickActionButton = [
            ...(hitCount > 0
              ? [
                  {
                    label: 'Search and replace item',
                    icon: 'search',
                    variant: 'info',
                    onClick: ['replace', item],
                  },
                ]
              : []),
            ...(variant === 'error'
              ? [
                  {
                    label: 'Delete item',
                    icon: 'trash',
                    variant: 'danger',
                    divider: hitCount > 0,
                    onClick: ['delete', item.id],
                  },
                ]
              : []),
            ...(hitCount < 1
              ? []
              : [
                  {
                    label: 'Delete related sections',
                    icon: 'trash',
                    variant: 'danger',
                    divider: true,
                    onClick: ['deleteSections', item.id],
                  },
                ]),
          ]
          const additionalActions = []

          if (profiles?.length > 0) {
            const profileModalItem = this.formatedProfiles(profiles)

            additionalActions.push({
              label: 'Show profiles',
              icon: 'eye-fill',
              tooltip: 'Show profiles',
              onClick: ['show', profileModalItem],
            })
          }

          return {
            id: item.id,
            label: item.label,
            highlighted: true,
            hightlightVariant: variant,
            details: {
              sku: item.sku,
              brands: item.brand,
              hitCount,
              ...(variant && { [variant]: reason }),
            },
            ...(quickActionButton?.length > 0 && { quickActionButton }),
            ...(additionalActions?.length > 0 && { additionalActions }),
          }
        })
      },

      formatItems(items) {
        if (items?.firecampAirtableMerge?.length) {
          return items.firecampAirtableMerge
            .map((item) => {
              const profiles = this.getProfilesByItemID(item.id)
              const hitCount =
                this.detailedItems?.sectionsItems[item.id]?.total ?? 0
              const isContentItem = ['url', 'content'].includes(item.type)
              const isProductItem = item.type == 'product'
              const details = {
                ...(!isContentItem && item.sku && { sku: item.sku }),
                ...(!isContentItem && item.brand && { brands: item.brand }),
                ...(isContentItem &&
                  item.description && { description: item.description }),
                ...(isContentItem && item.type && { type: item.type }),
                hitCount,
                ...(!isContentItem && { success: 'Synchronized!' }),
              }
              const additionalActions = []

              if (profiles?.length > 0) {
                const profileModalItem = this.formatedProfiles(profiles)

                additionalActions.push({
                  label: 'Show profiles',
                  icon: 'eye-fill',
                  tooltip: 'Show profiles',
                  onClick: ['show', profileModalItem],
                })
              }

              const quickActionButton = [
                ...(isContentItem
                  ? [
                      {
                        label: 'Edit item',
                        icon: 'pencil',
                        variant: 'success',
                        onClick: ['edit', item],
                      },
                    ]
                  : []),
                ...(hitCount > 0
                  ? [
                      {
                        label: 'Search and replace item',
                        icon: 'search',
                        variant: 'info',
                        divider: isContentItem,
                        onClick: ['replace', item],
                      },
                    ]
                  : []),
                ...(isContentItem
                  ? [
                      {
                        label: 'Delete item',
                        icon: 'trash',
                        variant: 'danger',
                        divider: hitCount > 0,
                        onClick: ['delete', item.id],
                      },
                    ]
                  : []),
                ...(isProductItem
                  ? [
                      {
                        label: 'Show productAPI debug information',
                        icon: 'bug-fill',
                        variant: 'warning',
                        onClick: ['showDebugInformation', item],
                      },
                    ]
                  : []),
                ...(hitCount < 1
                  ? []
                  : [
                      {
                        label: 'Delete related sections',
                        icon: 'trash',
                        variant: 'danger',
                        divider: true,
                        onClick: ['deleteSections', item.id],
                      },
                    ]),
              ]

              return {
                id: item.id,
                label: item.label,
                details,
                profilesLinked: profiles ?? [],
                ...(quickActionButton?.length > 0 && { quickActionButton }),
                ...(additionalActions?.length > 0 && { additionalActions }),
              }
            })
            .sort((a, b) => b.details.hitCount - a.details.hitCount)
        }
        return []
      },

      getProfilesByItemID(itemID) {
        try {
          if (!this.detailedItems?.sectionsItems) return null
          const sectionsItemsKeys = Object.keys(
            this.detailedItems?.sectionsItems,
          )
          if (!sectionsItemsKeys || !sectionsItemsKeys?.includes(itemID))
            return null
          const profiles = this.detailedItems?.sectionsItems?.[
            itemID
          ]?.sectionsPaths
            ?.map((sectionsPath) => {
              const arrayPath = sectionsPath?.split('/')
              const profileID = arrayPath?.[arrayPath?.length - 3]
              const profile = this.profiles?.find(
                (profile) => profile?.id === profileID,
              )

              if (profile) return profile
            })
            .filter(Boolean)

          return profiles?.length > 0 ? profiles : null
        } catch (error) {
          console.error(error)
        }
      },

      async deleteItems() {
        this.itemsListLoading = true

        try {
          await Promise.all(
            this.itemsToDelete.map(async (uid) => {
              await Functions.searchReplaceItem(this.appDetails.id, uid)
              // Delete the main item.
              if (!this.deleteItem) {
                await deleteRef(`${this.prescription}/items/${uid}`)
              }
            }),
          )

          await this.init()

          this.setMessageInformations({
            message: this.deleteItem ? 'Sections removed' : 'Item removed.',
            state: 'success',
          })
        } catch (error) {
          this.handleErrors({ error })
        } finally {
          this.itemsListLoading = false
        }
      },

      reset() {
        Object.keys(initialData).forEach((key) => {
          this.$data[key] = initialData[key]
        })
      },

      async searchReplaceSuccess(numberOfUpdatedSections) {
        this.handleItemsListModals('modal-search-replace-item')
        this.setMessageInformations({
          message: `${numberOfUpdatedSections} sections updated.`,
          state: 'success',
        })
        await this.init()
      },

      async itemEditSuccess() {
        this.handleModal('modal-item-content')
        await this.sync()
      },

      async sync() {
        if (!this.appConfiguration) return
        this.itemsListLoading = true
        if (this.hasAirtableConfiguration) {
          try {
            await this.airtableImport()
            // If there is new items that are imported we reload the items list.
            if (this.importedItems?.length > 0) {
              await this.getItems().catch((error) => {
                console.error(error)
              })
            }
            await this.init()
          } finally {
            this.itemsListLoading = false
          }
        } else if (this.hasAirtableConfiguration === false) {
          this.handleErrors({ code: PRESCRIPTION_ERROR_MISSING_AIRTABLEAPI })
          this.itemsListLoading = false
        }
      },

      handleItemsListModals(modalId, item = null) {
        this.reset()
        this.item = item
        this.handleModal(modalId)

        if (item) {
          this.setWarning(item)
        }
      },

      handleDeletionModal(
        itemsIds,
        isSectionDeletion = false,
        deleteOnlySection = false,
      ) {
        this.reset()
        this.handleModal('modal-delete-confirmation-default')
        this.itemsToDelete = itemsIds
        this.deleteSections = isSectionDeletion
        this.deleteItem = deleteOnlySection
      },

      setWarning(item) {
        const filteredItem = this.filteredItems.find(
          (filteredItem) => filteredItem.id === item.id,
        )
        if (filteredItem?.details?.hitCount > 0) {
          this.warning = `\r Item ${filteredItem?.label} with sku ${filteredItem.details.sku} is used ${filteredItem.details.hitCount} times.`
        }
      },

      searchItem(input) {
        clearTimeout(typingTimer)
        typingTimer = setTimeout(() => {
          this.searchItemsInput = input.toLowerCase()
        }, 500)
      },

      setModalItem(profileModalItem) {
        this.profileModalItem = profileModalItem
      },

      formatedProfiles(profiles) {
        return profiles.map((profile) =>
          normalizeProfile(profile, this.$route?.params?.app_id),
        )
      },

      async flushCacheProductAPI() {
        this.itemsListLoading = true
        try {
          await this.flushProductApi()
          this.setMessageInformations({
            message: 'Product API flushed.',
            state: 'success',
          })
        } catch (error) {
          this.handleErrors({ error })
        } finally {
          this.itemsListLoading = false
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .item-list--error {
    margin: 5rem auto;
    color: var(--danger);
    font-size: 1.5rem;
    text-align: center;
  }

  .error-brands_list-element {
    &:first-child {
      margin-top: 15px;
    }

    color: var(--app-background-font-color);
  }
</style>
