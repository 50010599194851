<template>
  <div>
    <ProfilesListsProfilesTable
      :profiles="profiles"
      :loading="!isPrescriptionLoaded || loading"
      :actions="actions"
      @criteriaClick="addToCriteriasFilter"
    >
      <template #after-search>
        <b-form-group>
          <label>Filter by criterias</label>
          <b-form-input
            v-if="!isPrescriptionLoaded"
            class="loading"
            disabled
          />
          <CriteriasMultiselect
            v-else
            :values.sync="criteriasFilters"
            :options="criterias"
            display-as-tag
          />
        </b-form-group>

        <b-form-group>
          <label>Filter by Events or Steps</label>
          <b-form-input
            v-if="!isPrescriptionLoaded || loading"
            class="loading"
            disabled
          />
          <Multiselect
            v-else
            v-model="eventsStepsFilter"
            placeholder="Select the Events and steps"
            track-by="id"
            label="stepLabel"
            select-label=""
            deselect-label="Remove"
            group-values="steps"
            group-label="eventLabel"
            :group-select="true"
            :options="eventsStepsFilterOptions"
            multiple
          />
        </b-form-group>

        <b-form-group label="Profiles filters">
          <b-form-checkbox
            v-model="onlyEmptySections"
            inline
            @input="loadEmptyProfile"
          >
            Filter by empty sections
          </b-form-checkbox>

          <b-form-checkbox
            v-model="onlyDefaultProfiles"
            inline
          >
            Filter default profile(s)
          </b-form-checkbox>
        </b-form-group>
      </template>
      <template #quick-actions>
        <b-button
          v-if="!isAIPoweredPrescription"
          :to="{ name: 'profile-creation-edition' }"
          variant="primary"
          :disabled="!isPrescriptionLoaded"
        >
          <b-icon icon="plus-circle" />
          CREATE NEW PROFILE
        </b-button>
        <b-button
          class="ml-2"
          variant="primary"
          :disabled="!isPrescriptionLoaded"
          @click="resetProfilesHitsCount"
        >
          <b-icon icon="arrow-clockwise" />
          RESET HIT COUNTS
        </b-button>
        <b-button
          v-if="canDeleteAll"
          class="ml-2"
          variant="danger"
          :disabled="!isPrescriptionLoaded"
          @click="onDeleteAllProfiles"
        >
          <b-icon icon="trash" />
          DELETE ALL PROFILES
        </b-button>
      </template>
    </ProfilesListsProfilesTable>

    <ConfirmationModal
      v-if="profilePathToDelete"
      :item-id="profileIdToDelete"
      @cancel="resetProfileValues()"
      @close="resetProfileValues()"
      @deleteConfirmed="deleteProfile()"
    />

    <ProfilesListsDeleteAllProfilesModal
      v-model="deleteAllProfilesConfirmation"
    />
  </div>
</template>

<script>
  import { mapState, mapGetters, mapActions } from 'vuex'

  import * as FirebaseService from '@/service/FirebaseService'
  import Functions from '@/firebase/functions'

  import { RIGHTS } from '@/constants/UserConstants'
  import { PRESCRIPTION_ERROR_GET_PROFILES_FROM_STEPS_FAILED } from '@/constants/ErrorConstants'

  import Multiselect from '@/components/form/Multiselect.vue'
  import CriteriasMultiselect from '@/components/criterias-multiselect/CriteriasMultiselect'
  import ConfirmationModal from '@/components/modals/ConfirmationModal'
  import ProfilesListsDeleteAllProfilesModal from './ProfilesListsDeleteAllProfilesModal'
  import ProfilesListsProfilesTable from './ProfilesListsProfilesTable.vue'

  export default {
    name: 'ProfilesListsProfiles',

    components: {
      Multiselect,
      CriteriasMultiselect,
      ConfirmationModal,
      ProfilesListsDeleteAllProfilesModal,
      ProfilesListsProfilesTable,
    },

    data() {
      return {
        // Profile
        onlyEmptySections: false,
        onlyDefaultProfiles: false,
        deleteAllProfilesConfirmation: false,

        profilePathToDelete: null,
        profileIdToDelete: null,
        criteriasFilters: [],

        // Events Steps
        eventsStepsFilter: [],
        profilesUsedInEventsSteps: [],
        loading: false,

        actions: {
          key: 'actions',
          label: 'Actions',
          menuActions: [
            {
              label: 'Edit',
              to: ({ id }) => ({
                name: 'profile-creation-edition',
                params: { id },
              }),
              icon: 'pencil-square',
            },
            {
              label: 'Duplicate',
              variant: 'success',
              icon: 'file-earmark-plus',
              onClick: ({ id }) => this.duplicateProfile(id),
              hide: () => this.isAIPoweredPrescription,
            },
            {
              label: 'Delete',
              variant: 'danger',
              icon: 'trash',
              divider: !this.isAIPoweredPrescription,
              onClick: ({ id }) => this.showDeleteModal(id),
              hide: () => this.isAIPoweredPrescription,
            },
          ],
        },
      }
    },

    computed: {
      ...mapState({
        appRouteId: (state) => state.approutes.currentRoute?.id,
        criterias: (state) => state.prescriptions.criterias,
        rawProfiles: (state) => state.prescriptions.profiles,
        prescriptionView: (state) => state.prescriptions.view.events,
        prescriptionEvents: (state) => state.prescriptions.events,
        prescriptionSteps: (state) => state.prescriptions.steps,
        isPrescriptionLoaded: (state) => state.prescriptions.isLoaded,
      }),

      ...mapGetters([
        'canUserDo',
        'getAppRoute',
        'prescriptionPath',
        'isAIPoweredPrescription',
      ]),

      profiles() {
        return this.rawProfiles.filter((profile) => {
          let result
          if (this.criteriasFilters.length > 0) {
            result = this.criteriasFilters.some((criteriaFilter) => {
              return (
                profile.inclusiveCriterias?.includes(criteriaFilter.id) ||
                profile.inclusiveStrictCriterias?.includes(criteriaFilter.id) ||
                profile.exclusiveCriterias?.includes(criteriaFilter.id) ||
                profile.inclusiveRootItemCriterias?.includes(criteriaFilter.id)
              )
            })
            if (!result) return false
          }

          if (this.profilesUsedInEventsSteps.length > 0) {
            result = this.profilesUsedInEventsSteps.includes(profile.id)

            if (!result) return false
          }

          if (this.onlyEmptySections && !profile.withEmptySections) return false
          if (this.onlyDefaultProfiles && !profile.isDefault) return false
          return true
        })
      },

      eventsStepsFilterOptions() {
        return this.prescriptionView?.map((viewEvent) => {
          const event = this.prescriptionEvents?.find(
            (e) => e.id === viewEvent.eventId,
          )
          return {
            ...event,
            eventLabel: `${event?.label} (${event?.id})`,
            steps: this.formatStepsOptionsFromIds(
              viewEvent?.steps?.map((step) => step?.stepId),
            ),
          }
        })
      },

      canDeleteAll() {
        return (
          this.canUserDo(RIGHTS.PRESCRIPTION_PROFILES_DELETE_ALL) &&
          this.profiles?.length > 0
        )
      },
    },

    watch: {
      isPrescriptionLoaded(newValue) {
        if (newValue) {
          const stepsFilter = this.$route.query?.stepsFilter
          if (!stepsFilter || this.eventsStepsFilter?.length > 0) return

          this.eventsStepsFilter =
            (!Array.isArray(stepsFilter)
              ? this.formatStepsOptionsFromIds([stepsFilter])
              : this.formatStepsOptionsFromIds(stepsFilter)) ?? []
        }
      },

      eventsStepsFilter: {
        async handler(newFilters) {
          if (!newFilters?.length > 0) return

          this.loading = true
          try {
            const stepsFilterIds = newFilters.map((step) => step.id)
            const filteredProfilesResult = await Functions.getProfilesFromSteps(
              this.appRouteId,
              stepsFilterIds,
            )
            if (!filteredProfilesResult.success) {
              this.handleErrors({
                code: PRESCRIPTION_ERROR_GET_PROFILES_FROM_STEPS_FAILED,
              })
              return
            }
            this.profilesUsedInEventsSteps =
              filteredProfilesResult.profilesInSteps.map(
                (profile) => profile.profileId,
              )
          } catch (error) {
            this.handleErrors(error)
          } finally {
            if (this.isPrescriptionLoaded) this.loading = false
          }
        },
        immediate: true,
      },
    },

    async mounted() {
      await this.bindAppRoutes()
    },

    methods: {
      ...mapActions([
        'getEmptySectionsProfiles',
        'resetProfilesHit',
        'bindAppRoutes',
      ]),

      /**
       * if val true, search all empty sections
       * if val false, do nothing
       * @param {boolean} val
       */
      async loadEmptyProfile(val) {
        if (!val) {
          return
        }
        this.loading = true
        try {
          await this.getEmptySectionsProfiles()
        } catch (error) {
          this.handleErrors({ error })
        } finally {
          this.loading = false
        }
      },

      /**
       * Show a confirmation modal and delete the selected profile if ok clicked.
       *
       * @param {Object} profilePath - A firebase profile reference.
       */
      async deleteProfile() {
        this.loading = true

        try {
          await FirebaseService.deleteRef(this.profilePathToDelete)
          this.setMessageInformations({
            message: 'Profile deleted successfully.',
            state: 'success',
          })

          this.resetProfileValues()
        } catch (error) {
          this.handleErrors({ error })
        } finally {
          this.loading = false
        }
      },

      /**
       * Duplicate a profile.
       *
       * @param {Object} profilePath - A firebase profile reference.
       */
      async duplicateProfile(profileId) {
        this.loading = true
        return Functions.duplicateDocument(
          this.prescriptionPath + '/profiles/' + profileId,
        )
          .catch((error) => {
            this.handleErrors({ error })
          })
          .finally(() => (this.loading = false))
      },

      /**
       * Reset all hits counts of each profiles.
       */
      async resetProfilesHitsCount() {
        this.loading = true
        try {
          await this.resetProfilesHit()
          this.setMessageInformations({
            message: 'All hits have been successfully reset',
            state: 'success',
          })
        } catch (error) {
          const errorMessage = error.message ?? error
          this.setMessageInformations({
            message: errorMessage,
            state: 'error',
          })
        } finally {
          this.loading = false
        }
      },

      /**
       * On click on the criterias, we add them to filter criterias.
       *
       * @param {Object} fil - A criteria object.
       */
      addToCriteriasFilter(fil) {
        if (!this.criteriasFilters.some((filter) => filter.id === fil.id))
          this.criteriasFilters.push(fil)
      },

      formatStepsOptionsFromIds(stepsIds) {
        return stepsIds?.map((stepId) => {
          const step = this.prescriptionSteps?.find((s) => s?.id === stepId)
          return {
            id: step?.id,
            stepLabel: `${step?.label} (${step?.id})`,
          }
        })
      },

      showDeleteModal(profileId) {
        this.profilePathToDelete =
          this.prescriptionPath + '/profiles/' + profileId
        this.profileIdToDelete = profileId
        this.$nextTick(() => {
          this.$bvModal.show('modal-delete-confirmation-default')
        })
      },

      resetProfileValues() {
        this.profileIdToDelete = null
        this.profilePathToDelete = null
      },

      onDeleteAllProfiles() {
        this.deleteAllProfilesConfirmation = true
      },
    },
  }
</script>
