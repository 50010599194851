<template>
  <div class="main-collection_container container-fluid">
    <TitleContainer
      v-if="!included"
      :title="title"
    />
    <b-form
      ref="app"
      class="app-form"
      @submit.prevent="onSubmit"
      @reset.prevent="onReset"
    >
      <b-form-group
        id="app-id-group"
        class="col-9 mb-3 pl-0"
        :state="appStates.id"
        label="Application ID:"
        label-for="display-name"
        description="All attached, no capital letters. Example: skindrv2, shadefinder"
        :invalid-feedback="errorMsg"
      >
        <b-form-input
          id="app-id"
          v-model="app.id"
          type="text"
          placeholder="Enter id..."
          data-valid="id"
          :state="appStates.id"
          :disabled="isAppLoading || edition"
          required
        />
      </b-form-group>

      <b-form-group
        id="display-name-group"
        class="col-9 mb-3 pl-0"
        :state="appStates.displayName"
        label="Application display name:"
        label-for="display-name"
        description="This will be the name displayed in Firecamp. Example: SkinDr, ShadeFinder"
        invalid-feedback="Only letters, can be seperated with spaces"
      >
        <b-form-input
          id="display-name"
          v-model="app.displayName"
          type="text"
          placeholder="Enter display name..."
          data-valid="displayName"
          :state="appStates.displayName"
          :disabled="isAppLoading"
          required
        />
      </b-form-group>

      <b-form-group
        id="description-group"
        class="col-9 pl-0"
        :state="appStates.description"
        label="Application description:"
        label-for="display-name"
        description="A little description of your awesome new app."
        invalid-feedback="A description is required"
      >
        <b-form-textarea
          id="description"
          v-model="app.description"
          placeholder="Enter description..."
          rows="3"
          max-rows="6"
          data-valid="description"
          :state="appStates.description"
          :disabled="isAppLoading"
          required
        />
      </b-form-group>
      <b-row>
        <div :class="included ? 'action-button_container' : 'mt-4 pl-0'">
          <b-button
            type="reset"
            variant="link"
            class="text-danger"
            :class="!included ? 'w-100' : ''"
            >{{ edition ? 'Delete' : 'Reset' }}</b-button
          >
          <SubmitButton
            :disabled="cantSubmit"
            :loading="isAppLoading"
            :class="included ? 'w-auto mb-auto ml-2' : ''"
          />
        </div>
      </b-row>
    </b-form>
  </div>
</template>

<script>
  import { updateRef } from '@/service/FirebaseService'

  import TitleContainer from '@/components/containers/TitleContainer'
  import SubmitButton from '@/components/form/SubmitButton'

  export default {
    name: 'CreateAppModal',

    components: {
      TitleContainer,
      SubmitButton,
    },

    props: {
      included: {
        type: Boolean,
        default: false,
      },
      edition: {
        type: Boolean,
        default: false,
      },
      id: {
        type: String,
        default: null,
      },
      displayName: {
        type: String,
        default: null,
      },
      description: {
        type: String,
        default: null,
      },
      meta: {
        type: Object,
        default: null,
      },
    },

    data() {
      return {
        app: {
          id: null,
          description: null,
          displayName: null,
        },
        appStates: {
          id: null,
          description: null,
          displayName: null,
        },
        errorMsg: null,
        idRegex: new RegExp(/^[a-z0-9]*$/),
        displayNameRegex: new RegExp(/^[a-zA-Z0-9 ]*$/),
        success: false,
      }
    },

    computed: {
      title() {
        return `${this.collectionSingular} ${
          this.edition ? 'Edition' : 'Creation'
        }`
      },

      cantSubmit() {
        return (
          [this.app.id, this.app.displayName].includes(null) ||
          [this.app.id, this.app.displayName].includes('') ||
          !this.appStates.id ||
          !this.appStates.displayName
        )
      },
    },

    watch: {
      app: {
        deep: true,
        handler(newValue) {
          this.appStates.id = newValue.id
            ? this.idRegex.test(newValue.id)
            : null
          this.appStates.displayName = newValue.displayName
            ? this.displayNameRegex.test(newValue.displayName)
            : null
        },
      },
    },

    created() {
      if (this.edition) {
        this.app.id = this.id
        this.app.displayName = this.displayName
        this.app.description = this.description
      }
    },

    methods: {
      resetValues() {
        this.app.id = null
        this.app.description = null
        this.app.displayName = null
      },

      async onSubmit() {
        this.appLoading(true)

        try {
          if (this.edition) {
            const finalObject = {
              displayName: this.app?.displayName,
              description: this.app?.description,
            }

            await updateRef(`applications/${this.id}`, finalObject)
          } else {
            // Create app
            const app = await this.$store.dispatch('createApp', {
              appDetails: this.app,
            })

            if (app.state == 'success') {
              this.success = true
            } else {
              this.appStates.id = false
              throw new Error('This ID already exists.')
            }
          }

          this.setMessageInformations({
            message: `Application ${
              this.app?.displayName
            } has been successfully ${this.edition ? 'updated' : 'created'}`,
            state: 'success',
          })

          this.$emit('done')
        } catch (error) {
          this.handleErrors({ error })
        } finally {
          this.appLoading(false)
        }
      },

      onReset() {
        if (this.edition && this.id) {
          this.$emit('delete', this.id)
        } else {
          this.resetValues()
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .form-container {
    padding: 1rem;
    margin: 1rem auto;
    position: relative;
    max-width: 550px;
  }
</style>
