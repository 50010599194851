<template>
  <b-modal
    :id="modalId"
    :visible="show"
    :title="edition ? 'Edit link' : 'Add link'"
    :cancel-variant="edition ? 'danger' : 'link'"
    :cancel-title="edition ? 'Unlink' : 'Cancel'"
    @change="$emit('update:show', $event)"
    @hidden="resetModal"
    @cancel="handleCancel"
    @ok="handleOk"
  >
    <b-form>
      <b-form-group
        label="Enter link's text:"
        :state="text.length > 0"
      >
        <b-form-input
          v-model="text"
          type="text"
          required
        />
      </b-form-group>

      <b-form-group
        :state="urlState"
        label="Enter link's url:"
        invalid-feedback="Please provide a valid url starting with http(s)://"
        :description="
          urlState === false
            ? ''
            : `Provide a valid url starting with http(s)://`
        "
      >
        <b-form-input
          v-model="url"
          type="url"
          :state="urlState"
          required
        />
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
  import { isValidUrl } from '@/utils/StringHelpers'

  export default {
    name: 'WysiwygLinkModal',

    model: {
      prop: 'show',
      event: 'update:show',
    },

    props: {
      show: {
        type: Boolean,
        default: false,
      },
      edition: {
        type: Boolean,
        default: false,
      },
      linkText: {
        type: String,
        default: 'link text',
      },
      linkUrl: {
        type: String,
        default: '',
      },
    },

    data() {
      return {
        modalId: 'modal-wysiwyg-link',
        text: '',
        url: '',
        urlState: null,
      }
    },

    watch: {
      linkText(newVal) {
        this.text = newVal
      },
      linkUrl(newVal) {
        this.url = newVal
      },
    },

    methods: {
      checkFormValidity() {
        this.urlState = isValidUrl(this.url)
        return this.urlState && this.text?.length > 0
      },

      resetModal() {
        this.url = ''
        this.text = ''
        this.$emit('update:show', false)
      },

      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()

        // Check form validity
        if (this.checkFormValidity()) {
          this.$emit('linkSaved', this.text, this.url)
        }
      },

      handleCancel(bvModalEvt) {
        if (this.edition) {
          bvModalEvt.preventDefault()
          this.$emit('unlink')
        }
      },
    },
  }
</script>
