<template>
  <b-modal
    id="modal-liability-pop-in"
    ref="modal"
    size="lg"
    title="Modiface Retailers - Liability"
    :modal-class="interactState ? 'modal-anim-interact' : ''"
    :content-class="redBorder ? 'modal-border-red' : ''"
    centered
    ok-only
    :footer-bg-variant="termsOfUseAccepted ? 'success' : 'light'"
    hide-header
    @hide="handleHide"
    @ok="handleOk"
  >
    <h1 class="text-center user-select-none">Welcome to Firecamp!</h1>
    <div class="body-content_popin-retailers pl-3 pt-3 pr-3 user-select-none">
      <p>
        Your access and use of ModiFace's Firecamp CMS Portal is subject to
        these
        <a
          href="/retailers-terms-of-use.html"
          target="_blank"
          rel="noopener noreferrer"
          class="text-info"
          @click="termsOfUseOpened = true"
        >
          Terms of Use
        </a>
        which form a legal agreement between ModiFace Inc. and you.
      </p>
      <p>
        Please read the Terms of Use carefully, as they affect your legal
        rights, remedies and obligations. By completing the account registration
        process and checking the box below, you fully accept and agree to comply
        with and be bound by, these Terms of Use.
      </p>
    </div>

    <div class="ml-3">
      <b-form-checkbox
        v-if="termsOfUseOpened"
        v-model="termsOfUseAccepted"
      >
        The Customer accepts and agrees to comply with the Terms of Use
      </b-form-checkbox>

      <p v-else>Please open and read the Terms Of Use to be able to accept.</p>
    </div>

    <template #modal-footer="{ ok }">
      <div class="d-flex justify-content-center w-100">
        <b-button
          variant="primary"
          :disabled="!termsOfUseAccepted"
          @click="ok()"
        >
          AGREE
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
  import { mapState } from 'vuex'
  import { updateRef } from '@/service/FirebaseService'

  export default {
    name: 'LiabilityPopIn',

    data() {
      return {
        termsOfUseAccepted: false,
        termsOfUseOpened: false,
        interactState: false,
        redBorder: false,
        accepted: false,
      }
    },

    computed: {
      ...mapState({
        userId: (state) => state.user.id,
      }),
    },

    methods: {
      async handleOk() {
        this.accepted = true
        await updateRef('/users/' + this.userId, {
          retailersTermsOfUseAccepted: true,
        })
        this.$emit('terms-accepted')
      },

      handleHide(event) {
        if (this.accepted) return
        event.preventDefault()
        this.setInteractionState()
      },

      setInteractionState() {
        this.interactState = true
        this.redBorder = true
        setTimeout(() => {
          this.interactState = false
        }, 100)
      },
    },
  }
</script>

<style lang="scss">
  .modal-anim-interact {
    animation: shakelateral 0.1s ease-in-out;
  }

  .modal-border-red {
    border: solid var(--danger) 2px !important;
  }

  .body-content_popin-retailers {
    line-height: 1.7;
    text-align: justify;
    font-weight: 400;
    font-size: 1.1rem;
  }
</style>
