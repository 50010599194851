<template>
  <MultistepForm
    v-model="formData"
    :title="title"
    :total-step-number="totalStepNumber"
    success-message="App version successfully created!"
    :steps="stepsComponents"
    :is-next-disabled="isNextDisabled"
    :is-submit-success="success"
    :step.sync="stepIndex"
    @submit="createAppVersion"
    @createAppVersionOk="isCreateAppVersionOk = $event"
  >
    <template #success>
      <NextSteps />
    </template>
  </MultistepForm>
</template>

<script>
  import { mapActions } from 'vuex'

  import MultistepForm from '@/components/multistep-form/MultistepForm'
  import NextSteps from '@/components/apps/forms/NextSteps'

  import {
    getDocumentsInCollection,
    getFirstActiveDocumentData,
  } from '@/service/FirebaseService'

  const initialData = {
    isCreate: true,
    appversion: {
      application: null,
      meta: {
        version: null,
      },
      prescription: false,
      releaseNotes: null,
    },
    mode: 'json',
    firehierarchy: null,
    firecomponents: null,
    firedefaultcontent: {
      appconfigurations: null,
      appcontent: null,
      appcustomisations: null,
      appresources: null,
    },
  }

  export default {
    name: 'CreateAppVersion',

    components: {
      MultistepForm,
      NextSteps,
    },

    data() {
      return {
        stepsLabels: [
          'Application Version',
          'Application Hierarchy',
          'Application Components',
          'Application Default Content',
        ],
        success: false,
        stepIndex: 1,
        formData: JSON.parse(JSON.stringify(initialData)),
        isCreateAppVersionOk: false,
      }
    },

    computed: {
      title() {
        return 'Create App Version'
      },

      totalStepNumber() {
        return this.formData.mode === 'dynamic' ? 2 : 4
      },

      stepsComponents() {
        return [
          'CreateAppVersionForm',
          'CreateAppHierarchy',
          'CreateAppComponents',
          'CreateAppDefaultContent',
        ].map((component, index) => {
          return {
            id: component,
            label: this.stepsLabels[index],
            component: () => import(`@/components/apps/forms/${component}`),
            step: index + 1,
          }
        })
      },

      isNextDisabled() {
        let isDisabled = true
        switch (this.stepIndex) {
          case 1:
            isDisabled = !this.isCreateAppVersionOk
            break
          case 2:
            isDisabled =
              !this.formData.mode ||
              (this.formData.mode === 'json' &&
                this.formData.firehierarchy === null)
            break
          case 3:
            isDisabled = this.formData.firecomponents === null
            break
          case 4:
            isDisabled =
              this.formData.firedefaultcontent.appconfigurations === null ||
              this.formData.firedefaultcontent.appcontent === null ||
              this.formData.firedefaultcontent.appcustomisations === null ||
              this.formData.firedefaultcontent.appresources === null
            break
          default:
            isDisabled = false
            break
        }

        return isDisabled || this.isAppLoading
      },
    },

    methods: {
      ...mapActions(['createVersion']),

      async createAppVersion() {
        this.appLoading(true)
        try {
          if (this.formData.appVersionIdSource) {
            const [firehierarchy, firecomponents, defaultcontent] =
              await Promise.all([
                getFirstActiveDocumentData(
                  `appversions/${this.formData.appVersionIdSource}/firehierarchy`,
                ),
                getDocumentsInCollection(
                  `appversions/${this.formData.appVersionIdSource}/firecomponents`,
                ),
                getDocumentsInCollection(
                  `appversions/${this.formData.appVersionIdSource}/defaultcontent`,
                ),
              ])
            this.formData.firehierarchy = firehierarchy
            this.formData.firecomponents = firecomponents.reduce((acc, cur) => {
              if (!acc[cur.meta.section]) {
                acc[cur.meta.section] = {}
              }
              acc[cur.meta.section][cur.id] = {
                fields: cur.fields,
              }
              return acc
            }, {})
            this.formData.firedefaultcontent = defaultcontent.reduce(
              (acc, cur) => {
                acc[cur.id] = cur
                delete acc[cur.id].id
                return acc
              },
              {},
            )
          }

          // Create appversion
          const { versionRef } = await this.createVersion({
            appversion: this.formData.appversion,
            hierarchy: this.formData.firehierarchy,
            components: this.formData.firecomponents,
            defaultcontent: this.formData.firedefaultcontent,
          })

          if (this.formData.mode === 'dynamic') {
            this.$router.push({
              name: 'version-mapping',
              params: {
                appVersionId: versionRef.id,
              },
            })
          } else {
            this.setMessageInformations({
              message: 'App version created successfully!',
              state: 'success',
            })
          }

          this.success = true
        } catch (error) {
          this.handleErrors({ error })
        } finally {
          this.appLoading(false)
        }
      },
    },
  }
</script>
