<template>
  <div class="container-fluid horizontal-scroll_container">
    <div class="row">
      <div
        v-if="title"
        class="col horizontal-scroll_title"
      >
        <h3
          :title="title"
          class="text-truncate"
        >
          {{ title }}
        </h3>
      </div>
      <div class="col horizontal-scroll_content">
        <div class="row horizontal-scroll_actions">
          <slot name="action" />
        </div>
        <div
          ref="list"
          v-auto-animate
          class="row horizontal-scroll_list"
          :class="[
            itemPageLength < itemsPerPage || disableScroll || loading
              ? 'justify-content-start'
              : 'justify-content-between',
            disableScroll ? 'horizontal-scroll_list--disable-scroll' : '',
          ]"
          v-on="$listeners"
          @wheel="disableScroll ? undefined : onScroll($event)"
        >
          <template v-if="!loading">
            <div
              v-for="item in disableScroll ? items : itemsPage"
              :key="`item-${item.item}`"
            >
              <slot
                :item="item"
                name="item"
              />
            </div>
          </template>
          <template v-else>
            <Tile
              v-for="index in 4"
              :key="index"
              mock
              small
            />
          </template>
        </div>

        <b-button
          v-if="currentPage !== 0 && !disableScroll"
          class="horizontal-scroll_button horizontal-scroll_button--left"
          variant="outline"
          @click="previousNext"
        >
          <b-icon icon="chevron-left" />
        </b-button>
        <b-button
          v-if="maxPage > currentPage && !disableScroll"
          class="horizontal-scroll_button horizontal-scroll_button--right"
          variant="outline"
          @click="nextPage"
        >
          <b-icon icon="chevron-right" />
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
  import Tile from '@/components/tile/Tile'

  export default {
    components: {
      Tile,
    },

    props: {
      title: {
        type: String,
        default: null,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      items: {
        type: Array,
        default: () => [],
      },
      sizeItem: {
        type: Number,
        default: 140,
        validator: (value) => value > 0,
      },
      disableScroll: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        currentPage: 0,
        itemsPerPage: null,
      }
    },
    computed: {
      itemsPage() {
        return this.items.slice(
          this.currentPage * this.itemsPerPage,
          (this.currentPage + 1) * this.itemsPerPage,
        )
      },
      maxPage() {
        return Math.ceil(this.items.length / this.itemsPerPage) - 1
      },
      itemPageLength() {
        return this.itemsPage?.length ?? -1
      },
    },
    watch: {
      items() {
        this.currentPage = 0
      },
    },
    mounted() {
      this.setItemPerPage()
      window.addEventListener('resize', this.setItemPerPage)
    },
    unmounted() {
      window.removeEventListener('resize', this.setItemPerPage)
    },
    methods: {
      setItemPerPage() {
        if (this.$refs.list) {
          this.itemsPerPage = Math.floor(
            this.$refs.list.offsetWidth / this.sizeItem,
          )
        }
      },

      onScroll(event) {
        event.preventDefault()
        if (event.deltaY < 0) {
          this.previousNext()
        } else {
          this.nextPage()
        }
      },
      previousNext() {
        this.currentPage = Math.max(0, this.currentPage - 1)
      },
      nextPage() {
        this.currentPage = Math.min(this.currentPage + 1, this.maxPage)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .horizontal-scroll_container {
    position: relative;
    background-color: var(--app-background-color);
    &--only-child {
      & > .row:first-child {
        min-height: 155px;
      }
    }
  }

  .horizontal-scroll_title {
    display: flex;
    flex: 0 175px;
    max-width: 175px;
    padding-top: 5px;
    padding-bottom: 5px;
    background: var(--primary);

    h3 {
      text-transform: uppercase;
      font-size: 1rem;
      letter-spacing: 2px;
      color: white;
      align-self: center;
      margin-bottom: 0;
    }

    & + .horizontal-scroll_content {
      width: calc(100% - 175px - 80px);
    }
  }

  .horizontal-scroll_content {
    position: relative;
    width: calc(100% - 80px);
    margin: 15px 40px 10px 40px;
    padding: 0;
  }

  .horizontal-scroll_actions {
    margin-bottom: 15px;
  }

  .horizontal-scroll_list {
    flex-wrap: nowrap;
    overflow-y: auto;
    margin: 0 auto;
    column-gap: 10px;
    min-height: 125px;
    display: flex;

    // Removes scrolling elements when the  list is empty.
    &:empty + .horizontal-scroll_button,
    &:empty + .horizontal-scroll_button + .horizontal-scroll_button {
      display: none;
    }

    &.horizontal-scroll_list--disable-scroll {
      flex-wrap: wrap;
      overflow-y: hidden;
    }
  }

  .horizontal-scroll_actions {
    &:empty {
      display: none;
    }
  }

  .horizontal-scroll_button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    color: var(--app-background-font-color);

    &:hover {
      color: var(--primary-highlight);
    }

    &--left {
      left: -35px;
    }

    &--right {
      right: -35px;
    }
  }

  .horizontal-scroll_buttons {
    margin-left: auto;

    button {
      padding: 0;

      &:first-child {
        margin-right: 10px;
      }
    }
  }
</style>
