<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <div :class="fireLoaderClasses">
      <FirecampAnimation />
      <transition
        name="fade"
        mode="out-in"
      >
        <h1
          v-if="title"
          :key="title"
          class="fire-loader_title"
        >
          {{ title }}
        </h1>
      </transition>
      <template v-if="loadingBar">
        <b-progress
          variant="secondary"
          :value="percentage"
          :max="100"
          class="fire-loader_progress"
        />
        {{
          new Intl.NumberFormat('en', { style: 'percent' }).format(
            percentage / 100,
          )
        }}
      </template>
    </div>
  </transition>
</template>

<script>
  import FirecampAnimation from '@/components/misc/FirecampAnimation'

  export default {
    name: 'FirecampLoader',

    components: {
      FirecampAnimation,
    },

    props: {
      title: {
        type: String,
        default: null,
      },
      fixed: {
        type: Boolean,
        default: false,
      },
      loadingBar: {
        type: Boolean,
        default: false,
      },
      percentage: {
        type: Number,
        default: 0,
      },
    },

    computed: {
      fireLoaderClasses() {
        return {
          'fire-loader_container': true,
          'fire-loader_container--fixed': this.fixed,
        }
      },
    },

    created() {
      if (this.fixed) {
        document.body.style.overflow = 'hidden'
      }
    },

    destroyed() {
      if (this.fixed) {
        document.body.style = null
      }
    },
  }
</script>

<style lang="scss" scoped>
  .fire-loader_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &--fixed {
      position: fixed;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      z-index: 9999;

      &:after {
        content: '';
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        padding-top: 0;
        background: var(--primary);
      }

      .fire-loader_title {
        z-index: 10001;
        color: var(--white);
      }

      .fire_container {
        height: auto;
      }
    }
  }

  .fire-loader_title {
    font-size: 3rem;
    text-align: center;
    font-weight: normal;
    color: var(--app-background-font-color);
  }

  .fire-loader_progress {
    width: 50%;
    height: 20px;
    margin: 15px 0;
    z-index: 1;
  }
</style>
