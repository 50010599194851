
  import Vue from 'vue'
  import SubmitButton from './SubmitButton.vue'

  export default Vue.extend({
    name: 'ExportCSVButton',

    extends: SubmitButton,

    props: {
      csv: {
        type: String,
        required: false,
        default: undefined,
      },
      size: {
        type: String,
        default: '',
      },
      csvFunction: {
        type: Function,
        required: false,
        default: () => '',
      },
      fileName: {
        type: String,
        default: '',
      },
      loadingLabel: {
        type: String,
        default: null,
      },
    },

    data() {
      return {
        urlBlob: '',
        loadingInternal: false,
      }
    },

    computed: {
      labelDisplay() {
        return this.loadingInternal && this.loadingLabel
          ? this.loadingLabel
          : this.label
      },
    },

    methods: {
      async onClick() {
        this.loadingInternal = true
        const csv = this.csv ? this.csv : await this.csvFunction()
        if (!csv) {
          this.loadingInternal = false
          return
        }
        this.urlBlob = URL.createObjectURL(
          new Blob([csv], {
            type: 'text/csv;charset=utf-8;',
          }),
        )
        this.$nextTick(() => {
          this.$refs.downloadLink.click()
          this.loadingInternal = false
        })
      },
    },
  })
