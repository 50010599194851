<template>
  <MultistepForm
    v-model="formData"
    :title="title"
    :total-step-number="totalStepNumber"
    :is-submit-success="results.length > 0"
    :steps="steps"
    :is-next-disabled="isNextDisabled"
    success-message="Instance(s) successfully upgraded."
    :loading-message="loadingMessage"
    submit-label="Upgrade"
    :step.sync="stepIndex"
    @submit="submitForm"
  >
    <template #success-message>
      <h2 class="text-center">Upgrade summary:</h2>
    </template>
    <template #success>
      <div class="mt-3 ml-auto mr-auto result-container">
        <ul>
          <li
            v-for="{ id, response } in results"
            :key="`instanceresult${id}`"
          >
            <div class="result-container_item">
              <span>{{ id }} :</span>
              <span :class="response.success ? 'text-success' : 'text-danger'">
                <b-icon
                  :icon="
                    response.success ? 'check-circle-fill' : 'x-circle-fill'
                  "
                  class="ml-3"
                />
                {{ response.success ? 'upgraded' : 'failed' }}
              </span>
            </div>
          </li>
        </ul>
      </div>
      <NextSteps />
    </template>
  </MultistepForm>
</template>

<script>
  import { AppManagementMixin } from '@/mixins/AppManagementMixin.js'
  import Functions from '@/firebase/functions'
  import { updateRef, getDocumentReference } from '@/service/FirebaseService'

  import MultistepForm from '@/components/multistep-form/MultistepForm'
  import NextSteps from '@/components/apps/forms/NextSteps'
  import { GENERIC_ERROR_MISSING_APP_ROUTE_ID } from '@/constants/ErrorConstants'
  import { mapActions } from 'vuex'

  const APPINSTANCE_COLLECTIONS = [
    'appconfigurations',
    'appcontent',
    'appcustomisations',
    'appresources',
  ]
  const FILES_DATA = APPINSTANCE_COLLECTIONS.reduce(
    (accumulator, collectionName) => {
      accumulator[collectionName] = null
      return accumulator
    },
    {},
  )
  const initialData = {
    source: {
      appId: null,
      appVersionId: null,
      appRouteId: null,
      usedContent: null,
      filesContent: FILES_DATA,
      files: FILES_DATA,
    },
    destination: {
      appId: null,
      appVersionId: null,
      appRouteId: null,
    },
  }

  export default {
    name: 'UpgradeRoute',

    components: {
      MultistepForm,
      NextSteps,
    },

    mixins: [AppManagementMixin],

    data() {
      return {
        stepsLabels: [
          'Instance to upgrade',
          'Get the data from',
          'Instances comparison',
        ],
        results: [],
        stepIndex: 1,
        totalStepNumber: 3,
        formData: JSON.parse(JSON.stringify(initialData)),
        title: 'Upgrade instance',
        loadingMessage: 'Loading ...',
        steps: [],
      }
    },

    computed: {
      isNextDisabled() {
        let isDisabled = true

        switch (this.stepIndex) {
          case 1:
            isDisabled = !Object.keys(this.formData.destination).every(
              (key) => this.formData.destination[key] !== null,
            )
            break
          case 2: {
            const usedContent = this.formData.source.usedContent
            isDisabled = !this.usedContentCheck(usedContent)
            break
          }
          default:
            isDisabled = false
            break
        }

        return isDisabled || this.isAppLoading
      },
    },

    created() {
      this.steps = this.stepsComponents()
    },

    methods: {
      ...mapActions(['bindAppRoutes', 'unbindAppRoutes']),
      usedContentCheck(usedContentType) {
        if (!usedContentType) return false
        let isCheckOk = false

        switch (usedContentType) {
          case 'route':
            isCheckOk = ['appId', 'appVersionId', 'appRouteId']
              .filter(Boolean)
              .every((key) => this.formData.source[key] !== null)
            break
          case 'custom':
            isCheckOk = APPINSTANCE_COLLECTIONS.some(
              (collection) =>
                this.formData.source.filesContent[collection] !== null,
            )
            break
          case 'default':
          default:
            isCheckOk = true
            break
        }

        return isCheckOk
      },

      stepsComponents() {
        return [
          'UpgradeAppRouteSelection',
          'UpgradeAppRouteSelection',
          'UpgradeAppRouteComparison',
        ].map((component, index) => {
          let props = null
          let size = 'small'

          if ([0, 1].includes(index)) {
            props = {
              sourceData: this.formData.source,
              destinationData: this.formData.destination,
              ...(index === 1 && { isSourceSelection: true }),
            }
          }

          if (index === 2) {
            size = 'medium'
          }

          return {
            id: component,
            label: this.stepsLabels[index],
            component: () => import(`@/components/apps/forms/${component}`),
            step: index + 1,
            size,
            ...(props && { props }),
          }
        })
      },

      async submitForm() {
        if (!this.formData?.destination?.appRouteId) {
          this.handleErrors({ code: GENERIC_ERROR_MISSING_APP_ROUTE_ID })
          return
        }

        try {
          this.appLoading(true)
          this.loadingMessage = 'Starting migration'

          this.results = await Promise.all(
            this.formData.destination.appRouteId.map(async (appRouteId) => {
              // Update the version of the destination route with the one selected in source.
              if (
                this.formData.source.appVersionId !==
                this.formData.destination.appVersionId
              ) {
                const destinationRoutePath = `approutes/${appRouteId}`
                await updateRef(destinationRoutePath, {
                  appversion: getDocumentReference(
                    `appversions/${this.formData.source.appVersionId}`,
                  ),
                })
              }

              const migrateRoute =
                this.formData.source.usedContent === 'custom'
                  ? await Functions.migrateRouteWithJson(
                      appRouteId,
                      this.formData.source.filesContent,
                    )
                  : await Functions.migrateRoute({
                      ...(this.formData.source.appRouteId && {
                        sourceAppRouteId: this.formData.source.appRouteId,
                      }),
                      ...(appRouteId && { destinationAppRouteId: appRouteId }),
                      ...(this.formData.source.appVersionId && {
                        sourceVersionId: this.formData.source.appVersionId,
                      }),
                    })

              await this.unbindAppRoutes()
              await this.bindAppRoutes()
              return {
                id: appRouteId,
                response: migrateRoute,
              }
            }),
          )
        } catch (error) {
          this.handleErrors({ error })
        } finally {
          this.appLoading(false)
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .result-container {
    width: 550px;
    &_item {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-right: 20px;
      flex-wrap: wrap;
    }
  }
</style>
