<template>
  <div
    v-if="!canIgnoreMaintenanceMode"
    class="maintenance-page_container"
  >
    <div class="firecamp-logo-text_container mt-5">Firecamp</div>

    <FirecampAnimation
      class="firecamp-animation_container"
      :lit="true"
    />

    <div class="maintenance-message_container">
      <h1>
        <strong>Sorry!</strong> We are under <strong>maintenance</strong> ...
      </h1>
      <h3>
        Firecamp is currently undergoing maintenance. We will be right back.
      </h3>
      <h3>Thank you for your patience.</h3>
    </div>
  </div>
  <Message
    v-else
    message="Firecamp is in maintenance mode."
    state="warning"
    keep-showing
    :dismissible="false"
    class="maintenance_message"
  />
</template>

<script>
  import { mapGetters } from 'vuex'

  import FirecampAnimation from '@/components/misc/FirecampAnimation'
  import Message from '@/components/menus/message/Message'
  import { RIGHTS } from '@/constants/UserConstants'

  export default {
    name: 'MaintenancePage',

    components: {
      FirecampAnimation,
      Message,
    },

    computed: {
      ...mapGetters(['canUserDo']),
      canIgnoreMaintenanceMode() {
        return this.canUserDo(RIGHTS.IGNORE_MAINTENANCE_MODE)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .maintenance-page_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;

    background-color: var(--primary);
    color: var(--primary-font-color);
  }

  .firecamp-logo-text_container {
    text-align: center;
    font-size: 8rem;
    font-family: Blair;
    z-index: 10002;
  }

  .firecamp-animation_container {
    margin-top: -40px;
    transform: scale(0.8);
  }

  .maintenance-message_container {
    text-align: center;

    h1 {
      font-weight: 400;
    }

    h3 {
      font-weight: 350;
    }
  }

  .maintenance_message {
    text-align: center;
    margin-bottom: 0;
  }
</style>
