<template>
  <router-link
    :class="cardContainerClass"
    :to="destination"
  >
    <b-card
      class="board-card"
      :class="squareCard ? 'board-card--square' : ''"
    >
      <div
        class="card-text"
        :class="title ? '' : 'card-text--slot-content'"
      >
        <span
          v-if="title"
          class="card-title card-title--title-only"
        >
          {{ title }}
        </span>
        <slot
          v-else
          name="card-content"
        />
      </div>
    </b-card>
  </router-link>
</template>

<script>
  export default {
    name: 'BoardCard',

    props: {
      title: {
        type: String,
        default: null,
      },
      destination: {
        type: [Object, String],
        default: null,
      },
      evenHighlighted: {
        type: Boolean,
        default: false,
      },
      squareCard: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      cardContainerClass() {
        return {
          'collection-card_container': true,
          'col-sm-6 col-lg-3 col-xl-2': !this.title,
          'col-sm-6 col-lg-3 col-xl-3': !!this.title,
          'collection-card_container--even-highlighted': this.evenHighlighted,
        }
      },
    },
  }
</script>

<style lang="scss">
  .collection-card_container {
    margin-bottom: 30px;
    color: var(--secondary-font-color);
    text-decoration: none;

    &--even-highlighted {
      &:nth-child(even) {
        .board-card.card {
          background-color: var(--secondary);

          .card-title {
            color: var(--secondary-font-color);
          }
        }
      }
    }

    &:hover {
      cursor: pointer;
      color: var(--secondary-font-color);
      text-decoration: none;
    }
  }

  .board-card {
    min-height: 100px;
    height: 100%;

    &:hover,
    &:focus,
    &:active {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }

    &.card {
      border-color: var(--border-color);
      background-color: var(--primary);
    }

    .card-body {
      display: flex;
      padding: 10px;
    }

    .card-title {
      display: block;
      text-transform: uppercase;
      margin-bottom: 2px;
      color: var(--primary-font-color);

      &--title-only {
        text-align: center;
        width: 100%;
        font-size: 1.3rem;
      }
    }

    .card-text {
      display: flex;
      width: 100%;
      align-items: center;
      z-index: 1;
      color: var(--primary-font-color);

      &--slot-content {
        font-size: 1.2rem;
        align-content: center;
        text-align: center;
        flex-direction: column;
      }
    }

    &--square {
      min-height: 100px;
      width: 100px;
    }
  }
</style>
