<template>
  <div class="base-management_container">
    <List
      :is-tile-list="true"
      :items="filteredData"
      :loading="loading"
      :show-flag="showFlag"
      @create="handleCreate"
      @edit="initCreationModal(...arguments, true)"
      @delete="deleteModal"
      v-on="$listeners"
    >
      <template #top>
        <b-row>
          <b-col
            sm="12"
            md="6"
            lg="4"
          >
            <b-form-group
              :label="searchFieldLabel"
              label-for="search-field"
            >
              <b-input-group>
                <b-input-group-prepend is-text>
                  <b-icon icon="search" />
                </b-input-group-prepend>
                <b-form-input
                  id="search-field"
                  type="search"
                  :value="searchInput"
                  :disabled="loading"
                  :debounce="500"
                  @update="emitSearchEvent"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col
            sm="12"
            md="6"
            lg="4"
          >
            <slot name="additional-top" />
          </b-col>
        </b-row>
      </template>
      <template #main-action>
        <b-button
          v-if="modalComponent && !disallowCreate"
          id="mainActionButton"
          class="action-button"
          variant="primary"
          :disabled="loading"
          @click="handleCreate"
        >
          <b-icon icon="plus-circle" />
          {{ mainActionLabel }}
        </b-button>

        <slot name="additional-actions" />
      </template>
    </List>

    <b-modal
      v-if="modalComponent && elementToAdd"
      id="modal-creation"
      v-model="showModalCreation"
      hide-footer
      :title="modalTitle"
      size="lg"
      @close="handleModal('modal-creation')"
    >
      <component
        :is="modalComponent"
        v-if="isModalComponent"
        v-bind="elementToAdd"
        @done="onDone"
        @delete="deleteModal"
      />
    </b-modal>

    <ConfirmationModal
      v-if="elementToDeleteId"
      :item-id="elementToDeleteId"
      :message="messageDeleteConfirmation"
      @cancel="elementToDeleteId = null"
      @close="elementToDeleteId = null"
      @deleteConfirmed="onDelete(elementToDeleteId)"
    />
  </div>
</template>

<script>
  import { firstCharacterToUppercase } from '@/utils/StringHelpers'
  import { generateShowDataValues } from '@/utils/ModalHelpers'
  import { ModalMixin } from '@/mixins/ModalMixin'

  import List from '@/components/list/List'
  import ConfirmationModal from '@/components/modals/ConfirmationModal'

  const showDataValues = generateShowDataValues(['modal-creation'])
  const initialData = {
    elementToAdd: null,
    elementToDeleteId: null,
    searchInput: null,
  }
  export default {
    name: 'BaseManagement',

    components: {
      List,
      ConfirmationModal,
    },

    mixins: [ModalMixin],

    props: {
      filteredData: {
        type: Array,
        required: true,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      searchFieldLabel: {
        type: String,
        default: 'Search',
      },
      name: {
        type: String,
        default: 'element',
      },
      modalComponent: {
        type: [Object, String],
        required: false,
        default: null,
      },
      modalProps: {
        type: Object,
        default: null,
      },
      showFlag: {
        type: Boolean,
        default: false,
      },
      disallowCreate: {
        type: Boolean,
        default: false,
      },
      messageDeleteConfirmation: {
        type: String,
        default: null,
      },
    },

    data() {
      return {
        ...initialData,
        ...showDataValues,
      }
    },

    computed: {
      mainActionLabel() {
        return `CREATE NEW ${this.name?.toUpperCase()}`
      },

      modalTitle() {
        const prefix = firstCharacterToUppercase(this.name)
        const suffix =
          this.elementToAdd && this.elementToAdd.edition
            ? 'edition'
            : 'creation'
        return `${prefix} ${suffix}`
      },

      isModalComponent() {
        return this.modalComponent && typeof this.modalComponent === 'object'
      },
    },

    methods: {
      initCreationModal(object, isEdition = false) {
        this.showModalCreation = true
        this.elementToAdd = {
          ...object,
          ...(this.modalProps && this.modalProps),
          included: true,
          ...(isEdition && { edition: true }),
        }
      },

      onDone(obj) {
        this.emitSearchEvent(null)
        this.handleModal('modal-creation')
        this.$emit('created', obj)
      },

      onDelete(id) {
        this.$emit('deleteConfirmed', id)
      },

      deleteModal(uid) {
        this.elementToDeleteId = uid
        this.$nextTick(() => {
          this.$bvModal.show('modal-delete-confirmation-default')
        })
      },

      emitSearchEvent(input) {
        this.searchInput = input
        this.$emit('searchInput', input)
      },

      handleCreate() {
        if (this.isModalComponent) {
          this.initCreationModal({})
        } else {
          this.$emit(this.modalComponent)
        }
      },
    },
  }
</script>

<style lang="scss">
  .action-button {
    background-color: var(--primary-font-color);
    color: var(--secondary-font-color);
  }
</style>
