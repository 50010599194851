<template>
  <span
    :class="tabClasses"
    draggable
    @dragleave.prevent="tabDragLeave($event)"
    @drop.prevent="tabDrop($event, sectionTabId)"
    @dragover.prevent="tabDragOver($event, sectionTabId)"
    @dragenter.prevent
    v-on="$listeners"
  >
    <span class="tabs_tab_move">
      <b-icon icon="grip-vertical" />
    </span>
    <span
      v-if="title"
      class="tabs_tab_text"
      >{{ title }}</span
    >
    <button
      class="tabs_tab_close"
      @click.prevent="$emit('removed-tab')"
    >
      <b-icon icon="x" />
    </button>
  </span>
</template>

<script>
  export default {
    name: 'MappingTabTitle',

    props: {
      title: {
        type: String,
        required: true,
      },
      sectionTabId: {
        type: String,
        required: true,
      },
      currentIndex: {
        type: Number,
        required: true,
      },
      draggedElement: {
        type: Object,
        required: true,
      },
      vertical: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        isDraggedOver: false,
        isDraggedOverLeft: false,
        isDraggedOverTop: false,
      }
    },

    computed: {
      tabClasses() {
        const classes = [
          'tabs_tab',
          ...(this.vertical ? ['tabs_tab--vertical'] : []),
        ]

        if (this.isDraggedOver) {
          classes.push('tabs_tab--dragover')

          if (this.vertical) {
            classes.push(
              `tabs_tab--dragover--${this.isDraggedOverTop ? 'top' : 'bottom'}`,
            )
          } else {
            classes.push(
              `tabs_tab--dragover--${
                this.isDraggedOverLeft ? 'left' : 'right'
              }`,
            )
          }
        }
        return classes.filter(Boolean).join(' ')
      },
    },

    methods: {
      tabDragOver(event, sectionTabId) {
        if (!this.draggedElement) return

        const isDraggedElement =
          sectionTabId === this.draggedElement.sectionTabId &&
          this.currentIndex === this.draggedElement.index
        const tabParent = event.target.closest('.tabs_tab')
        const tabRect = tabParent?.getBoundingClientRect()

        if (this.isDraggedOver && isDraggedElement) {
          this.resetDraggedOver()
          return
        }

        this.isDraggedOver = true

        if (this.vertical) {
          this.isDraggedOverTop =
            event.clientY < tabRect.top + tabRect.height / 2
        } else {
          this.isDraggedOverLeft =
            event.clientX < tabRect.left + tabRect.width / 2
        }
      },

      tabDragLeave() {
        if (!this.draggedElement) return

        if (this.isDraggedOver) {
          this.resetDraggedOver()
        }
      },

      tabDrop(_event, sectionTabId) {
        const isDraggedElement =
          sectionTabId === this.draggedElement?.sectionTabId &&
          this.currentIndex === this.draggedElement?.index
        let newIndex = this.currentIndex

        if (isDraggedElement || !this.draggedElement) {
          this.resetDraggedOver()
          return
        }

        newIndex =
          (this.vertical && this.isDraggedOverTop) || this.isDraggedOverLeft
            ? newIndex
            : newIndex + 1

        if (newIndex < 0) {
          newIndex = 0
        }

        if (this.isDraggedOver) {
          this.resetDraggedOver()
        }

        this.$emit('tab-drop', {
          oldIndex: this.draggedElement.index,
          newIndex,
        })
      },

      resetDraggedOver() {
        this.isDraggedOver = false
        this.isDraggedOverLeft = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .tabs_tab {
    display: flex;
    align-items: center;
    user-select: none;
    // Reset size to allow easier drap & drop
    margin: -8px -16px;
    padding: 8px 16px;
    transition: all ease-in-out 0.2s;
    border-left: 1px dashed transparent;
    border-right: 1px dashed transparent;

    &_text {
      flex: 1;
    }

    &:hover,
    &:active,
    &:focus {
      color: inherit;

      .tabs_tab_close {
        opacity: 1;
        pointer-events: auto;
      }
    }

    &:focus {
      border: 1px solid red;
    }

    &_close {
      margin-left: 10px;
      padding: 2px;
      background: transparent;
      border: none;
      color: inherit;
      pointer-events: none;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;

      &:hover,
      &:active,
      &:focus {
        color: var(--danger);
      }
    }

    &_move {
      cursor: move;
      margin-right: 8px;
      color: grey;
    }

    &--dragover {
      * {
        pointer-events: none;
      }

      &--left {
        border-left: 1px dashed white;
      }

      &--right {
        border-right: 1px dashed white;
      }
    }

    &--vertical {
      border-left: none;
      border-top: 1px dashed transparent;
      border-bottom: 1px dashed transparent;

      &.tabs_tab--dragover {
        * {
          pointer-events: none;
        }

        &--top {
          border-top: 1px dashed white;
        }

        &--bottom {
          border-bottom: 1px dashed white;
        }
      }
    }

    &_index {
      margin-right: 8px;
    }
  }
</style>
