<template>
  <CollapseContainer
    :open.sync="isOpen"
    border
    :variant="containerVariant"
    :class="containerClasses"
    :style="`--index: ${field.parentsIndexs ? field.parentsIndexs.length : 0}`"
    v-on="$listeners"
  >
    <template #title>
      <div class="mapping-field_title_container">
        <b-icon
          v-if="/separator/.test(field.settings.type)"
          icon="caret-down-fill"
          :class="{ 'mapping-field_title_icon--collapse': separatorCollapsed }"
          class="mapping-field_title_icon mr-2"
          @click.stop="$emit('toogle-separator')"
        />
        <span class="mapping-field_title_move">
          <b-icon icon="grip-vertical" />
        </span>
        <b-form-input
          :value="getFieldData(fieldLocation).id"
          class="mapping-field_title_container_input-id"
          type="text"
          small
          :debounce="500"
          @input="onUpdateId"
          @click.stop=""
        />
      </div>
    </template>
    <div
      v-if="isOpen"
      class="mapping-field_wrapper"
    >
      <b-alert
        v-if="hasError"
        variant="danger"
        class="mb-4"
        show
      >
        <div class="mb-0">
          <b-icon icon="exclamation-triangle" />
          Invalid value in this field :
          <ul class="mb-0">
            <li
              v-for="(error, indexError) in getFieldData(fieldLocation).errors"
              :key="`error-${indexError}`"
            >
              {{ error }}
            </li>
          </ul>
        </div>
      </b-alert>

      <b-form-group
        label="Field label:"
        :label-for="`field-label-${fieldIndex}`"
        required
      >
        <b-form-input
          :id="`field-label-${fieldIndex}`"
          :value="getFieldData(fieldLocation).label"
          type="text"
          required
          :debounce="500"
          @input="onUpdateLabel"
        />
      </b-form-group>
      <b-form-group
        label="Field type:"
        required
      >
        <Multiselect
          placeholder="Select the field type"
          select-label=""
          deselect-label=""
          group-values="types"
          group-label="style"
          :custom-label="customLabel"
          :options="fieldTypesAllowed"
          :multiple="false"
          :value="getFieldData(fieldLocation).settings.type"
          @input="changeFieldSettings($event)"
        />
      </b-form-group>

      <b-form-group
        label="Field description:"
        :label-for="`field-description-${fieldIndex}`"
      >
        <b-form-textarea
          :id="`field-description-${fieldIndex}`"
          :value="getFieldData(fieldLocation).description"
          placeholder="Please provide a description for this field."
          rows="3"
          no-resize
          @input="onUpdateField('description', $event)"
        />
      </b-form-group>
      <b-form-group
        v-if="typesIncludePlaceholder.includes(field.settings.type)"
        label="Field Placeholder:"
      >
        <b-form-input
          :value="getFieldData(fieldLocation).placeholder"
          type="text"
          required
          placeholder="Please provide a placeholder for this field."
          :debounce="500"
          @input="onUpdateField('placeholder', $event)"
        />
      </b-form-group>
      <b-form-group
        v-if="field.settings.type === 'repeater'"
        label="Field Empty Message:"
      >
        <b-form-input
          :value="getFieldData(fieldLocation).empty"
          type="text"
          required
          placeholder="Please provide a message when repeater is empty."
          :debounce="500"
          @input="onUpdateField('empty', $event)"
        />
      </b-form-group>

      <MappingFieldsFieldDefaultValue
        v-if="
          !inRepeater &&
          !['subseparator', 'separator', 'repeater'].includes(
            getFieldData(fieldLocation).settings.type,
          )
        "
        :value="getFieldData(fieldLocation).defaultValue"
        :type="getFieldData(fieldLocation).settings.type"
        @input="onUpdateField('defaultValue', $event)"
      />

      <MappingFieldsFieldOptions
        v-if="getFieldData(fieldLocation).options"
        :options="getFieldData(fieldLocation).options"
        @update:options="onUpdateField('options', $event)"
      />
      <MappingFieldsFieldSettings
        v-if="getFieldData(fieldLocation).settings"
        :settings="getFieldData(fieldLocation).settings"
        :field-id="field.id"
        :formated-hierarchy="collectionsHierarchy"
        @update:settings="onUpdateField('settings', $event)"
      />

      <b-button
        class="mt-2"
        variant="danger"
        @click="deleteField"
      >
        <b-icon icon="trash" />
        Delete field
      </b-button>
    </div>
    <MappingFields
      v-if="isOpen && getFieldData(fieldLocation).settings.type === 'repeater'"
      :section-index="field.sectionIndex"
      :sub-index="field.subIndex"
      :parents-indexs="parentsIndexs"
      :collections-hierarchy="collectionsHierarchy"
      class="mapping-field_fields-repeater_subfields"
      in-repeater
    />
    <div
      v-else-if="
        isOpen && getFieldData(fieldLocation).settings.type === 'events'
      "
      class=""
    >
      <div class="events-fields_container">
        <p class="subfields_tag">Event subfields</p>
        <MappingFields
          :section-index="field.sectionIndex"
          :sub-index="field.subIndex"
          :parents-indexs="parentsIndexs"
          :collections-hierarchy="collectionsHierarchy"
          class="mapping-field_fields-repeater_subfields"
          event-subfields
        />
      </div>
      <div class="events-fields_container">
        <p class="subfields_tag">Step subfields</p>
        <MappingFields
          :section-index="field.sectionIndex"
          :sub-index="field.subIndex"
          :parents-indexs="parentsIndexs"
          :collections-hierarchy="collectionsHierarchy"
          class="mapping-field_fields-repeater_subfields"
          step-subfields
        />
      </div>
    </div>
    <span v-show="false">
      {{ lastUpdate }}
    </span>
  </CollapseContainer>
</template>

<script>
  import CollapseContainer from '@/components/containers/CollapseContainer'
  import Multiselect from '@/components/form/Multiselect'
  import MappingFieldsFieldOptions from '@/components/mapping/MappingFieldsFieldOptions'
  import MappingFieldsFieldSettings from '@/components/mapping/MappingFieldsFieldSettings'
  import MappingFieldsFieldDefaultValue from '@/components/mapping/MappingFieldsFieldDefaultValue'

  import { firstCharacterToUppercase } from '@/utils/StringHelpers'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'MappingFieldsField',
    components: {
      MappingFieldsFieldOptions,
      MappingFieldsFieldSettings,
      MappingFieldsFieldDefaultValue,
      Multiselect,
      CollapseContainer,
      MappingFields: () => import('@/components/mapping/MappingFields'),
    },
    props: {
      field: {
        type: Object,
        required: true,
      },

      collectionsHierarchy: {
        type: Object,
        required: true,
      },

      fieldIndex: {
        type: Number,
        required: true,
      },

      inRepeater: Boolean,
      eventSubfields: Boolean,
      stepSubfields: Boolean,
      separatorCollapsed: Boolean,
    },
    data() {
      return {
        lastUpdate: Date.now(), // used to force the component to re-render when the field is updated
        typesIncludePlaceholder: [
          'input',
          'number',
          'url',
          'repeater',
          'textarea',
          'select',
          'video',
          'code',
          'baseUrl',
          'file',
        ],
        fieldTypes: [
          {
            style: 'Separators',
            types: ['separator', 'subseparator'],
          },
          {
            style: 'Inputs',
            types: ['input', 'number', 'range', 'textarea', 'richText'],
          },
          {
            style: 'Media',
            types: ['image', 'video', 'file', 'font', 'url'],
          },
          {
            style: 'Selection',
            types: ['select', 'collection', 'checkbox', 'radio'],
          },
          {
            style: 'Styling',
            types: ['css', 'pixel'],
          },
          {
            style: 'Others',
            types: [
              ...(this.eventSubfields || this.stepSubfields
                ? []
                : ['repeater']),
              'baseUrl',
              'api',
              'code',
              'switch',
              'table',
              ...(this.eventSubfields || this.stepSubfields || this.inRepeater
                ? []
                : ['events']),
            ],
          },
        ],
        isOpen: false,
      }
    },
    computed: {
      ...mapGetters({
        getFieldData: 'mapping/getFieldData',
        getAutomaticFieldId: 'mapping/getAutomaticFieldId',
      }),
      fieldLocation() {
        return {
          ...this.field,
          index: this.fieldIndex,
        }
      },
      hasError() {
        const fieldData = this.getFieldData(this.fieldLocation)
        return Array.isArray(fieldData.errors) && fieldData.errors.length > 0
      },
      containerClasses() {
        const fieldData = this.getFieldData(this.fieldLocation)
        const type = fieldData?.settings?.type
        return {
          'mapping-field': true,
          'mapping-field--separator': type === 'separator',
          'mapping-field--subseparator': type === 'subseparator',
          'mapping-field--repeater': type === 'repeater',
          'mapping-field--error': this.hasError,
        }
      },
      containerVariant() {
        const fieldData = this.getFieldData(this.fieldLocation)
        if (this.hasError) {
          return 'danger'
        }
        return fieldData.settings.type === 'subseparator'
          ? 'tertiary'
          : 'secondary'
      },
      fieldTypesAllowed() {
        if (this.inRepeater || this.eventSubfields || this.stepSubfields) {
          return this.fieldTypes.filter(({ style }) => style !== 'Separators')
        }
        return this.fieldTypes
      },
      parentsIndexs() {
        if (this.field.parentsIndexs)
          return [...this.field.parentsIndexs, this.fieldIndex]
        return [this.fieldIndex]
      },
    },
    created() {
      const fieldData = this.getFieldData(this.fieldLocation)
      this.isOpen =
        (fieldData && fieldData.id.length === 0) || fieldData.label.length === 0
    },
    methods: {
      ...mapActions('mapping', [
        'updateTypeField',
        'updateField',
        'removeField',
      ]),
      customLabel(label) {
        return firstCharacterToUppercase(label)
      },

      changeFieldSettings(type) {
        this.updateTypeField({ field: this.fieldLocation, type })
        this.lastUpdate = Date.now()
      },

      onUpdateLabel(value) {
        const { sectionIndex, subIndex, index } = this.fieldLocation
        const fieldData = this.getFieldData(this.fieldLocation)
        if (!fieldData.forceId) {
          const id = this.getAutomaticFieldId(
            sectionIndex,
            subIndex,
            index,
            value,
            this.inRepeater,
          )
          this.onUpdateField('id', id, false)
        }
        this.onUpdateField('label', value)
      },

      onUpdateId(value) {
        this.onUpdateField('forceId', true)
        this.onUpdateField('id', value)
      },

      onUpdateField(key, value) {
        this.updateField({
          field: this.field,
          key,
          value,
        })
        this.lastUpdate = Date.now()
      },

      deleteField() {
        this.removeField(this.fieldLocation)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .mapping-field_wrapper {
    padding: 1em 2em;
  }
  .mapping-field {
    margin-top: 2em;
    position: relative;

    &--error {
      border-color: var(--danger);
    }

    &--separator {
      z-index: 1;
      &:not(:first-child) {
        margin-top: 4em;
      }

      & ~ .mapping-field:not(.mapping-field--separator) {
        margin-left: 30px;

        &:before,
        &:after {
          content: '';
          position: absolute;
        }

        &:before {
          height: calc(100% + 2.5em);
          width: 20px;
          border-left: 2px solid var(--secondary);
          border-bottom: 2px solid var(--secondary);
          left: -20px;
          top: -3.5em;
          border-bottom-left-radius: 5px;
        }
      }

      & + .mapping-field:not(.mapping-field--separator) {
        &:before {
          height: calc(100% + 1em);
          top: -2em;
        }
      }
    }
    .mapping-field_fields-repeater_subfields & {
      border: 1px solid hsl(calc(var(--index) * 20), 50%, 40%);
      margin: 1.5rem;
    }

    &--subseparator {
      margin-top: 2.2em;

      &
        ~ .mapping-field:not(.mapping-field--subseparator):not(
          .mapping-field--separator
        ) {
        margin-left: 60px;

        &:before {
          border-color: var(--tertiary);
        }

        &:after {
          background: var(--secondary);
          left: -50px;
          top: -3.5em;
          width: 2px;
          height: calc(100% + 3em);
        }
      }

      & + .mapping-field:not(.mapping-field--subseparator) {
        &:before {
          height: calc(100% + 1em);
          top: -2em;
        }
      }
    }

    &_title_move {
      cursor: move;
      margin-right: 5px;
      color: grey;
    }
    &_title_container {
      flex: 1;
      display: flex;
      align-items: center;
      &_input-id {
        background: transparent;
        color: white;
        max-width: 350px;
      }
      .mapping-field_title_icon {
        &--collapse {
          transform: rotate(-90deg);
          fill: var(--app-background-font-color);
        }
        stroke: var(--app-background-font-color);
        fill: transparent;
        transition: all 0.5s ease;
      }
    }

    &--dragover {
      * {
        pointer-events: none;
      }

      border-top: 1px dashed white;
    }

    &--ghost {
      position: relative;
      bottom: -1rem;

      background: rgba(255, 255, 255, 0);
      border: none;
      --secondary: var(--primary-highlight);
      .collapse_header {
        height: 20rem !important;
      }
      &::before {
        display: none;
      }
    }
  }

  .events-fields_container {
    border: solid 2px var(--tertiary);
    margin: 1rem 2rem;
  }

  .subfields_tag {
    background-color: var(--tertiary);
    color: var(--tertiary-font-color);
    padding-left: 5px;
  }
</style>
