<template>
  <div class="main-collection_container container-fluid">
    <TitleContainer
      v-if="!included"
      :title="title"
    />
    <b-form
      class="main-collection_form"
      @submit.prevent="onSubmit"
      @reset.prevent="onReset"
    >
      <div class="main-collection_fields">
        <b-row>
          <b-form-group
            class="col"
            label="Country :"
            required
          >
            <Multiselect
              v-model="country"
              label="displayName"
              :custom-label="
                ({ id, displayName }) => `${id.toLowerCase()} - ${displayName}`
              "
              track-by="id"
              :show-pointer="false"
              :options="bmdmCountries"
              :disabled="isAppLoading || edition || !fetchedResources.countries"
            />
          </b-form-group>

          <b-form-group
            class="col"
            label="Language :"
          >
            <Multiselect
              v-model="language"
              :custom-label="({ code, name }) => `${code} - ${name}`"
              track-by="code"
              :show-pointer="false"
              :options="languages"
              :disabled="isAppLoading || edition"
            />
          </b-form-group>
        </b-row>

        <b-form-group
          class="col-5 mb-2 pl-0"
          label="Display name:"
          required
        >
          <b-form-input
            id="country-input-display-name"
            v-model.trim="displayNameValue"
            :disabled="isAppLoading"
            :debounce="500"
            required
          />
        </b-form-group>
        <b-row>
          <div :class="included ? 'action-button_container' : 'mt-4 pl-0'">
            <b-button
              type="reset"
              variant="link"
              class="text-danger"
              :class="!included ? 'w-100' : ''"
              >{{ edition ? 'Delete' : 'Reset' }}</b-button
            >
            <SubmitButton
              :disabled="isSubmitDisabled"
              :loading="isAppLoading"
              :class="included ? 'w-auto mb-auto ml-2' : ''"
            />
          </div>
        </b-row>
      </div>
    </b-form>
  </div>
</template>

<script>
  import { DictionnariesCreationMixin } from '@/mixins/DictionnariesCreationMixin'
  import { RESOURCES } from '@/service/ResourceService'
  import Multiselect from '../../form/Multiselect.vue'
  import { mapActions, mapState } from 'vuex'
  import { LANGUAGES } from '@/constants/LanguageConstants'

  const initialData = {
    country: null,
    displayNameValue: null,
    language: null,
  }

  export default {
    name: 'CountryCreation',
    components: { Multiselect },

    mixins: [DictionnariesCreationMixin],

    data() {
      return {
        ...initialData,
        initialData,
        stateKey: 'countries',
        languages: LANGUAGES,
      }
    },

    computed: {
      ...mapState({
        bmdmCountries: (state) => state.bmdmCountries,
        fetchedResources: (state) => state.fetchedResources,
      }),
      finalObject() {
        if (!this.idValue) return null
        return {
          id: this.idValue,
          displayName: this.displayNameValue,
          ...(this.language ? { languageCode: this.language.code } : {}),
          countryA2Code: this.country.alternatives['alpha-2'],
          countryA3Code: this.country.alternatives['alpha-3'],
        }
      },
      idValue() {
        if (!this.country) return null
        return (
          this.country.id.toLowerCase() +
          (this.language ? '-' + this.language.code : '')
        )
      },
    },

    watch: {
      country() {
        this.displayNameValue = this.getAutoDisplayName()
      },
      language() {
        this.displayNameValue = this.getAutoDisplayName()
      },
    },

    async mounted() {
      if (this.bmdmCountries.length === 0) {
        this.appLoading(true)
        await this.loadBmdmCountries()
        this.appLoading(false)
      }
      if (this.edition) {
        const ids = this.id.split('-')
        this.country = this.bmdmCountries.find(
          (country) => country.id.toLowerCase() === ids[0],
        )
        if (ids.length > 1) {
          this.language = this.languages.find(
            (language) => language.code === ids[1],
          )
        }
        this.displayNameValue = this.displayName
      }

      await this.loadResources({ resourceNames: [RESOURCES.COUNTRIES] })
    },

    methods: {
      ...mapActions(['loadBmdmCountries', 'loadResources']),
      getAutoDisplayName() {
        if (!this.country) return null

        return (
          this.country.displayName +
          ' (' +
          this.country.id.toLowerCase() +
          (this.language ? '-' + this.language.code : '') +
          ')'
        )
      },
    },
  }
</script>
