<template>
  <div :class="containerClasses">
    <TitleContainer
      v-if="title"
      :title="title"
      :class="titleContainerClasses"
      @click.native="titleClicked"
    >
      <slot name="titleContainer">
        <button
          v-if="reduceContainer"
          type="button"
        >
          <b-icon :icon="showList ? 'chevron-up' : 'chevron-down'" />
        </button>
      </slot>
    </TitleContainer>

    <template v-if="reduceContainer">
      <b-collapse
        v-if="showList"
        visible
        appear
        class="base-container_content base-container_content--reduce"
        v-on="$listeners"
      >
        <slot />
      </b-collapse>
    </template>

    <div
      v-else
      class="base-container_content"
    >
      <slot />
    </div>
  </div>
</template>

<script>
  import TitleContainer from '@/components/containers/TitleContainer'

  export default {
    name: 'BaseContainer',

    components: {
      TitleContainer,
    },

    props: {
      title: {
        type: String,
        default: null,
      },
      reduceContainer: {
        type: Boolean,
        default: false,
      },
      border: {
        type: Boolean,
        default: false,
      },
      reduced: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        showList: true,
      }
    },

    computed: {
      containerClasses() {
        return ['base-container', this.border ? 'base-container--border' : '']
          .filter(Boolean)
          .join(' ')
      },

      titleContainerClasses() {
        return this.reduceContainer ? 'title-container--reduce' : ''
      },

      hasTitleSlot() {
        return !!this.$slots.title
      },
    },

    watch: {
      reduced(isReduced) {
        this.showList = !isReduced
      },
    },

    created() {
      this.showList = !this.reduced
    },

    methods: {
      titleClicked() {
        if (this.reduceContainer) this.showList = !this.showList
      },
    },
  }
</script>

<style lang="scss" scoped>
  .base-container_content {
    margin-top: 1rem;
    color: var(--app-background-font-color);
  }

  .base-container {
    &--border {
      padding: 0 15px;
      border: 1px solid var(--primary);
      border-radius: 5px;
    }
  }

  .title-container--reduce {
    &:hover {
      cursor: pointer;
    }
  }
</style>
