<template>
    <b-select
      v-if="tenants.length > 1"
      v-model="tenant"
      class="ml-2"
    >
      <b-select-option
        v-for="t in tenants"
        :key="t.id"
        :value="t.id"
      >
        {{ t.displayName }}
      </b-select-option>
      
    </b-select>
  </template>
  
  <script>
  import { mapGetters } from 'vuex';
  
    export default {
      name: 'ProductAPIConfigurationSelectTenant',

      watch: {
        tenants(tenants) {
          if(tenants.length === 1) {
            this.tenant = this.tenants[0].id
          }
        }
      },
  
      computed: {
        ...mapGetters('scopes', ['computedScope']),
  
        tenants() {
          return this.computedScope.tenants.options
        },
  
        tenant: {
          get() {
            return this.$route.params.tenant
          },
          set(value) {
            if (this.tenant !== value) {
              this.$router.push({
                name: this.$route.name,
                params: {
                  ...this.$route.params,
                  tenant: value,
                },
              })
            }
          }
        },
      }
    }
  </script>
  