import { render, staticRenderFns } from "./ProductAPIConfigurationEdition.vue?vue&type=template&id=3f0195da&scoped=true&"
import script from "./ProductAPIConfigurationEdition.vue?vue&type=script&lang=js&"
export * from "./ProductAPIConfigurationEdition.vue?vue&type=script&lang=js&"
import style0 from "./ProductAPIConfigurationEdition.vue?vue&type=style&index=0&id=3f0195da&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f0195da",
  null
  
)

export default component.exports