<template>
  <ul
    v-if="show"
    class="tile-information_container"
  >
    <li
      v-for="(info, key) in details"
      :key="key"
      v-b-tooltip.hover
      class="tile-information_list"
      :class="tileInformationClasses(key)"
      :title="tooltip(key)"
    >
      <b-icon :icon="informationsTooltips[key].icon" />
      <template v-if="tagsValues && tagsValues.includes(key)">
        <Tag
          v-for="data in info"
          :key="data.label + '-criteria'"
          :color="data.color"
          :text="data.label"
          @click="$parent.$parent.$emit('addToFilter', { key, data })"
        />
      </template>
      <div
        v-else-if="key === 'countries'"
        class="tile-information_list_country"
      >
        <span>{{ info.label }}</span
        ><CountryFlag
          v-if="isCountryValid(info.id)"
          :country="info.id.match(/^(\w+)-?/)[1]"
          rounded
          size="normal"
        />
      </div>
      <span v-else>{{ info }}</span>
    </li>
  </ul>
</template>

<script>
  import Tag from '@/components/tag/Tag'
  import CountryFlag from 'vue-country-flag'

  const EXCLUDED_FLAGS = ['dmi', 'int']

  export default {
    name: 'TileInformation',

    components: {
      Tag,
      CountryFlag,
    },

    props: {
      show: {
        type: Boolean,
        default: true,
      },
      inline: {
        type: Boolean,
        default: false,
      },
      details: {
        type: Object,
        default: null,
      },
      tagsValues: {
        type: Array,
        default: () => {
          return []
        },
      },
    },

    data() {
      return {
        informationsTooltips: {
          appRoute: {
            tooltip: 'App Instance ID',
            icon: 'key',
          },
          id: {
            tooltip: 'Id',
            icon: 'key-fill',
          },
          criterias: {
            tooltip: 'Criterias',
            icon: 'exclamation-circle',
          },
          inclusiveStrictCriterias: {
            tooltip: 'Inclusive Strict criterias',
            icon: 'exclamation-circle',
            condition: 'MUST',
          },
          inclusiveCriterias: {
            tooltip: 'Inclusive criterias',
            icon: 'check-circle',
            condition: 'SHOULD',
          },
          exclusiveCriterias: {
            tooltip: 'Exclusive criterias',
            icon: 'dash-circle',
            condition: 'MUST NOT',
          },
          inclusiveRootItemCriterias: {
            tooltip: 'Inclusive root criterias',
            icon: 'gift',
            condition: 'MUST',
          },
          hitCount: {
            tooltip: 'Number of times this profile has been hit',
            icon: 'bullseye',
          },
          hitPercent: {
            tooltip: 'Percentage of hits for this profile',
            icon: 'percent',
          },
          email: {
            tooltip: 'User email',
            icon: 'at',
          },
          name: {
            tooltip: 'User name',
            icon: 'person-circle',
          },
          group: {
            tooltip: 'Group',
            icon: 'people',
          },
          role: {
            tooltip: 'Role',
            icon: 'award-fill',
          },
          scope: {
            tooltip: 'Scope',
            icon: 'search',
          },
          team: {
            tooltip: 'User team',
            icon: 'building',
          },
          description: {
            tooltip: 'Description',
            icon: 'card-text',
          },
          applications: {
            tooltip: 'Applications',
            icon: 'window',
          },
          brands: {
            tooltip: 'Brands',
            icon: 'badge-tm-fill',
          },
          countries: {
            tooltip: 'Countries',
            icon: 'globe',
          },
          customers: {
            tooltip: 'Customers',
            icon: 'bag-fill',
          },
          environments: {
            tooltip: 'Environments',
            icon: 'hdd-stack-fill',
          },
          tenants: {
            tooltip: 'Tenants',
            icon: 'key-fill',
          },
          touchpoints: {
            tooltip: 'Touchpoints',
            icon: 'geo-fill',
          },
          version: {
            tooltip: 'Version',
            icon: 'kanban-fill',
          },
          type: {
            tooltip: 'Type',
            icon: 'file-earmark-text-fill',
          },
          sku: {
            tooltip: 'Sku',
            icon: 'upc',
          },
          success: {
            tooltip: 'Success',
            icon: 'check-circle-fill',
          },
          error: {
            tooltip: 'Error',
            icon: 'x-circle-fill',
          },
          warning: {
            tooltip: 'Warning',
            icon: 'exclamation-triangle-fill',
          },
          hidden: {
            tooltip: 'Hidden',
            icon: 'eye-slash-fill',
          },
          tenant: {
            tooltip: 'Tenant',
            icon: 'wallet-fill',
          },
          color: {
            tooltip: 'Color',
            icon: 'brush',
          },
          bmdmRef: {
            tooltip: 'BMDM reference',
            icon: 'folder-symlink-fill',
          },
          bmdmValue: {
            tooltip: 'BMDM value',
            icon: 'file-earmark-easel-fill',
          },
          question: {
            tooltip: 'question',
            icon: 'question-square',
          },
          step: {
            tooltip: 'Step',
            icon: 'bar-chart-steps',
          },
          answer: {
            tooltip: 'Answer',
            icon: 'chat-dots',
          },
          active: {
            tooltip: 'Active',
            icon: 'check-circle-fill',
          },
          disabled: {
            tooltip: 'Not active',
            icon: 'slash-circle-fill',
          },
          prescription: {
            tooltip: 'Prescription enabled',
            icon: 'clipboard-data',
          },
          default: {
            tooltip: 'Default profile',
            icon: 'shield-fill',
          },
          documentation: {
            tooltip: 'Documentation source',
            icon: 'book',
          },
          parent: {
            tooltip: 'Parent page',
            icon: 'diagram-2-fill',
          },
        },
      }
    },

    methods: {
      tileInformationClasses(string) {
        return [
          'tile-information_list--' +
            string.replace(/[A-Z]/g, '-$&').toLowerCase(),
          this.inline ? 'tile-information_list--inline' : '',
        ].join(' ')
      },

      tooltip(key) {
        if (!this.informationsTooltips[key]) {
          return ''
        }
        const condition = this.informationsTooltips[key]?.condition
          ? ` : ${this.informationsTooltips[key].condition}`
          : ''
        return this.informationsTooltips[key].tooltip + condition
      },

      isCountryValid(countryId) {
        if (!countryId) return false
        return !EXCLUDED_FLAGS.includes(countryId)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .tile-information_container {
    flex: 0 0 100%;
    font-size: 0.8rem;
    list-style-type: none;
    text-align: left;
    margin-top: 20px;
    padding: 0;
    word-break: break-word;
    order: 2;

    .tile-information_list {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;

      &_country {
        display: inline-flex;
      }

      &--inline {
        display: inline-flex;
        flex-wrap: wrap;
        margin-right: 20px;

        &:first-child {
          margin-left: 0;
        }
      }

      & > .b-icon {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
</style>
