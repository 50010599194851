import { BeautyHubEnvSchema, BeautyHubTenantSchema } from "@/firebase/schemas"

// Duplicated to functions/src/utils/instances.ts
export const parseAppRouteId = (appRouteId: string) => {
  const data = appRouteId.split('-')
  
  /** impossible to extract country and customer from the approute name because some country name contain many languages seperated by hyphen (ex: hkg-zh-hans) and some customer name also contain hyphens (ex: laroche-posay) */
  const [application, tenant] = data.splice(0, 2)
  const [touchpoint, environment, type] = data.splice(data.length - 3, 3)
  
  return {
    application,
    tenant: BeautyHubTenantSchema.parse(tenant),
    touchpoint,
    environment: BeautyHubEnvSchema.parse(environment),
    type,
  }
}

