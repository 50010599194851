<template>
  <BaseManagement
    :filtered-data="filteredFormatedGroups"
    :loading="loading"
    search-field-label="Search by name"
    name="group"
    :modal-component="modalComponent"
    @deleteConfirmed="deleteGroup"
    @searchInput="setSearchInput"
    @created="init"
  />
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import { deleteRef } from '@/service/FirebaseService'

  import { UserManagementMixin } from '@/mixins/UserManagementMixin.js'

  import BaseManagement from '@/components/admin/BaseManagement'
  import GroupCreation from '@/components/admin/user/GroupCreation'

  export default {
    name: 'GroupManagement',

    components: {
      BaseManagement,
    },

    mixins: [UserManagementMixin],

    data() {
      return {
        searchInput: '',
        modalComponent: GroupCreation,
      }
    },

    computed: {
      ...mapState({
        securityGroups: (state) => state.scopesrolesgroups.groups,
      }),

      filteredFormatedGroups() {
        const groups = this.searchInput
          ? this.securityGroups?.filter(
              (group) =>
                group?.displayName?.toLowerCase().includes(this.searchInput) ||
                group?.id?.toLowerCase().includes(this.searchInput),
            )
          : this.securityGroups
        return this.setGroupsForList(groups)
      },
    },

    methods: {
      ...mapActions({
        getGroups: 'scopesrolesgroups/getGroups',
      }),

      async init() {
        await this.getGroups()
      },

      setGroupsForList(groups) {
        this.loading = true
        if (!groups) return []

        // Get role and scope displayName.
        const formatedGroups = groups?.map((group) => {
          const { id, displayName } = group
          const details = ['role', 'scope'].reduce((accumulator, detail) => {
            if (group?.[detail]) {
              accumulator[detail] =
                group[detail].displayName ?? group[detail].id
            }
            return accumulator
          }, {})

          return {
            id,
            label: displayName ?? id,
            details,
            quickActionButton: [
              {
                label: 'Edit group',
                icon: 'pencil-fill',
                variant: 'success',
                onClick: ['edit', group],
              },
              {
                label: 'Delete group',
                variant: 'danger',
                icon: 'trash',
                divider: true,
                onClick: ['delete', id],
              },
            ],
          }
        })

        this.loading = false
        return formatedGroups
      },

      async deleteGroup(uid) {
        this.loading = true
        try {
          await deleteRef(`/securitygroups/${uid}`)

          this.$nextTick(() => {
            this.$bvModal.hide('modal-creation')
          })

          this.setMessageInformations({
            message: `Group ${uid} successfully deleted.`,
            state: 'success',
          })
        } catch (error) {
          this.handleErrors({ error })
        } finally {
          await this.getGroups()
          this.loading = false
        }
      },

      setSearchInput(searchInput) {
        if (searchInput === null) {
          searchInput = ''
        }
        this.searchInput = searchInput.toLowerCase()
      },
    },
  }
</script>
