<template>
  <BaseTab
    class="mt-4"
    :loader="false"
    :tabs="editPrescriptionTabs"
    vertical
    lazy
  />
</template>

<script>
  import BaseTab from '@/components/admin/BaseTab.vue'
  import { mapGetters } from 'vuex'

  export default {
    name: 'EditPrescription',

    components: {
      BaseTab,
    },

    computed: {
      ...mapGetters(['isAIPoweredPrescription']),

      editPrescriptionTabs() {
        return [
          {
            id: 'settings',
            title: 'Edit Dictionnaries',
            component: () =>
              import('@/components/prescription/EditPrescriptionParam.vue'),
            props: {
              keepAlive: true,
            },
          },
          {
            id: 'edit-event-step',
            title: 'Edit Events and Steps',
            component: () =>
              import(
                '@/components/prescription/EditPrescriptionEventsSteps.vue'
              ),
            props: {
              keepAlive: true,
            },
          },
          ...(this.isAIPoweredPrescription
            ? [
                {
                  id: 'prescription-automation',
                  title: 'Prescription Automation',
                  component: () =>
                    import(
                      '@/components/prescription/prescription-automation/PrescriptionAutomation.vue'
                    ),
                  props: {
                    keepAlive: true,
                  },
                },
              ]
            : []),
          {
            id: 'prescription-configuration',
            title: 'Prescription configuration',
            component: () =>
              import(
                '@/components/prescription/PrescriptionAdvancedSettings.vue'
              ),
            props: {
              keepAlive: true,
            },
          },
          {
            id: 'TestPrescriptions',
            title: 'Test Prescriptions',
            component: () =>
              import('@/components/prescription/TestPrescriptions.vue'),
            props: {
              keepAlive: true,
            },
          },
          {
            id: 'export-profiles',
            title: 'Export Profiles',
            component: () =>
              import(
                '@/components/prescription/EditPrescriptionExportProfiles.vue'
              ),
            props: {
              keepAlive: true,
            },
          },
          {
            id: 'backup-restore',
            title: 'Backup & Restore',
            component: () =>
              import(
                '@/components/prescription/EditPrescriptionBackUpRestore.vue'
              ),
            props: {
              keepAlive: true,
            },
          },
        ]
      },
    },
  }
</script>
