import {
  getDocumentReference,
  updateRef,
  getNewServerTimestamp,
} from '@/service/FirebaseService'
import Auth from '@/firebase/auth'
import { arraysHasSameValues } from '@/service/UtilsService'
import Functions from '../../firebase/functions'
import { ActionTree, MutationTree, GetterTree, Commit, Dispatch } from 'vuex'
import { RIGHTS } from '@/constants/UserConstants'
import { User } from '@/types/User'
import { RootState } from '@/store'
import Vue from 'vue'

interface ScopeValue<T> {
  included: ('*' | T)[]
  excluded?: T[]
}

interface ScopeSchemaNeutral<T> {
  applications: ScopeValue<T>
  customers: ScopeValue<T>
  countries: ScopeValue<T>
  tenants: ScopeValue<T>
  touchpoints: ScopeValue<T>
  environments: ScopeValue<T>
  brands: ScopeValue<T>
}

type Keys = keyof typeof RIGHTS
type Values = (typeof RIGHTS)[Keys]

type Role = {
  id: string
  permissions: Values[]
}

class UserInitialState {
  id: string = null
  name: string = null
  firstname: string = null
  email: string = null
  groupId: string = null
  role: Role = null
  scope: ScopeSchemaNeutral<string> = null
  theme: string = null
  profilePic: string = null
  sideMenuReduced: boolean = null
  unsubscribe: () => null = null
  retailersTermsOfUseAccepted: boolean = null
  tableCustomColumns: {
    [key: string]: string[]
  } = {}
  filtersOpen: Record<string, boolean> = {}
}

export type UserState = UserInitialState

let state = new UserInitialState()

const mutations: MutationTree<UserInitialState> = {
  userMutator: (state, payload: { key: string; value: any }) => {
    state[payload.key] = payload.value
  },

  setUser: (state, user: User) => {
    Object.keys(user).forEach((userField) => {
      state[userField] = user[userField]
    })
  },

  changeSideMenuReduced: (state, sideMenuReduced: boolean) => {
    state.sideMenuReduced = sideMenuReduced
  },

  UPDATE_FILTERS_OPEN: (state, { filterId, open }: { filterId: string, open: boolean }) => {
		state.filtersOpen[filterId] = !!open
	},

  UPDATE_TABLE_CUSTOM_COLUMNS: (
    state,
    { tableId, columns }: { tableId: string; columns: string[] },
  ) => {
    Vue.set(state.tableCustomColumns, tableId, columns)
  },

  resetUserState() {
    state = new UserInitialState()
  },
}

const actions: ActionTree<UserInitialState, RootState> = {
  /**
   * Set the current user in the state.
   *
   * @async
   * @function setCurrentUser
   */
  async setCurrentUser({ state, commit, dispatch }, user: User) {
    if (!user) throw new Error('Missing user argument')
    const userId = user.id
    const userPath = `users/${userId}`
    let isGetUserLoading = true
    const userInfo = (await Functions.getUser()).user

    await setUserData({ commit, dispatch }, userInfo, user)
    const unsubscribe = getDocumentReference(userPath).onSnapshot(
      async (userSnapshot) => {
        const userData = userSnapshot.data()

        if (state.groupId !== userData.group.id) {
          if (!isGetUserLoading) {
            isGetUserLoading = true

            // Use cloud function to get group, scope and role
            const userInfo = (await Functions.getUser()).user
            isGetUserLoading = false
            await setUserData({ state, commit, dispatch }, userInfo, user)
          }
        } else {
          state.sideMenuReduced = userData.sideMenuReduced
        }
      },
    )

    await updateRef(userPath, {
      meta: { lastConnexion: getNewServerTimestamp },
    })
    isGetUserLoading = false
    commit('userMutator', { key: 'unsubscribe', value: unsubscribe })
  },

  async updateSideMenuReducedRef({ state, commit }, reduced: boolean) {
    await updateRef('/users/' + state.id, { sideMenuReduced: reduced })
    commit('changeSideMenuReduced', reduced)
  },

  async updateFiltersOpen(
    { state, commit },
    { filterId, open }: { filterId: string; open: boolean },
  ) {
    if (!filterId) return
    commit('UPDATE_FILTERS_OPEN', { filterId, open })
    await updateRef('/users/' + state.id, { filtersOpen: state.filtersOpen })
  },

  /**
   * Update.
   *
   * @async
   * @function updateTableCustomColumns
   * @param {Function} commit - The commit function.
   * @param {Object} user - The Firebase user object.
   */
  async updateTableCustomColumns(
    { state, commit },
    { tableId, columns }: { tableId: string; columns: string[] },
  ) {
    commit('UPDATE_TABLE_CUSTOM_COLUMNS', { tableId, columns })
    await updateRef('/users/' + state.id, {
      tableCustomColumns: state.tableCustomColumns,
    })
  },

  setHasAcceptedRetailersTermsOfUse({ commit }, value: boolean) {
    commit('userMutator', {
      key: 'retailersTermsOfUseAccepted',
      value: !!value,
    })
  },

  /**
   * Unset all the value in the user state.
   *
   * @function unsetCurrentUser
   */
  unsetCurrentUser({ commit }) {
    commit('resetUserState')
  },
}

const getters: GetterTree<UserInitialState, RootState> = {
  getUserScopeCollection: (state) => (collectionId: string) => {
    if (!collectionId) return []
    return state.scope[collectionId] ?? []
  },

  canUserDo: (state) => (actionId: string) => {
    if (!state?.role) return false
    if (Array.isArray(actionId))
      return actionId?.some((action) =>
        state?.role?.permissions?.includes(action),
      )
    return !!state?.role?.permissions?.includes(actionId)
  },

  isUserSet: (state) => {
    const requiredUserKeys = ['email', 'id', 'unsubscribe', 'role']

    return !requiredUserKeys.some((key) => !state[key])
  },

  getUserTheme: (state): string => {
    return state.theme
  },

  equalOrInferiorRoles: (state) => {
    switch (state?.role?.id) {
      case 'business':
        return ['business']
      case 'dmi':
        return ['dmi', 'business']
      case 'tech':
        return ['tech', 'dmi', 'business']
      case 'retailer':
        return ['retailer']
      case 'dsf_scale_service':
        return ['dsf_scale_service', 'retailer', 'tech', 'dmi', 'business']
      case 'dev':
        return [
          'dev',
          'dsf_scale_service',
          'retailer',
          'tech',
          'dmi',
          'business',
        ]
      case 'portal_administrator':
        return [
          'portal_administrator',
          'dev',
          'dsf_scale_service',
          'retailer',
          'tech',
          'dmi',
          'business',
        ]

      default:
        return []
    }
  },

  isEqualOrInferiorRole: (state, getters) => (roleId: string) => {
    return getters.equalOrInferiorRoles?.includes(roleId)
  },

  hasModifaceTenant: (state) => {
    const tenantsScopeIncluded = state?.scope?.tenants?.included
    const tenantsScopeExcluded = state?.scope?.tenants?.excluded
    return (
      (tenantsScopeIncluded?.includes('*') &&
        !tenantsScopeExcluded?.includes('modiface')) ||
      tenantsScopeIncluded.includes('modiface')
    )
  },

  isModifaceRetailer: (state, getters) => {
    return state?.role?.id === 'retailer' && getters.hasModifaceTenant
  },

  hasAcceptedRetailersTermsOfUse: (state) => {
    return !!state.retailersTermsOfUseAccepted
  },

  getTableColumns: (state) => (tableId: string) => {
    return state.tableCustomColumns[tableId]
  },

  getFilterOpen: (state) => (filterId: string) => {
    return state.filtersOpen[filterId] ?? false
  },
}

async function setUserData(
  {
    state,
    commit,
    dispatch,
  }: { state?: UserInitialState; commit: Commit; dispatch: Dispatch },
  userInfo: Record<string, any>,
  user: User,
) {
  if (userInfo === undefined) {
    dispatch('unsetCurrentUser')
    throw new Error(
      `Your user ${user.email} has been enrolled using the Google Identity Provider. Your user has not yet any permissions on Firecamp, please contact DSF to be granted with appropriate permissions on your scope.`,
    )
  }

  try {
    const date = '?' + Date.now()

    user = {
      ...user,
      ...(!!userInfo?.group?.scope && { scope: userInfo.group.scope }),
      ...(!!userInfo.group?.role && { role: userInfo.group.role }),
      ...(!!userInfo?.group?.id && { groupId: userInfo.group.id }),
      ...(!!userInfo?.theme && { theme: userInfo?.theme }),
      ...(!!userInfo?.name && { name: userInfo.name }),
      ...(!!userInfo?.firstname && { firstname: userInfo.firstname }),
      ...(!!userInfo?.email && { email: userInfo.email }),
      ...(!!userInfo?.profilePic && { profilePic: userInfo.profilePic + date }),
      ...(!!userInfo?.tableCustomColumns && {
        tableCustomColumns: userInfo.tableCustomColumns,
      }),
      ...(!!userInfo?.filtersOpen && { filtersOpen: userInfo.filtersOpen }),
      ...(Object.prototype.hasOwnProperty.call(userInfo, 'sideMenuReduced') && {
        sideMenuReduced: userInfo.sideMenuReduced,
      }),
      ...(Object.prototype.hasOwnProperty.call(
        userInfo,
        'retailersTermsOfUseAccepted',
      ) && {
        retailersTermsOfUseAccepted: userInfo.retailersTermsOfUseAccepted,
      }),
    }

    let userChanges: Record<string, any> = user

    if (state) {
      userChanges = Object.keys(state).reduce((accumulator, key) => {
        const stateValue = state?.[key]
        const userValue = user?.[key]

        switch (true) {
          case key === 'sideMenuReduced':
          case key === 'retailersTermsOfUseAccepted':
            accumulator[key] = userValue
            return accumulator
          case key === 'unsubscribe':
          case !stateValue:
          case !userValue:
            return accumulator
          case Array.isArray(stateValue) &&
            Array.isArray(userValue) &&
            !arraysHasSameValues(stateValue, userValue):
          case !(Array.isArray(stateValue) && Array.isArray(userValue)) &&
            stateValue !== userValue:
            accumulator[key] = userValue
            return accumulator
          default:
            return accumulator
        }
      }, {})
    }

    commit('setUser', userChanges)
  } catch (error) {
    dispatch('unsetCurrentUser')
    await Auth.signOut()
    throw error
  }

  return user
}

export default {
  state,
  mutations,
  actions,
  getters,
}
