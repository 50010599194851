<template>
  <div class="user-management_container container-fluid">
    <TitleContainer
      v-if="!included"
      :title="title"
    />
    <b-form
      class="user-management_form"
      @submit="onSubmit"
      @reset="onReset"
    >
      <div class="user-management_fields">
        <b-form-group
          label="Profile picture:"
          invalid-feedback="Invalid picture"
          description="You can upload a profile picture by clicking above."
        >
          <UserProfilePicture
            :image-path="userProfilePicInput"
            :user-name="userName"
            @updateProfilePic="onUpdateProfilePicFile"
          />
        </b-form-group>
        <b-form-group label="User email">
          <b-form-input
            v-model="userMailInput"
            :disabled="loading"
            type="email"
            :state="isUserMailInputValid"
            required
            placeholder="Enter email"
            @update="emailValidation(userMailInput)"
          />
        </b-form-group>
        <b-form-group label="User firstname">
          <b-form-input
            v-model="userFirstnameInput"
            :disabled="loading"
            type="text"
            placeholder="Enter user firstname"
            required
          />
        </b-form-group>
        <b-form-group label="User name">
          <b-form-input
            v-model="userNameInput"
            :disabled="loading"
            type="text"
            placeholder="Enter user name"
            required
          />
        </b-form-group>
        <b-form-group label="User team">
          <b-form-input
            v-model="userTeamInput"
            :disabled="loading"
            type="text"
            placeholder="Enter user team name"
          />
        </b-form-group>
        <b-form-group label="Security group">
          <Multiselect
            v-model="userSecurityGroup"
            placeholder="Select security group"
            track-by="id"
            label="displayName"
            :options="securityGroups"
            :show-labels="false"
            :disabled="loading"
            required
          />
        </b-form-group>

        <p>
          Users with an email adress provided by L'Oréal Group must authenticate
          themselves by clicking on "Login with Google" (it triggers the
          official L'Oréal Single Sign-On system linked to Azure Active
          Directory).
          <br />
          {{ passwordResetMessage }}
        </p>

        <b-form-group
          v-if="edition && !isLorealDomainEmail(userMailInput)"
          description="Send an email to this user inviting him to create/reset his password"
        >
          <b-form-checkbox
            v-model="userSendPasswordEmail"
            name="user-send-password-email"
            :disabled="loading"
            switch
          >
            Send password modification email
          </b-form-checkbox>
        </b-form-group>

        <b-row>
          <div :class="included ? 'action-button_container' : 'mt-4 pl-0'">
            <b-button
              type="reset"
              variant="link"
              class="text-danger"
              :class="!included ? 'w-100' : ''"
              :disabled="loading"
            >
              {{ edition ? 'Delete' : 'Reset' }}
            </b-button>
            <SubmitButton
              :disabled="isSubmitDisabled"
              :loading="loading"
              :label="edition ? 'Edit user' : 'Create new user'"
              :class="included ? 'w-auto mb-auto ml-2' : ''"
            />
          </div>
        </b-row>
      </div>
    </b-form>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { emailValidation, isLorealDomainEmail } from '@/service/FormsService'
  import { uploadUserProfilePicture } from '@/service/FirebaseService'
  import Functions from '@/firebase/functions'

  import TitleContainer from '@/components/containers/TitleContainer'
  import UserProfilePicture from '@/components/admin/user/profile/UserProfilePicture'
  import SubmitButton from '@/components/form/SubmitButton'
  import Multiselect from '@/components/form/Multiselect'

  const initialData = {
    userMailInput: null,
    isUserMailInputValid: null,
    userSecurityGroup: null,
    userNameInput: null,
    userFirstnameInput: null,
    userTeamInput: null,
    userSendPasswordEmail: false,
    userProfilePicInput: null,
    newProfilePicFile: null,
  }

  export default {
    name: 'UserCreation',

    components: {
      TitleContainer,
      Multiselect,
      SubmitButton,
      UserProfilePicture,
    },

    props: {
      included: {
        type: Boolean,
        default: false,
      },
      edition: {
        type: Boolean,
        default: false,
      },
      id: {
        type: String,
        default: null,
      },
      profilePic: {
        type: String,
        default: null,
      },
      email: {
        type: String,
        default: null,
      },
      team: {
        type: String,
        default: null,
      },
      name: {
        type: String,
        default: null,
      },
      firstname: {
        type: String,
        default: null,
      },
      group: {
        type: Object,
        default: () => ({}),
      },
    },

    data() {
      return {
        loading: false,
        ...initialData,
      }
    },

    computed: {
      ...mapState({
        securityGroups: (state) => state.scopesrolesgroups.groups,
      }),

      title() {
        return this.edition ? 'User edition' : 'User creation'
      },

      isSubmitDisabled() {
        return (
          !this.isUserMailInputValid ||
          !this.userSecurityGroup ||
          !this.userNameInput ||
          !this.userFirstnameInput
        )
      },

      userName() {
        return this.firstname?.length > 0 && this.name?.length > 0
          ? `${this.firstname} ${this.name}`
          : this.name
      },

      passwordResetMessage() {
        return this.edition
          ? 'Other users need a password. To send them an email allowing them to create their password, please activate the switch below.'
          : 'Other users will receive an email inviting them to create a password. They will use this password to login to Firecamp'
      },
    },

    watch: {
      userMailInput(value) {
        if (isLorealDomainEmail(value)) {
          this.userSendPasswordEmail = false
        }
      },
    },

    async created() {
      this.resetValues()

      if (!this.edition) {
        this.userSendPasswordEmail = true
      }

      if (this.email) {
        this.userMailInput = this.email
        this.emailValidation()
      }

      if (this.team) this.userTeamInput = this.team
      if (this.name) this.userNameInput = this.name
      if (this.firstname) this.userFirstnameInput = this.firstname
      if (this.profilePic) this.userProfilePicInput = this.profilePic
      if (this.group)
        this.userSecurityGroup = this.securityGroups.find(
          (securityGroup) => securityGroup.id === this.group.id,
        )
    },

    methods: {
      async onSubmit(event) {
        event.preventDefault()
        this.loading = true

        if (this.newProfilePicFile && this.edition) {
          this.userProfilePicInput = await uploadUserProfilePicture(
            this.id,
            this.newProfilePicFile,
          )
        }

        const user = {
          email: this.userMailInput,
          group: this.userSecurityGroup.id,
          ...(this.userTeamInput && { team: this.userTeamInput }),
          ...(this.userNameInput && { name: this.userNameInput }),
          ...(this.userFirstnameInput && {
            firstname: this.userFirstnameInput,
          }),
          ...(this.userProfilePicInput && {
            profilePic: this.userProfilePicInput,
          }),
          ...(this.id && { uid: this.id }),
        }

        let finalUser = null

        try {
          finalUser = {
            id: this.id,
            ...(this.edition
              ? await this.userEdition(user)
              : await this.userCreation(user)),
            group: this.userSecurityGroup.id,
          }
          this.$emit('done', finalUser)
        } catch (error) {
          this.handleErrors({ error })
        } finally {
          this.loading = false
        }

        return finalUser
      },

      async userCreation(user) {
        try {
          const userCreationResponse = await Functions.createFirebaseUser(
            user,
            this.userSendPasswordEmail,
            this.newProfilePicFile,
          )
          if (!userCreationResponse.success)
            throw new Error(userCreationResponse.message)
          this.setMessageInformations({
            message: `User has been successfully created.${
              this.userSendPasswordEmail
                ? ' ' +
                  this.userMailInput.toLowerCase() +
                  ' should soon receive an email to create a password.'
                : ''
            }`,
            state: 'success',
          })
          return userCreationResponse.user
        } catch (error) {
          this.handleErrors({ error })
        }
      },

      async userEdition(user) {
        try {
          const userEditionResponse = await Functions.updateFirebaseUser(
            user,
            this.userSendPasswordEmail,
          )
          if (!userEditionResponse.success)
            throw new Error(userEditionResponse.message)
          this.setMessageInformations({
            message: `User has been successfully updated.${
              this.userSendPasswordEmail
                ? ' ' +
                  this.userMailInput.toLowerCase() +
                  " should soon receive an email to reset the account's password."
                : ''
            }`,
            state: 'success',
          })
          return userEditionResponse.user
        } catch (error) {
          this.handleErrors({ error })
        }
      },

      async emailValidation() {
        try {
          if (this.userMailInput?.length > 0) {
            this.isUserMailInputValid = emailValidation(this.userMailInput)
          }
        } catch (error) {
          this.handleErrors({ error })
        }
      },

      // To be used in template
      isLorealDomainEmail,

      onUpdateProfilePicFile(file) {
        this.newProfilePicFile = file
      },

      resetValues() {
        Object.keys(initialData).forEach((key) => {
          this.$data[key] = initialData[key]
        })
      },

      onReset(event) {
        event.preventDefault()
        if (this.edition && this.id) {
          this.$emit('delete', { id: this.id })
        } else {
          this.resetValues()
        }
      },
    },
  }
</script>
