<template>
  <section>
    <TitleContainer
      title="Version mapping"
      :subtitle="appversionData?.id"
    />

    <div class="container--tabs row">
      <b-tabs
        content-class="container--vertical_content container-fuild"
        nav-wrapper-class="container--horizontal_nav container--horizontal_nav--sticky"
        nav-class="tabs_nav--horizontal"
        lazy
      >
        <p
          v-if="sectionsTabs.length === 0"
          class="no-sections"
        >
          No sections, please add one.
        </p>
        <b-tab
          v-for="(section, sectionIndex) in sectionsTabs"
          :key="`tab-${sectionIndex}`"
        >
          <template #title>
            <MappingTabTitle
              :title="section.label"
              :current-index="sectionIndex"
              section-tab-id="sections"
              :dragged-element="draggedElement"
              @removed-tab="removeTab(sectionIndex)"
              @tab-drop="
                sectionIndexUpdate(arguments[0].oldIndex, arguments[0].newIndex)
              "
              @dragstart="tabDragStart($event, 'sections', sectionIndex)"
            />
          </template>

          <MappingSectionForm
            :section-index="sectionIndex"
            :tabs-number="sectionsTabs.length"
            :current-index="sectionIndex"
            border
            class="mt-4 ml-2 mr-2"
            @order-changed="
              sectionIndexUpdate(arguments[0].oldIndex, arguments[0].newIndex)
            "
          />

          <b-tabs
            content-class="container--horizontal_content mr-0"
            nav-wrapper-class="container--vertical_nav"
            nav-class="tabs_nav--vertical"
            vertical
            card
            pills
            lazy
            class="mt-4"
          >
            <b-tab
              v-for="(subsection, subIndex) in section.subsections"
              :key="`subsection-tab-${subIndex}`"
              lazy
            >
              <template #title>
                <MappingTabTitle
                  :title="subsection.label"
                  :current-index="subIndex"
                  section-tab-id="subsections"
                  :dragged-element="draggedElement"
                  vertical
                  @removed-tab="removeSubSectionTab(sectionIndex, subIndex)"
                  @tab-drop="
                    subsectionIndexUpdate(
                      sectionIndex,
                      arguments[0].oldIndex,
                      subIndex,
                    )
                  "
                  @dragstart="tabDragStart($event, 'subsections', subIndex)"
                />
              </template>

              <MappingSectionForm
                :section-index="sectionIndex"
                :sub-index="subIndex"
                :tabs-number="section.subsections.length"
                :current-index="subIndex"
                is-subsection
                border
                @order-changed="
                  subsectionIndexUpdate(
                    sectionIndex,
                    arguments[0].oldIndex,
                    arguments[0].newIndex,
                  )
                "
              />

              <MappingFields
                :section-index="sectionIndex"
                :sub-index="subIndex"
                :collections-hierarchy="collectionsHierarchy"
                :default-content="appversionData.defaultContent"
              />
            </b-tab>
            <template #tabs-end>
              <b-nav-item
                class="tabs_tab"
                role="presentation"
                @click.prevent="newTab('subsections', sectionIndex)"
              >
                <b-icon icon="plus" />
              </b-nav-item>
            </template>
          </b-tabs>
        </b-tab>

        <template #tabs-end>
          <b-nav-item
            class="tabs_tab"
            role="presentation"
            @click.prevent="newTab('sections')"
          >
            <b-icon icon="plus" />
          </b-nav-item>
        </template>
      </b-tabs>
    </div>
    <div class="mapping-version_action-container">
      <b-button
        class="mapping-version"
        variant="success"
        :disabled="isSaving"
        @click="onSave"
      >
        <b-icon
          v-if="isSaving"
          icon="arrow-clockwise"
          animation="spin"
        />
        <b-icon
          v-else
          icon="upload"
        />
        Save version
      </b-button>
    </div>
  </section>
</template>

<script>
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
  import {
    GENERIC_ERROR_MISSING_PARAMETERS,
    GENERIC_ERROR_NO_DATA_MATCH,
    INVALID_FIELDS,
  } from '@/constants/ErrorConstants'
  import {
    getDocumentsInCollection,
    getFirstActiveDocumentData,
  } from '@/service/FirebaseService'
  import { RESOURCES } from '@/service/ResourceService'

  import TitleContainer from '@/components/containers/TitleContainer'
  import MappingSectionForm from '@/components/mapping/MappingSectionForm'
  import MappingTabTitle from '@/components/mapping/MappingTabTitle'
  import MappingFields from '@/components/mapping/MappingFields'

  export default {
    name: 'VersionMapping',

    components: {
      TitleContainer,
      MappingSectionForm,
      MappingTabTitle,
      MappingFields,
    },

    data() {
      return {
        collectionsHierarchy: {},
        appversionData: null,
        draggedElement: { sectionTabId: null, index: null, event: null },
        isSaving: false,
      }
    },

    computed: {
      ...mapGetters(['getAppVersionsById']),
      ...mapState({
        sectionsTabs: (state) => state.mapping.sectionsTabs,
      }),
    },

    async created() {
      const { appVersionId } = this.$route.params

      if ([null, undefined].includes(appVersionId)) {
        this.handleErrors({ code: GENERIC_ERROR_MISSING_PARAMETERS })
        this.$router.push({
          name: 'error-page',
          params: {
            code: 500,
            message: 'There was an issue going on this page.',
          },
        })
      }

      // REFACTOR: migrate to store action (loadAppVersion)
      const appVersionPath = `appversions/${appVersionId}`

      await this.loadResources({ resourceNames: [RESOURCES.APP_VERSIONS] })

      this.appversionData = this.getAppVersionsById(appVersionId)

      const [firehierarchy, firecomponents, defaultcontent] = await Promise.all(
        [
          getFirstActiveDocumentData(`${appVersionPath}/firehierarchy`),
          getDocumentsInCollection(`${appVersionPath}/firecomponents`),
          getDocumentsInCollection(`${appVersionPath}/defaultcontent`),
        ],
      )
      if (firecomponents) {
        this.appversionData.firecomponents = firecomponents
      }

      if (defaultcontent) {
        this.appversionData.defaultContent = defaultcontent.reduce(
          (acc, defaultcontent) => {
            acc[defaultcontent.id] = defaultcontent
            delete defaultcontent.id
            return acc
          },
          {},
        )
      }

      if (firehierarchy) {
        this.appversionData.firehierarchy = firehierarchy

        const initIndexAttribut = (
          fields,
          sectionIndex,
          subIndex,
          defaultContent,
          parentsIndexs = null,
          parentFieldsKey = null,
        ) => {
          return fields.map((f, i) => ({
            ...f,
            ...(defaultContent && defaultContent[f.settings.collection]?.[f.id]
              ? { defaultValue: defaultContent[f.settings.collection]?.[f.id] }
              : {}),
            index: i,
            sectionIndex,
            subIndex,
            forceId: true,
            ...(parentsIndexs ? { parentsIndexs } : {}),
            ...(parentFieldsKey ? { parentFieldsKey } : {}),
            ...(f.fields
              ? {
                  fields: initIndexAttribut(
                    f.fields,
                    sectionIndex,
                    subIndex,
                    null,
                    parentsIndexs ? [...parentsIndexs, i] : [i],
                  ),
                }
              : {}),
            ...(f.eventFields
              ? {
                  eventFields: initIndexAttribut(
                    f.eventFields,
                    sectionIndex,
                    subIndex,
                    null,
                    parentsIndexs ? [...parentsIndexs, i] : [i],
                    'eventFields',
                  ),
                }
              : {}),
            ...(f.stepFields
              ? {
                  stepFields: initIndexAttribut(
                    f.stepFields,
                    sectionIndex,
                    subIndex,
                    null,
                    parentsIndexs ? [...parentsIndexs, i] : [i],
                    'stepFields',
                  ),
                }
              : {}),
          }))
        }

        const sectionsTabs = firehierarchy.sections.map((section, index) => {
          return {
            ...section,
            index: index + 1,
            subsections: section.subsections.map((subsection, subIndex) => {
              const { fields, dependencies } = this.getSubsectionFields(
                subsection.id,
              )
              const fieldsFormatted = initIndexAttribut(
                fields,
                index,
                subIndex,
                this.appversionData.defaultContent,
              )
              return {
                ...subsection,
                index: subIndex + 1,
                fields: fieldsFormatted,
                dependencies,
              }
            }),
          }
        })
        this.setSectionsTabs(sectionsTabs)

        this.collectionsHierarchy = this.sectionsTabs.reduce((acc, section) => {
          section.subsections.forEach((section) => {
            section.fields.forEach((field) => {
              const collection = field?.settings?.collection
              const hierarchy = field?.settings?.hierarchy
              if (!collection || !hierarchy) return
              if (!acc[collection]) acc[collection] = []
              const hasHierarchy = acc[collection].find(
                (hier) => hierarchy[0] === hier,
              )
              if (hasHierarchy) return
              acc[collection] = [...(acc[collection] ?? []), ...hierarchy]
            })
          })
          return acc
        }, {})
      }

      if (!this.appversionData) {
        this.handleErrors({ code: GENERIC_ERROR_NO_DATA_MATCH })
      }
    },

    methods: {
      ...mapMutations({
        setSectionsTabs: 'mapping/SET_SECTIONS_TABS',
      }),
      ...mapActions('mapping', [
        'saveMapping',
        'moveSection',
        'moveSubsection',
        'removeSection',
        'removeSubsection',
        'addSectionTab',
        'addSubsection',
      ]),
      ...mapActions(['loadResources']),

      newTab(sectionTabId, sectionIndex) {
        switch (sectionTabId) {
          case 'sections': {
            const sectionsLength = this.sectionsTabs.length
            this.addSectionTab({
              id: `new_section_${sectionsLength}`,
              label: `New section ${sectionsLength}`,
              index: sectionsLength + 1,
              subsections: [
                {
                  id: 'new_subsection',
                  label: 'New subsection',
                  fields: [],
                },
              ],
            })

            break
          }
          case 'subsections': {
            const subsectionLength =
              this.sectionsTabs[sectionIndex].subsections.length

            this.addSubsection({
              sectionIndex,
              subSectionTab: {
                id: `new_subsection_${subsectionLength}`,
                label: `New subsection ${subsectionLength}`,
                index: subsectionLength + 1,
                fields: [],
              },
            })
          }
        }
      },

      removeTab(sectionIndex) {
        this.removeSection(sectionIndex)
      },

      removeSubSectionTab(sectionIndex, subIndex) {
        this.removeSubsection({ sectionIndex, subIndex })
      },

      sectionIndexUpdate(fromIndex, toIndex) {
        this.moveSection({ fromIndex, toIndex })
      },

      subsectionIndexUpdate(sectionIndex, fromIndex, toIndex) {
        this.moveSubsection({ sectionIndex, fromIndex, toIndex })
      },

      getSubsectionFields(subsectionId) {
        const component = this.appversionData.firecomponents.find(
          (firecomponent) => subsectionId === firecomponent.id,
        )
        return component
      },

      tabDragStart(event, sectionTabId, index) {
        this.draggedElement = { sectionTabId, index, event }
        event.dataTransfer.effectAllowed = 'move'
      },

      async onSave() {
        try {
          this.isSaving = true
          await this.saveMapping(this.appversionData.id)
          this.isSaving = false
          this.setMessageInformations({
            message: 'Mapping saved successfully',
            state: 'success',
          })
          if (this.$route.params.fromUpdate) {
            this.$router.push({
              name: 'update-version',
              params: {
                versionId: this.appversionData.id,
                appId: this.appversionData.application.id,
                simulation: true,
              },
            })
          }
        } catch (error) {
          if (error === INVALID_FIELDS) {
            this.handleErrors({ code: INVALID_FIELDS })
          } else {
            this.handleErrors({ error })
          }
          this.isSaving = false
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .no-sections {
    margin: 5rem auto;
    font-size: 1.5rem;
    text-align: center;
  }

  .mapping-version_action-container {
    display: flex;
    position: fixed;
    bottom: 30px;
    right: 110px;
    height: 45px;
    transition: right 0.2s ease-in-out;
    z-index: 2;
  }
</style>
