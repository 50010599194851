
  import vuescroll from 'vuescroll'
  import { compareValues } from '@/service/UtilsService'
  import Search from '@/components/menus/Search.vue'
  import SortAlphaButtons from '@/components/buttons/SortAlphaButtons.vue'

  export default {
    name: 'InPageSideMenu',

    components: {
      vuescroll,
      Search,
      SortAlphaButtons,
    },

    props: {
      title: {
        type: String,
        default: null,
      },
      items: {
        type: Array,
        required: true,
      },
      overrideActiveItem: {
        type: String,
        default: null,
      },
      sortable: {
        type: Boolean,
        default: true,
      },
      areItemsSubmenus: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        searchText: null,
        sortMode: null,
        sortableItems: [],
        scrollbarOps: {
          scrollPanel: {
            scrollingX: false,
            speed: 600,
            easing: 'easeInOutQuart',
          },
          rail: {
            background: '#ffffff',
            opacity: 0.2,
            gutterOfSide: '2px',
          },
          bar: {
            onlyShowBarOnScroll: false,
            keepShow: true,
          },
        },
        isBarVisible: false,
        collapseShown: [],
        activeItem: null,
      }
    },

    computed: {
      sortActionAlphaDescClass() {
        return {
          'sort-action': true,
          'sort-action--selected': this.sortMode === 'alphaDesc',
        }
      },

      sortActionAlphaAscClass() {
        return {
          'sort-action': true,
          'sort-action--selected': this.sortMode === 'alphaAsc',
        }
      },

      stickyContainerClasses() {
        return {
          'page-side-menu_sticky-container': true,
          'page-side-menu_sticky-container--no-title':
            !this.reducedHeight &&
            (this.areItemsSubmenus || this.title === null),
          'page-side-menu_sticky-container--reduced-no-title':
            this.reducedHeight &&
            (this.areItemsSubmenus || this.title === null),
          'page-side-menu_sticky-container--reduced':
            this.reducedHeight && !this.areItemsSubmenus && this.title,
        }
      },

      filteredSortedItems() {
        return this.sortItems(this.sortableItems)?.filter((item) =>
          this.searchText
            ? item?.label?.toLowerCase().includes(this.searchText.toLowerCase())
            : item,
        )
      },

      filteredSortedSubMenuItems() {
        return this.sortableItems.map((subMenuItem) => {
          return {
            category: subMenuItem.category,
            items: this.sortItems(subMenuItem.items).filter((item) =>
              this.searchText
                ? item?.label
                    ?.toLowerCase()
                    .includes(this.searchText.toLowerCase())
                : item,
            ),
          }
        })
      },

      isCollapseShown() {
        return this.collapseShown.some((collapse) => collapse)
      },
    },

    watch: {
      items(newVal) {
        this.sortableItems = JSON.parse(JSON.stringify(newVal))
      },

      overrideActiveItem(newVal) {
        this.activeItem = newVal
      },
    },

    created() {
      this.sortableItems = JSON.parse(JSON.stringify(this.items))
      this.activeItem = this.overrideActiveItem
    },

    methods: {
      selectItem(itemId: number, category?) {
        this.activeItem = itemId
        this.$emit('selectItem', { itemId, category })
      },

      handleSortAlpha(type) {
        this.sortMode = type
        if (this.sortMode === null) {
          this.sortableItems = JSON.parse(JSON.stringify(this.items))
        }
      },

      sortItems(itemsToSort) {
        if (this.sortMode === null || !this.sortable) return itemsToSort
        return itemsToSort.sort(
          compareValues('label', this.sortMode === 'alphaAsc' ? 'asc' : 'desc'),
        )
      },

      isItemListEmpty(itemList) {
        return !itemList || itemList?.length < 1
      },

      clearSearch() {
        this.searchText = null
      },

      handleResize(v) {
        this.isBarVisible = v.barSize > 0
      },
    },
  }
