import Firebase from './firebase'
import 'firebase/compat/firestore'

const FIRESTORE = Firebase.firestore()

// Allow to use the local rules.
if (process.env.VUE_APP_ENV === 'local') {
  FIRESTORE.settings({
    experimentalForceLongPolling: true,
    host: 'localhost:8081',
    ssl: false,
    merge: true,
  })
} else {
  FIRESTORE.settings({
    experimentalForceLongPolling: true,
    merge: true,
  })
}

export default FIRESTORE
