<template>
  <AuthContainer
    title="Logged out"
    :on-submit-callback="() => {}"
    :loading="false"
  >
    <b-button
      :to="{ name: 'login' }"
      class="w-100"
      variant="primary"
      >Redirect now</b-button
    >
  </AuthContainer>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import { AUTH_ERROR_LOGOUT } from '@/constants/ErrorConstants'
  import Auth from '@/firebase/auth'
  import AuthContainer from '@/components/auth/AuthContainer.vue'

  let timeout

  export default {
    name: 'Logout',

    components: {
      AuthContainer,
    },

    beforeRouteLeave(from, to, next) {
      // Clear the timeout to avoid double redirection.
      clearTimeout(timeout)
      next()
    },

    data() {
      return {
        success: null,
        secondCounter: 2,
      }
    },

    computed: {
      ...mapState({
        unsubscribeUser: (state) => state.user.unsubscribe,
        unsubscribeSettings: (state) => state.settings.unsubscribe,
      }),
    },

    created() {
      this.logout()
    },

    methods: {
      ...mapActions(['unbindApp']),

      logout() {
        this.unsubscribeSettings()
        this.unsubscribeUser()
        this.unbindApp()
        setTimeout(() => {
          Auth.signOut()
            .then(async () => {
              this.success = true
              this.setMessageInformations({
                message:
                  'You have been correctly logged out of Firecamp. Redirecting ...',
                state: 'success',
              })
              await this.redirectTo('login')
            })
            .catch(async () => {
              this.handleErrors({ code: AUTH_ERROR_LOGOUT })
              await this.redirectTo('homepage')
            })
        }, 1000)
      },

      async redirectTo(routeName) {
        setInterval(() => {
          if (this.secondCounter > 0) this.secondCounter -= 1
        }, 1000)
        timeout = setTimeout(() => this.$router.push({ name: routeName }), 2000)
      },
    },
  }
</script>
