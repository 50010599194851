var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CollapseContainer',{staticClass:"mapping-field_settings",attrs:{"variant":"warning","title":"Settings","border":"","open":_vm.isOpen},on:{"update:open":function($event){_vm.isOpen=$event}}},[_c('div',{staticClass:"mapping-field_settings_wrapper"},[_vm._l((_vm.sortedSettings),function(settingValue,settingKey){return _c('b-form-group',{key:`field-${settingKey}`,staticClass:"align-content-center mb-2",attrs:{"label-cols":"12","label-cols-lg":"2","label-cols-md":"12","label":`${_vm.customLabel(settingKey)}:`,"required":settingKey === 'collection'}},[(settingKey === 'collection')?_c('b-select',{attrs:{"value":settingValue,"options":[
          'appcustomisations',
          'appconfigurations',
          'appcontent',
          'appresources',
        ]},on:{"input":function($event){return _vm.updateSettings(settingKey, $event)}}}):(settingKey === 'collectionType')?_c('b-select',{attrs:{"value":settingValue,"options":[
          'questionnaires',
          'prescription',
          'repeater',
          'dictionnaries',
        ]},on:{"input":function($event){return _vm.updateSettings(settingKey, $event)}}}):(settingKey === 'minChecked')?_c('b-input',{attrs:{"type":"number","value":settingValue},on:{"change":function($event){_vm.updateSettings(
            settingKey,
            _vm.getType(settingValue) === 'number' ? +$event : $event,
          )}}}):(
          settingKey === 'hierarchy' && _vm.hierarchyFields && settingValue
        )?_c('Multiselect',{attrs:{"value":settingValue,"placeholder":"Select the hierarchy","select-label":"","deselect-label":"","options":_vm.hierarchyOptions,"disabled":!_vm.settings.collection,"taggable":"","multiple":"","allow-unknow":""},on:{"input":function($event){return _vm.hierarchyInput(arguments[0])},"tag":_vm.addHierarchyField}}):(settingKey === 'fileTypes')?_c('Multiselect',{attrs:{"value":settingValue.replace(/\s+/g, '').split(','),"placeholder":"Select file types","select-label":"","deselect-label":"","options":_vm.FILES_TYPES,"multiple":"","taggable":"","allow-unknow":""},on:{"input":function($event){_vm.updateSettings(settingKey, $event.join(','))}}}):(settingKey === 'allowedOptions')?_c('Multiselect',{attrs:{"value":_vm.getRichTextAllowedOptions(settingValue),"options":_vm.RICH_TEXT_ALLOWED_OPTIONS,"group-label":"id","group-values":"sub","placeholder":"Select allowed options","select-label":"","deselect-label":"Remove","group-select":"","multiple":"","allow-unknow":""},on:{"input":function($event){return _vm.updateSettings(settingKey, $event)}}}):(settingKey === 'dependencies')?_c('MappingFieldsFieldSettingsDependencies',{staticClass:"ml-4",attrs:{"dependencies":settingValue},on:{"change":function($event){return _vm.updateSettings(settingKey, $event)}}}):(['text', 'number'].includes(_vm.getType(settingValue)))?_c('b-input-group',{attrs:{"append":_vm.getSuffix(settingKey)}},[_c('b-input',{attrs:{"value":settingValue,"type":_vm.getType(settingValue),"debounce":500},on:{"input":function($event){_vm.updateSettings(
              settingKey,
              _vm.getType(settingValue) === 'number' ? +$event : $event,
            )}}})],1):(_vm.getType(settingValue) === 'boolean')?_c('b-form-checkbox',{staticClass:"mapping-field_settings_formgroup_switch",attrs:{"checked":settingValue,"switch":""},on:{"input":function($event){return _vm.updateSettings(settingKey, !settingValue)}}}):_vm._e()],1)}),(_vm.settingsCouldBeAdded.length > 0)?_c('b-input-group',{staticClass:"col-lg-3 ml-auto pr-0",attrs:{"size":"sm"}},[_c('b-select',{attrs:{"options":_vm.settingsCouldBeAdded},on:{"change":({ key, value }) => _vm.updateSettings(key, value)}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('span',[_vm._v("Add")])])],1):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }