<template>
  <ConfirmationModal
    v-if="value"
    modal-id="all-profiles"
    :allow-copy-button="false"
    slot-only
    item-id="I want to delete all profiles"
    @cancel="emit('input', false)"
    @close="emit('input', false)"
    @deleteConfirmed="deleteAllProfilesConfirmed"
  >
    You are about to delete <strong>all profiles</strong> of this prescription.
    This actions is <strong>irreversible</strong>.

    <p class="mt-2">
      If you change your mind, you'll have to re-enter them all by hand.
    </p>
    <p>
      Please enter
      <strong class="text-danger"> I want to delete all profiles </strong> to
      confirm.
    </p>
  </ConfirmationModal>
</template>

<script setup>
  import { computed, watch, nextTick } from 'vue'
  import Functions from '@/firebase/functions'
  import ConfirmationModal from '@/components/modals/ConfirmationModal'
  import { useBvModal } from '@/utils/hooks/useBvModal'
  import { useStore } from '@/utils/hooks/useStore'
  import { useAppLoading } from '@/utils/hooks/useAppLoading'

  const props = defineProps({
    value: Boolean,
  })
  const emit = defineEmits(['input'])

  const { show } = useBvModal()
  const { dispatch } = useStore()
  const { appLoading } = useAppLoading()

  watch(
    () => props.value,
    (value) => {
      if (value) {
        nextTick(() => {
          show('modal-delete-confirmation-all-profiles')
        })
      }
    },
  )

  const appRouteId = computed(
    (ctx) => ctx.$store.state.approutes.currentRoute?.id,
  )

  async function deleteAllProfilesConfirmed() {
    appLoading(true)
    emit('input', false)
    await Functions.deleteAllPrescriptionProfiles(appRouteId.value)
    dispatch('deleteAllProfilesFromState')
    appLoading(false)
  }
</script>
