enum BrandApiTemplateEnum {
  Local = 'Local',
  NGLORA = 'NGLORA',
  WSF = 'WSF',
  Baozun = 'Baozun',
  Salesforce = 'Salesforce',
  Magento = 'Magento',
  VTEX = 'VTEX',
  Shopee = 'Shopee',
}

export enum BrandApiAuthenticationEnum {
  Anonymous = 'anonymous',
  ApiKey = 'apikey',
  Bearer = 'bearer',
  Basic = 'basic',
}

interface ApiKeyAuthenticationConfiguration {
  headername: string
  apikey: string
}
interface BasicAuthenticationConfiguration {
  username: string
  password: string
}
interface BearerAuthenticationConfiguration {
  token: string
}

type AuthenticationInfosType =
  | ApiKeyAuthenticationConfiguration
  | BasicAuthenticationConfiguration
  | BearerAuthenticationConfiguration
  | null

export interface CatalogConfigurationWithoutBrandApi {
  name?: string
  template?: BrandApiTemplateEnum.Local | string
  customerCode?: string
  countryCode?: string
}

export interface CatalogConfigurationWithBrandApi {
  customerCode?: string
  countryCode?: string
  name?: string
  apiUri?: string
  query?: null
  authenticationType?: BrandApiAuthenticationEnum
  authentication?: AuthenticationInfosType
  template?: BrandApiTemplateEnum | string
  productSyncInterval?: string
  syncTimeout?: string
  maxConcurrentFetch?: number
  delayBetweenFetch?: string
  testSKU?: string
}

export enum BulkProductsActionEnum {
  ADD = 'add',
  UPDATE = 'update',
  DELETE = 'delete',
}

export interface BulkProductsPayload {
  actions: {
    action: BulkProductsActionEnum
    product: {
      sku: string
      isActive?: boolean
      brandCode?: string
      countryCode?: string
      name?: string
      originalName?: string
      franchise?: string
      shortDescription?: string
      packshotImageUrl?: string
      pdpUrl?: string
      eCommerce?: {
        price: number
        currency: string
        orderable: true
        size: string
        color: string
        availability: string
        priceWithoutDiscount: number
        clickToBuyUrl: string
        outOfStock: number
      }
      analytics?: {
        originalName: string
        productMasterId: string
        brandAnalyticsTrigram: string
        productCategory: string
        productVariant: string
        outOfStock: number
        serviceEngraving: string
        serviceVirtualTryOn: string
      }
      brandLabel?: string
      subTitle?: string
      services?: [
        {
          type: string
          available: true
        },
      ]
      variations?: [
        {
          sku: string
          variationValues: [
            {
              id: string
              value: string
              hexaCode: string
              image: string
            },
          ]
        },
      ]
      rating?: number
      ratingCount?: number
      addToCartSku?: string
      family?: string
      modifaceVTOUpcName?: string
      shadeImagePath?: string
      shadeName?: string
      hexValue?: string
      finish?: string
      collection?: string
      isNew?: boolean
    }
  }[]
}
