import {
  updateRef,
  getFirstActiveDocumentData,
} from '@/service/FirebaseService'
import Functions from '@/firebase/functions'
import { compareValues } from '@/service/UtilsService'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { RootState } from '@/store'
import { Application, DocumentMeta } from '@/models'

export const PRODUCTAPISETTINGS_PATH = '/productapisettings'

type ProductApiSettingsFormData = {
  template: string
  authModeSelected: string
  apiUri: string
  apiUsername?: string
  apiPassword?: string
  headerName?: string
  headerValue?: string
  pdpUrl?: string
  authenticationId?: string
  customParameters: Record<string, string>
  query?: string
  airtables: Array<{
    serviceName: Application
    airtableUri: string
    authenticationId?: string
  }>
  addedValues: Array<{
    key: string
    value: string
  }>
}

class Template {
  hasBrandApi: boolean
  isModifaceTenant: boolean
  customParameters: {
    key: string
    value: string
  }[]
  constructor(
    hasBrandApi = true,
    isModifaceTenant = false,
    customParameters = [],
  ) {
    this.hasBrandApi = hasBrandApi
    this.isModifaceTenant = isModifaceTenant
    this.customParameters = customParameters
  }
}

type Hierarchy = { customerId: string; countriesIds: string[] }

type ProductApiSettingsState = {
  templates: Record<string, Template>
  propertiesCouldBeAdded: string[]
  hierarchyStaging: Hierarchy[]
  hierarchyProduction: Hierarchy[]
  countryCode: string
  customerId: string
}

const initialState = {
  templates: {},
  propertiesCouldBeAdded: ['pdpUrl', 'defaultCurrency'],
  hierarchyStaging: [],
  hierarchyProduction: [],
  countryCode: '',
  customerId: '',
}

const state: ProductApiSettingsState = { ...initialState }

const mutations: MutationTree<ProductApiSettingsState> = {
  SET_TEMPLATES: (state, payload: Record<string, Template>) => {
    state.templates = payload
  },
  SET_HIERARCHY_STAGING: (state, payload: Hierarchy[]) => {
    state.hierarchyStaging = payload
  },
  SET_HIERARCHY_PRODUCTION: (state, payload: Hierarchy[]) => {
    state.hierarchyProduction = payload
  },
  SET_COUNTRY_CODE: (state, payload: string | null) => {
    state.countryCode = payload || ''
  },
  SET_CUSTOMER_ID: (state, payload: string) => {
    state.customerId = payload
  },
}

const actions: ActionTree<ProductApiSettingsState, RootState> = {
  setCountryCustomerId(
    { commit },
    payload: { countryCode: string; customerId: string },
  ) {
    const { countryCode, customerId } = payload

    if (customerId) {
      commit('SET_CUSTOMER_ID', customerId)
    }
    commit('SET_COUNTRY_CODE', countryCode)
  },

  async loadProductApiParams({ commit, rootGetters }) {
    const [stagingRes, productionRes, docData] = await Promise.all([
      Functions.getProductConfigurations('staging'),
      Functions.getProductConfigurations('production'),
      getFirstActiveDocumentData(PRODUCTAPISETTINGS_PATH),
    ])

    let templates = (docData?.templates as Record<string, Template>) || {}
    const hierarchyStaging = stagingRes.configurations.reduce((acc, config) => {
      if (!templates[config.template]) {
        templates[config.template] = new Template()
      }

      acc = addCountryToHierarchy(acc, config.brandCode, config.countryCode)
      return acc
    }, [])

    const hierarchyProduction = productionRes.configurations.reduce(
      (acc, config) => {
        if (!templates[config.template]) {
          templates[config.template] = new Template()
        }

        acc = addCountryToHierarchy(acc, config.brandCode, config.countryCode)
        return acc
      },
      [],
    )

    await updateRef(docData.path, {
      templates: JSON.parse(JSON.stringify(templates)),
    })

    if (!rootGetters.hasModifaceTenant) {
      templates = Object.fromEntries(
        Object.entries(templates).filter(
          ([, { isModifaceTenant }]) => !isModifaceTenant,
        ),
      )
    }

    commit('SET_TEMPLATES', templates)
    commit('SET_HIERARCHY_STAGING', hierarchyStaging)
    commit('SET_HIERARCHY_PRODUCTION', hierarchyProduction)
  },

  async loadProductApiConfig(
    { state, rootState },
    {
      customerId,
      countryCode,
      env = 'staging',
    }: {
      customerId: string
      countryCode: string
      env?: 'staging' | 'production'
    },
  ) {
    let productApiSettings // TODO: add TYpe when function is ready
    try {
      productApiSettings = await Functions.getProductConfiguration(
        env,
        customerId,
        countryCode,
      )
    } catch (_error) {
      // NOTHING DATA NOT EXIST
    }

    if (!productApiSettings?.success) {
      return undefined
    }
    const formData = {
      ...productApiSettings.configuration,
      customParameters: {},
      authModeSelected: 'none',
      addedValues: [],
    }

    if (productApiSettings.configuration.customParameters) {
      formData.customParameters =
        productApiSettings.configuration.customParameters
    }

    if (formData.header) {
      formData.authModeSelected = 'header'
      formData.headerName = formData.header.name
      formData.headerValue = formData.header.value
    } else if (formData.apiUser && formData.apiPwd) {
      formData.authModeSelected = 'username_password'
      formData.apiUsername = formData.apiUser
      formData.apiPassword = formData.apiPwd
    }

    formData.airtables = formData.airtableUriList?.map((airtable) => {
      let app: Application
      if (airtable.serviceName === 'virtualtryon') {
        app = {
          ...rootState.applications.find((app) => app.id === 'vto'),
          id: 'virtualtryon',
        }
      } else if (airtable.serviceName === 'defaultService') {
        app = {
          id: 'defaultService',
          displayName: 'Default',
          meta: <DocumentMeta>{
            active: true,
          },
        }
      } else {
        app = rootState.applications.find(
          (app) => app.id === airtable.serviceName,
        )
      }

      return {
        ...airtable,
        airtableUri: airtable.url,
        serviceName: app,
      }
    }) ?? [
      {
        authenticationId: 'AirtableToken',
        serviceName: '',
      },
    ]

    state.propertiesCouldBeAdded.forEach((key) => {
      if (formData[key]) {
        formData.addedValues.push({
          key,
          value: formData[key],
        })
      }
    })
    return formData
  },

  async getAirtableURIFromAppRouteConfiguration(
    { rootGetters, dispatch },
    {
      customerId,
      countryCode,
      env,
      serviceName,
    }: {
      customerId: string
      countryCode: string
      env: string
      serviceName: string
    },
  ) {
    await dispatch('bindAppRoutes')

    const routes = rootGetters.getAppRoutesByCustomerCountryEnvAppIds(
      customerId,
      countryCode,
      env,
      serviceName,
    )

    for (const { id } of routes) {
      const configurations = await getFirstActiveDocumentData(
        `approutes/${id}/appconfigurations`,
      )
      if (!configurations) continue
      const airtableId = configurations.airtableApi?.airtableId

      if (airtableId) {
        return airtableId
      }
    }

    return null
  },

  async updateProductApiParams(
    { state, rootState, commit, getters },
    {
      formData,
      customerId,
      countryCode,
      env = 'staging',
    }: {
      formData: ProductApiSettingsFormData
      customerId: string
      countryCode: string
      env?: 'staging' | 'production'
    },
  ) {
    const isNew =
      env === 'staging' ? !getters.hasStagingData : !getters.hasProductionData

    const body = {
      AirtableUriList: formData.airtables.map((airtable) => ({
        url: airtable.airtableUri,
        serviceName: airtable.serviceName.id,
        authenticationId: airtable.authenticationId,
      })),
      Template: formData.template,
      Query: '',
      ApiUri: formData.apiUri,
      ApiPwd: '',
      ApiUser: '',
      PdpUrl: formData.pdpUrl ?? '',
      AuthenticationId: formData.authenticationId ?? '',
      customParameters: null,
      Header: null,
      tenant: '',
    }

    if (state.templates[formData.template].customParameters) {
      body.customParameters = formData.customParameters
    }

    if (state.templates[formData.template].hasBrandApi) {
      body.Query = formData.query ?? ''
      body.ApiUri = formData.apiUri
      if (formData.authModeSelected === 'header') {
        body.Header = {
          Name: formData.headerName,
          Value: formData.headerValue,
        }
      } else if (formData.authModeSelected === 'username_password') {
        body.ApiPwd = formData.apiPassword
        body.ApiUser = formData.apiUsername
      }
    }

    formData.addedValues.forEach(({ key, value }) => {
      body[key] = value
    })

    const customer = rootState.customers.find((c) => c.id === customerId)
    const tenant = customer?.tenant?.id
    if (tenant) body.tenant = tenant

    if (isNew) {
      await Functions.createProductConfiguration(
        env,
        customerId,
        countryCode,
        body,
      )
      const hierarchyName =
        'hierarchy' + env.charAt(0).toUpperCase() + env.slice(1)
      const hierarchy = addCountryToHierarchy(
        state[hierarchyName],
        customerId,
        countryCode,
      )
      commit('SET_HIERARCHY_' + env.toUpperCase(), hierarchy)
    } else {
      await Functions.updateProductConfiguration(
        env,
        customerId,
        countryCode,
        body,
      )
    }
  },
}

const getters: GetterTree<ProductApiSettingsState, RootState> = {
  hasProductionData: (state) => {
    if (!state.countryCode || !state.customerId) return false
    const customer = state.hierarchyProduction.find(
      ({ customerId }) => customerId === state.customerId,
    )
    return customer?.countriesIds?.includes(state.countryCode) || false
  },
  hasStagingData: (state) => {
    if (!state.countryCode || !state.customerId) return false
    const customer = state.hierarchyStaging.find(
      ({ customerId }) => customerId === state.customerId,
    )
    return customer?.countriesIds?.includes(state.countryCode) || false
  },
  country: (state, _getters, _rootState, rootGetters) => {
    return rootGetters.getRootStateValueById({
      stateKey: 'countries',
      id: state.countryCode,
    })
  },
  customer: (state, _getters, _rootState, rootGetters) => {
    return rootGetters.getRootStateValueById({
      stateKey: 'customers',
      id: state.customerId,
    })
  },
  templatesLabels: (state) => {
    return Object.keys(state.templates).sort(compareValues())
  },
}

const addCountryToHierarchy = (
  hierarchy: Hierarchy[],
  customerId: string,
  countryCode: string,
) => {
  const newHierarchy = [...hierarchy]

  const customer = newHierarchy.find((h) => h.customerId === customerId)

  if (customer) {
    if (!customer.countriesIds.includes(countryCode)) {
      customer.countriesIds.push(countryCode)
    }
  } else {
    newHierarchy.push({
      customerId,
      countriesIds: [countryCode],
    })
  }

  return newHierarchy
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
