<template>
  <BaseAdminPage
    :title="title"
    :components="components"
  />
</template>

<script>
  import BaseAdminPage from '@/components/admin/BaseAdminPage'
  import UserManagement from '@/components/admin/user/UserManagement'
  import ScopeManagement from '@/components/admin/user/ScopeManagement'
  import GroupManagement from '@/components/admin/user/GroupManagement'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'UserAdmin',

    components: {
      BaseAdminPage,
    },

    computed: {
      ...mapGetters(['canUserDo']),

      title() {
        return this.$route.meta?.displayName ?? 'User management'
      },

      components() {
        return [
          ...(this.canUserDo('user-management-users')
            ? [
                {
                  id: 'UserManagement',
                  title: 'User Management',
                  component: UserManagement,
                },
              ]
            : []),
          ...(this.canUserDo('user-management-groups')
            ? [
                {
                  id: 'GroupManagement',
                  title: 'Group Management',
                  component: GroupManagement,
                },
              ]
            : []),
          ...(this.canUserDo('user-management-scopes')
            ? [
                {
                  id: 'ScopeManagement',
                  title: 'Scope Management',
                  component: ScopeManagement,
                },
              ]
            : []),
        ]
      },
    },

    async created() {
      this.appLoading(true)
      await this.initScopesGroups()
      this.appLoading(false)
    },

    methods: mapActions('scopesrolesgroups', ['initScopesGroups']),
  }
</script>
