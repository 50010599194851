import {
  getDocumentsInCollection,
  database,
  FirestoreReference,
} from '../../service/FirebaseService'
import Vue from 'vue'
import { DocumentMeta } from '@/models'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { RootState } from '@/store'

type Category = {
  id: string
  displayName: string
  meta: DocumentMeta
}

type Page = {
  id: string
  displayName: string
  meta: DocumentMeta
  parent?: Page
  sourceDocumentation: string
  text: string
  weight: number
}

type DocumentationsState = {
  categories: Category[]
  pages: Page[]
}

const initialState = {
  categories: [],
  pages: [],
}

const state: DocumentationsState = { ...initialState }

const mutations: MutationTree<DocumentationsState> = {
  INIT_CATEGORIES(state, categories) {
    Vue.set(state, 'categories', categories)
  },

  INIT_PAGES(state, pages) {
    Vue.set(state, 'pages', pages)
  },
}

const actions: ActionTree<DocumentationsState, RootState> = {
  async loadCategories({ commit, rootState }) {
    function customFilter(ref: FirestoreReference) {
      return ref.where(
        'meta.allowedRoles',
        'array-contains',
        database.doc('securityroles/' + rootState.user.role.id),
      )
    }
    const categories = await getDocumentsInCollection(
      'documentations',
      true,
      null,
      null,
      customFilter,
    )
    commit('INIT_CATEGORIES', categories)
  },

  async loadPages({ commit, state }) {
    const pages = (
      await Promise.all(
        state.categories.map(async ({ id }) => {
          const categoryPages = await getDocumentsInCollection(
            `documentations/${id}/pages`,
          )

          const formattedPages = categoryPages?.map((page) => {
            return { ...page, sourceDocumentation: id }
          })
          return formattedPages
        }),
      )
    ).flat()

    commit('INIT_PAGES', pages)
  },
}

const getters: GetterTree<DocumentationsState, RootState> = {
  getPagesByCategories: (state) => (categoryIds: string[]) => {
    if (categoryIds.length === 0) return state.pages
    return state.pages.filter((page) =>
      categoryIds.includes(page.sourceDocumentation),
    )
  },

  getPagesByCategory: (state) => (categoryId: string) => {
    return state.pages.filter((page) => page.sourceDocumentation === categoryId)
  },

  getCategoryById: (state) => (id: string) => {
    return state.categories.find((category) => category.id === id)
  },

  getPageById: (state) => (id: string) => {
    return state.pages.find((page) => page.id === id)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
