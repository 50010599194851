<template>
  <b-modal
    id="modal-item-debug-info"
    :visible="visible"
    size="xl"
    :title="title"
    hide-footer
    v-on="$listeners"
    @change="$emit('input', $event)"
  >
    <b-button
      class="mt-3 mb-3"
      variant="warning"
      @click="recallAndForceRefresh"
    >
      <b> Recall and force cache refresh </b>
    </b-button>

    <h3 class="mb-4">Debug information</h3>

    <div class="m-3">
      <b-form-group
        label="Status code:"
        class="debug-info_field-group mb-4"
      >
        <b-form-input
          class="debug-info_field"
          :value="debugInfo.productSourceHttpCode ?? 'NONE'"
          type="text"
          :state="productStatusCodeState"
          disabled
        />
      </b-form-group>

      <b-form-group
        label="Product source url:"
        class="debug-info_field-group"
      >
        <b-input-group>
          <b-form-input
            class="debug-info_field"
            :value="debugInfo.productSourceUrl"
            type="url"
            disabled
          />

          <b-input-group-append v-if="debugInfo.productSourceUrl">
            <b-button
              variant="outline-primary"
              @click="goToUrl(debugInfo.productSourceUrl)"
            >
              Go to URL
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>

      <b-form-group
        label="Product cache last updated at:"
        class="debug-info_field-group"
      >
        <b-input-group>
          <b-form-input
            class="debug-info_field"
            :value="productCacheUpdated"
            type="text"
            disabled
          />

          <b-input-group-append>
            <b-button
              variant="outline-primary"
              @click="productCacheDateFormated = !productCacheDateFormated"
            >
              {{
                productCacheDateFormated ? 'Original string' : 'Formated date'
              }}
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>

      <b-form-group
        :label="`Product cache file${
          formatedFullproductSourceJson ? ' summary' : ''
        }:`"
        class="debug-info_field-group"
      >
        <JSONEditor
          v-if="!productSourceJsonSummarized && formatedFullproductSourceJson"
          id="debug-info-product-cache-file"
          :json-value="formatedFullproductSourceJson"
          class="json-editor"
          :disabled="true"
        />

        <div v-else-if="formatedFullproductSourceJson">
          <ul class="debug-info-file-summary_list">
            <li
              v-for="(line, index) in productSourceJsonSummary"
              :key="`product-cache-summary-line-${index}`"
            >
              <u> {{ line.label }} </u>
              <a
                v-if="productSummaryLink.includes(line.label)"
                :href="line.value"
                target="_blank"
              >
                {{ `: ${line.value}` }}
              </a>
              <span v-else> {{ `: ${line.value}` }} </span>
            </li>
          </ul>
        </div>

        <span
          v-else
          class="not-found-label"
        >
          NOT FOUND.
        </span>

        <b-button
          v-if="formatedFullproductSourceJson"
          class="mt-2"
          variant="outline-primary"
          @click="productSourceJsonSummarized = !productSourceJsonSummarized"
        >
          {{ productSourceJsonSummarized ? 'Show full JSON' : 'Show summary' }}
        </b-button>
      </b-form-group>

      <b-form-group
        label="Airtable source url:"
        class="debug-info_field-group"
      >
        <b-input-group>
          <b-form-input
            class="debug-info_field"
            :value="debugInfo.airtableSourceUrl"
            type="url"
            disabled
          />

          <b-input-group-append v-if="debugInfo.airtableSourceUrl">
            <b-button
              variant="outline-primary"
              @click="goToUrl(debugInfo.airtableSourceUrl)"
            >
              Go to URL
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>

      <b-form-group
        label="Airtable cache last updated at:"
        class="debug-info_field-group"
      >
        <b-input-group>
          <b-form-input
            class="debug-info_field"
            :value="airtableCacheUpdated"
            type="text"
            disabled
          />

          <b-input-group-append>
            <b-button
              variant="outline-primary"
              @click="airtableCacheDateFormated = !airtableCacheDateFormated"
            >
              {{
                airtableCacheDateFormated ? 'Original string' : 'Formated date'
              }}
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>

      <b-form-group
        :label="`Airtable cache file${
          formatedFullairtableSourceJson ? ' summary' : ''
        }:`"
        class="debug-info_field-group"
      >
        <JSONEditor
          v-if="!airtableSourceJsonSummarized && formatedFullairtableSourceJson"
          id="debug-info-airtable-cache-file"
          :json-value="formatedFullairtableSourceJson"
          class="json-editor"
          :disabled="true"
        />

        <div v-else-if="formatedFullairtableSourceJson">
          <ul>
            <li>{{ airtableSourceJsonSummary }}</li>
          </ul>
        </div>

        <span
          v-else
          class="not-found-label"
        >
          NOT FOUND.
        </span>

        <b-button
          v-if="formatedFullairtableSourceJson"
          class="mt-2"
          variant="outline-primary"
          @click="airtableSourceJsonSummarized = !airtableSourceJsonSummarized"
        >
          {{ airtableSourceJsonSummarized ? 'Show full JSON' : 'Show summary' }}
        </b-button>
      </b-form-group>
    </div>
  </b-modal>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import ProductService from '@/service/ProductService'
  import JSONEditor from '@/components/fields/genericFields/JSONEditor.vue'

  export default {
    name: 'ItemDebugInfoModal',

    components: {
      JSONEditor,
    },

    model: {
      event: 'input',
      prop: 'visible',
    },

    props: {
      visible: Boolean,
      item: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        productCacheDateFormated: true,
        airtableCacheDateFormated: true,
        productSourceJsonSummarized: true,
        airtableSourceJsonSummarized: true,
        productSummaryLink: ['url', 'add_to_cart', 'image'],
      }
    },

    computed: {
      ...mapGetters(['getConfigurationInformations']),

      title() {
        return `Product API debug information - Item: ${this.item?.label} (${this.item?.id})`
      },

      debugInfo() {
        return this.item?.debugInfo ?? {}
      },

      productCacheUpdated() {
        return this.productCacheDateFormated
          ? new Date(
              this.debugInfo.productCacheUpdateUtcDatetime,
            )?.toUTCString()
          : this.debugInfo.productCacheUpdateUtcDatetime
      },

      airtableCacheUpdated() {
        return this.airtableCacheDateFormated
          ? new Date(
              this.debugInfo.airtableCacheUpdateUtcDatetime,
            )?.toUTCString()
          : this.debugInfo.airtableCacheUpdateUtcDatetime
      },

      productSourceJsonSummary() {
        const cacheFile = JSON.parse(this.debugInfo.productSourceJson)
        return [
          ...(cacheFile.master_id
            ? [{ label: 'master_id', value: cacheFile.master_id }]
            : []),
          ...(cacheFile.url ? [{ label: 'url', value: cacheFile.url }] : []),
          ...(cacheFile.add_to_cart
            ? [{ label: 'add_to_cart', value: cacheFile.add_to_cart }]
            : []),
          ...(cacheFile.image
            ? [{ label: 'image', value: cacheFile.image }]
            : []),
        ]
      },

      airtableSourceJsonSummary() {
        return `Records length: ${
          JSON.parse(this.debugInfo.airtableSourceJson)?.records.length
        }`
      },

      formatedFullproductSourceJson() {
        return this.debugInfo.productSourceJson
          ? JSON.stringify(
              JSON.parse(this.debugInfo.productSourceJson),
              undefined,
              4,
            )
          : false
      },

      formatedFullairtableSourceJson() {
        return this.debugInfo.airtableSourceJson
          ? JSON.stringify(
              JSON.parse(this.debugInfo.airtableSourceJson),
              undefined,
              4,
            )
          : false
      },

      productStatusCodeState() {
        return this.debugInfo.productSourceHttpCode === 'OK'
      },
    },

    methods: {
      ...mapActions(['updateItemBySku']),

      async recallAndForceRefresh() {
        const productApiInfo = this.getConfigurationInformations('productApi')
        const productService = new ProductService(productApiInfo)
        const product = await productService.getProductInfoBySku(
          this.item,
          1,
          true,
        )

        this.updateItemBySku(product)
      },

      goToUrl(link) {
        window.open(link, '_blank')
      },
    },
  }
</script>

<style lang="scss" scoped>
  .debug-info_field-group {
    margin-bottom: 10px;
    overflow: hidden;
  }

  .debug-info_field {
    &:disabled {
      opacity: 1;
    }
  }

  .not-found-label {
    color: red;
  }

  .json-editor {
    .CodeMirror {
      height: 300px;
      max-width: 70%;
      border: 2px solid var(--border-color);
    }
  }

  .debug-info-file-summary_list {
    a {
      color: var(--tertiary);

      &:hover {
        color: var(--tertiary);
        text-decoration: underline !important;
        cursor: pointer;
      }
    }
  }
</style>
