<template>
  <b-modal
    id="modal-add-logo"
    ref="modal"
    :visible="visible"
    title="Add logo"
    size="lg"
    cancel-variant="link"
    :ok-disabled="submitDisable"
    @change="$emit('change', $event)"
    v-on="$listeners"
    @ok="handleOk"
  >
    <div class="container-fluid">
      <b-form class="modal-add-logo_form">
        <div class="modal-add-logo_fields">
          <b-form-group
            class="col"
            label="Customer :"
            required
          >
            <Multiselect
              v-model="selectedCustomer"
              label="displayName"
              track-by="id"
              :show-pointer="false"
              :options="customers"
              :disabled="
                isAppLoading ||
                !fetchedResources.customers ||
                !!doesCustomerIdExist
              "
            />
          </b-form-group>
          <b-form-group
            class="col"
            label="Country :"
            required
          >
            <Multiselect
              v-model="selectedCountry"
              label="displayName"
              track-by="id"
              :show-pointer="false"
              :options="countries"
              :disabled="
                isAppLoading ||
                !fetchedResources.countries ||
                !!doesCountryIdExist
              "
            />
          </b-form-group>
          <b-form-group
            class="col"
            label="Logo id :"
          >
            <label v-if="selectedCountry && selectedCustomer">{{
              `${brandId}/${getLogoId}`
            }}</label>
          </b-form-group>
          <b-form-group
            class="col"
            label="Logo :"
            required
          >
            <b-form-file
              v-model="logoValue"
              placeholder="Choose a file ..."
              drop-placeholder="Drop file here..."
              accept=".jpg, .png, .jpeg"
              :disabled="isAppLoading || doesLogoAlreadyExist"
            />
          </b-form-group>
          <b-row v-if="logoPreview">
            <b-col cols="7">
              <MediaPreview
                type="image"
                :src="logoPreview"
              />
            </b-col>
          </b-row>
        </div>
      </b-form>
      <label
        v-if="doesLogoAlreadyExist"
        class="text-danger"
        >{{
          `Caution: This logo for the brand ${brandId} referring to the customer ${selectedCustomer.displayName} and the country ${selectedCountry.displayName} has been already contributed.`
        }}</label
      >
    </div>
  </b-modal>
</template>

<script>
  import Multiselect from '@/components/form/Multiselect.vue'
  import MediaPreview from '@/components/media/MediaPreview'
  import { mapState } from 'vuex'

  export default {
    name: 'AddBrandLogo',
    components: { Multiselect, MediaPreview },

    data() {
      return {
        selectedCountry: null,
        selectedCustomer: null,
        logoValue: null,
      }
    },

    model: {
      event: 'change',
      prop: 'visible',
    },

    props: {
      brandId: {
        type: String,
        required: true,
      },
      customerId: {
        type: String,
        default: null,
      },
      countryId: {
        type: String,
        default: null,
      },
      existingId: {
        type: Array,
        default: () => [],
      },
      visible: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      ...mapState({
        customers: (state) => state.customers,
        countries: (state) => state.countries,
        fetchedResources: (state) => state.fetchedResources,
      }),

      doesCustomerIdExist() {
        return this.customers.find(
          (customer) => customer.id === this.customerId,
        )
      },

      doesCountryIdExist() {
        return this.countries.find((country) => country.id === this.countryId)
      },

      doesLogoAlreadyExist() {
        if (this.selectedCustomer && this.selectedCountry) {
          return this.existingId.includes(this.getLogoId)
        }
        return false
      },

      getLogoId() {
        return `${this.selectedCustomer.id}_${this.selectedCountry.id}_customLogo`
      },

      submitDisable() {
        return (
          this.doesLogoAlreadyExist ||
          !this.logoValue ||
          !this.selectedCustomer ||
          !this.selectedCountry
        )
      },

      logoPreview() {
        return this.getLogoPreview(this.logoValue)
      },
    },

    async mounted() {
      const customer = this.doesCustomerIdExist
      if (this.customerId && customer) {
        this.selectedCustomer = customer
      }
      const country = this.doesCountryIdExist
      if (this.countryId && country) {
        this.selectedCountry = country
      }
    },

    methods: {
      getLogoPreview(logoValue) {
        if (logoValue) {
          return URL.createObjectURL(logoValue)
        }
        return null
      },

      handleOk() {
        this.$emit('submit', {
          customerId: this.selectedCustomer.id,
          countryId: this.selectedCountry.id,
          logo: this.logoValue,
          logoId: this.getLogoId,
        })
      },
    },
  }
</script>
