export enum FiretableFieldType {
  Boolean = 'BOOLEAN',
  Count = 'COUNT',
  CountryFlag = 'COUNTRY_FLAG',
  Custom = 'CUSTOM',
  Icon = 'ICON',
  Img = 'IMG',
  PackshotPreview = 'PACKSHOT_PREVIEW',
  InnerHtml = 'INNER_HTML',
  ObjectArraySummary = 'OBJECT_ARRAY_SUMMARY',
  ObjectSummary = 'OBJECT_SUMMARY',
  RouterLink = 'ROUTER_LINK',
  Tags = 'TAGS',
  Url = 'URL',
  SplitCell = 'SPLIT_CELL',
  Switch = 'SWITCH',
}
