
  import ProductAPIConfigurationSelectEnvironment from '@/components/product-api-config/ProductAPIConfigurationSelectEnvironment.vue'
  import ProductAPIConfigurationSelectTenant from '@/components/product-api-config/ProductAPIConfigurationSelectTenant.vue'
  import TitleContainer from '@/components/containers/TitleContainer.vue'
  import Multiselect from '@/components/form/Multiselect.vue'
  import { mapActions, mapGetters, mapState } from 'vuex'
  import List from '@/components/list/List.vue'
  import Tile from '@/components/tile/Tile.vue'
  import Functions from '@/firebase/functions'
  import groupBy from 'lodash/fp/groupBy'
  import { RESOURCES } from '@/service/ResourceService'
  import isEmpty from 'lodash/fp/isEmpty'
  import FirecampLoader from '@/components/loader/FirecampLoader.vue'


  const reduceDisplayName = (
    elements: { id: string; displayName: string }[],
  ): Record<string, string> => {
    if (!elements) return {}

    return elements.reduce((indexed, { id, displayName }) => {
      indexed[id] = displayName
      return indexed
    }, {})
  }

  export default {
    name: 'CatalogConfigurationList',
    components: {
      ProductAPIConfigurationSelectEnvironment,
      ProductAPIConfigurationSelectTenant,
      TitleContainer,
      Multiselect,
      List,
      Tile,
      FirecampLoader,
    },
    props: {
      listEmpty: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isLoading: false,
        country: null,
        customer: null,
        dataLoaded: false,
        searchInput: '',
        catalogList: [],
        additionalActions: true,
        environment: this.$route.params.environment,
        tenant: this.$route.params.tenant,
      }
    },
    computed: {
      ...mapState({
        customers: (state: { customers: any }) => state.customers,
        countries: (state: { countries: any }) => state.countries,
        fetchedResources: (state) => state.fetchedResources,
        appRouteID: (state) => state.approutes.currentRoute.id,
      }),
      ...mapGetters('scopes', ['computedScope']),
      title() {
        return this.$route.meta?.displayName ?? 'Catalog Configuration'
      },
      customerDisplayName() {
        return reduceDisplayName(this.customers)
      },
      groupByCustomerCode() {
        return !this.dataLoaded
          ? {}
          : groupBy('customerCode')(this.filteredCatalogList)
      },
      filteredCatalogList() {
        if (!this.dataLoaded) return []

        const filteredList = this.catalogList.filter((catalog) => {
          if (this.customer === null && this.country === null)
            return this.catalogList
          if (this.customer && this.country)
            return (
              catalog.customerCode.toLowerCase() ===
                this.customer.id.toLowerCase() &&
              catalog.countryCode.toLowerCase() ===
                this.country.id.toLowerCase()
            )
          if (this.customer)
            return (
              catalog.customerCode.toLowerCase() ===
              this.customer.id.toLowerCase()
            )
          if (this.country)
            return (
              catalog.countryCode.toLowerCase() ===
              this.country.id.toLowerCase()
            )
        })

        if (!isEmpty(filteredList) && !isEmpty(this.searchInput)) {
          return filteredList.filter((catalog) => {
            return catalog.name
              .toLowerCase()
              .includes(this.searchInput.toLowerCase())
          })
        }

        return filteredList
      },
    },
    async mounted() {
      await this.loadData()
    },
    watch: {
      computedScope() {
        this.loadCatalog()
      },
      "$route.params.tenant"() {
        this.loadCatalog()
      },
      "$route.params.environment"() {
        this.loadCatalog()
      },
    },
    methods: {
      ...mapActions(['loadResources', 'bindAppRoutes']),
      async loadCatalog() {
        this.catalogList = []
        const tenant = this.$route.params.tenant

        if(!tenant && this.computedScope.tenants.options.length > 0) {
          this.$router.push({
            name: this.$route.name,
            params: {
              ...this.$route.params,
              tenant: this.computedScope.tenants.options[0].id,
            },
          })
          return
        }
        
        if(!this.computedScope.tenants.options.map(tenant => tenant.id).includes(tenant)) {
          return
        }

        try {
          this.appLoading(true)
          this.catalogList = await Functions.getCatalogs(
            this.searchInput,
            this.$route.params.environment,
            tenant
          )
          this.dataLoaded = true
        } catch (error) {
          this.handleErrors(error)
        } finally {
          this.appLoading(false)
        }
      },

      async loadData() {
        try {
          this.appLoading(true)
          await Promise.all([
            this.loadResources({
              resourceNames: [RESOURCES.COUNTRIES, RESOURCES.CUSTOMERS],
            }),
            this.bindAppRoutes(),
          ])
          await this.loadCatalog()
        } catch (error) {
          this.handleErrors(error)
        } finally {
          this.appLoading(false)
        }
      },

      redirectToCatalog(id) {
        this.$router.push({
          name: 'catalog-config',
          params: { id: id },
        })
      },
    },
  }
