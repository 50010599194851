<template>
  <b-modal
    id="modal-duplicate-app-default"
    ref="modal"
    size="xl"
    title="Duplicate App"
    :cancel-disabled="isAppLoading"
    :ok-disabled="isSubmitDisabled"
    @hidden="resetModal"
    @ok="handleOk"
    v-on="$listeners"
  >
    <CreateRouteConfiguration
      :value="{ configuration }"
      @input="onChangeRoute"
    />
    <b-alert
      v-if="messageInfo.displayed"
      show
      :variant="messageInfo.type"
      >{{ messageInfo.message }}</b-alert
    >
  </b-modal>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { duplicateRoute } from '@/service/AppRouteService'
  import CreateRouteConfiguration from '../apps/forms/CreateRouteConfiguration.vue'

  export default {
    name: 'DuplicateAppModal',
    components: { CreateRouteConfiguration },

    props: {
      approuteId: {
        type: String,
        default: null,
      },

      version: {
        type: String,
        default: null,
      },

      app: {
        type: String,
        default: null,
      },
    },

    data() {
      return {
        configuration: {
          app: this.app,
          version: this.version,
          copyFrom: this.approuteId,
          type: null,
          tenant: null,
          customer: null,
          country: null,
          touchpoint: null,
          env: null,
        },
        messageInfo: {
          message: '',
          type: '',
          displayed: false,
        },
      }
    },

    computed: {
      ...mapGetters(['getAppRoute']),

      isSubmitDisabled() {
        return this.isAppLoading || !this.checkFormValidity()
      },
    },

    async mounted() {
      this.bindAppRoutes()
    },

    methods: {
      ...mapActions(['bindAppRoutes']),

      onChangeRoute({ configuration }) {
        this.configuration = configuration
      },

      resetError() {
        this.error = false
        this.errorMessage = ''
      },

      checkFormValidity() {
        let valid = true

        Object.keys(this.configuration)?.reduce((accumulator, key) => {
          valid = valid && this.configuration[key]?.length
          return accumulator
        })

        return valid
      },

      resetModal() {
        this.configuration = {
          app: null,
          version: null,
          copyFrom: null,
          type: null,
          tenant: null,
          customer: null,
          country: null,
          touchpoint: null,
          env: null,
        }

        this.messageInfo.message = ''
        this.messageInfo.type = ''
        this.messageInfo.displayed = false
      },

      async handleOk(bvModalEvt) {
        this.appLoading(true)

        this.messageInfo.message =
          'Copy in progress, please wait. This might take a few seconds due to images & prescriptions copying...'
        this.messageInfo.type = 'info'
        this.messageInfo.displayed = true

        // Prevent modal from closing
        bvModalEvt.preventDefault()

        try {
          const routeDocData = {
            ...this.configuration,
          }

          // Not needed for route creation
          delete routeDocData.copyFrom

          // Get if the source version has a prescription
          const sourceRouteHasPrescription =
            this.getAppRoute(
              this.configuration.copyFrom,
            )?.meta?.subcollections?.includes('appprescriptions') ?? false

          // Create approute
          const routeId = await duplicateRoute(
            routeDocData,
            this.configuration.copyFrom,
            sourceRouteHasPrescription,
          )

          if (routeId) {
            this.messageInfo.message = `${this.configuration.env} has been deployed successfully!`
            this.messageInfo.type = 'success'
            this.messageInfo.displayed = true

            this.$nextTick(() => {
              this.$bvModal.hide('modal-duplicate-app-default')
            })
          }
        } catch (error) {
          this.messageInfo.message =
            'This route already exists. Please change your criteria or delete the existing route first!'
          this.messageInfo.type = 'danger'
          this.messageInfo.displayed = true
        } finally {
          this.appLoading(false)
        }
      },
    },
  }
</script>
