<template>
  <CollapseContainer
    variant="warning"
    title="Settings"
    class="mapping-field_settings"
    border
    :open.sync="isOpen"
  >
    <div class="mapping-field_settings_wrapper">
      <b-form-group
        v-for="(settingValue, settingKey) in sortedSettings"
        :key="`field-${settingKey}`"
        label-cols="12"
        label-cols-lg="2"
        label-cols-md="12"
        :label="`${customLabel(settingKey)}:`"
        :required="settingKey === 'collection'"
        class="align-content-center mb-2"
      >
        <b-select
          v-if="settingKey === 'collection'"
          :value="settingValue"
          :options="[
            'appcustomisations',
            'appconfigurations',
            'appcontent',
            'appresources',
          ]"
          @input="updateSettings(settingKey, $event)"
        />
        <b-select
          v-else-if="settingKey === 'collectionType'"
          :value="settingValue"
          :options="[
            'questionnaires',
            'prescription',
            'repeater',
            'dictionnaries',
          ]"
          @input="updateSettings(settingKey, $event)"
        />
        <b-input
          v-else-if="settingKey === 'minChecked'"
          type="number"
          :value="settingValue"
          @change="
            updateSettings(
              settingKey,
              getType(settingValue) === 'number' ? +$event : $event,
            )
          "
        />
        <Multiselect
          v-else-if="
            settingKey === 'hierarchy' && hierarchyFields && settingValue
          "
          :value="settingValue"
          placeholder="Select the hierarchy"
          select-label=""
          deselect-label=""
          :options="hierarchyOptions"
          :disabled="!settings.collection"
          taggable
          multiple
          allow-unknow
          @input="hierarchyInput(arguments[0])"
          @tag="addHierarchyField"
        />
        <Multiselect
          v-else-if="settingKey === 'fileTypes'"
          :value="settingValue.replace(/\s+/g, '').split(',')"
          placeholder="Select file types"
          select-label=""
          deselect-label=""
          :options="FILES_TYPES"
          multiple
          taggable
          allow-unknow
          @input="updateSettings(settingKey, $event.join(','))"
        />

        <Multiselect
          v-else-if="settingKey === 'allowedOptions'"
          :value="getRichTextAllowedOptions(settingValue)"
          :options="RICH_TEXT_ALLOWED_OPTIONS"
          group-label="id"
          group-values="sub"
          placeholder="Select allowed options"
          select-label=""
          deselect-label="Remove"
          group-select
          multiple
          allow-unknow
          @input="updateSettings(settingKey, $event)"
        />

        <MappingFieldsFieldSettingsDependencies
          v-else-if="settingKey === 'dependencies'"
          class="ml-4"
          :dependencies="settingValue"
          @change="updateSettings(settingKey, $event)"
        />

        <b-input-group
          v-else-if="['text', 'number'].includes(getType(settingValue))"
          :append="getSuffix(settingKey)"
        >
          <b-input
            :value="settingValue"
            :type="getType(settingValue)"
            :debounce="500"
            @input="
              updateSettings(
                settingKey,
                getType(settingValue) === 'number' ? +$event : $event,
              )
            "
          />
        </b-input-group>
        <b-form-checkbox
          v-else-if="getType(settingValue) === 'boolean'"
          class="mapping-field_settings_formgroup_switch"
          :checked="settingValue"
          switch
          @input="updateSettings(settingKey, !settingValue)"
        />
      </b-form-group>

      <b-input-group
        v-if="settingsCouldBeAdded.length > 0"
        class="col-lg-3 ml-auto pr-0"
        size="sm"
      >
        <b-select
          :options="settingsCouldBeAdded"
          @change="({ key, value }) => updateSettings(key, value)"
        />
        <b-input-group-append is-text>
          <span>Add</span>
        </b-input-group-append>
      </b-input-group>
    </div>
  </CollapseContainer>
</template>

<script>
  import { firstCharacterToUppercase } from '@/utils/StringHelpers'

  import {
    FILES_TYPES,
    RICH_TEXT_ALLOWED_OPTIONS,
  } from '@/constants/FieldsMappingConstants'
  import { getFieldSettings } from '@/factories/FieldMappingFactory'
  import CollapseContainer from '@/components/containers/CollapseContainer'
  import Multiselect from '@/components/form/Multiselect'
  import MappingFieldsFieldSettingsDependencies from './MappingFieldsFieldSettingsDependencies.vue'

  export default {
    name: 'MappingFieldsFieldSettings',

    components: {
      CollapseContainer,
      Multiselect,
      MappingFieldsFieldSettingsDependencies,
    },
    props: {
      settings: {
        type: Object,
        required: true,
      },
      fieldId: {
        type: String,
        required: true,
      },
      formatedHierarchy: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        FILES_TYPES,
        RICH_TEXT_ALLOWED_OPTIONS,
        hierarchyFields: [],
        isOpen: false,
      }
    },

    computed: {
      settingsCouldBeAdded() {
        return Object.entries(getFieldSettings(this.settings.type)).reduce(
          (acc, [key, value]) => {
            if (
              !Object.keys(this.settings).includes(key) &&
              key !== 'hierarchy'
            )
              acc.push({
                text: firstCharacterToUppercase(key),
                value: { key, value },
              })
            return acc
          },
          [],
        )
      },
      sortedSettings() {
        const defaultSettings = this.settings.type.includes('separator')
          ? { expanded: false, dependencies: [] }
          : {
              dependencies: [],
              collection: '',
              hierarchy: [],
              required: false,
            }
        const settingsCopy = {
          ...defaultSettings,
          ...this.settings,
        }
        const settingsSorted = Object.keys(settingsCopy)
          .sort((a, b) => {
            const settingsOrder = [
              'single',
              'maxValue',
              'minWidth',
              'maxWidth',
              'minHeight',
              'maxHeight',
              'maxSize',
              'required',
              'collection',
              'hierarchy',
              'dependencies',
            ]
            const aIndex = settingsOrder.indexOf(a)
            const bIndex = settingsOrder.indexOf(b)
            if (aIndex === -1 && bIndex === -1) {
              return b > a ? -1 : 1
            }
            return aIndex - bIndex
          })
          .reduce((acc, setting) => {
            if (
              setting === 'type' ||
              (this.settings.single === true && setting === 'maxValue') // Collection field
            )
              return acc
            acc[setting] = settingsCopy[setting]
            return acc
          }, {})
        return settingsSorted
      },

      hierarchyOptions() {
        if (!this.settings.collection) return []
        return this.hierarchyFields[this.settings.collection] ?? []
      },
    },

    created() {
      this.hierarchyFields = this.formatedHierarchy
    },

    methods: {
      customLabel(label) {
        return firstCharacterToUppercase(label)
      },

      getSuffix(key) {
        if (/size/gi.test(key)) return 'KB'
        if (/(width|height)/gi.test(key)) return 'PX'
      },

      getType(value) {
        const type = typeof value

        switch (type) {
          case 'string':
            return 'text'
          default:
            return type
        }
      },

      addHierarchyField(newTag) {
        if (!this.settings.collection) return
        if (!this.hierarchyFields[this.settings.collection])
          this.hierarchyFields[this.settings.collection] = []
        this.hierarchyFields[this.settings.collection].push(newTag)
      },

      hierarchyInput(newValue) {
        this.updateSettings('hierarchy', newValue)
      },

      updateSettings(key, value) {
        this.$emit('update:settings', { ...this.settings, [key]: value })
      },

      getRichTextAllowedOptions(value) {
        return (
          value?.reduce((acc, optionOrGroup) => {
            const group = this.RICH_TEXT_ALLOWED_OPTIONS.find(
              (groupOption) => groupOption.id === optionOrGroup,
            )
            return [...acc, ...(group ? group.sub : [optionOrGroup])]
          }, []) ?? []
        )
      },
    },
  }
</script>

<style lang="scss" scoped>
  .mapping-field_settings_wrapper {
    padding: 1em 2em;
    .mapping-field_settings_dependencies-row:not(:last-of-type) {
      border-bottom: 1px solid var(--border-color);
    }
    .mapping-field_settings_formgroup_switch {
      margin-top: 0.4rem;
    }
  }
</style>
