<template>
  <div class="item-content_container container-fluid">
    <b-form
      ref="form"
      :class="containerClasses"
      @submit.stop.prevent
      @submit="onSubmit"
      @reset="onReset"
    >
      <b-form-group
        label="Select the item type:"
        label-for="item-content-type-select"
        description="Select an item type to display the linked fields."
      >
        <b-form-select
          v-model="selectedItemType"
          name="item-content-type-select"
          :options="itemTypes"
          :disabled="isAppLoading"
          required
        >
          <template #first>
            <b-form-select-option
              :value="null"
              disabled
              >Select an item type</b-form-select-option
            >
          </template>
        </b-form-select>
      </b-form-group>

      <transition
        name="fade"
        mode="out-in"
      >
        <div
          v-if="selectedItemType"
          :key="`${selectedItemType}_container`"
        >
          <h4 class="mt-4 mb-4">{{ idToLabel(selectedItemType) }}</h4>
          <b-form-group
            v-for="(input, name) in formData[selectedItemType]"
            :key="`${selectedItemType}-input-${name}`"
            :label="idToLabel(name)"
            :label-for="`item-content-${selectedItemType}-${name}`"
            :required="formData[selectedItemType][name].required"
          >
            <b-form-input
              v-if="
                ['text', 'url'].includes(formData[selectedItemType][name].type)
              "
              v-model="formData[selectedItemType][name].value"
              debounce="500"
              :state="
                formData[selectedItemType][name].type === 'url'
                  ? urlInputState
                  : null
              "
              :disabled="isAppLoading"
              :type="formData[selectedItemType][name].type"
            />

            <b-form-textarea
              v-if="
                ['textarea'].includes(formData[selectedItemType][name].type)
              "
              v-model="formData[selectedItemType][name].value"
              placeholder="Begin typing ..."
              debounce="500"
              rows="3"
              max-rows="6"
              :disabled="isAppLoading"
            />

            <WysiwygEditor
              v-if="['editor'].includes(formData[selectedItemType][name].type)"
              v-model="formData[selectedItemType][name].value"
              :disabled="isAppLoading"
              :allowed-options="allWysiwygOptions"
            />
          </b-form-group>
          <b-row>
            <div :class="included ? 'action-button_container' : 'mt-4 col-12'">
              <b-button
                type="reset"
                variant="link"
                class="text-danger"
              >
                {{ edition ? 'Delete' : 'Reset' }}
              </b-button>
              <SubmitButton
                :label="`${edition ? 'Edit' : 'Create'} item`"
                type="submit"
                :disabled="isSubmitDisabled"
                :loading="isAppLoading"
                class="w-auto ml-2"
                :class="included ? 'mb-auto' : 'mb-0'"
              />
            </div>
          </b-row>
        </div>
      </transition>
    </b-form>
  </div>
</template>

<script>
  import { firstCharacterToUppercase, isValidUrl } from '@/utils/StringHelpers'
  import { addGeneratedDoc, updateRef } from '@/service/FirebaseService'
  import { PrescriptionMixin } from '@/mixins/PrescriptionMixin.js'
  import { Item, itemConverter } from '@/types/Item'
  import { PRESCRIPTION_ERROR_UNSUPPORTED_ITEM_TYPE } from '@/constants/ErrorConstants'
  import { RICH_TEXT_ALLOWED_OPTIONS } from '@/constants/FieldsMappingConstants'

  import WysiwygEditor from '@/components/wysiwyg/WysiwygEditor'
  import SubmitButton from '@/components/form/SubmitButton'

  const defaultData = {
    url: {
      label: {
        value: null,
        type: 'text',
        required: true,
      },
      content: {
        value: null,
        type: 'text',
        required: true,
      },
      url: {
        value: null,
        type: 'url',
        required: true,
      },
      description: {
        value: null,
        type: 'text',
        required: false,
      },
    },
    content: {
      label: {
        value: null,
        type: 'text',
        required: true,
      },
      content: {
        value: null,
        type: 'editor',
        required: true,
      },
      description: {
        value: null,
        type: 'text',
        required: false,
      },
    },
  }

  export default {
    name: 'ItemContent',

    components: {
      WysiwygEditor,
      SubmitButton,
    },

    mixins: [PrescriptionMixin],

    props: {
      visible: Boolean,
      included: {
        type: Boolean,
        default: false,
      },
      edition: {
        type: Boolean,
        default: false,
      },
      item: {
        type: Object,
        default: null,
      },
    },

    data() {
      return {
        selectedItemType: null,
        itemTypes: ['url', 'content'],
        formData: JSON.parse(JSON.stringify(defaultData)),
      }
    },

    computed: {
      isSubmitDisabled() {
        if (!this.selectedItemType) return true
        const values = this.formData[this.selectedItemType]
        return Object.values(values).some((field) => {
          const value = field.value
          return (
            (field.required &&
              ([null, undefined].includes(value) || value?.length === 0)) ||
            (field.type === 'url' && !isValidUrl(value))
          )
        })
      },

      containerClasses() {
        return {
          'col-12': true,
          ['col-md-6 ml-auto mr-auto mt-4']: !this.included,
        }
      },

      allWysiwygOptions() {
        return RICH_TEXT_ALLOWED_OPTIONS.map((optionGroup) => optionGroup.id)
      },

      urlInputState() {
        const urlField = this.formData.url.url
        return urlField.value?.length > 0 ? isValidUrl(urlField.value) : null
      },
    },

    watch: {
      selectedItemType(newItemType, oldItemType) {
        // Reset old item type values.
        if (oldItemType) {
          this.formData[oldItemType] = JSON.parse(
            JSON.stringify(defaultData[oldItemType]),
          )
        }
      },
    },

    created() {
      if (this.item) {
        this.initEdit()
      }
    },

    methods: {
      idToLabel(id) {
        return firstCharacterToUppercase(id) + ':'
      },

      initEdit() {
        const type = this.item.type
        if (!['url', 'content'].includes(type)) {
          this.handleErrors({
            code: PRESCRIPTION_ERROR_UNSUPPORTED_ITEM_TYPE,
            message: `This item type (${type}) is not supported.`,
          })
          return
        }

        this.selectedItemType = type
        const typeKeys = Object.keys(defaultData[type])
        for (const key of typeKeys) {
          this.formData[type][key].value =
            key === 'url' ? this.item[key]?.url : this.item[key]
        }
      },

      async onSubmit() {
        this.appLoading(true)
        try {
          let id = this.item?.item

          if (!id) {
            id = addGeneratedDoc(`${this.prescription}/items`).id
          }

          const data = this.formData[this.selectedItemType]
          const item = new Item({
            ...(data?.content?.value?.length > 0 && {
              content: data.content.value,
            }),
            ...(data?.url?.value?.length > 0 && { url: data.url.value }),
            ...(data?.label?.value?.length > 0 && { label: data.label.value }),
            ...(data?.description?.value?.length > 0 && {
              description: data.description.value,
            }),
            ...(id && { item: id }),
            type: this.selectedItemType,
          })

          await updateRef(
            `${this.prescription}/items/${id}`,
            itemConverter.toFirestore(item),
            !this.edition,
          )

          this.setMessageInformations({
            message: this.edition
              ? `${item.label} successfully updated!`
              : 'New item successfully added!',
            state: 'success',
          })
          this.$emit('success', item)
          this.resetForm()
        } catch (error) {
          this.handleErrors({ error })
        } finally {
          this.appLoading(false)
        }
      },

      onReset() {
        if (this.edition) {
          this.$emit('delete', this.item.item)
          this.$emit('close', { keepSelectedItem: true })
        }
        this.resetForm()
      },

      resetForm() {
        this.selectedItemType = null
        this.formData = JSON.parse(JSON.stringify(defaultData))
      },
    },
  }
</script>

<style lang="scss" scoped>
  .item-content_container {
    color: var(--app-background-font-color);
  }
</style>
