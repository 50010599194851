<template>
  <BaseManagement
    :filtered-data="filteredFormatedScopes"
    :loading="loading"
    search-field-label="Search by name"
    name="scope"
    :modal-component="modalComponent"
    @created="getScopes"
    @deleteConfirmed="deleteScope"
    @searchInput="setSearchInput"
  />
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import omit from 'lodash/fp/omit'
  import { deleteRef } from '@/service/FirebaseService'

  import { UserManagementMixin } from '@/mixins/UserManagementMixin.js'

  import BaseManagement from '@/components/admin/BaseManagement'
  import ScopeCreation from '@/components/admin/user/ScopeCreation'

  export default {
    name: 'ScopeManagement',

    components: {
      BaseManagement,
    },

    mixins: [UserManagementMixin],

    data() {
      return {
        modalComponent: ScopeCreation,
        searchInput: null,
      }
    },

    computed: {
      ...mapState({
        userScope: (state) => state.user.scope,
        securityScopes: (state) => state.scopesrolesgroups.scopes,
      }),

      filteredFormatedScopes() {
        const scopes = this.searchInput
          ? this.securityScopes?.filter(
              (scope) =>
                scope?.label?.toLowerCase().includes(this.searchInput) ||
                scope?.id?.toLowerCase().includes(this.searchInput),
            )
          : this.securityScopes
        return this.setScopesForList(scopes)
      },
    },

    methods: {
      ...mapActions({
        getScopes: 'scopesrolesgroups/getScopes',
      }),

      async init() {
        await this.getScopes()
      },

      setScopesForList(scopes) {
        this.loading = true
        const formatedScopes = scopes?.map((scope) => {
          const { id, displayName } = scope
          const details = omit(['id', 'displayName', 'meta'])(scope)
          const formatedDetails = this.formatDetails(details)
          return {
            id,
            label: displayName ?? id,
            details: formatedDetails,
            tagsValues: Object.keys(details),
            quickActionButton: [
              {
                label: 'Edit scope',
                icon: 'pencil-fill',
                variant: 'success',
                onClick: ['edit', scope],
              },
              {
                label: 'Delete scope',
                variant: 'danger',
                icon: 'trash',
                divider: true,
                onClick: ['delete', id],
              },
            ],
          }
        })
        this.loading = false
        return formatedScopes
      },

      async deleteScope(uid) {
        this.loading = true
        try {
          await deleteRef(`/securityscopes/${uid}`)

          this.$nextTick(() => {
            this.$bvModal.hide('modal-creation')
          })

          this.setMessageInformations({
            message: `Scope ${uid} successfully deleted.`,
            state: 'success',
          })
        } catch (error) {
          this.handleErrors({ error })
        } finally {
          await this.getScopes()
          this.loading = false
        }
      },

      setSearchInput(searchInput) {
        this.searchInput = searchInput?.toLowerCase()
      },

      formatDetails(details) {
        const formatedDetails = {}
        const orderedDetailsKeys = Object.keys(details).sort()
        for (const key of orderedDetailsKeys) {
          let labels = []
          if (!Object.prototype.hasOwnProperty.call(details[key], 'included'))
            continue
          for (const [infoKey, infoValues] of Object.entries(details[key])) {
            const formatedValues = infoValues?.map((ref) => {
              const id = ref === '*' ? '*' : ref.id
              const label = ref === '*' ? 'all' : ref.id
              return {
                id,
                label,
                color: infoKey === 'included' ? '#41b883' : '#e97171',
              }
            })
            labels = labels.concat(formatedValues)
          }

          if (labels?.length > 0) {
            formatedDetails[key] = labels
          }
        }
        return formatedDetails
      },
    },
  }
</script>
