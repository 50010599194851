<template>
  <div class="user-management_container container-fluid">
    <TitleContainer
      v-if="!included"
      :title="title"
    />
    <b-form
      class="user-management_form"
      @submit="onSubmit"
      @reset="onReset"
    >
      <div class="user-management_fields">
        <b-form-group
          label="Group name"
          label-for="group-input-name"
          class="col-5 mb-2 pl-0"
        >
          <b-form-input
            id="group-input-name"
            v-model="displayNameValue"
            required
          />
        </b-form-group>

        <FireDetails
          class="col-5 mb-4 pl-0"
          title="Description"
        >
          <b-form-textarea
            id="comments"
            v-model="descriptionValue"
            placeholder="Enter a description"
            rows="3"
            no-resize
            :disabled="loading"
          />
        </FireDetails>
        <b-form-group
          label="Security role"
          label-for="user-security-role"
          class="pl-0"
        >
          <Multiselect
            id="user-security-role"
            v-model="securityRoleValue"
            placeholder="Select security role"
            track-by="id"
            label="displayName"
            :options="securityRoles"
            :show-labels="false"
            :disabled="loading"
          />
        </b-form-group>
        <b-form-group
          label="Security scope"
          label-for="security-scope"
          class="pl-0 mb-5"
        >
          <Multiselect
            id="security-scope"
            v-model="securityScopeValue"
            placeholder="Select security scope"
            track-by="id"
            label="displayName"
            :options="securityScopes"
            :show-labels="false"
            :disabled="loading"
          />
        </b-form-group>
        <b-row>
          <div :class="included ? 'action-button_container' : 'mt-4 pl-0'">
            <b-button
              type="reset"
              variant="link"
              class="text-danger"
              :class="!included ? 'w-100' : ''"
              >{{ edition ? 'Delete' : 'Reset' }}</b-button
            >
            <SubmitButton
              :disabled="isSubmitDisabled"
              :loading="loading"
              :class="included ? 'w-auto mb-auto ml-2' : ''"
            />
          </div>
        </b-row>
      </div>
    </b-form>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import {
    getDocumentReference,
    getNewServerTimestamp,
    addDocToCollection,
  } from '@/service/FirebaseService'

  import TitleContainer from '@/components/containers/TitleContainer'
  import Multiselect from '@/components/form/Multiselect'
  import SubmitButton from '@/components/form/SubmitButton'
  import FireDetails from '@/components/containers/FireDetails'
  import { deleteRef } from '../../../service/FirebaseService'

  const initialData = {
    displayNameValue: null,
    descriptionValue: null,
    securityRoleValue: null,
    securityScopeValue: null,
  }

  export default {
    name: 'GroupCreation',

    components: {
      TitleContainer,
      Multiselect,
      SubmitButton,
      FireDetails,
    },

    props: {
      included: {
        type: Boolean,
        default: false,
      },
      edition: {
        type: Boolean,
        default: false,
      },
      id: {
        type: String,
        default: null,
      },
      displayName: {
        type: String,
        default: null,
      },
      description: {
        type: String,
        default: null,
      },
      role: {
        type: [Object, String],
        default: null,
      },
      scope: {
        type: [Object, String],
        default: null,
      },
      meta: {
        type: [Object, String],
        default: null,
      },
    },

    data() {
      return {
        loading: false,
        ...initialData,
      }
    },

    computed: {
      ...mapState({
        securityRoles: (state) => state.scopesrolesgroups.roles,
        securityScopes: (state) => state.scopesrolesgroups.scopes,
      }),

      title() {
        return this.edition ? 'Group edition' : 'Group creation'
      },

      isSubmitDisabled() {
        return this.displayNameValue?.length === 0
      },
    },

    async created() {
      if (this.edition) {
        await this.initEdition()
      }
    },

    methods: {
      async initEdition() {
        try {
          this.displayNameValue = this.displayName
          this.descriptionValue = this.description
          this.securityRoleValue = this.securityRoles?.find(
            (securityRole) => securityRole.id === this.role.id,
          )
          this.securityScopeValue = this.securityScopes?.find(
            (securityScope) => securityScope.id === this.scope.id,
          )
        } catch (error) {
          this.handleErrors({ error })
        }
      },

      async onSubmit(event) {
        event.preventDefault()
        this.loading = true

        try {
          const id = this.edition ? this.id : this.createId()
          const docPath = `securitygroups/${id}`

          const savedMeta = {
            ...this.meta,
          }

          if (this.meta) {
            savedMeta.version =
              typeof this.meta.version === 'string'
                ? Number(this.meta.version) + 1
                : this.meta.version + 1
            savedMeta.updatedAt = getNewServerTimestamp
          }

          const finalObject = {
            displayName: this.displayNameValue,
            ...(this.descriptionValue && {
              description: this.descriptionValue,
            }),
            role: getDocumentReference(
              `securityroles/${this.securityRoleValue.id}`,
            ),
            scope: getDocumentReference(
              `securityscopes/${this.securityScopeValue.id}`,
            ),
          }

          if (this.edition) {
            // Due firestore rules limit not update, remove and recreate (https://jira.e-loreal.com/browse/MF-23308)
            await deleteRef(docPath)
            await addDocToCollection(
              null,
              'securitygroups',
              finalObject,
              null,
              id,
            )
          } else {
            await addDocToCollection(
              null,
              'securitygroups',
              finalObject,
              null,
              id,
            )
          }

          this.setMessageInformations({
            message:
              `Group ${this.displayNameValue} has been successfully ` +
              (this.edition ? 'updated!' : 'created!'),
            state: 'success',
          })

          this.$emit('done')
        } catch (error) {
          this.handleErrors({ error })
        } finally {
          this.loading = false
        }
      },

      createId() {
        return this.displayNameValue
          .trim()
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f!@#$%^&*]/g, '')
          .replace(/ /g, '-')
      },

      resetValues() {
        Object.keys(initialData).forEach((key) => {
          this.$data[key] = initialData[key]
        })
      },

      onReset(event) {
        event.preventDefault()
        if (this.edition && this.id) {
          this.$emit('delete', this.id)
        } else {
          this.resetValues()
        }
      },
    },
  }
</script>
