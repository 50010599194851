<template>
  <div class="search_container">
    <b-icon
      class="search_search-icon"
      icon="search"
      scale="1.2"
    />

    <input
      :value="valueModel"
      class="search_input"
      type="text"
      :placeholder="placeholder"
      :disabled="disabled"
      @input="onNewInput"
    />

    <button
      v-if="valueModel"
      class="search_clear-button"
      @click="clearSearch"
    >
      <b-icon icon="x-circle" />
    </button>
  </div>
</template>

<script>
  export default {
    name: 'Search',

    props: {
      valueModel: {
        type: String,
        default: null,
      },
      placeholder: {
        type: String,
        default: 'Search ...',
      },
      debounce: {
        type: Number,
        default: 500,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        timeout: null,
      }
    },

    methods: {
      clearSearch() {
        this.$emit('update:value-model', null)
      },

      onNewInput($event) {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.$emit('update:value-model', $event.target.value)
        }, this.debounce)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .search_container {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: 0;
      left: 0;
      background-color: var(--primary-highlight);
      transition: height 0.2s ease-in-out;
    }

    &:focus-within {
      &::after {
        height: 3px;
      }
    }

    ::placeholder {
      color: inherit;
      font-size: 1.2rem;
      opacity: 0.8;
    }
  }

  .search_input {
    height: 2rem;
    width: 100%;
    padding: 0px 30px;
    border: none;
    border-radius: 0%;
    background-color: transparent;
    color: inherit;

    &:focus,
    &:active {
      border: none;
      outline: none;
      background-color: transparent;
    }
  }

  .search_search-icon {
    position: absolute;
    height: 2rem;
    top: 0;
    left: 0;
    padding-left: 2px;
    padding-bottom: 3px;
    background-color: transparent;
    color: var(--primary-highlight);
  }

  .search_clear-button {
    position: absolute;
    height: 2rem;
    top: 0;
    right: 0;
    margin-left: -30px;
    padding-bottom: 3px;
    background-color: transparent;
    color: inherit;
    border: none;
    z-index: 5;

    &:hover {
      color: var(--danger);
    }
  }
</style>
