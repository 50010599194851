<template>
  <CollapseContainer
    variant="tertiary"
    border
    :title="`Edit ${sectionLabel} : ${label}`"
  >
    <div class="col-lg-8 col-4 mt-4">
      <b-form-group
        :label="`${sectionLabel} label:`"
        required
        label-cols="12"
        label-cols-lg="4"
      >
        <b-form-input
          v-model="label"
          type="text"
          required
          :debounce="500"
        />
      </b-form-group>

      <b-form-group
        :label="`${sectionLabel}'s order:`"
        required
        label-cols="12"
        label-cols-lg="4"
      >
        <b-form-select
          v-model="index"
          :options="sectionOrderOptions"
          required
          @input="emitOrderChanged"
        >
          <template #first>
            <b-form-select-option
              :value="null"
              disabled
              >Select an order</b-form-select-option
            >
          </template>
        </b-form-select>
      </b-form-group>

      <MappingFieldsFieldSettingsDependencies
        v-if="isSubsection"
        v-model="dependencies"
        class="mb-4 ml-3"
      />
    </div>
  </CollapseContainer>
</template>

<script>
  import CollapseContainer from '@/components/containers/CollapseContainer'
  import { mapActions, mapState } from 'vuex'
  import MappingFieldsFieldSettingsDependencies from './MappingFieldsFieldSettingsDependencies.vue'

  export default {
    name: 'MappingSectionForm',

    components: {
      CollapseContainer,
      MappingFieldsFieldSettingsDependencies,
    },

    props: {
      sectionIndex: {
        type: Number,
        required: true,
      },
      subIndex: {
        type: Number,
        required: false,
        default: null,
      },
      tabsNumber: {
        type: Number,
        default: 0,
      },
      currentIndex: {
        type: Number,
        default: 0,
      },
      border: {
        type: Boolean,
        default: false,
      },
      isSubsection: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      ...mapState({
        data(state) {
          const section = state.mapping.sectionsTabs[this.sectionIndex]

          if (!this.isSubsection) {
            return {
              id: section.id,
              label: section.label,
              index: section.index,
            }
          } else {
            const subSection = section.subsections[this.subIndex]
            return {
              id: subSection.id,
              label: subSection.label,
              index: subSection.index,
              dependencies: subSection.dependencies,
            }
          }
        },
      }),

      sectionOrderOptions() {
        return Array.from({ length: this.tabsNumber }, (x, index) => index + 1)
      },

      sectionLabel() {
        return this.isSubsection ? 'Subsection' : 'Section'
      },
      label: {
        set(value) {
          this.editSection({
            sectionIndex: this.sectionIndex,
            subIndex: this.subIndex,
            key: 'label',
            value,
          })

          // generate a new id from the label
          const id = value.toLowerCase().replace(/\s/g, '-')
          this.editSection({
            sectionIndex: this.sectionIndex,
            subIndex: this.subIndex,
            key: 'id',
            value: id,
          })
        },

        get() {
          return this.data.label
        },
      },

      index: {
        set(value) {
          this.editSection({
            sectionIndex: this.sectionIndex,
            subIndex: this.subIndex,
            key: 'index',
            value,
          })
        },

        get() {
          return this.data.index
        },
      },

      dependencies: {
        set(value) {
          this.editSection({
            sectionIndex: this.sectionIndex,
            subIndex: this.subIndex,
            key: 'dependencies',
            value,
          })
        },

        get() {
          return this.data.dependencies
        },
      },
    },

    methods: {
      ...mapActions('mapping', ['editSection']),
      emitOrderChanged() {
        this.$emit('order-changed', {
          oldIndex: this.currentIndex,
          newIndex: this.sectionTab.index - 1,
        })
      },
    },
  }
</script>
