<template>
  <div
    v-if="(isImage || isVideo) && showPreview"
    class="media-preview_container"
  >
    <div class="media-preview_buttons">
      <b-button
        :href="src"
        target="_blank"
        size="sm"
        class="media-preview_link"
      >
        <b-icon icon="box-arrow-up-right" />
        <span class="media-preview_link_label">Open in new tab</span>
      </b-button>
      <CopyButton
        :text-to-copy="src"
        class="media-preview_copy"
      />
    </div>
    <img
      v-if="isImage"
      :src="src"
      :alt="alt"
      loading="lazy"
      @error="handleFileError"
      @load="onLoad"
    />
    <video
      v-if="isVideo"
      class="media-preview_video"
      controls
    >
      <source
        :src="src"
        @error="handleFileError"
        @load="onLoad"
      />
    </video>
  </div>
</template>

<script>
  import CopyButton from '@/components/buttons/CopyButton'

  export default {
    name: 'MediaPreview',

    components: {
      CopyButton,
    },

    props: {
      src: {
        type: String,
        required: true,
      },
      alt: {
        type: String,
        default: '',
      },
      type: {
        type: String,
        validator: (type) => ['image', 'video'].includes(type),
        required: true,
      },
    },

    data() {
      return {
        showPreview: true,
      }
    },

    computed: {
      isImage() {
        return this.type === 'image' && !!this.src
      },
      isVideo() {
        return this.type === 'video' && !!this.src
      },
    },

    watch: {
      src(newValue) {
        this.showPreview = !!newValue
      },
    },

    methods: {
      handleFileError() {
        this.showPreview = false
      },
      onLoad() {
        this.showPreview = true
      },
    },
  }
</script>

<style lang="scss" scoped>
  .media-preview_container {
    display: flex;
    flex-wrap: wrap;
    background: var(--primary);
    padding: 0.5rem;
    border: 1px solid var(--primary);
    border-radius: 5px;
    max-height: 200px;
    height: 100%;
    position: relative;

    img {
      margin: auto;
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
    }

    &:hover,
    &:active,
    &:focus {
      .media-preview_buttons {
        opacity: 1;
        top: 0;
      }
    }
  }

  .media-preview_buttons {
    display: flex;
    position: absolute;
    justify-content: center;
    top: 100%;
    left: 0;
    opacity: 0;
    width: 100%;
    transition: all 0.2s ease-in-out;
  }

  .media-preview_link {
    display: flex;
    align-items: center;
    left: 0.5rem;
    transition: all 0.2s ease-in-out;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 5px;

    &:hover,
    &:active,
    &:focus {
      .media-preview_link_label {
        width: 100%;
        padding-left: 10px;
      }
    }

    .media-preview_link_label {
      width: 0;
      overflow: hidden;
      padding-left: 0;
      transition: padding-left 0.2s ease-in-out, width 0.2s ease-in-out;
    }
  }

  .media-preview_copy {
    right: 0.5rem;
    color: var(--white);
  }

  .media-preview_video {
    width: 100%;
    height: 100%;
  }
</style>
