<template>
  <div>
    <multiselect
      v-model="value"
      label="name"
      :options="options"
      track-by="name"
      :multiple="true"
      :taggable="true"
      @tag="addTag"
      placeholder="Add a SKU"
      :show-no-options="false"
    >
      <template
        slot="tag"
        slot-scope="{ option, remove }"
      >
        <span
          :class="[
            'tag-style',
            { 'is-valid': option.isValid, 'is-invalid': !option.isValid },
          ]"
        >
          {{ option.name }}
          <span
            class="tag-remove"
            @click="remove(option)"
            >×</span
          >
        </span>
      </template>
    </multiselect>
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect'

  export default {
    components: { Multiselect },
    data() {
      return {
        value: [],
        options: [],
      }
    },

    watch: {
      value(value) {
        this.$emit('tagsAdded', value)
        if (value.some((v) => v.isValid === false)) {
          this.$emit('disabledTagsAdd', true)
        } else {
          this.$emit('disabledTagsAdd', false)
        }
      },
    },

    methods: {
      addTag(newTag) {
        let tags = newTag.split(';')
        tags = tags
          .filter((t) => t.trim().length)
          .map((newTag) => {
            newTag = newTag.trim()
            if (newTag.length) {
              const isValid = this.validateTag(newTag)
              return {
                name: newTag,
                isValid: isValid,
              }
            }
            return
          })
        this.options.push(...tags)
        this.value.push(...tags)
      },
      validateTag(tag) {
        // Rule: No spaces, \r, \n, or carriage return the SKU input field can accept only numbers, letters, ","
        const invalidCharacters = /^[a-zA-Z0-9,]*$/
        return invalidCharacters.test(tag)
      },
    },
    computed: {
      tagClass() {
        // Compute the class based on tag validity
        return this.value.map((tag) => ({
          'is-valid': tag.isValid,
          'is-invalid': !tag.isValid,
        }))
      },
    },
  }
</script>
<style>
  .tag-style {
    display: inline-flex;
    align-items: center;
    padding: 4px 8px;
    margin: 2px 2px 10px 2px;
    border-radius: 15px;
    font-size: 0.875em;
    color: #ffffff;
    transition: all 0.2s ease;
  }

  .tag-remove {
    display: inline-block;
    margin-left: 8px;
    cursor: pointer;
    transition: transform 0.2s ease;
  }

  .tag-remove:hover {
    transform: scale(1.2);
  }

  .is-valid {
    background-color: #76b582;
  }

  .is-invalid {
    background-color: #e57373;
  }

  /* Add a hover effect for tags */
  .tag-style:hover {
    opacity: 0.9;
    transform: scale(1.05);
  }
</style>
