<template>
  <div class="create-edit-documentation_form container-fluid">
    <b-form
      class="documentation_form"
      @submit.prevent="onSubmit"
      @reset.prevent="onReset"
    >
      <div class="documentation_fields">
        <b-form-group
          class="col-5 mb-2 pl-0"
          label="Documentation id:"
        >
          <b-form-input
            :value="idValue"
            disabled
            :debounce="500"
            :state="idState"
            aria-describedby="documentation-input-id-feedback"
            required
          />
          <b-form-invalid-feedback id="documentation-input-id-feedback">
            {{ errorMessage }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          class="col-5 mb-2 pl-0"
          label="Display name:"
        >
          <b-form-input
            v-model="displayNameValue"
            :disabled="isAppLoading"
            :debounce="500"
            required
          />
        </b-form-group>

        <b-form-group
          class="col-8 mb-2 pl-0"
          label="Roles :"
          :state="rolesState"
          :invalid-feedback="`You need to include your role (${roleId})`"
        >
          <Multiselect
            v-model="rolesValues"
            :options="roles"
            :disabled="isAppLoading"
            placeholder="Select security role"
            track-by="id"
            label="displayName"
            :show-labels="false"
            required
            :state="rolesState"
            :multiple="true"
          />
        </b-form-group>

        <b-row>
          <div class="action-button_container">
            <b-button
              type="reset"
              variant="link"
              class="text-danger"
              >{{ edition ? 'Delete' : 'Reset' }}</b-button
            >
            <SubmitButton
              :disabled="isSubmitDisabled"
              :loading="isAppLoading"
              class="w-auto mb-auto ml-2"
            />
          </div>
        </b-row>
      </div>
    </b-form>
  </div>
</template>

<script>
  import { database } from '@/service/FirebaseService'

  import { DictionnariesCreationMixin } from '@/mixins/DictionnariesCreationMixin.js'
  import Multiselect from '../form/Multiselect.vue'
  import { mapActions, mapState } from 'vuex'
  import { formatStringToId } from '../../utils/StringHelpers'

  const initialData = {
    displayNameValue: null,
    rolesValues: [],
  }

  export default {
    name: 'DocumentationCreationEdition',
    components: { Multiselect },
    mixins: [DictionnariesCreationMixin],
    data() {
      return {
        ...initialData,
        initialData,
        idValueEdition: null,
        stateKey: 'documentations',
        idAlreadyExists: false,
        idValidRegex: new RegExp(/^[A-z0-9-]+$/),
        defaultErrorMessage:
          "Id must only contains letters without accentuation (lowercase or uppercase), numbers and '-' character.",
      }
    },
    computed: {
      ...mapState('scopesrolesgroups', ['roles']),
      ...mapState({
        roleId: (state) => state.user.role.id,
      }),
      finalObject() {
        return {
          displayName: this.displayNameValue,
          meta: {
            allowedRoles: this.rolesValues.map((role) =>
              database.collection('securityroles').doc(role.id),
            ),
          },
        }
      },
      idValue() {
        if (this.idValueEdition) return this.idValueEdition
        if (!this.displayNameValue) return ''
        return formatStringToId(this.displayNameValue) + '-' + Date.now()
      },
      rolesState() {
        return this.roleId
          ? this.rolesValues.some(({ id }) => id === this.roleId)
          : null
      },
    },
    async mounted() {
      await this.getRoles()
      if (this.edition) {
        this.idValueEdition = this.id
        this.displayNameValue = this.displayName
        this.rolesValues = this.meta.allowedRoles.map(({ id }) =>
          this.roles.find((role) => role.id === id),
        )
      } else {
        this.rolesValues = [this.roles.find((role) => role.id === this.roleId)]
      }
    },
    methods: mapActions('scopesrolesgroups', ['getRoles']),
  }
</script>
