<template>
  <div class="create-edit-doc-page-form container-fluid">
    <b-form
      class="documentation-page_form"
      @submit.prevent="onSubmit"
      @reset.prevent="onReset"
    >
      <div class="documentation-page_fields">
        <b-collapse
          id="edit-fields-collapse"
          :visible="isModal"
        >
          <b-form-group
            class="col-5 mb-2 pl-0"
            label="Page id:"
            label-for="page-input-id"
          >
            <b-form-input
              id="page-input-id"
              v-model="idValue"
              :disabled="isAppLoading || edition"
              :debounce="500"
              :state="idState"
              aria-describedby="page-input-id-feedback"
              required
            />
            <b-form-invalid-feedback id="page-input-id-feedback">
              {{ errorMessage }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            class="col-5 mb-2 pl-0"
            label="Display name:"
            label-for="page-input-display-name"
          >
            <b-form-input
              id="page-input-display-name"
              v-model="displayNameValue"
              :disabled="isAppLoading"
              :debounce="500"
              required
            />
          </b-form-group>

          <b-form-group
            v-if="!edition"
            class="col-5 mb-2 pl-0"
            label="Source documentation:"
            label-for="page-input-documentation"
          >
            <b-form-select
              id="page-input-documentation"
              v-model="sourceDocumentationValue"
              :options="selectListDocumentation"
              placeholder="Select the source documentation"
              :disabled="isAppLoading"
            />
          </b-form-group>

          <b-form-group
            class="col-5 mb-2 pl-0"
            label="Parent page:"
            label-for="page-input-parent-page"
          >
            <b-form-select
              id="page-input-parent-page"
              v-model="parentPageValue"
              :options="selectListPages"
              placeholder="Select a parent page (optional)"
              :disabled="isAppLoading || !sourceDocumentationValue"
            />
          </b-form-group>
        </b-collapse>

        <div
          v-if="!isModal && canEdit"
          class="action-button_container action-button_container--top action-button_container--left-align"
        >
          <SubmitButton
            :disabled="isSubmitDisabled"
            :loading="isAppLoading"
            label="Save"
            class="w-auto mb-auto mr-2"
          />
          <b-button
            v-b-toggle.edit-fields-collapse
            class="mr-2"
          >
            Edit fields
            <b-icon icon="pencil-square" />
          </b-button>
          <b-button
            class="w-auto mb-auto"
            @click="changeMode"
          >
            {{ isPreview ? 'Edit' : 'Preview' }}
          </b-button>
        </div>

        <MarkdownEditor
          v-model="textValue"
          class="documentation-page-editor mt-3"
          :editing="isEditing"
          :toolbar-options="toolbarOptions"
          :image-upload-path="imageUploadPath"
        />

        <b-row v-if="isModal && canEdit">
          <div class="action-button_container">
            <b-button
              type="reset"
              variant="link"
              class="text-danger"
            >
              {{ edition ? 'Delete' : 'Reset' }}
            </b-button>
            <SubmitButton
              :disabled="isSubmitDisabled"
              :loading="isAppLoading"
              class="w-auto mb-auto ml-2"
            />
          </div>
        </b-row>
      </div>
    </b-form>
  </div>
</template>

<script>
  import {
    deleteRef,
    updateRef,
    addDocToCollection,
    getDocumentReference,
  } from '@/service/FirebaseService'
  import { DictionnariesCreationMixin } from '@/mixins/DictionnariesCreationMixin.js'

  import MarkdownEditor from '@/components/documentation/MarkdownEditor.vue'
  import { mapGetters, mapState } from 'vuex'
  import { RIGHTS } from '@/constants/UserConstants'

  const initialData = {
    idValue: null,
    displayNameValue: null,
    parentPageValue: null,
    sourceDocumentationValue: null,
    textValue: null,
  }

  export default {
    name: 'DocumentationPageCreationEdition',

    components: {
      MarkdownEditor,
    },

    mixins: [DictionnariesCreationMixin],

    props: {
      isModal: {
        type: Boolean,
        default: true,
      },
      text: {
        type: String,
        default: '',
      },
      sourceDocumentation: {
        type: String,
        default: null,
      },
      parent: {
        type: Object,
        default: null,
      },
      weight: {
        type: Number,
        default: null,
      },
      edition: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        ...initialData,
        initialData,
        type: 'page',
        idAlreadyExists: false,
        idValidRegex: new RegExp(/^[A-z0-9-]+$/),
        defaultErrorMessage:
          "Id must only contains letters without accentuation (lowercase or uppercase), numbers or '-' character.",
        imageUploadPath: 'documentations',
        toolbarDisabledAdmin: [
          'trash',
          'save',
          'htmlcode',
          'subfield',
          'preview',
          'fullscreen',
        ],
        toolbarDisabledViewOnly: [
          'bold',
          'italic',
          'header',
          'underline',
          'strikethrough',
          'mark',
          'superscript',
          'subscript',
          'quote',
          'ol',
          'ul',
          'link',
          'imagelink',
          'code',
          'table',
          'fullscreen',
          'htmlcode',
          'undo',
          'redo',
          'trash',
          'save',
          'subfield',
          'preview',
          'alignleft',
          'aligncenter',
          'alignright',
        ],
      }
    },

    computed: {
      ...mapGetters(['canUserDo']),
      ...mapState('documentations', ['categories', 'pages']),
      ...mapGetters('documentations', ['getPagesByCategory']),
      canEdit() {
        return this.canUserDo(RIGHTS.DOCUMENTATION_BOARD_EDITION)
      },
      idState() {
        if (this.edition) return true
        if (this.idValue === null || this.idValue.length === 0) return null
        if (!this.edition && this.idAlreadyExists) return false
        return this.idValidRegex.test(this.idValue)
      },

      docPath() {
        if (!this.idValue || !this.sourceDocumentationValue) return null
        return `documentations/${this.sourceDocumentationValue}/pages/${this.idValue}`
      },

      parentDocPath() {
        if (!this.idValue || !this.sourceDocumentationValue) return null
        return `documentations/${this.sourceDocumentationValue}`
      },

      errorMessage() {
        if (this.idState === null) return null
        if (!this.edition && this.idAlreadyExists)
          return `Id ${this.idValue} already exists in ${this.sourceDocumentationValue} documentation.`
        return this.defaultErrorMessage ?? 'Id does not math pattern.'
      },

      isSubmitDisabled() {
        return (
          [null, false].includes(this.idState) ||
          this.displayNameValue === null ||
          !this.displayNameValue?.length > 0 ||
          this.sourceDocumentationValue === null ||
          !this.sourceDocumentationValue?.length > 0
        )
      },

      isEditing() {
        return !this.isPreview
      },

      toolbarOptions() {
        const options = this.isEditing
          ? this.toolbarDisabledAdmin
          : this.toolbarDisabledViewOnly
        return options?.reduce((accumulator, key) => {
          return { [key]: false, ...accumulator }
        }, {})
      },

      isPreview() {
        return this.$route.name === 'documentation-page--preview'
      },

      selectListDocumentation() {
        return this.categories.map(({ id, displayName }) => {
          return {
            value: id,
            text: displayName ?? id,
          }
        })
      },
      selectListPages() {
        const options = this.getPagesByCategory(
          this.sourceDocumentationValue,
        )?.reduce(
          (accumulator, page) => {
            const { id, displayName } = page
            return id === this.idValue ||
              (this.edition && this.isDescendant(this.idValue, page))
              ? [...accumulator]
              : [{ value: id, text: displayName ?? id }, ...accumulator]
          },
          [{ value: null, text: '-- No parent page --' }],
        )
        return options
      },
    },

    watch: {
      async id(newV) {
        this.idValue = newV
      },

      displayName(newV) {
        this.displayNameValue = newV
      },

      text(newV) {
        this.textValue = newV
      },

      sourceDocumentation(newV) {
        this.sourceDocumentationValue = newV
      },

      parent(newV) {
        this.parentPageValue = newV?.id
      },
    },

    async created() {
      if (this.edition) {
        this.idValue = this.id
        this.displayNameValue = this.displayName
        this.textValue = this.text
        this.sourceDocumentationValue = this.sourceDocumentation
        this.parentPageValue = this.parent?.id
      }
    },

    methods: {
      isDescendant(parentPageId, descendantPage) {
        if ([null, undefined].includes(descendantPage)) return false
        return (
          descendantPage?.parent?.id === parentPageId ||
          this.isDescendant(parentPageId, descendantPage?.parent?.parent)
        )
      },

      documentAlreadyExists() {
        return this.getPagesByCategory(this.sourceDocumentationValue).some(
          ({ id }) => id === this.idValue,
        )
      },

      onTextChanged(value) {
        this.textValue = value
      },

      currentMaxWeight() {
        const pagesWithSameParent =
          this.getPagesByCategory(this.sourceDocumentationValue)?.filter(
            (page) =>
              page?.parent?.id === this.parentPageValue ||
              (this.parentPageValue === null &&
                [null, undefined].includes(page?.parent)),
          ) ?? []
        const maxWeight = pagesWithSameParent?.reduce((accumulator, page) => {
          return Math.max(accumulator, page?.weight)
        }, -1)

        return maxWeight
      },

      async onSubmit() {
        this.appLoading(true)

        try {
          if (!this.docPath) throw new Error('Missing document path')
          if (!this.edition && (await this.documentAlreadyExists())) return

          const changedDocumentation =
            this.sourceDocumentationValue != this.sourceDocumentation

          const finalObject = {
            displayName: this.displayNameValue,
            ...(this.meta && { meta: this.meta }),
            parent: this.parentPageValue
              ? getDocumentReference(
                  `documentations/${this.sourceDocumentationValue}/pages/${this.parentPageValue}`,
                )
              : null,
            text: this.textValue ? this.textValue : '',
            ...(this.weight && { weight: this.weight }),
          }

          if (this.edition && !changedDocumentation) {
            await updateRef(this.docPath, finalObject)
          } else {
            if (this.edition && changedDocumentation)
              await deleteRef(
                `documentations/${this.sourceDocumentation}/pages/${this.idValue}`,
              )

            finalObject.weight = this.currentMaxWeight() + 1
            await addDocToCollection(
              this.parentDocPath,
              'pages',
              finalObject,
              null,
              this.idValue,
            )
          }

          this.setMessageInformations({
            message: `Documentation page ${
              this.displayNameValue
            } has been successfully ${this.edition ? 'updated' : 'created'}`,
            state: 'success',
          })

          this.$emit('done', this.type)
        } catch (error) {
          this.handleErrors({ error })
        } finally {
          this.appLoading(false)
        }
      },

      changeMode() {
        this.$router.push({
          name: 'documentation-page' + (this.isPreview ? '' : '--preview'),
          params: { documentation: this.sourceDocumentation, id: this.id },
        })
      },
    },
  }
</script>

<style lang="scss">
  .create-edit-doc-page-form {
    .documentation-page-editor {
      &:not(.fullscreen) {
        max-width: calc(100vw - 410px);
      }
    }
    .v-note-wrapper {
      z-index: unset; // because default z-index is 1500 in mavon editor
    }
  }
</style>
