import { DocumentMeta } from '@/models'
import firebase from 'firebase/compat/app'
import { getMetaData, getNewServerTimestamp } from '../service/FirebaseService'

/**
 * The Profile class.
 */
export class Profile {
  id: string
  ref: string
  label: string
  isDefault: boolean
  inclusiveCriterias: string[]
  inclusiveStrictCriterias: string[]
  exclusiveCriterias: string[]
  inclusiveRootItemCriterias: string[]
  inclusiveItemCriterias: string[]
  exclusiveItemCriterias: string[]
  hit: number
  meta: DocumentMeta
  isLocked: boolean
  comment?: string

  /**
   * Profile constructor.
   *
   * @param {String} [id] - The firebase id.
   * @param {String} [ref] - The firebase profile path.
   * @param {String} [label] - The profile label.
   * @param {Boolean} [isDefault] - Set if the profile is default or not.
   * @param {Array} [inclusiveCriterias] - The list of inclusive criterias.
   * @param {Array} [inclusiveStrictCriterias] - The list of inclusive criterias.
   * @param {Array} [exclusiveCriterias] - The list of exclusive criterias.
   * @param {Array} [inclusiveRootItemCriterias] - The list of the inclusive root item criterias.
   * @param {Array} [inclusiveItemCriterias] - The list of the inclusive item criterias.
   * @param {Array} [exclusiveItemCriterias] - The list of the exclusive item criterias.
   * @param {Number} [hit] - Set the number of times the profile has been returned by the prescription.
   * @param {Boolean} [isLocked] - If the profile is locked and therefor cannot be updated through the prescription automation.
   * @param {Object} [meta] - An object with all metadata.
   * @return {Object} Return a Profile object.
   */
  constructor({
    id = '',
    ref = '',
    label = '',
    isDefault = false,
    inclusiveCriterias = [],
    inclusiveStrictCriterias = [],
    exclusiveCriterias = [],
    inclusiveRootItemCriterias = [],
    inclusiveItemCriterias = [],
    exclusiveItemCriterias = [],
    hit = 0,
    isLocked = false,
    meta = getMetaData,
    comment = '',
  } = {}) {
    this.id = id
    this.ref = ref
    this.label = label
    this.isDefault = isDefault
    this.inclusiveCriterias = inclusiveCriterias
    this.inclusiveStrictCriterias = inclusiveStrictCriterias
    this.exclusiveCriterias = exclusiveCriterias
    this.inclusiveRootItemCriterias = inclusiveRootItemCriterias
    this.inclusiveItemCriterias = inclusiveItemCriterias
    this.exclusiveItemCriterias = exclusiveItemCriterias
    this.hit = hit
    this.isLocked = isLocked
    this.comment = comment
    this.meta = meta && Object.keys(meta).length > 3 ? meta : getMetaData
  }
}

export const profileConverter = {
  toFirestore: (profile: Profile) => {
    return {
      label: profile.label,
      isDefault: profile.isDefault,
      inclusiveCriterias: profile.inclusiveCriterias,
      inclusiveStrictCriterias: profile.inclusiveStrictCriterias,
      exclusiveCriterias: profile.exclusiveCriterias,
      inclusiveRootItemCriterias: profile.inclusiveRootItemCriterias,
      inclusiveItemCriterias: profile.inclusiveItemCriterias,
      exclusiveItemCriterias: profile.exclusiveItemCriterias,
      hit: profile.hit,
      isLocked: profile.isLocked ?? false,
      comment: profile.comment ?? '',
      meta: {
        ...profile.meta,
        createdAt: profile.meta.createdAt ?? getNewServerTimestamp,
        updatedAt: getNewServerTimestamp,
      },
    }
  },
  fromFirestore: (
    snapshot: firebase.firestore.DocumentSnapshot,
    options?: firebase.firestore.SnapshotOptions,
  ): Profile => {
    const data = snapshot.data(options)
    return new Profile({
      id: snapshot.id,
      ref: snapshot.ref.path,
      label: data.label,
      isDefault: data.isDefault,
      inclusiveCriterias: data.inclusiveCriterias,
      inclusiveStrictCriterias: data.inclusiveStrictCriterias,
      exclusiveCriterias: data.exclusiveCriterias,
      inclusiveRootItemCriterias: data.inclusiveRootItemCriterias,
      inclusiveItemCriterias: data.inclusiveItemCriterias,
      exclusiveItemCriterias: data.exclusiveItemCriterias,
      hit: data.hit,
      isLocked: data.isLocked,
      comment: data.comment,
      meta: data.meta,
    })
  },
}
