<template>
  <div class="main-collection_container container-fluid">
    <TitleContainer
      v-if="!included"
      :title="title"
    />
    <b-form
      class="main-collection_form"
      @submit.prevent="onSubmit"
      @reset.prevent="onReset"
    >
      <div class="main-collection_fields">
        <b-form-group
          class="col-5 mb-2 pl-0"
          label="Customer id:"
          required
        >
          <b-form-input
            v-model="idValue"
            :disabled="isAppLoading || !fetchedResources.tenants || edition"
            :debounce="500"
            :state="idState"
            aria-describedby="customer-input-id-feedback"
            required
          />
          <b-form-invalid-feedback id="customer-input-id-feedback">
            {{ errorMessage }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          class="col-5 mb-2 pl-0"
          label="Display name:"
          required
        >
          <b-form-input
            id="customer-input-display-name"
            v-model.trim="displayNameValue"
            :disabled="isAppLoading || !fetchedResources.tenants"
            :debounce="500"
            required
          />
        </b-form-group>
        <b-form-group
          class="col-5 mb-2 pl-0"
          label="Tenant:"
          required
        >
          <b-form-select
            :value="tenantValue"
            :options="listTenants"
            placeholder="Enter the tenant"
            disabled
          />
        </b-form-group>
        <b-row>
          <div :class="included ? 'action-button_container' : 'mt-4 pl-0'">
            <b-button
              type="reset"
              variant="link"
              class="text-danger"
              :class="!included ? 'w-100' : ''"
              >{{ edition ? 'Delete' : 'Reset' }}</b-button
            >
            <SubmitButton
              :disabled="isSubmitDisabled"
              :loading="isAppLoading || !fetchedResources.tenants"
              :class="included ? 'w-auto mb-auto ml-2' : ''"
            />
          </div>
        </b-row>
      </div>
    </b-form>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import { RESOURCES } from '@/service/ResourceService'
  import { getDocumentReference } from '@/service/FirebaseService'

  import { DictionnariesCreationMixin } from '@/mixins/DictionnariesCreationMixin'

  const initialData = {
    idValue: null,
    displayNameValue: null,
    tenantValue: 'modiface',
  }
  export default {
    name: 'CustomerCreation',

    mixins: [DictionnariesCreationMixin],

    props: {
      tenant: {
        type: Object,
        default: null,
      },
    },

    data() {
      return {
        ...initialData,
        initialData,
        listTenants: [],
        stateKey: 'customers',
        idValidRegex: new RegExp(/^[A-Za-z0-9]+$/),
        defaultErrorMessage:
          'Id must only contains letters without accentuation (lowercase or uppercase) or numbers.',
      }
    },

    computed: {
      ...mapState({
        tenantsState: (state) => state.tenants,
        fetchedResources: (state) => state.fetchedResources,
      }),

      finalObject() {
        return {
          id: this.idValue,
          displayName: this.displayNameValue,
          tenant: getDocumentReference(`tenants/${this.tenantValue}`),
        }
      },
    },

    async mounted() {
      this.listTenants = this.getListTenants()

      if (this.edition) {
        this.idValue = this.id
        this.displayNameValue = this.displayName
        this.tenantValue = this.tenant?.id
      }

      await this.loadResources({
        resourceNames: [RESOURCES.TENANTS, RESOURCES.CUSTOMERS],
      })
    },

    methods: {
      ...mapActions(['loadResources']),

      getListTenants() {
        return this.tenantsState?.map((tenant) => {
          const { id, displayName } = tenant
          return {
            value: id,
            text: displayName ?? id,
          }
        })
      },
    },
  }
</script>
