// Color used in tiles informations for tags
export enum ColorConstants {
  TileTagsColor = '#41B883',
  ClassicTagBackground = '#CF4425',
  LightTagBackground = '#96CAC7',
  DarkTagBackground = '#5D6C81',
}

export type ColorCodes = '#41B883' | '#CF4425' | '#96CAC7' | '#5D6C81'

export const TILE_TAGS_COLOR = '#41B883'
export const CLASSIC_TAG_BACKGROUND = '#CF4425'
export const LIGHT_TAG_BACKGROUND = '#96CAC7'
export const DARK_TAG_BACKGROUND = '#5D6C81'
