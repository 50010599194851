<template>
  <div>
    <FireTable
      table-id="profilesMissing"
      :items="items"
      :fields="fields"
      :busy="!isPrescriptionLoaded"
      hide-search
    >
      <template #search />
      <template #quick-actions>
        <b-button
          v-if="!showHiddenHits && hiddenHitCount > 0"
          variant="primary"
          @click="showHiddenHits = !showHiddenHits"
        >
          <b-icon icon="eye-fill" />
          Show {{ hiddenHitCount }} hidden hits
        </b-button>

        <b-button
          v-if="showHiddenHits && hiddenHitCount > 0"
          variant="primary"
          @click="showHiddenHits = !showHiddenHits"
        >
          <b-icon icon="eye-slash-fill" />
          Hide ignored hits
        </b-button>
      </template>

      <template #cell(ignored)="{ value }">
        <b-icon :icon="`eye${value ? '-slash' : ''}-fill`" />
        {{ value ? 'Ignored' : 'Visible' }}
      </template>

      <template #cell(criterias)="{ value }">
        <Tag
          v-for="tag in value"
          :key="`missing-${tag.label}`"
          :tag-fomatted="(tagFomatted = getCriteriaById(tag))"
          :color="tagFomatted.color"
          :text="tagFomatted.label"
        />
      </template>
      <template #cell(itemCriterias)="{ value }">
        <Tag
          v-for="v in value"
          :key="`missingItem-${v}`"
          :text="v"
        />
      </template>
    </FireTable>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import FireTable from '@/components/table/FireTable'
  import Tag from '@/components/tag/Tag'
  import { DEFAULT_LOCALE } from '@/constants/App.constants'
  import { FiretableFieldType } from '@/constants/FiretableFieldType.enum'

  export default {
    name: 'ProfilesListsMissing',

    components: {
      FireTable,
      Tag,
    },

    data() {
      return {
        showHiddenHits: false,
        modalItem: null,
        showModal: false,

        fields: [
          {
            key: 'latestHit',
            label: 'Latest hit',
            sortable: true,
            formatter: (v) =>
              'Latest hit: ' + v.toDate().toLocaleString(DEFAULT_LOCALE),
          },
          {
            label: 'Display',
            key: 'ignored',
            sortable: true,
            type: FiretableFieldType.Custom,
          },
          {
            label: 'Use',
            sortable: true,
            key: 'count',
          },
          {
            key: 'criterias',
            label: 'Criterias',
            sortable: true,
            type: FiretableFieldType.Tags,
          },
          {
            key: 'itemCriterias',
            label: 'Items',
            sortable: true,
            type: FiretableFieldType.Tags,
          },
          {
            label: 'Actions',
            key: 'actions',
            menuActions: [
              {
                label: 'Create profile',
                variant: 'success',
                icon: 'plus-circle',
                onClick: (hit) => this.createProfileWithCriteriasFromHit(hit),
              },
              {
                divider: true,
                icon: ({ ignored }) => `eye${ignored ? '' : '-slash'}-fill`,
                label: ({ ignored }) => (ignored ? 'Visible' : 'Ignore'),
                onClick: (hit) => this.toggleIgnore(hit),
              },
            ],
          },
        ],
      }
    },

    computed: {
      ...mapState({
        missingPrescriptionHits: (state) =>
          state.prescriptions.missingPrescriptionHits,
        isPrescriptionLoaded: (state) => state.prescriptions.isLoaded,
      }),

      ...mapGetters(['getCriteriaById']),

      items() {
        return this.missingPrescriptionHits.filter(
          (hit) => (hit.ignored && this.showHiddenHits) || !hit.ignored,
        )
      },

      hiddenHitCount() {
        return this.missingPrescriptionHits.filter((hit) => hit.ignored).length
      },
    },

    methods: {
      ...mapActions(['createPartialProfile', 'updateMissingPrescriptionHit']),

      async createProfileWithCriteriasFromHit(hit) {
        const criteriaLabel = hit.criterias
          .map((criteria) => this.getCriteriaById(criteria).label)
          .join(', ')

        await this.createPartialProfile({
          label: `New route with ${criteriaLabel}`,
          inclusiveCriterias: hit.criterias,
          inclusiveRootItemCriterias: hit.itemCriterias,
        })

        if (!hit.ignored) {
          await this.updateMissingPrescriptionHit({
            id: hit.id,
            ignored: true,
          })
        }
      },

      toggleIgnore(row) {
        this.updateMissingPrescriptionHit({
          id: row.id,
          ignored: !row.ignored,
        })
      },

      setModalItem({
        inclusiveCriterias,
        inclusiveStrictCriterias,
        exclusiveCriterias,
      }) {
        this.modalItem = inclusiveCriterias.concat(
          inclusiveStrictCriterias,
          exclusiveCriterias,
        )
        this.showModal = true
      },
    },
  }
</script>
