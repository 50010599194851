import firebase from 'firebase/compat/app'
import { getNewServerTimestamp } from '@/service/FirebaseService'
import { Item } from '@/types/Item'

/**
 * The StepItem class.
 */
export class StepItem extends Item {
  step: string
  weight: number
  flags: string[]

  /**
   * StepItem constructor.
   *
   * @param {String} [id] - The Firebase id.
   * @param {String} [ref] - The firebase profile path.
   * @param {String} [brand] - The section's brand.
   * @param {String} [sku] - The section's sku.
   * @param {String} [item] - The section's id.
   * @param {String} [label] - The section's label.
   * @param {String} [step] - The step the section is in.
   * @param {String} [type] - The type of the section.
   * @param {Number} [weight] - The weight of the section, used to order it's position.
   * @param {Array} [flags] - A list of flags set for the section.
   * @param {Object} [meta] - An object with all metadata.
   * @return {Object} Returns a StepItem object.
   */
  constructor({
    id = '',
    ref = '',
    brand = '',
    sku = '',
    item = '',
    label = '',
    step = '',
    type = '',
    weight = 0,
    flags = [],
    meta = {},
  }) {
    super({ id, ref, brand, sku, item, label, type, meta })
    this.step = step
    this.weight = weight
    this.flags = flags
  }
}

export const stepItemConverter = {
  toFirestore: (stepItem: StepItem) => {
    return {
      item: stepItem.item,
      label: stepItem.label,
      step: stepItem.step,
      type: stepItem.type,
      weight: stepItem.weight,
      ...(!!stepItem.brand && { brand: stepItem.brand }),
      ...(!!stepItem.sku && { sku: stepItem.sku }),
      ...(stepItem.flags?.length > 0 && { flags: stepItem.flags }),
      meta: {
        ...stepItem.meta,
        createdAt: stepItem.meta.createdAt ?? getNewServerTimestamp,
        updatedAt: getNewServerTimestamp,
      },
    }
  },
  fromFirestore: (
    snapshot: firebase.firestore.DocumentSnapshot,
    options?: firebase.firestore.SnapshotOptions,
  ): StepItem => {
    const data = snapshot.data(options)
    return new StepItem({
      id: snapshot.id,
      ref: snapshot.ref.path,
      item: data.item,
      label: data.label,
      step: data.step,
      type: data.type,
      weight: data.weight,
      ...(!!data.brand && { brand: data.brand }),
      ...(!!data.sku && { sku: data.sku }),
      flags: data.flags ?? [],
      meta: data.meta,
    })
  },
}
