<template>
  <div class="private-menu">
    <NotificationsDisplay />
    <div class="private-menu_container">
      <div class="breadcrumb_container">
        <Breadcrumb />
      </div>

      <div class="private-menu_container--right">
        <SearchMenu
          v-if="hasSearchMenu"
          class="search-menu_container"
        />

        <b-dropdown
          class="menu-widget_container"
          variant="outline"
          right
          no-caret
          @show="showTooltip = false"
          @hide="showTooltip = true"
        >
          <template #button-content>
            <UserProfilePicture
              id="profile-image-widget"
              class="profile-picture"
              :image-path="currentUser.profilePic"
              :display-mode="true"
            />
          </template>
          <b-dropdown-text>
            <div>
              <strong>{{
                currentUser.firstname + ' ' + currentUser.name
              }}</strong>
            </div>
            <div>{{ currentUser.email }}</div>
            <div>{{ roleDisplayName }}</div>
          </b-dropdown-text>
          <b-dropdown-divider />
          <b-dropdown-item to="/user/profile">
            <b-icon icon="person-circle" />
            Profile
          </b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item to="/logout">
            <b-icon icon="power" />
            Logout
          </b-dropdown-item>
        </b-dropdown>
        <b-tooltip
          v-if="showTooltip"
          target="profile-image-widget"
          title="Name and email"
          triggers="hover"
          placement="left"
          noninteractive
          no-fade
        >
          <div>
            <strong>{{
              currentUser.firstname + ' ' + currentUser.name
            }}</strong>
          </div>
          <div>{{ currentUser.email }}</div>
          <div>{{ roleDisplayName }}</div>
        </b-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  import SearchMenu from '@/components/menus/SearchMenu'
  import UserProfilePicture from '@/components/admin/user/profile/UserProfilePicture'
  import Breadcrumb from '@/components/menus/Breadcrumb'
  import NotificationsDisplay from '@/components/menus/message/NotificationsDisplay'

  export default {
    name: 'PrivateMenu',

    components: {
      SearchMenu,
      UserProfilePicture,
      Breadcrumb,
      NotificationsDisplay,
    },

    data() {
      return {
        showTooltip: true,
      }
    },

    computed: {
      ...mapState({
        currentUser: (state) => state.user,
      }),

      roleDisplayName() {
        return this.currentUser?.role?.displayName ?? 'Role'
      },

      hasSearchMenu() {
        return this.$route.name === 'homepage'
      },
    },
  }
</script>

<style lang="scss">
  .private-menu {
    display: flex;
    flex-direction: column;

    &_container {
      background-color: var(--app-background-color);
      margin: 0;
      display: flex;
      align-items: center;
      height: 45px;

      // Elevation
      box-shadow: 0 2px 20px -10px rgba(17, 17, 17, 0.5);

      &--right {
        margin-left: auto;
        display: flex;
        align-items: center;
      }

      .breadcrumb_container {
        padding-left: 10px;
      }

      .search-menu_container {
        margin-right: 10px;
      }

      .menu-widget_container {
        .btn {
          padding: 0 10px;

          &:focus {
            box-shadow: none;
          }
        }

        .nav-link {
          padding-top: 0;
          padding-bottom: 0;
        }
      }

      .profile-picture {
        max-height: 30px;
        max-width: 30px;
      }

      .tooltip {
        top: 0;
      }

      .tooltip-inner {
        text-align: left !important;
      }
    }
  }
</style>
