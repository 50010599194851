<template>
  <article class="base-sidemenu-container_main-container">
    <InPageSideMenu
      class="base-sidemenu-container_sidemenu-container"
      :title="title"
      :override-active-item="activeItem"
      :items="sideMenuItems"
      :are-items-submenus="areSidemenuItemsSubmenus"
      :sortable="isSortable"
      v-on="$listeners"
    />

    <section class="base-sidemenu-container_content-container">
      <slot />
    </section>
  </article>
</template>

<script>
  import InPageSideMenu from '@/components/menus/InPageSideMenu'

  export default {
    name: 'BaseSideMenuContainer',

    components: {
      InPageSideMenu,
    },

    props: {
      title: {
        type: String,
        default: null,
      },
      activeItem: {
        type: String,
        default: null,
      },
      sideMenuItems: {
        type: Array,
        required: true,
      },
      areSidemenuItemsSubmenus: {
        type: Boolean,
        default: false,
      },
      isSortable: {
        type: Boolean,
        default: false,
      },
      isHeightReduced: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {}
    },
  }
</script>

<style lang="scss" scoped>
  .base-sidemenu-container_main-container {
    height: 100%;

    display: grid;
    grid-template-areas: 'sidemenu page-content';
    grid-template-columns: auto 1fr;
  }

  .base-sidemenu-container_sidemenu-container {
    grid-area: sidemenu;
  }

  .base-sidemenu-container_content-container {
    grid-area: page-content;
  }
</style>
