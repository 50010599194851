
  import Vue from 'vue'
  import CriteriasMultiselect from '@/components/criterias-multiselect/CriteriasMultiselect.vue'
  import FieldsGroup from '@/components/fields-group/FieldsGroup.vue'
  import { PrescriptionMixin } from '@/mixins/PrescriptionMixin.js'
  import BaseContainer from '@/components/containers/BaseContainer.vue'
  import ConfirmationModal from '@/components/modals/ConfirmationModal.vue'
  import FireDetails from '@/components/containers/FireDetails.vue'

  import { mapState, mapGetters, mapActions } from 'vuex'

  import Multiselect from '../form/Multiselect.vue'
  import { DocumentResult } from '@/models'

  const indexBy = (key) => (elements) => {
    return elements.reduce((hash, element) => {
      hash[element[key]] = element
      return hash
    }, {})
  }

  const indexById = indexBy('id')
  const indexByItem = indexBy('item')

  export default Vue.extend({
    name: 'ProfileCreationEditionConfiguration',

    components: {
      CriteriasMultiselect,
      FieldsGroup,
      BaseContainer,
      FireDetails,
      Multiselect,
      ConfirmationModal,
    },

    mixins: [PrescriptionMixin],

    data() {
      return {
        showDeletionModal: false,

        criteriasTypes: [
          {
            id: 'inclusiveStrictCriterias',
            condition: 'MUST',
            label: 'have these criteria',
          },
          {
            id: 'inclusiveCriterias',
            condition: 'SHOULD',
            label: 'have these criteria',
          },
          {
            id: 'exclusiveCriterias',
            condition: 'MUST NOT',
            label: 'have these criteria',
          },
        ],
        itemsTypes: [
          {
            id: 'inclusiveRootItemCriterias',
            condition: 'MUST',
            label: 'have these items',
          },
          {
            id: 'inclusiveItemCriterias',
            condition: 'SHOULD',
            label: 'have these items',
          },
          {
            id: 'exclusiveItemCriterias',
            condition: 'MUST NOT',
            label: 'have these items',
          },
        ],
      }
    },

    computed: {
      ...mapState({
        criterias: (state) => state.prescriptions.criterias,
        profile: (state) => state.prescriptionProfile.profile,
      }),

      ...mapGetters([
        'itemsAsCriterias',
        'getCriteriaById',
        'acceptItemsProfileCriteria',
        'getItemById',
        'isAIPoweredPrescription',
      ]),

      ...mapGetters('prescriptionProfile', [
        'inclusiveCriterias',
        'inclusiveStrictCriterias',
        'exclusiveCriterias',
        'inclusiveRootItemCriterias',
        'inclusiveItemCriterias',
        'exclusiveItemCriterias',
      ]),

      profileConditionsLine() {
        return [
          {
            type: 'criteria',
            label: 'Criterias',
            columns: this.criteriasTypes.map((criteriasType) => {
              return {
                ...criteriasType,
                values: this[criteriasType.id],
              }
            }),
          },
          ...(this.acceptItemsProfileCriteria
            ? [
                {
                  type: 'item',
                  label: 'Items',
                  columns: this.itemsTypes.map((itemType) => {
                    return {
                      ...itemType,
                      values: this[itemType.id],
                    }
                  }),
                },
              ]
            : []),
        ]
      },

      emptyConditionsProfile() {
        return !this.profileConditionsLine.some((line) => {
          return line.columns.some((column) => {
            return this[column.id].length > 0
          })
        })
      },

      inclusiveCriteriasById() {
        return indexById(this.inclusiveCriterias)
      },

      inclusiveStrictCriteriasById() {
        return indexById(this.inclusiveStrictCriterias)
      },

      exclusiveCriteriasById() {
        return indexById(this.exclusiveCriterias)
      },

      criteriasOptions() {
        const inclusiveStrictCriterias = this.criterias.filter(
          (c) =>
            !this.inclusiveCriteriasById[c.id] &&
            !this.exclusiveCriteriasById[c.id],
        )

        const inclusiveCriterias = this.criterias.filter(
          (c) =>
            !this.inclusiveStrictCriteriasById[c.id] &&
            !this.exclusiveCriteriasById[c.id],
        )

        const exclusiveCriterias = this.criterias.filter(
          (c) =>
            !this.inclusiveStrictCriteriasById[c.id] &&
            !this.inclusiveCriteriasById[c.id],
        )

        return {
          inclusiveStrictCriterias,
          inclusiveCriterias,
          exclusiveCriterias,
        }
      },

      inclusiveRootItemCriteriasByItem() {
        return indexByItem(this.inclusiveRootItemCriterias)
      },

      inclusiveItemCriteriasByItem() {
        return indexByItem(this.inclusiveItemCriterias)
      },

      exclusiveItemCriteriasByItem() {
        return indexByItem(this.exclusiveItemCriterias)
      },

      itemsOptions() {
        const inclusiveRootItemCriterias = this.itemsAsCriterias.filter(
          (c) =>
            !this.inclusiveItemCriteriasByItem[c.item] &&
            !this.exclusiveItemCriteriasByItem[c.item],
        )

        const inclusiveItemCriterias = this.itemsAsCriterias.filter(
          (c) =>
            !this.inclusiveRootItemCriteriasByItem[c.item] &&
            !this.exclusiveItemCriteriasByItem[c.item],
        )

        const exclusiveItemCriterias = this.itemsAsCriterias.filter(
          (c) =>
            !this.inclusiveRootItemCriteriasByItem[c.item] &&
            !this.inclusiveItemCriteriasByItem[c.item],
        )

        return {
          inclusiveRootItemCriterias,
          inclusiveItemCriterias,
          exclusiveItemCriterias,
        }
      },

      loadingClass() {
        return this.isAppLoading ? 'loading' : ''
      },

      profileLabelState() {
        if (!this.profile) {
          return null
        }

        return this.profile.label.length > 0
      },

      linkToPrescription() {
        return {
          name: 'prescription',
          params: { app_id: this.$route?.params?.app_id },
        }
      },
    },

    methods: {
      ...mapActions('prescriptionProfile', [
        'updateProfile',
        'updateProfileActive',
        'duplicateProfile',
        'deleteProfile',
      ]),
      /**
       * Proxy method to update the fields of a reference.
       *
       * @param {Object} fields - An object with the fields and associated values.
       */
      async onUpdateField(key: string, value: string[]) {
        try {
          await this.updateProfile({ key, value })
        } catch (error) {
          this.handleErrors({ error })
        }
      },

      /**
       * Update the active field in meta for a profile.
       */
      async onUpdateProfileActive(val: boolean) {
        try {
          await this.updateProfileActive(val)
        } catch (error) {
          this.handleErrors({ error })
        }
      },

      async onUpdateDefaultProfile(value: boolean) {
        await this.onUpdateField('isDefault', value)
        this.checkDefaultProfileState()
      },

      /**
       * Update the criterias of the profile.
       *
       * @param {String} type - The criteria type (inclusive, exclusive ...).
       * @param {Array} filters - The list of criterias.
       */
      async onUpdateCriterias(type, colId, filters) {
        try {
          const updateFilter = filters.map((filter) => {
            return type === 'item' ? filter.item : filter.id
          })
          await this.onUpdateField(colId, updateFilter)
        } catch (error) {
          this.handleErrors({ error })
        }
      },

      /**
       * Proxy method to delete a profile.
       */
      async deleteProfileConfirmed() {
        try {
          await this.deleteProfile()
          this.setMessageInformations({
            message: 'Profile deleted successfully.',
            state: 'success',
          })
          this.$router.push(this.linkToPrescription)
        } catch (error) {
          this.handleErrors({ error })
        }
      },

      deleteModal() {
        this.showDeletionModal = true
        this.$nextTick(() => {
          this.$bvModal.show('modal-delete-confirmation-default')
        })
      },

      async duplicateExistingProfile(): Promise<void> {
        const newDocument: DocumentResult = await this.duplicateProfile()
        this.$emit('selectItem', { itemId: newDocument.id })
      },
    },
  })
