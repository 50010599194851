/**
 * The Product Model Type.
 */
export class Product {
  readonly item: string
  readonly brand: string
  readonly sku: string
  readonly label: string
  readonly type: string
  readonly description: string
  readonly image: string
  readonly rating: string
  debugInfo: Record<string, any>

  /**
   * Product constructor.
   *
   * @param {String} item - The item id.
   * @param {String} brand - The product's brand.
   * @param {String} sku - The product's sku.
   * @param {String} label - The product's label.
   * @param {String} [image] - The product's packshot image.
   * @param {String} [description] - The product's description.
   * @param {Number} [rating] - The product's rating/notation.
   * @param {Object} [debugInfo] - Debug information about the ProductAPI call to retrieve product information
   * @return {Object} Return a Product object.
   */
  constructor(
    item,
    brand,
    sku,
    label,
    image = null,
    description = null,
    rating = null,
    debugInfo = null,
  ) {
    this.item = item
    this.brand = brand
    this.sku = sku
    this.label = label
    this.type = 'product'
    this.description = description
    this.image = image
    this.rating = rating?.toFixed(1)
    this.debugInfo = debugInfo
  }
}
