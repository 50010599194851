<template>
  <article class="base-tree-navigation-container_main-container">
    <TreeNavigation
      class="base-tree-navigation"
      :title="title"
      :override-current-item="currentItem"
      :items="items"
      :sortable="isSortable"
      v-on="$listeners"
    />

    <section class="base-tree-navigation_content-container">
      <slot />
    </section>
  </article>
</template>

<script>
  import TreeNavigation from '@/components/documentation/TreeNavigation.vue'

  export default {
    name: 'BaseTreeNavigationContainer',

    components: {
      TreeNavigation,
    },

    props: {
      title: {
        type: String,
        default: null,
      },
      currentItem: {
        type: String,
        default: null,
      },
      items: {
        type: Array,
        required: true,
      },
      isSortable: {
        type: Boolean,
        default: true,
      },
    },
  }
</script>

<style lang="scss" scoped>
  .base-tree-navigation-container_main-container {
    height: 100%;

    display: grid;
    grid-template-areas: 'tree-navigation page-content';
    grid-template-columns: auto 1fr;
  }

  .base-tree-navigation {
    grid-area: tree-navigation;
  }

  .base-tree-navigation_content-container {
    grid-area: page-content;
  }
</style>
