<template>
  <div
    class="editor"
    :class="disabled ? 'editor--disabled' : ''"
  >
    <div class="d-flex flex-row">
      <WysiwygMenuButton
        v-for="button in menuButtons"
        :key="`wysiwyg-menu-${button.icon}`"
        :is-active="activeCommands[button.id]"
        :icon="button.icon"
        :tooltip="button.tooltip"
        :disabled="button.disabled"
        :rotate="button.rotate ?? '0'"
        @click="button.onClickAction"
      />

      <div class="ml-auto">
        <WysiwygMenuButton
          icon="arrow-counterclockwise"
          rotate="-45"
          tooltip="Undo"
          :disabled="disabled"
          @click="commandToRun('undo')"
        />

        <WysiwygMenuButton
          icon="arrow-clockwise"
          rotate="45"
          tooltip="Redo"
          :disabled="disabled"
          @click="commandToRun('redo')"
        />
      </div>
    </div>

    <article class="editor-content_section">
      <vuescroll
        class="editor-content_scroll"
        :ops="scrollbarOps"
      >
        <EditorContent
          class="editor-content"
          :editor="editor"
        />
      </vuescroll>
    </article>

    <div class="d-flex flex-row-reverse">
      <DocumentationLinkButton
        destination="/documentations/view/user-guide/instance-setup-fields/"
        variant="info"
      />
    </div>

    <WysiwygLinkModal
      v-model="showLinkModal"
      :edition="linkEdition"
      :link-text="linkText"
      :link-url="linkUrl"
      @linkSaved="handleLinkSave"
      @unlink="handleUnlink"
      @cancelled="resetModalData"
    />
  </div>
</template>

<script>
  import { Editor, EditorContent } from '@tiptap/vue-2'
  import { isValidUrl } from '@/utils/StringHelpers'

  import Heading from '@tiptap/extension-heading'
  import OrderedList from '@tiptap/extension-ordered-list'
  import BulletList from '@tiptap/extension-bullet-list'
  import ListItem from '@tiptap/extension-list-item'
  import Bold from '@tiptap/extension-bold'
  import Italic from '@tiptap/extension-italic'
  import Strike from '@tiptap/extension-strike'
  import Underline from '@tiptap/extension-underline'
  import History from '@tiptap/extension-history'
  import Text from '@tiptap/extension-text'
  import Document from '@tiptap/extension-document'
  import Paragraph from '@tiptap/extension-paragraph'
  import Link from '@tiptap/extension-link'

  import WysiwygMenuButton from '@/components/wysiwyg/WysiwygMenuButton.vue'
  import WysiwygLinkModal from '@/components/wysiwyg/WysiwygLinkModal.vue'
  import vuescroll from 'vuescroll'
  import DocumentationLinkButton from '@/components/documentation/DocumentationLinkButton.vue'

  let debounce = null

  const EXTENSIONS = [
    {
      id: 'bold',
      group: 'textStyles',
      extension: Bold,
      icon: 'type-bold',
      tooltip: 'Bold',
      commandName: 'toggleBold',
    },
    {
      id: 'italic',
      group: 'textStyles',
      extension: Italic,
      icon: 'type-italic',
      tooltip: 'Italic',
      commandName: 'toggleItalic',
    },
    {
      id: 'underline',
      group: 'textStyles',
      extension: Underline,
      icon: 'type-underline',
      tooltip: 'Underline',
      commandName: 'toggleUnderline',
    },
    {
      id: 'strike',
      group: 'textStyles',
      extension: Strike,
      icon: 'type-strikethrough',
      tooltip: 'Strike through',
      commandName: 'toggleStrike',
    },
    {
      id: 'bulletList',
      group: 'lists',
      extension: BulletList,
      icon: 'list-ul',
      tooltip: 'Bullet List',
      commandName: 'toggleBulletList',
    },
    {
      id: 'orderedList',
      group: 'lists',
      extension: OrderedList,
      icon: 'list-ol',
      tooltip: 'Ordered List',
      commandName: 'toggleOrderedList',
    },
    {
      id: 'heading1',
      group: 'headings',
      notStandard: true,
      level: 1,
      icon: 'type-h1',
      tooltip: 'Heading 1',
      commandName: 'toggleHeading',
      commandParams: { level: 1 },
    },
    {
      id: 'heading2',
      group: 'headings',
      notStandard: true,
      level: 2,
      icon: 'type-h2',
      tooltip: 'Heading 2',
      commandName: 'toggleHeading',
      commandParams: { level: 2 },
    },
    {
      id: 'heading3',
      group: 'headings',
      notStandard: true,
      level: 3,
      icon: 'type-h3',
      tooltip: 'Heading 3',
      commandName: 'toggleHeading',
      commandParams: { level: 3 },
    },
    {
      id: 'link',
      notStandard: true,
      icon: 'link',
    },
  ]

  export default {
    name: 'WysiwygEditor',

    components: {
      vuescroll,
      EditorContent,
      WysiwygMenuButton,
      WysiwygLinkModal,
      DocumentationLinkButton,
    },

    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
      value: {
        type: String,
        default: null,
      },
      required: {
        type: Boolean,
        default: false,
      },
      allowedOptions: {
        type: Array,
        default: () => [],
      },
    },

    data() {
      return {
        editor: {},
        scrollbarOps: {
          bar: {
            showDelay: 100,
            onlyShowBarOnScroll: false,
            keepShow: true,
            background: 'var(--primary)',
            opacity: 1,
            hoverStyle: true,
            specifyBorderRadius: false,
            size: '7px',
            disable: false,
          },
          rail: {
            background: 'var(--app-background-font-color)',
            opacity: 0.4,
            size: '9px',
            specifyBorderRadius: false,
            gutterOfEnds: '2px',
            gutterOfSide: '3px',
            keepShow: false,
          },
        },
        html: null,
        showLinkModal: false,
        linkEdition: false,
        linkText: '',
        linkUrl: '',
      }
    },

    computed: {
      /**
       * Return the list of allowed extensions, allowed by id or group
       * @returns {Array} The list of allowed extensions
       */
      filteredAllowedExtensions() {
        return EXTENSIONS.filter(
          (extension) =>
            (extension.group &&
              this.allowedOptions.includes(extension.group)) ||
            this.allowedOptions.includes(extension.id),
        )
      },

      activeCommands() {
        return this.filteredAllowedExtensions.reduce((acc, extension) => {
          return {
            ...acc,
            [extension.id]:
              extension.group === 'headings'
                ? this.editor.isActive('heading', { level: extension.level })
                : this.editor.isActive(extension.id),
          }
        }, {})
      },

      menuButtons() {
        return this.filteredAllowedExtensions.map((extension) => {
          return {
            ...extension,
            ...(extension.id === 'link'
              ? {
                  tooltip: this.activeCommands['link']
                    ? 'Edit link'
                    : 'Add link',
                  disabled: this.disabled,
                  onClickAction: () => this.handleLink(),
                }
              : {
                  disabled: this.disabled,
                  onClickAction: () =>
                    extension.commandParams
                      ? this.commandToRun(
                          extension.commandName,
                          extension.commandParams,
                        )
                      : this.commandToRun(extension.commandName),
                }),
          }
        })
      },
    },

    watch: {
      html(newHTML) {
        this.$emit('input', newHTML)
      },

      disabled(newValue) {
        this.editor.setEditable(!newValue)
      },

      showLinkModal(newValue) {
        if (!newValue) {
          this.resetModalData()
        }
      },
    },

    created() {
      this.editor = new Editor({
        extensions: this.getEditorExtensions(this.allowedOptions),
        onUpdate: ({ editor }) => {
          clearTimeout(debounce)
          debounce = setTimeout(() => {
            this.html = editor.getHTML()
          }, 200)
        },
        content: this.value,
        editable: !this.disabled,
      })
    },

    beforeDestroy() {
      this.editor.destroy()
    },

    methods: {
      getEditorExtensions(allowedOptions) {
        const headingsLevels = this.filteredAllowedExtensions.reduce(
          (acc, extension) => {
            if (extension.level) {
              acc.push(extension.level)
            }
            return acc
          },
          [],
        )

        const hasList =
          this.filteredAllowedExtensions.filter((extension) =>
            ['bulletList', 'orderedList'].includes(extension.id),
          ).length > 0

        const standardExtensions = this.filteredAllowedExtensions
          .filter((extension) => !extension.notStandard)
          .map((extension) => extension.extension)

        return [
          Document,
          Paragraph,
          Text,
          History,
          ...standardExtensions,
          ...(headingsLevels.length > 0
            ? [Heading.configure({ levels: headingsLevels })]
            : []),
          ...(hasList ? [ListItem] : []),
          ...(allowedOptions.includes('link')
            ? [
                Link.configure({
                  validate: (url) => isValidUrl(url),
                  HTMLAttributes: {
                    class: 'wysiwyg-link',
                  },
                }),
              ]
            : []),
        ]
      },

      commandToRun(commandName, params) {
        switch (true) {
          case !commandName:
            return
          case !params:
            this.editor.chain().focus()[commandName]().run()
            break
          default:
            this.editor.chain().focus()[commandName](params).run()
        }
      },

      getSelectedText() {
        const { view, state } = this.editor
        const { from, to } = view.state.selection
        const text = state.doc.textBetween(from, to, '')
        return text
      },

      replaceSelectedText(newText) {
        const { view } = this.editor
        const { from, to } = view.state.selection
        const range = { from, to }
        this.editor.commands.insertContentAt(range, newText)
        this.editor.commands.setTextSelection({
          from,
          to: from + newText?.length,
        })
      },

      handleLink() {
        this.linkText = this.getSelectedText()
        this.linkUrl = this.editor.getAttributes('link').href ?? ''
        this.linkEdition = this.activeCommands.link
        this.showLinkModal = true
      },

      handleLinkSave(text, url) {
        this.replaceSelectedText(text)
        this.editor.commands.setLink({
          href: url,
          target: '_blank',
        })
        this.resetModalData()
      },

      handleUnlink() {
        this.editor.commands.unsetLink()
        this.editor.commands.unsetMark('link')
        this.resetModalData()
      },

      resetModalData() {
        this.linkText = ''
        this.linkUrl = ''
        this.linkEdition = false
        this.showLinkModal = false
      },
    },
  }
</script>

<style lang="scss">
  .editor {
    &--disabled {
      opacity: 0.7;
    }
  }

  .editor-content_section {
    height: 250px;
    margin: 10px 0 5px;
    border: 1px solid var(--border);
    border-radius: 3px;
  }

  .editor-content {
    cursor: text;
    overflow-y: auto;

    &:focus-within {
      border-color: var(--border-color);
    }

    .ProseMirror[contenteditable='true'] {
      position: absolute;
      padding: 10px;
      width: 100%;
      min-height: 200px;

      &:focus {
        outline: none;
      }
    }
  }
</style>
