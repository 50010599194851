import {
  FireComponent,
  FireComponentField,
  FireComponentFieldEventsSteps,
  FireComponentFieldRepeater,
  FireComponentFieldWithData,
  FireHierarchy,
  FireHierarchySection,
  FireHierarchySubsection,
} from '@/models'
import { replaceStringTokens } from '@/service/UtilsService'

export type FileObject = {
  id: string
  file: File
  path: string
}

/**
 * Return array of file whose don't exceed maxSize
 * @param {{file, id, path}[]} files
 * @param {number} maxSize in bytes (default: 10485760 = 10Mo)
 * @returns {{file, id, path}[][]}
 */
export function getFilesChunks(
  files: FileObject[],
  maxSize: number = 10 * 1024 * 1024,
): FileObject[][] {
  let i = 0

  return files
    .reduce(
      (acc, objFile) => {
        const fileSize = objFile.file.size

        if (fileSize > maxSize) {
          throw new Error('File exceed maxSize : ' + objFile.id)
        }

        if (fileSize + acc[i].size > maxSize) {
          i++
          acc.push({
            files: [objFile],
            size: fileSize,
          })
        } else {
          acc[i].files.push(objFile)
          acc[i].size += fileSize
        }
        return acc
      },
      [{ files: [], size: 0 }],
    )
    .map((chunk) => {
      return chunk.files
    })
}

export const CSV_SEPARATOR = '";"'
export const CSV_LINE_SEPARATOR = '\n'
/**
 * Return csv string from components hierarchy
 * @param {*} components
 * @param {*} hierarchy
 * @param {*} data
 */
export function getCSVFromHierarchy(
  hierarchy: FireHierarchy,
  components: (FireComponent & { id: string })[],
  collectionsData: Record<string, any>,
  includesSwitchOff = false,
  prescriptionSturcture?: { eventId: string; steps: { stepId: string }[] }[],
): string {
  const csv = [
    `"Content${CSV_SEPARATOR}Page${CSV_SEPARATOR}Label${CSV_SEPARATOR}Text"`,
  ]

  for (const section of hierarchy.sections) {
    for (const subSection of section.subsections) {
      const component = components.find(
        (component) => component.id === subSection.id,
      )
      if (
        component &&
        (includesSwitchOff ||
          checkDependencies(component.dependencies, collectionsData))
      )
        getCSVField(
          csv,
          section,
          subSection,
          component,
          collectionsData,
          includesSwitchOff,
          prescriptionSturcture,
        )
    }
  }

  return csv.join(CSV_LINE_SEPARATOR)
}

/**
 * Get CSV field
 * @param {string[]} csv
 * @param {object} section
 * @param {object} subSection
 * @param {object} component
 * @param {object} collectionsData
 * @returns
 */
export function getCSVField(
  csv: string[],
  section: FireHierarchySection,
  subSection: FireHierarchySubsection,
  component: FireComponent | { fields: FireComponentField[] },
  collectionsData: Record<string, any>,
  includesSwitchOff = false,
  prescriptionStructure?: { eventId: string; steps: { stepId: string }[] }[],
  index?: number | string,
) {
  let hideToNextSeparator = undefined
  for (const field of component.fields) {
    const isSeparator = ['separator', 'subseparator'].includes(
      field.settings?.type,
    )
    if (
      !includesSwitchOff &&
      !checkDependencies(
        field.dependencies || field.settings?.dependencies,
        collectionsData,
      )
    ) {
      if (isSeparator) hideToNextSeparator = field.settings?.type

      continue
    } else if (hideToNextSeparator) {
      // if previous hidden field was a subseparator, we need to hide the next field
      if (['separator', hideToNextSeparator].includes(field.settings?.type))
        hideToNextSeparator = false
      else continue
    }

    const fieldWithData = <FireComponentFieldWithData>field

    let pathToGet = collectionsData[fieldWithData.settings.collection]
    if (fieldWithData.settings.hierarchy) {
      fieldWithData.settings.hierarchy.forEach((level) => {
        if (!pathToGet[level]) pathToGet[level] = {}
        pathToGet = pathToGet[level]
      })
    }
    const value = pathToGet?.[field.id] || ''

    if (field.settings.type === 'repeater') {
      for (let i = 1; i <= value; i++) {
        const fieldRepeater: FireComponentFieldRepeater = {
          ...(field as FireComponentFieldRepeater),
          fields: (field as FireComponentFieldRepeater).fields.map(
            (subField) => ({
              ...subField,
              id: replaceStringTokens(subField.id, {
                parentId: field.id,
                keyNumber: i.toString(),
              }),
              ...(Array.isArray(subField.settings.dependencies)
                ? {
                    dependencies: subField.settings.dependencies.map((dep) => ({
                      ...dep,
                      id: replaceStringTokens(dep.id, {
                        parentId: field.id,
                        keyNumber: i.toString(),
                      }),
                    })),
                  }
                : {}),
            }),
          ),
        }
        getCSVField(
          csv,
          section,
          subSection,
          fieldRepeater,
          collectionsData,
          includesSwitchOff,
          prescriptionStructure,
          i,
        )
      }
    } else if (field.settings.type === 'events' && prescriptionStructure) {
      const fieldEvents = field as FireComponentFieldEventsSteps
      prescriptionStructure.map((event) => {
        const fieldEventsFormatted = fieldEvents.eventFields.map(
          (subField) => ({
            ...subField,
            id: replaceStringTokens(subField.id, {
              parentId: field.id,
              eventId: event.eventId,
            }),
            ...(Array.isArray(subField.settings.dependencies)
              ? {
                  dependencies: subField.settings.dependencies.map((dep) => ({
                    ...dep,
                    id: replaceStringTokens(dep.id, {
                      parentId: field.id,
                      eventId: event.eventId,
                    }),
                  })),
                }
              : {}),
          }),
        )

        getCSVField(
          csv,
          section,
          subSection,
          { fields: fieldEventsFormatted },
          collectionsData,
          includesSwitchOff,
          prescriptionStructure,
          event.eventId,
        )

        event.steps.map((step) => {
          const fieldStepFormatted = fieldEvents.stepFields.map((subField) => ({
            ...subField,
            id: replaceStringTokens(subField.id, {
              parentId: field.id,
              eventId: event.eventId,
              stepId: step.stepId,
            }),
            ...(Array.isArray(subField.settings.dependencies)
              ? {
                  dependencies: subField.settings.dependencies.map((dep) => ({
                    ...dep,
                    id: replaceStringTokens(dep.id, {
                      parentId: field.id,
                      eventId: event.eventId,
                      stepId: step.stepId,
                    }),
                  })),
                }
              : {}),
          }))

          getCSVField(
            csv,
            section,
            subSection,
            { fields: fieldStepFormatted },
            collectionsData,
            includesSwitchOff,
            prescriptionStructure,
            event.eventId + ' - ' + step.stepId,
          )
        })
      })
    } else if (fieldWithData.settings.collection === 'appcontent') {
      csv.push(
        '"' +
          section.label +
          CSV_SEPARATOR +
          subSection.label +
          CSV_SEPARATOR +
          field.label +
          (index ? ` {${index}}` : '') +
          CSV_SEPARATOR +
          value +
          '"',
      )
    }
  }
}

export function formatedFileSizeFromKB(sizeInKB: number): string {
  const units = ['KB', 'MB', 'GB', 'TB']
  let size = sizeInKB
  let count = 0

  while (size >= 1024) {
    size = size / 1024
    count++
  }

  if (!Number.isInteger(size)) size = Math.round(size * 100) / 100

  return `${size} ${units[count]}`
}

export function checkDependencies(dependencies, collectionsData): boolean {
  if (!Array.isArray(dependencies)) return true
  return !dependencies.some((dep) => {
    let pathToGet = collectionsData[dep.collection]
    if (dep.hierarchy) {
      dep.hierarchy.forEach((level) => {
        if (!pathToGet[level]) pathToGet[level] = {}
        pathToGet = pathToGet[level]
      })
    }
    return Array.isArray(pathToGet?.[dep.id])
      ? !pathToGet?.[dep.id].includes(dep.value)
      : pathToGet?.[dep.id] !== dep.value
  })
}
